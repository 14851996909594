import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardManager from './DashboardManager'
import { Redirect } from 'react-router-dom'
import DefaultLayout from '../../../layouts/DefaultLayout'

import { Result, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import styled from 'styled-components'

const Chuc = styled.span`
  color:#D43B40;
  font-weight: bolder;
  font-size: 16px;
`

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Dashboard = (props) => {
  const { usersStore } = props
  return (
    <DefaultLayout>
      {/* <DashboardManager />
       */}

      <Result
        icon={<img alt="sample" src={`/assets/fun${randomInteger(1, 5)}.gif`} width={250} />
        }
        title={`Xin chào ${usersStore.currentUser.username}`}
        extra={
          <>
            <h3>Chúc các thành viên <strong>Nhà iu</strong> năm mới
            </h3>
            <h4 style={{ color: '#888' }}>
              <br />
              "<Chuc>Đẹp</Chuc> như hoa hồng.
              <br />
              <Chuc>Thành công</Chuc> như Cúc.
              <br />
              <Chuc>Hạnh phúc</Chuc> như hoa Mai.
              <br />
              <Chuc>Phát tài</Chuc> như hoa Pháo.
              <br />
              <Chuc>Độc đáo</Chuc> như hoa Lan.
              <br />
              <Chuc>An khang</Chuc> như hoa Huệ.
              <br />
              <Chuc>Trí tuệ</Chuc> như hoa Sen"
            </h4>
          </>} />

    </DefaultLayout>
  )
  // if (user.role.type === 'organizer') {
  //   return <DashboardManager />
  // }
  // if (user.role.type === 'booth_owner') {
  //   return <DashboardBoothOwner />
  // }

}
export default inject("usersStore")(observer(Dashboard))