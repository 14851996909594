import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useMutation } from '@apollo/react-hooks'
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import axios from 'axios'
// import Cookies from 'js-cookie'
// import config from '../../../config'

import {
    List,
    Drawer,
    Timeline,
    Button,
    Image,
    message,
    Space,
    Tag,
    Col
} from 'antd';
// import moment from 'moment'
// import MarkdownIt from 'markdown-it';
import {
    CopyOutlined,
} from '@ant-design/icons';

import { date_str } from '../../../ultils/datatableUtils'
import JobListItem from './JobListItem'
import { jobType, jobStatus } from './JobType'
// import ResourceDetail from './ResourceDetail'

import DataTableServices from '../../../services/datatableServices'
import useAsyncQuery from '../../../ultils/useAsyncQuery'
import JobDongHang from './JobDongHang'
import JobNhatHang from './JobNhatHang'
// const mdParser = new MarkdownIt(/* Markdown-it options */);

const JobList = ({
    datatableStore,
    commonStore,
    usersStore,
    rows,
    row_ids = false,
    rdata = false
    // currentResTag = ['all'],
    // currentResType = 'all'
}) => {

    const [showJobDetail, setShowJobDetail] = useState(false)
    // const [jobs, setjobs] = useState(rows || [])

    const findJobQuery = useAsyncQuery(DataTableServices.FIND_JOBS())
    const getjobs = async (dataInput) => {
        const { data, error } = await findJobQuery({
            input: {
                data: dataInput
            }
        })
        let result = data?.findjobs?.data
        if (result.status === 'OK')
            return result.items
        return false
    }

    const [deleteManyJob] = useMutation(
        DataTableServices.DELETE_JOBS(),
        {
            errorPolicy: 'all'
        }
    )


    useEffect(() => {
        if (usersStore.socket) {
            usersStore.socket.on("addOrderJob", (data) => {
                let findJob = datatableStore.currentJobs.find(j => j.id === data?.job?.id)
                if (!findJob) {
                    if (!datatableStore.usersRef[data?.creator?.id]) {
                        let u = {}
                        u[data?.creator?.id] = data.creator
                        datatableStore.setUsersRef(u)
                    }
                    datatableStore.setCurrentJobs([data.job, ...datatableStore.currentJobs])
                }
            })
            usersStore.socket.on("deleteOrderJob", (data) => {
                let ids = data.ids
                if (ids.length === 1) {
                    console.log('ids', ids)
                    // console.log('jobs', jobs)
                    let findJob = datatableStore.currentJobs.find(j => j.id === ids[0])
                    if (findJob) {
                        datatableStore.setCurrentJobs(datatableStore.currentJobs.filter(j => j.id !== ids[0]))
                    }
                }
            })

            usersStore.socket.on("requestSkipJob", (data) => {
                let id = data.id
                if (id) {
                    datatableStore.requestSkipJob(data)
                    // let findJob = datatableStore.currentJobs.find(j => j.id === ids[0])
                    // if (findJob) {
                    //     datatableStore.setCurrentJobs(datatableStore.currentJobs.filter(j => j.id !== ids[0]))
                    // }
                }
            })

        }
        return () => {
            // usersStore.socket.off("addOrderJob")
            // usersStore.socket.off("deleteOrderJob")
        }
    }, [usersStore.socket])

    useEffect(() => {
        async function init() {
            if (row_ids) {
                let res = await getjobs({ row_ids: row_ids })
                datatableStore.setCurrentJobs(res)
                // setjobs(res)
            } else {
                // setjobs(rows)
            }
        }
        init()

    }, [row_ids])

    // useEffect(() => {
    //     if (usersStore.socket) {
    //         usersStore.socket.on("addOrderJob", (data) => {
    //             let findJob = jobs.find(j => j.id === data?.job?.id)
    //             if (!findJob) {
    //                 if (!datatableStore.usersRef[data?.creator?.id]) {
    //                     let u = {}
    //                     u[data?.creator?.id] = data.creator
    //                     datatableStore.setUsersRef(u)
    //                 }
    //                 setjobs([data.job, ...jobs])
    //             }
    //         })
    //         // usersStore.socket.on("deleteOrderJob", (data) => {
    //         //     let ids = data.ids
    //         //     if (ids.length === 1) {
    //         //         console.log('ids', ids)
    //         //         console.log('jobs', jobs)
    //         //         let findJob = jobs.find(j => j.id === ids[0])
    //         //         if (findJob) {
    //         //             setjobs(jobs.filter(j => j.id !== ids[0]))
    //         //         }
    //         //     }
    //         // })
    //     }
    //     return () => {
    //         // usersStore.socket.off("addOrderJob")
    //         // usersStore.socket.off("deleteOrderJob")
    //     }
    // }, [usersStore.socket, jobs])


    const removeJobByID = async (id) => {
        let input = {
            data: {
                ids: [id]
            }
        }
        let r = await deleteManyJob({
            variables: { input }
        })
        let result = r?.data?.deleteManyJob?.data
        console.log('remove result', result)
        if (result.status === 'OK') {
            setShowJobDetail(false)
            // setjobs(jobs.filter(j => j.id !== id))
            datatableStore.setCurrentJobs(datatableStore.currentJobs.filter(j => j.id !== id))
            if (usersStore.socket) {
                console.log('id', id)
                usersStore.socket.emit("deleteOrderJob", { ids: [id] })
            }
        } else {
            alert("Xoa khong thanh cong!")
        }

    }

    // let title = truncate(resource?.name || '', 35)
    return (
        <>
            {/* <List
                className={'list-resource'}
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3
                }}
                dataSource={datatableStore.currentJobs}
                renderItem={item => <JobListItem job={item} onClick={() => {
                    console.log('item', item)
                    setShowJobDetail(item)
                }} />}
            /> */}

            <Timeline>
                {datatableStore.currentJobs.map(item => {
                    let jt = jobType.find(j => j.type === item.type)
                    let jstatus = jobStatus.find(j => j.type === item.status)
                    return (
                        <Timeline.Item color={jstatus.bgcolor}>
                            <Space>
                                <Button size="small"
                                    style={{ backgroundColor: jt.bgcolor, color: "#ffffff", borderRadius: 6 }}
                                >{jt.name}</Button>
                                <Tag style={{ lineHeight: "20px", height: 20, borderRadius: 8 }} title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>

                                {item?.requestSkip &&
                                    <Tag style={{ lineHeight: "20px", height: 20, borderRadius: 8, color:"black" }} color={"Yellow"} >Y/c hoãn</Tag>
                                }
                                {/* <span style={{ color: "#ccc" }}>{date_str(item?.createdAt)}</span> */}
                            </Space>

                            <JobListItem job={item} onClick={() => {
                                // console.log('item', item)
                                setShowJobDetail(item)
                            }} />
                        </Timeline.Item>
                    )
                })}
            </Timeline>

            {/* <Drawer
                width="100%"
                // closable={true}
                style={{ maxWidth: 960 }}
                visible={showResDetail ? true : false}
                title='Chi tiết tài nguyên'
                placement="right"
                onClose={() => {
                    setShowResDetail(false)
                }}
            >
                <ResourceDetail resource={showResDetail} />
            </Drawer> */}

            {showJobDetail &&
                <Drawer
                    width="100%"
                    closable={true}
                    style={{ maxWidth: 960 }}
                    // visible={showResDetail ? true : false}
                    visible={true}
                    title='Chi tiết công việc'
                    placement="right"
                    onClose={() => {
                        setShowJobDetail(false)
                    }}
                >
                    {showJobDetail.type === 'donghang' && <JobDongHang job={showJobDetail} rdata={rdata} onDelete={removeJobByID} />}
                    {showJobDetail.type === 'nhathang' && <JobNhatHang job={showJobDetail} rdata={rdata} />}


                    {/* <ResourceDetail resource={showResDetail} /> */}
                </Drawer>}
        </>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(JobList))