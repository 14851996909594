import React, { useEffect, useRef, useState, useForm } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { toJS } from "mobx"
import { useMutation } from '@apollo/react-hooks'
import config from '../../../../../config'

import {
    Button,
    Form,
    Input,
    Modal,
    Typography,
    Space,
    Select,
    Card,
    Row,
    Col,
    InputNumber
} from 'antd';
import {
    PhoneOutlined,
    UserOutlined,
} from '@ant-design/icons';

import DataSelectModal from '../../DataTools/DataSelectModal'
import DataTableServices from '../../../../../services/datatableServices'
import DatarowSelectInput from '../../DataTools/DatarowSelectInput'
import SearchBoxItem from '../../DataTools/SearchBoxItem'


import AssignUser from '../../DataTools/AssignUser'
import { removeVietnameseCharMark } from '../../../../../ultils/datatableUtils'
// import { set } from 'mobx';
// const { TabPane } = Tabs;
const { Text, Title } = Typography
const { TextArea } = Input;
const { Option } = Select;

const OrderEditInfo = ({
    commonStore,
    onSave,
    onClose,
    orderData
}) => {
    const [form] = Form.useForm();
    const [isSaved, setIsSaved] = useState(true)
    const [outOrder, setOutOrder] = useState(orderData)
    const [adding, setAdding] = useState(false)
    const [currentStatus, setCurrentStatus] = useState(orderData?.status)
    const [paymentStatus, setPaymentStatus] = useState(orderData?.pay_status)
    const [currentTags, setCurrentTags] = useState(orderData?.tags)

    const [currentLuotNhat, setCurrentLuotNhat] = useState(orderData?.luot_nhat)

    useEffect(async () => {
        form.resetFields()
        setIsSaved(true)
    }, [])

    const onFinish = (values) => {
        let out = values
        out.status = {
            color: currentStatus.color,
            name: currentStatus.name,
            ref: currentStatus.key || currentStatus.ref
        }

        if (paymentStatus)
            out.pay_status = {
                color: paymentStatus.color,
                name: paymentStatus.name,
                ref: paymentStatus.key || paymentStatus.ref
            }

        out.tags = currentTags
        if (values.chuyenkhoan < 1000) out.chuyenkhoan = values.chuyenkhoan * 1000
        if (values.giam_gia < 1000) out.giam_gia = values.giam_gia * 1000
        if (values.phiship < 1000) out.phiship = values.phiship * 1000

        if (currentLuotNhat) out.luot_nhat = currentLuotNhat
        else out.luot_nhat = ""
        onSave(out)
        setIsSaved(true)
    }

    return (
        <Modal
            title={false}
            visible={true}
            width={'100%'}
            maskClosable={false}
            onCancel={onClose}
            centered
            // footer={false}
            footer={[
                <Button onClick={onClose} disabled={adding}>
                    Hủy thay đổi
                </Button>,
                <Button key="submit" type="primary"
                    loading={adding}
                    onClick={() => {
                        setAdding(true)
                        form.submit()
                    }}>
                    Cập nhật
                </Button>,
            ]}
        >
            <div className="orderEditForm" style={{ width: "100%" }}>
                {/* <AssignUser /> */}
                <Form
                    layout="vertical"
                    form={form}
                    name="customer-info"
                    onFinish={onFinish}
                    initialValues={{
                        name: orderData?.customer?.name,
                        note: orderData?.note,
                        note_vitri: orderData?.note_vitri,
                        giam_gia: parseInt(orderData?.giam_gia),
                        phiship: orderData?.ship?.ship_fee,
                        chuyenkhoan: orderData?.chuyenkhoan,
                        // pay_status: orderData?.pay_status || {
                        //     "name": "Chưa thanh toán",
                        //     "color": "#949494",
                        //     "ref": "60d953854363d7001415f4af"
                        // }
                        // tags: orderData?.tags
                    }}
                    style={{ width: "100%", background: "#fff" }}
                    onValuesChange={(c, a) => {
                        // console.log(`c`, c)
                        // console.log(`a`, a)
                        if (isSaved) setIsSaved(false)
                    }}
                >

                    <Row gutter={[24, 16]}>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Card size="small"
                                title={<Title level={5}>Thông tin đơn hàng</Title>}
                            >
                                <Form.Item
                                    name="status"
                                    label="Trạng thái"
                                >
                                    <DatarowSelectInput
                                        tableId={commonStore?.company?.companyData?.status_table}
                                        width={100}
                                        emptyLabel='--trạng thái--'
                                        defaultEmpty={{ id: '-', name: '-trạng thái', color: '#ffffff' }}
                                        initFilter={{ match: { "category": 'Trạng thái đơn' } }}
                                        // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                                        // initValue={orderData?.status?.ref ? orderData?.status?.ref : '-'}
                                        initValue={currentStatus?.ref ? currentStatus?.ref : '-'}
                                        onOk={(item) => {
                                            setCurrentStatus(item)
                                            // console.log(`item`, item)
                                        }}
                                    />

                                </Form.Item>

                                {/* <Form.Item
                                    name="pay_status"
                                    label="Tình trạng thanh toán"
                                >
                                    <DatarowSelectInput
                                        tableId={commonStore?.company?.companyData?.status_table}
                                        width={100}
                                        emptyLabel='--thanh toán--'
                                        defaultEmpty={{
                                            id: '-', name: '--thanh toán--', color: '#ffffff'
                                        }}
                                        initFilter={{ match: { "category": 'Thanh toán' } }}
                                        // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                                        // initValue={orderData?.status?.ref ? orderData?.status?.ref : '-'}
                                        initValue={paymentStatus?.ref ? paymentStatus?.ref : '-'}
                                        onOk={(item) => {
                                            setPaymentStatus(item)
                                        }}
                                    />

                                </Form.Item> */}


                                <Form.Item
                                    name="tags"
                                    label="Thẻ gán"
                                >
                                    <DatarowSelectInput
                                        tableId={commonStore?.company?.companyData?.tag_table}
                                        width={'100%'}
                                        viewType={'tag'}
                                        // initFilter={{ match: { "category": 'Đơn hàng' } }}
                                        initFilter={{ match: { "category": 'Đơn hàng', "sub_cate_ne": "Tag vị trí" } }}
                                        // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                                        initValue={currentTags ? currentTags : false}
                                        onOk={(item) => {
                                            setCurrentTags(item)
                                            // let search = false
                                            // if (item && item.length > 0) {
                                            //     search = updateFilterParam(updateFilter, { 'tags': item.join('|') })
                                            // } else {
                                            //     search = updateFilterParam(updateFilter, { 'tags': false })
                                            // }
                                            // history.push({
                                            //     pathname: '/table/' + id,
                                            //     search: search,
                                            // }
                                            // )
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="note"
                                    label="Ghi chú"
                                >
                                    <TextArea rows={4} />
                                </Form.Item>

                                <Form.Item
                                    name="note_vitri"
                                    label="Ghi chú vị trí"
                                >
                                    <Input />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Card size="small"
                                title={<Title level={5}>Thông tin thanh toán</Title>}
                            >
                                <Form.Item
                                    name="chuyenkhoan"
                                    label="Tiền chuyển khoản"
                                >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item
                                    name="giam_gia"
                                    label="Giảm giá"
                                >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item
                                    name="phiship"
                                    label="Phí ship"
                                >
                                    <InputNumber />
                                </Form.Item>

                            </Card>

                            <Card size="small"
                                title={<Title level={5}>Thông tin khác</Title>}
                            >
                                <Form.Item
                                    name="chuyenkhoan"
                                    label="Lượt nhặt"
                                >
                                    <SearchBoxItem
                                        tableId={commonStore?.company?.companyData?.pick_table}
                                        initValue={orderData?.luot_nhat}
                                        filterOnDropdown={true}
                                        placeholder={'Lượt nhặt'}
                                        columns={['name']}
                                        filter={{ _sort: 'ngay_tao:-1' }}
                                        onSelect={(v, o) => {
                                            setCurrentLuotNhat(v)
                                        }}
                                    // onEnter={(v) => {
                                    //     console.log(`v`, v)
                                    //     setCurrentLuotNhat(v)
                                    //     // alert('ec')
                                    // }}
                                    />
                                </Form.Item>
                            </Card>

                        </Col>

                    </Row>

                </Form>
            </div>

        </Modal>
    )
}

export default inject("commonStore")(observer(OrderEditInfo))