import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'

const ButtonWrapper = styled.div`
  & .ant-btn {
    border: 0;
    background-color: ${({ theme, bgColor }) => bgColor && theme.colors[bgColor]};
    font-size: 14px;
    color: ${({ theme, textColor }) => textColor && theme.colors[textColor]};
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: normal;
    & > * {
      color: ${({ theme, textColor }) => textColor && theme.colors[textColor]};
    };
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme, textColor }) => textColor && theme.colors[textColor]};
      background-color: ${({ theme, bgColor }) => bgColor && theme.colors[bgColor]};
    }
  }
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

`

const StyledButton = ({
  children,
  loading,
  bgColor,
  textColor,
  margin,
  padding,
  onClick
}) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      margin={margin}
      padding={padding}
      bgColor={bgColor}
      textColor={textColor}>
      <Button loading={loading}>{children ? children : 'Default'}</Button>
    </ButtonWrapper>
  )
}

export default StyledButton
