import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'

import {
    Image,
    List,
    Button
} from 'antd';
import {
    DeleteOutlined
} from '@ant-design/icons';
import { toJS } from 'mobx'

import { imagePreset, price_k } from '../../../../ultils/datatableUtils'
import QuickListItem from './Item/QuickListItem'
// const { confirm } = Modal;

const ProductList = ({ datatableStore, commonStore, rows, onSave, view, type = 'default' }) => {
    const ProductListItem = ({ product }) => {
        let label = ""
        let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
        let imageSrc = product.image
        if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
        else {
            thumbnail = imagePreset(imageSrc, 'xsmall')
        }
        let cotheban = product.sl_tonkho - product.sl_giuhang
        if (isNaN(cotheban) || cotheban < 0) cotheban = 0
        let slColor = (product.sl_qty === cotheban) ? 'green' : 'blue'
        let ctbColor = cotheban > 0 ? slColor : 'red'
        if (product.sl_qty === 0) slColor = 'red'
        let imgSize = view === 'compact' ? 48 : 60
        return (
            <List.Item>
                <List.Item.Meta
                    onClick={() => {
                    }}
                    avatar={<Image
                        width={imgSize}
                        height={imgSize}
                        src={thumbnail}
                        preview={{
                            src: imageSrc,
                        }}
                    />}
                    title={
                        <a href={'/tool/search_product/' + product.key} target="_blank" title={product?.code + '-' + product?.name} >{product?.code + '-' + product?.name}</a>
                    }
                    description={
                        <>
                            {view === 'default' &&
                                <>
                                    <strong style={{ marginRight: 5 }}>SL kho / giữ hàng:</strong> {product.sl_tonkho} / {product.sl_giuhang}
                                    <br />
                                    <strong style={{ marginRight: 5 }}>CTB / SLine:</strong>
                                    <span style={{ fontWeight: 'bold', color: ctbColor }}> {cotheban}</span>/<span style={{ fontWeight: 'bold', color: slColor }}>{product.sl_qty}</span>
                                    <br />
                                    <strong style={{ marginRight: 5 }}> Giá/Giá live:</strong><span style={{ textDecoration: "line-through" }}>{price_k(product.price, '?', 3)}</span>/
                                    <span style={{ color: 'green' }}> {price_k(product.price_live, '?', 3)}</span>
                                    <br />
                                </>
                            }
                            <Button size={'small'} icon={<DeleteOutlined />}
                                onClick={() => {
                                    rows = rows.filter(r => r.code !== product.code)
                                    onSave(rows)
                                }}>
                                xóa</Button>
                        </>
                    }
                />
            </List.Item>
        )
    }

    return (
        <>
            {rows &&
                <List
                    style={{ padding: 10 }}
                    itemLayout="horizontal"
                    dataSource={rows}
                    renderItem={item => {
                        if (type === 'quickPList')
                            return <QuickListItem
                                view={view}
                                product={toJS(item)}
                                onDelete={(p) => {
                                    rows = rows.filter(r => r.code !== p.code)
                                    onSave(rows)
                                    // confirm({
                                    //     title: 'Bạn có chắc muốn bỏ sản phẩm này ra không?',
                                    //     icon: <ExclamationCircleOutlined />,
                                    //     okText: 'Xóa',
                                    //     okType: 'danger',
                                    //     cancelText: 'Bỏ qua',
                                    //     async onOk() {
                                    //         rows = rows.filter(r => r.code !== p.code)
                                    //         onSave(rows)
                                    //     },
                                    //     onCancel() {
                                    //         console.log('Cancel');
                                    //     },
                                    // });
                                }}
                            />
                        return <ProductListItem product={item} />
                    }
                    }

                // renderItem={item => <ProductListItem product={item} />}
                />
            }
        </>

    )
}

export default inject("datatableStore", "commonStore")(observer(ProductList))