import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import useAsyncQuery from '../../../../../ultils/useAsyncQuery'
import { Tags_render } from '../../DataTools/DataField'
import { inject, observer } from 'mobx-react'
import DataTableServices from '../../../../../services/datatableServices'
import { price_k, getOrderSummary } from '../../../../../ultils/datatableUtils'
import {
    Space,
    Timeline
} from 'antd';
import moment from 'moment'
import {
    CalendarOutlined,
    SmileFilled,
    FileTextOutlined,
    DollarOutlined,
    // CheckCircleOutlined,
    // InfoCircleOutlined
} from '@ant-design/icons';

const CustomerHistories = ({
    preloadTable,
    customer,
}) => {

    const [customerHistories, setCustomerHistories] = useState(false)

    const getCustomerHistories = useAsyncQuery(DataTableServices.GET_CUSTOMER_HISTORIES())
    const findHistories = async (dataInput) => {
        const { data, error } = await getCustomerHistories({
            input: {
                data: dataInput
            }
        })
        let result = data?.getCustomerHistories?.data
        if (result.status === 'OK')
            return result.out

        return false
    }


    const Histories = ({ orderData, style }) => {
        let color = orderData.status?.color ? orderData.status.color : "lightgray"
        // let address = customer?.address
        // if (!address)
        //   address = customer?.ref?.address
        if (!orderData.tong_tien) {
            let sum = getOrderSummary(orderData)
            orderData.tong_tien = sum.price
            orderData.tong_tra = sum.tong_tra
        }

        return (
            <div className='o-compact-render' style={style}>
                {/* <Space> */}
                <SmileFilled style={{ marginRight: 8, color: color }} />
                <span style={{ marginRight: 20, fontWeight: "bold", color: orderData.customer?.ref ? "green" : "#b59324" }}>
                    Mã:  <a href={'/row/' + orderData.key} target="_blank" title={orderData.code}>{orderData.code}</a>
                </span>
                {orderData.ngay_tao ?
                    <span>
                        <CalendarOutlined style={{ marginRight: 4 }} />
                        {moment(orderData?.ngay_tao).format('DD/MM/YY HH:mm')}
                    </span> : ""}

                {orderData.tong_tien ?
                    <span>
                        <br />
                        <DollarOutlined style={{ marginRight: 8, color: color }} />
                        <Space>
                            <span>Tổng đơn hàng: <b>{price_k(orderData.tong_tien, '?', 3)}</b></span>
                            <span>Tổng trả: <b>{price_k(orderData.tong_tra, '?', 3)}</b></span>
                            {orderData?.ship?.cod ?
                                <span
                                    style={{
                                        color: (orderData?.pay_status?.name === 'Đã thanh toán' && orderData.ship.cod > 0) ? "red" : "green"
                                    }}>
                                    /  COD: <strong>{price_k(orderData?.ship?.cod, '?', 3)}</strong>
                                </span> : ""}
                        </Space>

                    </span>
                    : ""
                }
                {orderData.note ?
                    <span style={{ color: "red", fontWeight: "bolder", fontSize: '12pt' }}>
                        <br />
                        <FileTextOutlined style={{ marginRight: 8, color: color }} />{orderData.note}
                    </span>
                    : ""
                }
                <span>
                    <br />
                    {orderData.status?.name ?
                        <Tags_render
                            tags={[orderData.status?.name]}
                            preloadTable={preloadTable.status}
                            extra={{
                                type: 'status'
                            }}
                        />
                        : ""
                    }
                    {orderData.tags ?
                        <Tags_render tags={orderData.tags} preloadTable={preloadTable.tags} />
                        : ""
                    }
                </span>
            </div>
        )
    }


    useEffect(() => {
        async function init() {
            let ch = await findHistories({ customer: customer })
            setCustomerHistories(ch)
        }
        if (customer)
            init()
    }, [customer])

    return (
        <>
            {customerHistories ?
                <>
                    <Timeline>
                        {customerHistories.orders.map(item => {
                            let op = 1
                            let timeColor = '#E1CE09'
                            let bgColor = '#FCF6B6'
                            if (['Đã hoàn thành', 'Hủy đơn', 'Đã bị gộp'].includes(item.status?.name) || item.status?.ref?.name) {
                                op = 0.3
                                timeColor = 'green'
                                bgColor = 'white'
                            }
                            if (['Nhặt hàng', 'Đóng hàng', 'Chờ vận chuyển'].includes(item.status?.name)) {
                                op = 0.6
                                timeColor = 'black'
                                bgColor = 'white'
                            }
                            // if (item.status?.name === 'Nhặt hàng' ) op = 0.7
                            return (
                                // <Timeline.Item color={item.status?.color ? item.status.color : "lightgray"}>
                                <Timeline.Item color={timeColor}>
                                    <Histories style={{ opacity: op, background: bgColor, padding: 5 }} orderData={item} />
                                </Timeline.Item>
                            )
                        }
                        )}
                    </Timeline>
                </> : ""}
        </>
    )
}
export default CustomerHistories