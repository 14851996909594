import React, { useState, useEffect } from 'react'
import MarkdownIt from 'markdown-it'
import Editor, { Plugins } from 'react-markdown-editor-lite'
// import PopupResource from '../custom/Popups/PopupResource'

const BHMedia = props => {
  const [resourceModal, setResourceModal] = useState(null)
  const insertOk = (resource) => {
    if (resource.data[0].src.mime && resource.data[0].src.mime.search('video') !== -1) {
      props.editor.insertText(' <video width="auto" height="auto" autoplay  controls> <source src="' + resource.data[0].src.url + '" type="video/mp4" />Your browser does not support the video tag.</video>')
    } else {
      props.editor.insertMarkdown('image', {
        imageUrl: resource.data[0].src.url
      })
    }
  }

  // const handleClick = () => {
  //   setResourceModal(
  //     <PopupResource
  //       title={{
  //         name: 'Insert Image' //rename title
  //       }}
  //       labelBtn='OK' //rename button add
  //       //fileType='image' // image | video | all
  //       getDataSource={insertOk}
  //       onClose={() => hideModal()}
  //     />
  //   )
  // }
  const hideModal = () => {
    setResourceModal(false)
  }
  return (
    <>
      <span
        className='button button-type-counter'
        title='Counter'
      // onClick={handleClick}
      >
        <i className='rmel-iconfont rmel-icon-image'></i>
      </span>
      {resourceModal}
    </>
  );
}

BHMedia.align = 'left'
BHMedia.pluginName = 'bhmedia'

Editor.unuse(Plugins.Image);

Editor.use(BHMedia, {});

const MarkDown = (props) => {

  const { height, width, textValue, setTextValue, minHeight } = props
  //const [videoText, setVideoText] = useState('')

  // useEffect(() =>{
  //   setTextValue(textValue + videoText)
  // },[videoText])

  const handleEditorChange = ({ html, text }) => {
    setTextValue(text)
  }

  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  })
  return (
    <>
      <Editor
        value={textValue}
        style={{ height: height, width: width, margin: '10px 0', minHeight: minHeight || 400 }}
        renderHTML={(text) =>
          '<h3 style="position:absolute; top: -53px; color:#6b6b6b">Preview</h3>' + mdParser.render(text)
        }
        defaultValue="Preview"
        onChange={(e) => handleEditorChange(e)}
      />

    </>
  )
}

export default MarkDown
