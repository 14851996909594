import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import {
    Button,
    Modal,
} from 'antd';
import {
    PlusOutlined,
    CloseCircleOutlined,
    PrinterOutlined
} from '@ant-design/icons';

import { autorun, toJS } from 'mobx'
import styled, { createGlobalStyle } from 'styled-components'
import { head } from 'lodash';

const PrintableBodyWrapper = styled.div`
 {
    margin: 1cm;
    font-size:10pt;
    table {
        width:85vw;
        font-size:9pt;
        tr {
            border:1px solid #ccc;
            margin:0;
            padding:10px;
            text-align:left;
                td {
                    border-right:1px solid #ccc;
                    padding:5px;
                }
                th {
                    padding: 10px 5px;
                    font-weight:bold;
                }
            }
    }
    .summary {
        font-size:12pt;
        padding: 10px 5px;
    }
  }
`;

class DatatableView extends React.PureComponent {

    render() {
        return (
            <PrintableBodyWrapper>
                {this.props.headerPrint ? this.props.headerPrint : ""}
                <table>
                    <tbody>
                        {
                            this.props.rows.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            this.props.columns.map(col => {
                                                let v = row[col]
                                                if (col === 'image' && index > 0 && v) v = <img width={40} height={40} src={row[col]} />
                                                if (col === 'price_live' && index > 0) {
                                                    let price = v
                                                    if (v) v = parseInt(price)
                                                    if (v > 0) {
                                                        v = (v / 1000).toString() + ' k'
                                                    } else {
                                                        v = ""
                                                    }
                                                }
                                                if (index === 0)
                                                    return (<th key={`${index}-${col}`}>{v}</th>)
                                                return (<td key={`${index}-${col}`}>{v}</td>)
                                            })
                                        }
                                    </tr>
                                )
                            }
                            )
                        }
                    </tbody>
                </table>
            </PrintableBodyWrapper>
        );
    }
}

const PrintLiveProducts = ({
    columns,
    title,
    printData,
    table,
    onClose,
}) => {

    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });



    const rowsToPrint = () => {
        if (!printData.items) return []
        // let rows = printData.items.map(row => {
        //     // let price = row.price_live
        //     // if (price) price = parseInt(price)
        //     // if (price > 0) {
        //     //     price = (price / 1000).toString() + ' k'
        //     // } else {
        //     //     price = ""
        //     // }
        //     // row.price_live = price
        //     return row
        // })
        return [{
            image: 'Ảnh',
            code: 'Mã',
            qty_live: 'SL',
            price_live: 'Giá live',
            name: 'Tên'
        }, ...printData.items]
    }

    const headerPrint = () => {
        return (
            <>
                <strong>Tên chiến dịch LIVE: </strong> {printData.live_name ? printData.live_name : "?"}
                <br />
                <strong>Ngày in: </strong> {moment().format('L')}
                <br />
            </>
        )
    }

    // const getSummary = () => {
    //     let summary = { price: 0, qty: 0 }
    //     if (!printData.items) return summary
    //     printData.items.forEach(row => {
    //         let qty = (row.quanity ? parseInt(row.quanity) : 0)
    //         if (qty > 0 && row.ref) {
    //             summary.qty += qty
    //             summary.price += (row.price ? row.price * qty : 0)
    //         }
    //     });
    //     return summary
    // }

    return (
        <>
            <div style={{ display: 'none' }}>
                <DatatableView
                    ref={printRef}
                    title={'In'}
                    columns={columns}
                    rows={rowsToPrint()}
                    headerPrint={headerPrint()}
                // summary={getSummary()}
                />
            </div>
            <Button shape="round" size={'small'} onClick={handlePrint}
                icon={<PrinterOutlined />}
            >{title}</Button>
        </>
    )
}
export default inject("datatableStore")(observer(PrintLiveProducts))

