import { gql } from 'apollo-boost'

class ConnectionServices {

  LOGIN() {
    return gql`
      mutation Login($input: UsersPermissionsLoginInput!){
        login(input:$input){
          jwt
          user {
            id
            email
            role {
              type
            }
            user_profile {
              fullName
              addressData
              birthday
              phone
              gender
              data
              avatar {
                url
              }
            }
            company_role            
          }
        }
      }
    `
  }

  // FORGOT_PASSWORD() {
  //   return gql`
  //     mutation ForgotPassword($email:String!) {
  //       forgotPassword(email:$email) {
  //         ok
  //       }
  //     }
  //   `
  // }
  // RESET_PASSWORD() {
  //   return gql`
  //     mutation ResetPassword($password:String!, $passwordConfirmation:String!, $code:String!) {
  //       resetPassword(password:$password, passwordConfirmation:$passwordConfirmation, code:$code) {
  //         jwt
  //       }
  //     }
  //   `
  // }
  // CHANGE_PASSWORD() {
  //   return gql`
  //     mutation changePassword($input:ChangePasswordInput!) {
  //       changePassword(input:$input) {
  //         id
  //       }
  //     }
  //   `
  // }
}

export default new ConnectionServices()
