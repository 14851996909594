import React, { useEffect, useState } from 'react'
import useAsyncQuery from '../../../../../ultils/useAsyncQuery'
// import { Tags_render } from '../../DataTools/DataField'
import DataTableServices from '../../../../../services/datatableServices'
// import { price_k } from '../../../../../ultils/datatableUtils'
import { flatMap } from "lodash-es"

import {
    Button,
    Space,
    Timeline,
    Tree,
    Card
} from 'antd';
import moment from 'moment'
import {
    ShoppingOutlined,
    ContactsOutlined,
    IdcardOutlined,
} from '@ant-design/icons';

function myFlat(v) {
    return Object.assign(v.row_data, { key: v.id })
}

const CustomerPanel = ({
    preloadTable,
    row_id,
    table_id,
    rowDetail,
    showEdit
}) => {

    const [customer, setCustomer] = useState(false)
    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, myFlat)
        return items
    }


    // const findRows = useAsyncQuery(DataTableServices.FIND_TABLE_ROW())
    // const findTableRows = async (sl_code) => {
    //     const { data, error } = await findRows({ where: { 'row_data.sl_id': sl_code } })
    //     const results = data ? data.datarowsConnection : ''
    //     const values = results?.values || ''
    //     if (values && values.length > 0) {
    //         return flatMap(values, myFlat)
    //     }
    //     return false
    // }

    // const [rowHistories, setRowHistories] = useState(false)

    // const getRowHistories = useAsyncQuery(DataTableServices.GET_ROW_HISTORIES())
    // const findHistories = async (dataInput) => {
    //     const { data, error } = await getRowHistories({
    //         input: {
    //             data: dataInput
    //         }
    //     })
    //     let result = data?.getRowHistories?.data
    //     if (result.status === 'OK')
    //         return result.out

    //     return false
    // }


    // const Histories = ({ logItem }) => {
    //     // console.log(`logItem`, logItem)
    //     // let address = customer?.address
    //     // if (!address)
    //     //   address = customer?.ref?.address
    //     let treeData = false
    //     if (logItem.hdata && logItem.hdata.type === "merge_order") {
    //         treeData = {
    //             title: "Đơn gộp #" + logItem.hdata.code + " từ:",
    //             key: logItem.hdata.code,
    //             icon: <ShoppingOutlined />,
    //             children: []
    //         }
    //         logItem.hdata.data.forEach(h => {
    //             treeData.children.push({
    //                 title:
    //                     (
    //                         <>
    //                             <a href={'/row/' + h.id} target="_blank" title={h.code}>#{h.code}</a> : <strong style={{ color: "#608EBF" }}>{price_k(h.tong_tien, '?', 3)}</strong> ({h.tong_sl})
    //                         </>
    //                     ),
    //                 key: h.code,
    //                 icon: <SmileFilled />
    //             })
    //         });
    //     }

    //     return (
    //         <div>
    //             {moment(logItem?.ngay_tao).format('DD/MM/YY HH:mm')} - {logItem.user}
    //             {logItem.change ?
    //                 <>
    //                     <br />
    //                     {logItem.change.map(item => {
    //                         if (item.content) {
    //                             return (
    //                                 <>
    //                                     <strong>{item.label}</strong> : {item.content}
    //                                     <br />
    //                                 </>
    //                             )
    //                         }
    //                         return (
    //                             <>
    //                                 <strong>{item.label}</strong> : {item.o} → {item.n}
    //                                 <br />
    //                             </>
    //                         )
    //                     })}
    //                 </>
    //                 : ""}
    //             {logItem.content && !logItem.hdata ?
    //                 <>
    //                     <br />
    //                     {logItem.content}
    //                 </>
    //                 : ""}
    //             {treeData &&
    //                 (<div>
    //                     <Tree
    //                         style={{ backgroundColor: "#DFE6E6" }}
    //                         showIcon
    //                         defaultExpandAll
    //                         selectable={false}

    //                         // defaultSelectedKeys={[logItem.hdata.code]}
    //                         treeData={[treeData]}
    //                     />
    //                 </div>)
    //             }
    //             {logItem.hdata && logItem.hdata.type === "be_merged" && (
    //                 <div
    //                     style={{ backgroundColor: "#E6DFE4", padding: 10 }}
    //                 >
    //                     Gộp vào đơn : <strong><a href={'/row/' + logItem.hdata.to_id} target="_blank" title={logItem.hdata.to_code}>#{logItem.hdata.to_code}</a></strong>
    //                 </div>
    //             )
    //             }
    //         </div>
    //     )
    // }


    useEffect(() => {
        async function init() {
            let rows = await findRowsByFilter({
                tableId: table_id,
                hasPreSearch: false,
                limit: 100,
                filter: { match: { "sl_id": rowDetail.customer?.sl_code } }
            })
            if (rows.length > 0)
                setCustomer(rows[0])
        }
        if (row_id)
            init()
    }, [row_id])

    return (
        <>
            <Card size="small"
                title={<div><IdcardOutlined style={{ marginRight: 10 }} />Khách hàng</div>}
                extra={<Button size={'small'} onClick={showEdit}>
                    Sửa</Button>}>
                {customer ?
                    <>
                        <Button icon={<ContactsOutlined />} type={'primary'}>{customer?.name}</Button>
                        <span style={{ marginLeft: 10 }}>
                            / <a href={`https://admin.shoplineapp.com/admin/nhaiuvn797/users/${customer.sl_id}`} target="_blank">link Shopline</a>
                        </span>
                    </>
                    :
                    <>
                        <p>
                            <b>Tên khách: </b>{rowDetail.customer?.name}
                            {/* {orderData?.row_data?.customer?.sl_code ?
                                        <span style={{ marginLeft: 10 }}>
                                            / <a href={`https://admin.shoplineapp.com/admin/nhaiuvn797/users/${rowDetail.customer.sl_code}`} target="_blank">link Shopline</a>
                                        </span>
                                        :
                                        ""} */}
                        </p>
                    </>
                }

                {/* {rowDetail.customer?.sl_user ? <p style={{ color: "green" }}><b>Shoplive user: </b>{rowDetail.customer.sl_user}                                  {orderData?.row_data?.customer?.sl_code ?
                    <span style={{ marginLeft: 10 }}>
                        / <a href={`https://admin.shoplineapp.com/admin/nhaiuvn797/users/${rowDetail.customer.sl_code}`} target="_blank">link Shopline</a>
                    </span>
                    :
                    ""}</p> : ""} */}

                <p><b>Điện thoại: </b>{rowDetail.customer?.mobile}</p>
                <p><b>Địa chỉ: </b>{rowDetail.customer?.address}</p>
                <p><b>Thành phố/tỉnh: </b>{rowDetail?.province?.ProvinceName || rowDetail?.ship?.sl_province}</p>
                <p><b>Quận/huyện: </b>{rowDetail?.district?.DistrictName || rowDetail?.ship?.sl_district}</p>
                <p><b>Xã/phường: </b>{rowDetail?.ward?.WardName || rowDetail?.ship?.sl_ward}</p>
                <br />
                {rowDetail?.branch &&
                    <p><b>Chi nhánh gửi :</b>
                        {rowDetail.branch}
                    </p>
                }
            </Card>
        </>
    )
}
export default CustomerPanel