import { inject, observer } from 'mobx-react'
import React, { useEffect, useState, useRef } from 'react'
import {
    Button,
    Modal,
    Input
} from 'antd';

const { TextArea } = Input;


const NoteModal = ({
    datatableStore,
    commonStore,
    usersStore,
    title,
    initValue = "",
    okLabel = "Lưu",
    onSave,
    onClose
}) => {
    // console.log(`row`, row)
    const [loading, setLoading] = useState(false)
    const noteText = useRef(null)

    useEffect(() => {
        if (noteText.current) noteText.current.focus()
    }, [noteText])

    return (
        <Modal
            title={title}
            visible={true}
            maskClosable={false}
            onCancel={onClose}
            centered
            footer={[
                <Button size={'small'} type="primary" style={{ marginRight: 8 }}
                    onClick={async () => {
                        if (noteText.current?.resizableTextArea?.props?.value !== undefined) {
                            let note = noteText.current?.resizableTextArea?.props?.value
                            onSave(note)
                        }
                    }}
                >{okLabel}</Button>
            ]}
        >
            <TextArea defaultValue={initValue} ref={noteText} showCount style={{ height: 150 }} />
            {/* <Button loading={loading} type="primary" danger icon={<DeleteOutlined />} size={'small'}
                onClick={async () => {
                    if (row) {
                        let order = toJS(datatableStore.rowDetail)
                        let rIndex = order.items.findIndex(item => item.code == row.code)
                        let newItem = toJS(row)
                        newItem.note = ""
                        if (newItem && rIndex > -1) {
                            setLoading(true)
                            let r = await datatableStore.updateOrderItem(newItem, orderId, updateManyDataRows)
                            if (r) {
                                order.items[rIndex] = newItem
                                datatableStore.setRowDetail(order)
                            }
                            setLoading(false)
                        }
                    }
                    onClose()
                }}
            /> */}

        </Modal>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(NoteModal))
