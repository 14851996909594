import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
// import Breadcrumb from '../../../../custom/BreadCrumb'
import { HeadWrapper } from '../../../../styles'
import { StyledHeading, StyledLoadingOverlay } from '../../../../common'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { PieChart, Pie, ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
import { Row, Col, DatePicker, Button } from 'antd'
import moment from 'moment'
import { DownloadOutlined } from '@ant-design/icons'
import { ExportToCsv } from 'export-to-csv'



const MainReport = styled.div`
  box-shadow: 4px 4px 20px 0 rgba(0,0,0,.01);
  padding: 15px;
  background: ${({ theme }) => theme.colors.white};
`
const Heading = styled.div`
  font-size: 18px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  display:flex;
  justify-content: space-between
`
const ReportContentWrapper = styled.div`
  display:flex;
  justify-content: space-around;
  align-items:center
`
const DetailContainer = styled.div``

const DetailItem = styled.div`

`
const DetailItemName = styled.div`
  font-size: 17px;
`

const DetailItemValue = styled.div`
font-size: 23px;

  span {
    font-weight: 500;
  }
`
const DashboardManager = ({ usersStore }) => {
  // const [startTime, setStartTime] = useState(moment(Date.now()).startOf('day'))
  // const [csvLoading, setCsvLoading] = useState(false)

  // const { loading: userSummaryLoading, data: userSummaryData } = useQuery(
  //   UserServices.GET_USER_SUMMARY(),
  //   {
  //     fetchPolicy: 'network-only'
  //   }
  // )
  // const { loading: userLoginLoading, data: userLoginData } = useQuery(
  //   CollectorServices.GET_COLLECTOR_SUMMARY(),
  //   {
  //     variables: {
  //       user: true
  //     },
  //     fetchPolicy: 'network-only'
  //   }
  // )


  // if (userSummaryLoading || userLoginLoading) return <StyledLoadingOverlay />

  // let userSummary = userSummaryData?.getUserSummary?.data
  // let dataUserSummary = [
  //   {
  //     name: 'Total',
  //     value: 100,
  //     originValue: userSummary?.totalUser,
  //     fill: 'black'
  //   },
  //   {
  //     name: 'Registered',
  //     value: Math.round((userSummary?.totalRegister || 0) / (userSummary?.totalUser || 1) * 100),
  //     originValue: userSummary?.totalRegister,
  //     fill: '#3F4093'
  //   }, {
  //     name: 'Created by Organizer',
  //     value: Math.round((userSummary?.totalCreated || 0) / (userSummary?.totalUser || 1) * 100),
  //     originValue: userSummary?.totalCreated,
  //     fill: '#bb1176'
  //   },
  // ]


  // const onChangeDate = (date, dateString) => {
  //   setStartTime(date ? moment(date).startOf('day') : null)
  // }

  return (
    <div>
      <HeadWrapper justify='space-between' align='center' mg='20px 0'>
        <StyledHeading
          name='Dashboard' />
      </HeadWrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xxl={12} >
          <MainReport>
            <Heading style={{ marginBottom: 20 }}>
              <div>Total User</div>
              {/* <Button loading={registeredLoading} onClick={() => exportRegisterCSV()}><DownloadOutlined /> Export Registered Users</Button> */}
            </Heading>
            <ReportContentWrapper>
              {/* <ResponsiveContainer width={225} height={225}>
                zzz
              </ResponsiveContainer> */}
              <DetailContainer>
                dfs
              </DetailContainer>
            </ReportContentWrapper>
          </MainReport>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xxl={12} >
          sgfgsdg
        </Col>
      </Row>
      {/* </Breadcrumb> */}
    </div>
  )
  // let times = graphData?.findCollectorDataByTime?.data || []
  // if(times && times.length > 0){
  //   times = times.map(v => {
  //     return {
  //       name: moment(v.name).format('HH:mm'),
  //       visits: v.visits
  //     }
  //   })
  // }
  return (
    <>
      {/* <Breadcrumb> */}
      <HeadWrapper justify='space-between' align='center' mg='20px 0'>
        <StyledHeading
          name='Dashboard' />
      </HeadWrapper>

      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xxl={12} >
          <MainReport >
            <Heading style={{ marginBottom: 20 }}>
              <div>Total User</div>
              {/* <Button loading={registeredLoading} onClick={() => exportRegisterCSV()}><DownloadOutlined /> Export Registered Users</Button> */}
            </Heading>
            <ReportContentWrapper>
              <ResponsiveContainer width={225} height={225}>
                zzz
              </ResponsiveContainer>
              <DetailContainer>
                dfs
              </DetailContainer>
            </ReportContentWrapper>
          </MainReport>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xxl={12} >
          <MainReport>
            <Heading style={{ marginBottom: 20 }}>
              <span>Total Visits</span>
            </Heading>
            <ReportContentWrapper>
              <ResponsiveContainer width={225} height={225}>
              </ResponsiveContainer>
              <DetailContainer>
                asdf
              </DetailContainer>
            </ReportContentWrapper>
          </MainReport>
        </Col>
      </Row>
      {/* </Breadcrumb> */}
    </>
  )
}

export default inject("usersStore")(observer(DashboardManager));