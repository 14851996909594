import React from 'react'
import styled from 'styled-components'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons'

const TriggerWrapper = styled.span`
  font-size: 18px;
  line-height: 40px;
  padding: 0 15px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${ ({ theme }) => theme.colors.blueLight };
  }
`
const TriggerButton = prop => {
  const { toggle, collapsed } = prop
  return (
    <TriggerWrapper onClick={() => toggle()}>
      { collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined /> }
    </TriggerWrapper>
  )
}

export default TriggerButton
