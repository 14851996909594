import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Dropdown,
  Menu,
  Space,
  Table,
  Input,
  Avatar,
  Button,
  Row,
  Col,
  AutoComplete,
  Empty,
  Drawer,
  Switch,
  message
} from 'antd';
import {
  SyncOutlined,
  DeleteOutlined,
  DownOutlined,
  PhoneOutlined,
  ShoppingOutlined,
  BarChartOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { nanoid } from 'nanoid'
import moment from 'moment'
import { flatMap, merge as LMerge, unionBy } from "lodash-es"
import { autorun, toJS } from 'mobx'
import { Prompt } from 'react-router'

import DefaultLayout from '../../../layouts/DefaultLayout'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";

import utils from "../../../../utils";
import { removeVietnameseCharMark, build_search_by_column } from '../../../../ultils/datatableUtils'
import LiveDetailPanel from './LiveDetailPanel'
import LiveOverview from './LiveOverview'
import CorrectProductPanel from './CorrectProductPanel'
// import DatatablePrinter from '../DataTools/DatatablePrinter'
import DatarowSelectInput from '../DataTools/DatarowSelectInput'
import PerfectScrollbar from 'react-perfect-scrollbar'

const TopBarContainer = styled.div`
  margin:10px 0;    
  display:flex;
  justify-content: space-between;
`
const MessageContainer = styled.div`    
  padding:3px;
  margin:1px;
  display:flex;
  background:${({ hasCodeSelect }) => hasCodeSelect ? '#FFE899' : '#f4f4f4'};
  line-height:14px;
  border-radius: 4px;
`
const Message = styled.div`
  font-family: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
  font-weight: ${({ hasCode }) => hasCode ? 'bold' : 'normal'};      
  color: '#000';
  font-size: 11px;    
  overflow: hidden;
  width: calc(100% - 50px);
  flex-grow: 4;
`
const TimeStyle = styled.div`
  font-weight: ${({ posTime }) => posTime ? 'bold' : 'normal'};
  font-size: 6pt;
  font-color: #777;
  width: 80px;
  flex-grow: 1;    
  background-color: #f7f5f1;
  border-radius: 4px;
  text-align: center;
`

const DataTableLive = ({ usersStore, commonStore, datatableStore, liveStore }) => {
  const [columns, setColumns] = useState(false)
  const [tdata, setTdata] = useState([])
  // const [aggregate, setAggregate] = useState('')
  const { id } = useParams()
  // const { sid } = useParams()
  const [currentDT, setCurrentDT] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([])
  const [resultSearch, setResultSearch] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [showOverview, setShowOverview] = useState(false)
  const [showCorrectProduct, setShowCorrectProduct] = useState(false)
  const searchRef = useRef(null)
  const maxOrderQty = 20


  const [statusFilter, setStatusFilter] = useState('-')

  // function sort_max_time(a, b) {
  //   let dateA = new Date(a.maxTime)
  //   let dateB = new Date(b.maxTime)
  //   return dateB - dateA
  // }

  useEffect(() => {
    if (statusFilter != '-') {
      // console.log(`statusFilter`, statusFilter)
      datatableStore.setCurrentDatarows(filterByStatus(tdata, statusFilter))
    }
  }, [statusFilter])

  function sort_time(a, b) {
    let dateA = new Date(a.time)
    let dateB = new Date(b.time)
    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
  }

  function sort_order_quanity(a, b) {
    return b.suggestion.length - a.suggestion.length
  }

  function sort_code(a, b) {
    let numA = a.code.match(/\d+/)
    let numB = b.code.match(/\d+/)
    if (numA.length > 0) numA = parseInt(numA[0])
    else numA = 0
    if (numB.length > 0) numB = parseInt(numB[0])
    else numB = 0

    return numA - numB
    // if (numA < numB) return -1
    // if (numA > numB) return 1
    // // if (a.code > b.code) return 1
    // return 0
  }

  function sort_by_code_search(a, b) {
    if (!liveStore.currentSearchCode) return 0
    let codeLive = liveStore.liveProducts.find(lp => lp.code.toLowerCase() == liveStore.currentSearchCode.toLowerCase())
    if (!codeLive || !codeLive?.customers) return 0
    let indexA = codeLive.customers.findIndex(item => item.sid === a.sid)
    let indexB = codeLive.customers.findIndex(item => item.sid === b.sid)
    return indexA - indexB
  }

  const [updateManyDataRows] = useMutation(
    DataTableServices.UPDATE_MANY_ROWS(),
    {
      errorPolicy: 'all'
    }
  )

  const [updateLiveProducts] = useMutation(
    DataTableServices.UPDATE_LIVE_PRODUCTS(),
    {
      errorPolicy: 'all'
    }
  )

  const [fillDataToTable] = useMutation(DataTableServices.FILL_TO_TABLE(), {
    errorPolicy: 'all',
  })
  const [deleteManyRows] = useMutation(DataTableServices.DELETE_MANY_ROWS(), {
    errorPolicy: 'all',
  })
  const [getProductByCodes] = useMutation(DataTableServices.GET_PRODUCTS_BY_CODES(), {
    errorPolicy: 'all',
  })

  const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
  const findRowsByFilter = async (dataInput) => {
    const { data, error } = await findRowsQuery({
      input: {
        data: dataInput
      }
    })
    let results = data?.findDataRow?.data
    if (!results?.items) return false
    var items = flatMap(results.items, myFlat)
    return items

  }

  // const findRows = useAsyncQuery(DataTableServices.FIND_TABLE_ROW())
  const findTableRows = async () => {
    commonStore.setInitializing(true)
    let dataSource = await findRowsByFilter({
      limit: 5000,
      tableId: id,
      hasPreSearch: false
    })
    if (dataSource) {
      await getHighlightCode(dataSource)
      dataSource.sort(sort_order_quanity)
      dataSource.forEach(ds => {
        ds.name_novn = ''
        if (ds.name) {
          ds.name_novn = removeVietnameseCharMark(ds.name)
          ds.name_novn = ds.name_novn.toLowerCase()
        }
      });
      setTdata(dataSource)
      // setAggregate(results?.aggregate || '')
      datatableStore.setCurrentDatarows(dataSource)
    } else {
      datatableStore.setCurrentDatarows([])
      liveStore.setCustomerRecord(false)
    }
    commonStore.setInitializing(false)
  }
  const prepareData = async () => {

    let liveProducts = await findRowsByFilter(
      {
        columns: ['live_id'],
        // tableId: commonStore?.company?.companyData?.order_table,
        tableId: '60b583bf198cc20014a6ecb6', // SUA LAI SAU
        hasPreSearch: false,
        matchValue: id,
        limit: 500
      }
    )

    if (liveProducts) liveStore.setLiveProducts(liveProducts)

    let orderProducts = await findRowsByFilter(
      {
        columns: ['live_id'],
        // tableId: commonStore?.company?.companyData?.order_table,
        tableId: '60100cf36de57c3f4804124d', // SUA LAI SAU , id bang order
        hasPreSearch: false,
        matchValue: id,
        limit: 500
      }
    )
    if (orderProducts) liveStore.liveOrders = orderProducts
    let statusData = await findRowsByFilter(
      {
        // tableId: commonStore?.company?.companyData?.order_table,
        tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
        hasPreSearch: false,
        limit: 100
      }
    )
    datatableStore.setPreloadTable('status', statusData)
    // console.log(`statusData`, statusData)

  }

  function autoLookCode(text, sid, time, name, mpos, allSuggestions) {
    let txt = removeVietnameseCharMark(text)
    // console.log(`txt`, txt)
    // var look = /([[:<:]][a-zA-Z]+\s{0,1}-{0,1}\d{1,5})\s{0,1}(\/{0,1})(\d+){0,1}/.exec(msg)
    // var regex1 = new RegExp(/([a-z]+\s*[-|.]*\d{1,5})\s{0,1}\/{0,1}(\d+){0,1}/gi);
    // const codeRegex = new RegExp(/([a-z]+\s*[-|.]*\d{1,5})\s{1,3}\/{0,1}(\d+){0,1}/gi);
    // const codeRegex = new RegExp(/([a-z]+\s*[-|.]*\d{1,5})(\s{0,1}\/(\d{1,2})[\s|\/])*/gi);
    // let re = new RegExp(`\\b${replaceThis}\\b`, 'gi');
    var reTex = "\\s*[^a-z0-9đĐưứựôọàâáéèẹíìị]([\\" + liveStore.codePrefix + "]{1,3}\\s*[-|.]*\\d{1,6}\\b)"
    const codeRegex = new RegExp(reTex, "gi");

    let codeOut = []
    let quanityOut = []
    let arr

    const quanityRegex = new RegExp(/(\d{1,2}|(mot|hai|ba|bon|nam|sau|bay|tam|chin|muoi|một|bốn|năm|sáu|bảy|tám|chín|mười)\s{0,1}?(muoi|mươi|mot|một|mốt|hai|tư|bốn|bon|năm|nam|lam|lăm|sau|sáu|bảy|bay|tam|tám|chín|chin){0,1}?)\s{0,1}(mau|màu|cai|cái|chiếc|chiec|coc|cốc|cóc|bát|bat|bo|bộ|dia|đĩa|to|tô|hu|hũ|hủ|set|sét|doi|đôi|con|binh|bình|c|cb)\b/gi);
    const quanityPlus = new RegExp(/[+|*|/|x|×|X]\s{0,1}(\d{1,2})\b/gi)

    while ((arr = codeRegex.exec(" " + txt)) !== null) {
      if (arr[1]) {
        // let code = arr[1].replace(/\.|\-/,'').toLowerCase();
        // let code = arr[1].replace(/\s+|\.+|\-+/, '').toLowerCase();
        let code = arr[1].replace(/\s+|\.+|\-+/, '').toUpperCase()
        if (code?.length < 6) {
          // if (code && !arr[3]) arr[3] = 1;
          let cIndex = codeOut.findIndex((item) => item.code === code);
          if (cIndex < 0) {
            let c = {
              code: code,
              time: time,
              // mpos: mpos,
              quanity: 1, //sl tu dong nhan
              qty: 1 // sl dieu chinh
            }
            if (!sid)
              c['match'] = arr[1]
            codeOut.push(c)
          }
        }
        // console.log(`codeOut`, codeOut)
      }
    }
    let newTxt = (" " + text).replace(codeRegex, "")
    // if (hasz) {
    //   console.log(`reTex`, reTex)
    //   console.log(`text :`, text)
    //   console.log(`newTxt :`, newTxt)
    //   console.log("--")
    // }
    while ((arr = quanityRegex.exec(newTxt)) !== null) {
      if (arr[1]) {
        let qty = utils.string2number(arr[1])
        if (qty < maxOrderQty) {
          quanityOut.push(
            {
              match: arr[0],
              quanity: qty,
              unit: arr[4],
              index: arr.index
            }
          );
        }
      }
    }

    if (quanityOut.length === 0) {
      while ((arr = quanityPlus.exec(newTxt)) !== null) {
        if (arr[1]) {
          let qnty = parseInt(arr[1])
          if (qnty < maxOrderQty) {
            quanityOut.push(
              {
                match: arr[0],
                quanity: qnty,
                // unit: 'c',
                index: arr.index
              }
            );
          }
        }
      }
    }
    // if (quanityOut.length > 0 && codeOut.length > 0) {
    //   console.log(`txt`, txt)
    //   console.log(`quanityOut`, quanityOut)
    //   console.log(`codeOut`, codeOut)
    // }
    let defaultQuanity = (quanityOut.length > 0) ? quanityOut[0].quanity : 1
    codeOut.forEach(item => {
      item.quanity = parseInt(defaultQuanity)
    });
    // if (datatableStore.tmpCodeRegex) {
    //   if (datatableStore.tmpCodeRegex) {
    //     datatableStore.tmpCodeRegex.forEach(tCode => {
    //       let cIndex = codeOut.findIndex((item) => item.code === tCode.code);
    //       if (cIndex < 0) {
    //         let c = Object.assign({
    //           time: time,
    //           mpos: mpos,
    //           quanity: defaultQuanity
    //         },
    //           tCode
    //         )
    //         codeOut.push(c)
    //       } else {
    //         codeOut[cIndex] = Object.assign(
    //           codeOut[cIndex],
    //           tCode
    //         )
    //       }
    //     });
    //   }
    // }
    if (sid) {
      codeOut.forEach(({ code, quanity, time }) => {
        let sgIndex = allSuggestions.findIndex((item) => item.code === code)
        if (sgIndex < 0) {
          allSuggestions.push({
            code: code,
            key: nanoid(10),
            customers: [],
            qty_order: 0,
            fTime: time // first time
          })
          sgIndex = allSuggestions.length - 1
        } else {
          if (allSuggestions[sgIndex].fTime) {
            if (new Date(time) < new Date(allSuggestions[sgIndex].fTime)) allSuggestions[sgIndex].fTime = time
          }
          if (!allSuggestions[sgIndex].customers) allSuggestions[sgIndex].customers = []
        }
        const sidMatchIndex = allSuggestions[sgIndex].customers.findIndex((item) => item.sid === sid);
        if (sidMatchIndex < 0) {
          allSuggestions[sgIndex].customers.push(
            {
              sid: sid,
              quanity: quanity,
              qty: quanity,
              time: time,
              name: name
            }
          )
        }
        else { //update time
          if (new Date(time) < new Date(allSuggestions[sgIndex].customers[sidMatchIndex].time)) {
            allSuggestions[sgIndex].customers[sidMatchIndex].time = time
            if (allSuggestions[sgIndex].customers[sidMatchIndex].quanity < quanity)
              allSuggestions[sgIndex].customers[sidMatchIndex].quanity = quanity
          }
        }
        allSuggestions[sgIndex].customers = allSuggestions[sgIndex].customers.sort(sort_time)
      });
    }

    const mobileRegex = new RegExp(/(\+\d{1,3}[- ]?)?\d{10,11}/)
    arr = mobileRegex.exec(txt)
    let mobile = false
    if (arr != null && arr[0]) mobile = arr[0]
    let out = {}
    if (codeOut.length > 0) out.c = codeOut
    if (quanityOut.length > 0) {
      out.q = quanityOut
      if (codeOut.length > 0) codeOut[0] = Object.assign(codeOut[0], {
        quanity: quanityOut[0].quanity,
        unit: quanityOut[0].unit
      })
    }
    if (mobile) out.m = mobile
    if (Object.keys(out).length === 0) return false
    // if (!codeOut && !quanityOut) return false
    return out
  }

  function mergeLiveProductDB(allSuggestions) {
    allSuggestions.forEach(codeData => {
      let lpIndex = liveStore.liveProducts.findIndex(lp => lp.code.toUpperCase().trim() === codeData.code)
      if (lpIndex > -1) {
        // tim duoc sp trong db
        if (!liveStore.liveProducts[lpIndex].qty_order) liveStore.liveProducts[lpIndex].qty_order = codeData.qty_order
        if (!liveStore.liveProducts[lpIndex].fTime) liveStore.liveProducts[lpIndex].fTime = codeData.fTime

        if (!liveStore.liveProducts[lpIndex].customers || liveStore.liveProducts[lpIndex].customers.length < 1) {
          liveStore.liveProducts[lpIndex].customers = codeData.customers
          return
        }
        if (liveStore.liveProducts[lpIndex].customers && codeData.customers) {
          codeData.customers.forEach(c => {
            let lpsIndex = liveStore.liveProducts[lpIndex].customers.findIndex(lpc => lpc.sid === c.sid)
            if (lpsIndex < 0) {
              liveStore.liveProducts[lpIndex].customers.push(c)
            } else {
              if (liveStore.liveProducts[lpIndex].customers[lpsIndex].quanity != c.quanity)
                liveStore.liveProducts[lpIndex].customers[lpsIndex].quanity = c.quanity
            }
          });
        }
      } else {
        liveStore.liveProducts.push({ ...codeData, edit_count: 0 })
      }
    });
  }

  function calcOrderQty(allSuggestions) {
    allSuggestions.forEach(s => {
      let qty_order = 0
      if (s.customers) {
        s.customers.forEach(c => {
          qty_order += (c.quanity ? c.quanity : 0)
        });
      }
      s.qty_order = qty_order
    });
  }

  async function getHighlightCode(ds) {
    // let allSuggestions = liveStore.liveProducts ? toJS(liveStore.liveProducts.filter(item => item.code)) : []
    // let liveProductFromDB = 
    await prepareData()
    // console.log(`liveProduct`, liveProduct)
    let allSuggestions = []
    ds.forEach(r => {
      let mobile = false
      r.suggestion = []
      r.msg.forEach((m, index) => {
        if (m[0]) {
          var acode = autoLookCode(m[0], r.sid, m[1], r.name, index, allSuggestions)
          if (!mobile && acode.m) mobile = acode.m
          if (acode.c) {
            acode.c.forEach(a => {
              let cIndex = r.suggestion.findIndex((item) => item.code === a.code);
              if (cIndex >= 0) {
                if (new Date(a.time) < new Date(r.suggestion[cIndex].time)) {
                  r.suggestion[cIndex] = a
                }
              } else
                r.suggestion.push(a)
            });
          }
        }
      });
      if (mobile) r.m = mobile
      // let order = liveStore.liveOrders.find(lo => lo.customer.lsid === r.sid)
      // if (!order)
      //   liveStore.setCustomerOrders(r.sid, r.suggestion, {
      //     name: r.name,
      //     mobile: mobile,
      //     lsid: r.sid
      //   })
    });
    allSuggestions.sort(sort_code)
    calcOrderQty(allSuggestions)

    // init du lieu order
    ds.forEach(r => {
      let order = liveStore.liveOrders.find(lo => lo.customer.lsid === r.sid)
      //neu chua co trong csdl
      if (!order) {
        r.suggestion.forEach(s => {
          let lp = liveStore.liveProducts.find(item => item.code === s.code)
          if (lp) {
            s.price = lp.price_live
            s.name = lp.name
            s.image = lp.image
            if (lp.match_code) s.match_code = lp.match_code
          }
        });
        liveStore.setCustomerOrders(r.sid, r.suggestion, {
          name: r.name,
          mobile: r.m,
          lsid: r.sid
        })
      }
    });
    // console.log(`liveStore.liveOrders`, toJS(liveStore.liveOrders))
    // console.log(`allSuggestions`, allSuggestions)
    // console.log(`liveStore.liveProducts`, toJS(liveStore.liveProducts))
    if (liveStore.liveProducts?.length < 1) {
      liveStore.setLiveProducts(allSuggestions)
      // console.log(`allSuggestions`, allSuggestions)
      // liveStore.autoSelectValid()
      liveStore.reCalcAllCode()
    } else {
      mergeLiveProductDB(allSuggestions)
      // console.log(`liveStore.liveProducts`, toJS(liveStore.liveProducts))
      liveStore.liveProducts.sort(sort_code)
      liveStore.reCalcAllCode()
      // liveStore.autoSelectValid()
    }
    // console.log(`liveStore.liveProducts`, toJS(liveStore.liveProducts))
    // console.log(`liveStore.liveOrders`, toJS(liveStore.liveOrders))
    // console.log(`liveStore.liveOrders`, toJS(liveStore.liveOrders))

  }

  // console.log(`commonStore?.company?.companyData?.order_table`, commonStore?.company?.companyData?.order_table)
  // const getTableById = useAsyncQuery(DataTableServices.GET_TABLE(commonStore?.company?.companyData?.order_table))
  // useEffect(async () => {
  //   let orderTable = await getTableById()
  //   liveStore.orderTable = orderTable.data
  //   console.log(`orderTable`, orderTable)
  // }, [])

  useEffect(async () => {
    if (id) {
      commonStore.setCurrentPage(id)
    }
    setColumns(false)
    setCurrentDT(false)
    findTableRows()
    liveStore.setCurrentSearchCode(false)
    liveStore.setCustomerRecord(false)
    liveStore.setSomethingUnsave(false)
    return () => {
      setColumns(false)
      setCurrentDT(false)
      // datatableStore.resetTableData()
      // liveStore.setCustomerRecord(false)
      // liveStore.setCurrentSearchCode(false)
    }
  }, [id])

  const onSyncClick = async () => {
    if (!currentDT) return
    const input = {
      data: currentDT
    }
    let rdata = await fillDataToTable({
      variables: { input }
    })
    findTableRows()
    // console.log('rdata', rdata)
    // datatableStore.setCurrentDatarows(ret)
  }

  function makeSuggestHighlight(msg, acode) {
    if (acode.c) {
      acode.c.forEach(codeItem => {

        const indexLive = liveStore.liveProducts.findIndex((item) => (item.code === codeItem.code || (item.match_code === codeItem.code)))

        // msg = msg.replaceAll(acode.c[code].match, `<mark class="code">${acode.c[code].match}</mark>`);
        if (indexLive >= 0) {
          // msg = msg.replaceAll(codeItem.match, `<span style="background-color:${myColors[indexLive % 10]}">${codeItem.match}</span>`);
          let bgColor = '#DCC7C3';
          if (liveStore.liveProducts[indexLive].ref) {
            bgColor = "#94B380"
          } else {
            if (liveStore.liveProducts[indexLive].sid)
              bgColor = "#BFCED7"
          }

          if (liveStore.currentOrderItems) {
            let co = liveStore.currentOrderItems.find(item => item.code === codeItem.match)
            if (co && co.quanity < 1)
              bgColor = '#FFBB99'
          }
          if (liveStore.liveProducts[indexLive].code.toLowerCase() == liveStore.currentSearchCode) bgColor = "yellow"

          msg = msg.replaceAll(codeItem.match, `<span style="background-color:${bgColor}; color:#333333">${codeItem.match}</span>`);
          // if (liveStore.liveProducts[indexLive].match_code)
          //   msg = msg.replaceAll(codeItem.match, `<span style="background-color:#94B380; color:#333333">${codeItem.match}</span>`);
          // else
          //   msg = msg.replaceAll(codeItem.match, `<span style="background-color:#DCC7C3; color:#333333">${codeItem.match}</span>`);

        } else {
          msg = msg.replaceAll(codeItem.match, `<mark class="code">${codeItem.match}</mark>`);
        }
      })
    }
    if (acode.q) {
      acode.q.forEach(quanity => {
        msg = msg.replaceAll(quanity.match, `<mark class="quanity">${quanity.match}</mark>`);
      });
    }

    if (acode.m) {
      msg = msg.replaceAll(acode.m, `<a class="tel" href="tel:+${acode.m}">${acode.m}</a>`);
    }
    // return msg
    return <div className="customer-msg" dangerouslySetInnerHTML={{ __html: msg }} />
  }

  useEffect(() => {
  }, [liveStore.currentMsgCode])

  const MessageRow = ({ msg, time, pos }) => {
    var acode = autoLookCode(msg, false, time, false, pos)
    // if (!liveStore.showAllComment)
    //   if (!acode) return null
    // console.log(`acode`, acode)
    msg = makeSuggestHighlight(msg, acode)
    let code = false
    if (acode.c)
      code = acode.c[0]?.code

    let hasCodeSelect = false
    if (liveStore.currentMsgCode) {
      hasCodeSelect = (liveStore.currentMsgCode === code)
    }
    // console.log('acode', acode)
    // msg = makeHighlight(msg, highlightCode)
    time = moment.utc(time).format('DD/MM HH:mm:ss')
    return (
      <MessageContainer hasCodeSelect={hasCodeSelect}>
        <Message onClick={() => {
          // alert(msg)
          if (acode.c)
            liveStore.setCurrentMsgCode(acode.c[0].code)
          // console.log(`acode`, acode)
        }}>
          {msg}
        </Message>
        <TimeStyle>{time}</TimeStyle>
      </MessageContainer>
    )
  }

  const MessageManyRow = ({ customerData }) => {
    return (
      <PerfectScrollbar>
        {customerData.msg.map((m, index) => {
          return (
            <MessageRow key={`msg-${index}`} msg={m[0]} time={m[1]} pos={index} />
          )
        })}
      </PerfectScrollbar>
    )
  }
  const CustomerRender = (value, row, index) => {
    let bg = '#f0f0f0'
    let border = "1px solid #999"
    let order = liveStore.liveOrders.find(lo => lo.customer?.lsid === row.sid)
    // if (datatableStore.hasOrder(row.sid)) bg = 'green'
    if (order?.code) { border = false }
    if (order?.status?.ref?.color) bg = order.status.ref.color
    if (!row.mobile && row.m) row.mobile = row.m

    if (liveStore.currentSearchCode) {
      let noOrder = true
      let uutien = false
      // let orderLive = liveStore.liveOrders.find(op => op.customer.lsid === row.sid)
      if (order?.items) {
        let o = order.items.find(ol => ol.code.toLowerCase() == liveStore.currentSearchCode.toLowerCase())
        if (o?.remain >= 0) noOrder = false
        if (o?.qty > 0 && o?.edit_count > 0) uutien = true
      }
      // let codeLive = liveStore.liveProducts.find(lp => lp.code.toLowerCase() == liveStore.currentSearchCode.toLowerCase())
      // if (codeLive && codeLive?.customers) {
      //   let c = codeLive.customers.find(item => item.sid === row.sid)
      //   if (c?.remain > 0) noOrder = false
      // }
      if (noOrder) border = "3px solid red"
      if (uutien) border = "3px solid blue"

      return (
        <div>
          {row.suggestion && row.suggestion.length > 0 ?
            <Avatar shape="circle" style={{ float: 'left', marginRight: 8, backgroundColor: bg, color: border ? "#999" : "white", border }}>
              {row.suggestion.length}
            </Avatar>
            :
            <Avatar shape="circle" style={{ float: 'left', marginRight: 8, backgroundColor: '#f0f0f0', color: "#ccc" }}>
              {row.name.charAt(0).toUpperCase()}
            </Avatar>
          }
          <div className="about">
            <div className="name">{row.name}</div>
            {row.mobile ?
              <div className="tel">
                <PhoneOutlined /> <a href={`tel:+${row.mobile}`}>{row.mobile}</a>
              </div> :
              ""}
          </div>
        </div>
      )
      // console.log(`row`, row)
    }

    return (
      <div>
        {row.suggestion && row.suggestion.length > 0 ?
          <Avatar shape="circle" style={{ float: 'left', marginRight: 8, backgroundColor: bg, color: border ? "#999" : "white", border }}>
            {row.suggestion.length}
          </Avatar> :
          <Avatar shape="circle" style={{ float: 'left', marginRight: 8, backgroundColor: '#f0f0f0', color: "#ccc" }}>
            {row.name.charAt(0).toUpperCase()}
          </Avatar>
        }
        <div className="about">
          <div className="name">{row.name}</div>
          {row.mobile ?
            <div className="tel">
              <PhoneOutlined /> <a href={`tel:+${row.mobile}`}>{row.mobile}</a>
            </div> :
            ""}
        </div>
      </div>
    )
  }

  useQuery(
    DataTableServices.GET_TABLE(id), {
    onCompleted: (tb) => {
      // console.log('tb', tb)
      if (tb.datatable) {
        commonStore.setCurrentPageTitle(tb.datatable.name)
        setCurrentDT(tb.datatable)
        datatableStore.setCurrentDatatable(tb.datatable)
        liveStore.setProductsDeleted(tb.datatable?.table_data?.productsDeleted)
        // liveStore.setLiveProducts(tb.datatable?.table_data?.products)

        // var tmpCodeRegex = makeCodeTmpRegex(tb.datatable?.table_data?.products, 'code_tmp')
        // liveStore.setCodeTmpSimilar(tmpCodeRegex)

        if (!tb.datatable.table_columns) {
          tb.datatable.table_columns = tb.datatable.data_table_template?.table_columns
          // console.log('tb', tb)
        }

        if (tb.datatable.table_columns) {
          tb.datatable.table_columns.forEach(c => {
            if (c.transformData == 'live_messages') {
              c.render = (value, row, index) => {
                return row.mcount
              }
              return
            }
            if (c.dataIndex == 'name') {
              c.render = CustomerRender
              // c = Object.assign(c, getColumnSearchProps('name'))
              // c.filterIcon = filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
              // c.onFilter = (value, record) =>
              // record['name']
              //   ? record['name'].toString().toLowerCase().includes(value.toLowerCase())
              //   : ''
            }
          });
          setColumns(tb.datatable.table_columns)
        }
      }
    }
  })

  function myFlat(v) {
    return Object.assign(v.row_data, { key: v.id })
  }

  const handleAdminActionClick = async (e) => {
    switch (e.key) {
      case 'sync-datasource':
        onSyncClick()
        break;
      case 'delete-all':
        if (!currentDT) return
        const input = {
          data: {
            tableId: currentDT.id,
            removeAll: true
          }
        }
        let delReturn = await deleteManyRows({
          variables: { input }
        })
        if (delReturn.data?.deleteManyDataRows?.data?.ok === 1) {
          // dataSource = []
          liveStore.setCustomerRecord(false)
          findTableRows()
        }
        // console.log('delReturn', delReturn)
        break;
      default:
        break;
    }
  }

  // const handleFilterActionClick = async (e) => {
  //   switch (e.key) {
  //     case 'sync-datasource':
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const AdminActionMenu = (
    <Menu onClick={handleAdminActionClick}>
      <Menu.Item key="sync-datasource" icon={<SyncOutlined />}>
        Đồng bộ excel
      </Menu.Item>
      <Menu.Item key="delete-all" icon={<DeleteOutlined />}>
        Xóa toàn bộ dữ liệu
      </Menu.Item>
    </Menu>
  );

  // const FilterActionMenu = (
  //   <Menu onClick={handleFilterActionClick}>
  //     <Menu.Item key="has-order" icon={<ShoppingCartOutlined />}>
  //       Có đơn hàng
  //     </Menu.Item>
  //     <Menu.Item key="show-all" icon={<SyncOutlined />}>
  //       Tất cả
  //     </Menu.Item>

  //   </Menu>
  // );

  const AdminTopBar = () => (
    <Space>
      <Dropdown overlay={AdminActionMenu}>
        <Button size='small'>
          Hệ thống <DownOutlined />
        </Button>
      </Dropdown>
      {/* <Button shape="round" icon={<SyncOutlined />} size='small' onClick={onSyncClick} >
        Sync datasource
      </Button> */}
    </Space>
  )

  const FilterBar = () => (
    <Space style={{ margin: "0 10px" }}>
      <Button
        // type="primary"
        size="small"
        icon={<ShoppingOutlined />}
        onClick={() => {
          setShowCorrectProduct(true)
        }}
      >Sản phẩm live</Button>

      {datatableStore.preloadTable?.status ?
        <DatarowSelectInput
        tableId="607d523459c61c2d08f434b2"
          // tableId="607d523459c61c2d08f434b2"
          // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
          emptyLabel='--trạng thái--'
          defaultEmpty={{ id: '-', name: '-trạng thái', color: '#ffffff' }}
          initValue={statusFilter}

          onOk={(item) => {
            if (item?.key) {
              setStatusFilter(item.key)
            }
            // let r = liveStore.currentLiveOrder
            // r.status.ref = item
            // liveStore.updateCartData(r)
            // console.log(`r`, r)
          }}
          // rows={datatableStore.preloadTable.status}
        />

        : ""}


      {/* <Switch checkedChildren="Ẩn bl" unCheckedChildren="Hiện tất" defaultChecked
        onChange={(checked) => {
          liveStore.setShowAllComment(!checked)
        }}
      /> */}
      {/* <Dropdown overlay={FilterActionMenu}>
        <Button size='small'>
          Bộ lọc <DownOutlined />
        </Button>
      </Dropdown> */}
    </Space>
  )

  // const LeftBar = () => {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <AutoComplete
  //         // notFoundContent="không tìm thấy"
  //         // dropdownMatchSelectWidth={252}
  //         style={{ width: 200 }}
  //         size={'small'}
  //         onSelect={
  //           (v, options) => {
  //             doSearchFilter(options)
  //             console.log('options', options)
  //             console.log('v=', v)
  //           }
  //         }
  //         // dataSource={tdata.map((item) => item.name)}
  //         onSearch={handleSearch}
  //         options={resultSearch}
  //       >
  //         <Input.Search
  //           allowClear size="small"
  //           // onPressEnter={() => {
  //           //   alert('ac')
  //           //   // datatableStore.setCurrentDatarows(tdata)
  //           // }}
  //           // enterButton
  //           placeholder="tìm khách hoặc mã sp"
  //         />
  //       </AutoComplete>
  //       <FilterBar />
  //     </div>
  //   )
  // }

  const RightBar = () => {
    const [sloading, setSloading] = useState(false)

    return (
      <div style={{ display: "flex", ustifyContent: 'flex-end' }}>

        <Space>
          {/* <DatatablePrinter title='In'/> */}
          <Button size={'small'}
            onClick={() => {
              setShowOverview(true)
            }}
            icon={<BarChartOutlined />}
          >Thống kê</Button>
          <Button size={'small'} disabled={!liveStore.someUnsaved} loading={sloading} type="primary"
            onClick={async () => {
              setSloading(true)

              // if (liveStore.liveOrders) if (liveStore.liveOrders.some(o => o.unsaved && o.code)) {
              //   let updateItems = [...liveStore.allUnsavedOrder]
              //   // console.log(`updateItems`, updateItems)
              //   if (updateItems.length > 0) {
              //     updateItems.forEach(item => {
              //       // item.se = build_search_by_column([{key:'code'},{key:'name'}])
              //       item.se = item.code + " " + removeVietnameseCharMark(item.customer?.name)
              //     });
              //     let r = await updateManyDataRows({
              //       variables: {
              //         input: {
              //           data: {
              //             type: "rowkey",
              //             rows: toJS(updateItems)
              //           }
              //         }
              //       }
              //     })
              //     message.success(
              //       {
              //         content: `Đã đồng bộ ${updateItems.length} dữ liệu!`,
              //         key: 'saving'
              //       });
              //     // console.log('r', r)
              //     liveStore.allBeSaved()
              //   }
              // }

              if (liveStore.liveProducts) if (liveStore.liveProducts.some(lp => lp.unsaved && lp.sid)) {
                let updateItems = [...liveStore.allUnsavedLiveProduct]
                // console.log(`updateItems`, updateItems)
                if (updateItems.length > 0) {
                  updateItems.forEach(item => {
                    item.se = build_search_by_column(item, [{ key: 'code' }, { key: 'match_code' }, { key: 'name' }])
                    // item.se = item.code + " " + removeVietnameseCharMark(item.customer?.name)
                  });
                  let r = await updateManyDataRows({
                    variables: {
                      input: {
                        data: {
                          type: "rowkey",
                          rows: toJS(updateItems)
                        }
                      }
                    }
                  })
                  message.success(
                    {
                      content: `Đã đồng bộ ${updateItems.length} dữ liệu!`,
                      key: 'saving'
                    });
                  // console.log('r', r)
                  liveStore.allLPBeSaved()
                }
              }


              setSloading(false)
            }}
            icon={<SaveOutlined />}
          >Lưu thay đổi</Button>
          {usersStore.currentUser.company_role === 'chu-cong-ty' ?
            <AdminTopBar />
            : ""
          }
        </Space>

      </div>
    )
  }

  // if (error) return <StyledLoadingOverlay />

  const renderItem = (itemData, foundtype) => {
    let value = false
    let txt = false
    if (foundtype == 'code') {
      value = itemData.code
      txt = value
      if (itemData.name)
        txt += '-' + itemData.name
    } else {
      value = itemData.name
      txt = value
    }
    return {
      value: value,
      foundtype,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {txt}
          {/* {count ?
            <span>
              <ShoppingCartOutlined /> {count}
            </span>
            : ""} */}

        </div>
      ),
    };
  };

  const doSearchFilter = (opt) => {
    // console.log(`opt`, opt)
    if (opt.value) {
      opt.value = removeVietnameseCharMark(opt.value)
      opt.value = opt.value.toLowerCase()
    }

    switch (opt.foundtype) {
      case "name":
        datatableStore.setCurrentDatarows(tdata.filter((item) => item.name_novn.includes(opt.value)))
        liveStore.setCurrentSearchCode(false)
        break;
      case "code":
        // datatableStore.setCurrentDatarows(tdata.filter((item) => item.highlight?.some((h) => h.id.toLowerCase().includes(opt.value))))
        liveStore.setCurrentSearchCode(opt.value.toLowerCase())
        let newTData = tdata.filter((item) => item.suggestion?.some((s) => s.code.toLowerCase() === opt.value.toLowerCase()))
        newTData.sort(sort_by_code_search)
        datatableStore.setCurrentDatarows(newTData)
        // console.log(`datatableStore.currentDatarows`, datatableStore.currentDatarows)
        break
      default:
        liveStore.setCurrentSearchCode(false)
        break;
    }
  }


  const filterByStatus = (customers, statusKey) => {

    if (customers) {
      let l = customers.filter(record => {
        // if (liveStore.getStatusFromCustomerOrder(record.sid)) {
        //   console.log(`statusKey`, statusKey)
        //   console.log(`record`, record)
        //   console.log(`liveStore.getStatusFromCustomerOrder(record.sid)`, liveStore.getStatusFromCustomerOrder(record.sid))
        // }
        return liveStore.getStatusFromCustomerOrder(record.sid) === statusKey
      }
      )
      // console.log(`l`, l)
      return l
    }
    // return customers.filter(record => {
    //   // if (liveStore.getStatusFromCustomerOrder(record.sid)) {
    //   //   console.log(`statusKey`, statusKey)
    //   //   console.log(`record`, record)
    //   //   console.log(`liveStore.getStatusFromCustomerOrder(record.sid)`, liveStore.getStatusFromCustomerOrder(record.sid))
    //   // }
    //   return liveStore.getStatusFromCustomerOrder(record.sid) === statusKey
    // }
    // )
    return []
  }

  const searchResult = (s, noOption) => {
    if (s) {
      s = removeVietnameseCharMark(s)
      s = s.toLowerCase()
    }
    let out = []
    // var filterDataName = tdata.filter((item) => item.name.toLowerCase().includes(s))
    var filterDataName = tdata.filter((item) => item.name_novn.includes(s))
    // var filterDataCodeCustomer = tdata.filter((item) => item.highlight?.some((h) => h.id.toLowerCase().includes(s)))
    // var filterDataCodeCustomer = tdata.filter((item) => item.suggestion?.some((h) => h.code.toLowerCase().includes(s)))
    let filterDataCode = []

    if (noOption) {
      filterDataCode = tdata.filter((item) => item.suggestion?.some((h) => h.code.toLowerCase().includes(s)))
      return unionBy(filterDataName, filterDataCode, 'sid')
    } else {
      if (liveStore.liveProducts) {
        filterDataCode = liveStore.liveProducts.filter((p) => p.code.toLowerCase().includes(s))
      }
    }

    // var filterDataCode = liveStore.suggestionCodes.filter((p) => p.code.toUpperCase().includes(s))
    // var filterDataCode = tdata.filter((item) => item.suggestion?.some((sg) => sg.code.toLowerCase() == s))
    // console.log(`filterDataCode`, toJS(filterDataCode))

    if (filterDataCode.length > 0)
      filterDataName = filterDataName.slice(0, 5)

    if (filterDataCode.length > 0) {
      let codeSearch = {
        label: (<span>Mã sản phẩm</span>),
        options: filterDataCode.map((p) => {
          return renderItem(p, 'code')
        })
      }
      out.push(codeSearch)
    }

    if (filterDataName.length > 0) {
      let nameSearch = {
        label: (<span>Khách hàng</span>),
        options: filterDataName.map((item) => {
          // return renderItem(item, item?.highlight?.length, 'name')
          return renderItem(item, 'name')
        })
      }
      out.push(nameSearch)
    }
    // datatableStore.setCurrentDatarows(filterDataName)
    // merge ket qua search name va code
    // datatableStore.setCurrentDatarows(unionBy(filterDataName, filterDataCodeCustomer, 'sid'))
    return out
  }

  const handleSearch = (value) => {
    // console.log(`value`, value)
    setResultSearch(searchResult(value))
    // setResultSearch(value ? searchResult(value) : [])
    // searchResult(value)
    // if (value == '') {
    //   setTableLoading(true)
    //   datatableStore.setCurrentDatarows(tdata)
    // }
  }

  useEffect(() => {
    // setCustomerRecord
    if (datatableStore.currentDatarows.length > 0) {
      let record = toJS(datatableStore.currentDatarows[0])
      if (liveStore.customerRecord?.key !== record.key) {
        liveStore.setCustomerRecord(record)
        // console.log(`record`, record)
        setSelectedKeys([record.key])
        // console.log(`liveStore.customerRecord`, liveStore.customerRecord)
      }
      // console.log(`datatableStore.currentDatarows`, datatableStore.currentDatarows)
      // liveStore.setCustomerRecord(record)
      // console.log(`record`, record)
      // setSelectedKeys([record.key])

      // liveStore.setCustomerRecord(false)
      setTableLoading(false)
      // setAggregate((v)=>{
      //   aggregate
      //   cout:
      // })
      // console.log('datatableStore.currentDatarows[0]', datatableStore.currentDatarows[0])

      // setSelectedKeys([datatableStore.currentDatarows[0].key])
    }
  }, [datatableStore.currentDatarows])


  useEffect(() => {
    // console.log(`liveStore.currentCustomerRecord`, liveStore.currentCustomerRecord)
    // console.log(`liveStore.currentMsgCode`, liveStore.currentMsgCode)
    // console.log(`liveStore.currentCustomerRecord.edit_count`, liveStore.currentCustomerRecord.edit_count)
  }, [liveStore.updateCount, liveStore.currentCustomerRecord])

  return (
    <DefaultLayout>
      <Prompt
        when={liveStore.somethingUnsave}
        message='Dữ liệu thay đổi và chưa được lưu, bạn vẫn muốn thoát và không lưu thay đổi ?'
      />

      <TopBarContainer>
        {/* <LeftBar /> */}
        <div style={{ display: "flex" }}>
          <AutoComplete
            // notFoundContent="không tìm thấy"
            // dropdownMatchSelectWidth={252}
            dropdownMatchSelectWidth={true}
            style={{ width: 200 }}
            size={'small'}
            onSelect={
              (v, options) => {
                doSearchFilter(options)
                // console.log('options', options)
                // console.log('v=', v)
              }
            }
            // dataSource={tdata.map((item) => item.name)}
            onSearch={handleSearch}
            options={resultSearch}
            onDropdownVisibleChange={
              (open) => {
                //   if (!open) {
                //     datatableStore.setFilter({ txt: inputValue })
                //   }
                //   console.log(`open`, open)
              }
            }
          >
            <Input.Search
              ref={searchRef}
              allowClear size="small"
              onPressEnter={(e) => {
                // alert('ac')
                let value = searchRef?.current?.state?.value
                if (value == "") {
                  datatableStore.setCurrentDatarows(tdata)
                  liveStore.setCurrentSearchCode(false)
                } else {
                  let ds = searchResult(value, true)
                  // console.log(`ds`, ds)
                  if (ds.length > 0)
                    datatableStore.setCurrentDatarows(ds)
                  else
                    alert('not found!')
                }
                // console.log(`searchRef.current`, searchRef.current)
                // console.log(`e`, e)
                // datatableStore.setCurrentDatarows(tdata)
              }}
              // enterButton
              placeholder="tìm khách hoặc mã sp" />
          </AutoComplete>
          <FilterBar />
        </div>
        <RightBar />

      </TopBarContainer>
      <Row style={{
        background: "#fff",
        height: 'calc(100vh - 100px)',
        borderTop: '2px solid #bbb',
        // boxShadow: "0px 0 10px rgba(200, 200, 200, 0.8)",
        flexFlow: 'row'
      }}>
        <Col
          id="liveSidebar"
          flex="250px"
        // xs={20} sm={16} md={12} lg={6}
        >
          {(columns && id) ?
            <PerfectScrollbar>
              <Table
                className={'liveTable'}
                // style={{ width: 300 }}
                loading={tableLoading}
                columns={columns}
                // dataSource={dataSource}
                // dataSource={tdata}
                dataSource={datatableStore.currentDatarows}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      // console.log('record', toJS(record))
                      liveStore.setCustomerRecord(record)
                      // console.log(`record`, record)
                      // console.log(`record`, record)
                      setSelectedKeys([record.key])
                    }, // double click row
                  };
                }}
                // scroll={{ y: 'calc(100vh - 150px)', scrollToFirstRowOnChange: true }}
                pagination={{
                  simple: true,
                  size: 'small',
                  position: ['bottom', 'bottomLeft'],
                  defaultCurrent: 1,
                  pageSize: 200,
                  showSizeChanger: false,
                  // total: aggregate ? aggregate.count : 0,
                  showTotal: total => `Total ${total} row`
                }}
                hideSelectionColumn={true}
                rowSelection={{
                  selectedRowKeys: selectedKeys,
                  // type: 'radio',
                  // onChange: (selectedRowKeys, selectedRows) => {
                  //   setSelectedKeys([selectedRowKeys])
                  //   //     datatableStore.setCustomerRecord(record)
                  // },
                  columnWidth: 0,
                  renderCell: () => "",
                  hideSelectAll: true
                }}
                showHeader={false}
                size="small" />
            </PerfectScrollbar>
            : ""
          }
        </Col>


        <Col
          flex="auto"
        // xs={20} sm={16} md={12} lg={18}
        // style={{
        //   height: 'calc(100vh - 120px)',
        // }}
        >

          <Row>
            <Col span={18}>
              {liveStore.currentCustomerRecord && id ?
                <LiveDetailPanel tableId={id} />
                : <Empty
                  style={{ marginTop: "calc(50vh - 129px)" }}
                  description={'Bạn chưa chọn dữ liệu'}
                />
              }

            </Col>
            <Col
              id="liveMessages"
              span={6}
              style={{
                height: 'calc(100vh - 100px)',
                borderRight: "1px solid #e4e4e4",
                overflow: 'auto'
              }}>
              {liveStore.currentCustomerRecord && id ?
                <MessageManyRow customerData={liveStore.currentCustomerRecord} />
                : <Empty
                  style={{ marginTop: "calc(50vh - 129px)" }}
                  description={'Bạn chưa chọn dữ liệu'}
                />
              }
            </Col>
          </Row>



        </Col>
        {/* <Col id="detailPanel" xs={20} sm={16} md={12} lg={6} >

          </Col> */}
      </Row>
      <Drawer
        id='overviewDrawer'
        width={'50%'}
        title={("Thống kê")}
        placement="right"
        closable={true}
        onClose={() => {
          setShowOverview(false)
        }}
        visible={showOverview}
      >
        {showOverview ?
          <LiveOverview />
          : ""}
      </Drawer>
      {showCorrectProduct ?
        <CorrectProductPanel
          tableId={id}
          onCancel={() => {
            setShowCorrectProduct(false)
          }}
          onSaveAll={async () => {
            // setShowCorrectProduct(false)
            let u = await updateLiveProducts({
              variables: {
                input: {
                  data: {
                    tableId: id,
                    products: liveStore.liveProducts
                  }
                }
              }
            })
            if (u.data.updateLiveProducts?.data?.status === "OK") {
              message.success(
                {
                  content: `Lưu dữ liệu thành công!`,
                  key: 'saving'
                });
            } else {
              message.error(
                {
                  content: "Có lỗi xảy ra!!"
                });
            }
          }}
        />
        : ""}

    </DefaultLayout>
  )
}

export default inject("usersStore", "commonStore", "datatableStore", "liveStore")(observer(DataTableLive))