import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
const { Option } = Select

const FlexStyled = styled.div`
  display: flex;
  align-items: center;
  & > * {
    font-weight: 600;
    font-size: 14px;
  }
`

const SelectWrapper = styled(Select)`
  width: 100px;
  & .ant-select-selector{
    border: 0 !important;
    margin-top: 3px;
  }
  margin-right: 13px;
`

const StyledSelect = () => {

  const onChange = (value) => {
    console.log(`selected ${value}`)
  }

  const onBlur = () => {
    console.log('blur')
  }

  const onFocus = () => {
    console.log('focus')
  }

  const onSearch = (val) => {
    console.log('search:', val)
  }
  return (
    <FlexStyled>
      <div>Sort by:</div>
      <SelectWrapper
        showSearch
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="jack">Jack</Option>
        <Option value="lucy">Lucy</Option>
        <Option value="tom">Tom</Option>
      </SelectWrapper>
    </FlexStyled>
  )
}

export default StyledSelect