import { gql } from 'apollo-boost'

class CoreServices {

  /**
   * Find many in a resource
   * @param {Object} options
   * $sort: {String} fieldname:type (type in ['ASC','DESC', 'asc', 'desc']) Example: 'username:asc'
   * $limit: {Int} - Limit the total results - Example: 10
   * $start: {Int} - Skip the n first results - Example: 1
   * $where: {Object} - Query condition - Example: { username_contains: "admin" }
   * See docs to learn more: https://strapi.io/documentation/3.0.0-beta.x/plugins/graphql.html#query-api
   */

  FIND(response, type) {
    return gql`
      query ${type}Connection($sort:String, $limit:Int, $start: Int, $where:JSON) {
        ${type}Connection(sort:$sort, limit:$limit, start:$start, where:$where) {
          values {
            ${response}
          }
          aggregate {
            count
            totalCount
          }
        }
      }
    `
  }

  /**
   * Find one in a resource
   * @param {ID} id - id of the entry
   */

  GET(response, type, id) {
    return gql`
      query ${type} {
        ${type}(id:"${id}") {
          ${response}
        }
      }
    `
  }

  /**
   * Mutation of an entry in a resource
   * @param {$input} - graphql input
   */

  MUTATION(response, type, service) {
    if (!response) response = this.response
    return gql`
      mutation ${type}($input: ${type}Input){
        ${type}(input:$input){
          ${service} {
            ${response}
          }
        }
      }
    `
  }

}

export default CoreServices
