import React, { useEffect, useState, useContext, useRef } from 'react'
import {
    Input,
    InputNumber,
    Form
} from 'antd';

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    inputType,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus({
                cursor: 'all',
            });
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    const onKeyDown = async e => {
        if (e.key == "Enter") {
            await save(e)
            e.preventDefault()
        }
        else if (e.key == "Escape") {
            //   datatableStore.setRowEditingKey(false)
            e.preventDefault()
        }
    }

    let inputNode

    switch (inputType) {
        case 'number':
            inputNode = <InputNumber
                size={'small'}
                // tabIndex={index}
                ref={inputRef}
                onBlur={save}
                // onPressEnter={save}
                onKeyDown={onKeyDown}

            />
            break;

        case 'tag-color':

        default:
            inputNode = <Input
                size={'small'}
                // autoFocus={true}
                // tabIndex={index}s
                ref={inputRef}
                // onPressEnter={save}
                onKeyDown={onKeyDown}
                onBlur={save}
            // onClick={() => {
            //   if (inputRef.current)
            //     inputRef.current.focus({
            //       cursor: 'all',
            //     });
            // }}
            />
    }

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                    padding: 0
                }}
                name={dataIndex}
            // rules={[
            //   {
            //     required: true,
            //     message: `${title} is required.`,
            //   },
            // ]}
            >
                {inputNode}
                {/* <Input size={'small'} ref={inputRef} onPressEnter={save} onBlur={save} /> */}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};