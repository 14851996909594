import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Avatar,
  Form,
  Button,
  Image,
  Tag,
  Input,
  InputNumber,
  Modal,
  Card,
  // message,
  Space
} from 'antd';

// import {
//   PlusOutlined,
//   CloseCircleOutlined,
//   LoadingOutlined
// } from '@ant-design/icons';
import { autorun, toJS } from 'mobx'

import DataTableServices from '../../../../services/datatableServices'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
import { nanoid } from 'nanoid'
import DataSelectModal from '../DataTools/DataSelectModal'

const { Meta } = Card;

const StockProductModal = ({
  datatableStore,
  commonStore,
  initData,
  width = 480,
  onSave,
  onClose
}) => {

  // const [selectedData, setSelectedData] = useState(initData)
  const [datatable, setDatatable] = useState(false)
  const [updating, setupdating] = useState(false)
  // const [value, setValue] = useState(initData)
  const [currentValue, setCurrentValue] = useState(false)
  const [showSelectData, setShowSelectData] = useState(false)

  const [addOnly, setAddOnly] = useState(true)
  // const [codeSelect, setCodeSelect] = useState(false)
  // const getTableById = useAsyncQuery(DataTableServices.GET_TABLE(table.id ? table.id : table))

  // const [createDatarow] = useMutation(DataTableServices.CREATE_DATAROW(), {
  //   errorPolicy: 'all',
  //   update(cache, { data }) {
  //   }
  // })
  const getRef = useAsyncQuery(DataTableServices.GET_ROW_BY_ID(initData?.id))

  useEffect(async () => {
    if (initData) {
      if (initData.code) setAddOnly(false)
      if (!initData.qty) initData.qty = 1
      if (initData.id && !initData.ref) {
        let product = await getRef()
        if (product) product = product.data?.datarow
        // console.log(`product`, product)
        if (product) {
          initData.ref = product.row_data
          // console.log(`initData`, initData)
          // setCurrentValue(toJS(initData))
        }
      }
      setCurrentValue(toJS(initData))
      if (!initData.code) setShowSelectData(true)
    }
    return () => {
      currentValue(false)
    }
  }, [])


  const [form] = Form.useForm();
  const closeModal = () => {
    onClose()
  }
  // useEffect(async () => {
  //   // console.log(`table`, table)
  //   // if (!table.id) {
  //   //   const { data } = await getTableById()
  //   //   console.log('datatable', data.datatable)
  //   //   setDatatable(data.datatable)
  //   // } else {
  //   //   setDatatable(table)
  //   // }
  // }, [])


  const onFinish = async (values) => {
    let sid = nanoid(10)
    let row_data = values
    row_data.sid = sid
    setupdating(true)
    let v = { ...currentValue, qty: values.qty, addOnly: addOnly }
    // console.log(`vzz`, v)
    setupdating(false)
    onSave(
      v
    )
  };

  const onRemove = () => {
    let v = { ...currentValue, remove: true }
    setupdating(false)
    onSave(
      v
    )
  }

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 19,
    },
  };

  // const CodeInput = ({ value = {}, onChange }) => {
  //   console.log(`value`, value)
  //   return (
  //     <div onClick={() => {
  //       setShowSelectData(true)
  //     }}>
  //       {value?.code ? <ProductItem row={value} /> :
  //         <Button onClick={() => {
  //           // setShowSelectData(true)
  //         }}
  //         >Chọn mã sản phẩm</Button>
  //       }
  //     </div>
  //   )
  // }

  const ProductItem = ({ onClick }) => {
    let name = currentValue?.ref?.name
    let title = currentValue?.ref?.code
    if (title) title = '[' + title + ']-' + name
    else title = name

    let imageSrc = currentValue?.ref?.image
    if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
    return (
      <Card
        onClick={onClick}
        bordered
      >
        <Meta
          avatar={
            <Image
              width={40}
              height={40}
              src={imageSrc}
            />
          }

          title={title}
          description={name}
        />
      </Card>
    )
  }

  const StockProductForm = () => {



    // let columns = datatable.table_columns ? datatable.table_columns : datatable.data_table_template.table_columns
    return (
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}>
        <Form.Item
          name={'code'}
          label={'Sản phẩm'}
        >
          {/* <CodeInput value={currentValue} /> */}
          <div>
            {currentValue ?
              currentValue?.code ?
                <ProductItem onClick={() => {
                  if (addOnly)
                    setShowSelectData(true)
                }} />

                // <Tag onClick={() => {
                //   setShowSelectData(true)
                // }}>
                //   {currentValue.code}
                // </Tag>
                :
                <Button onClick={() => {
                  setShowSelectData(true)
                }}>Chọn hoặc thêm mới</Button>
              : ""

            }

            {/* {currentValue?.code ?
              <ProductItem onClick={() => {
                setShowSelectData(true)
              }} />

              // <Tag onClick={() => {
              //   setShowSelectData(true)
              // }}>
              //   {currentValue.code}
              // </Tag>
              :
              <Button onClick={() => {
                setShowSelectData(true)
              }}
              >Chọn mã sản phẩm</Button>
            } */}
          </div>
        </Form.Item>
        <Form.Item
          name={'qty'}
          label={'Số lượng'}
        >
          <InputNumber defaultValue={initData?.qty ? initData.qty : 1} />
        </Form.Item>
      </Form>
    )
  }

  return (
    <Modal
      title={'Cập nhật sản phẩm trong kho'}
      visible={true}
      width={width}
      maskClosable={false}
      onCancel={closeModal}
      centered
      // footer={false}
      footer={[
        <Button
          onClick={closeModal}
          disabled={updating}>
          Hủy thay đổi
      </Button>,
        !addOnly ? <Button key="remove" danger onClick={onRemove} disabled={updating}>
          Xóa
       </Button> : ""
        ,
        <Button key="submit" type="primary"
          loading={updating}
          onClick={() => {
            setupdating(true)
            form.submit()
          }}>
          Cập nhật kho
      </Button>,
      ]}
    >
      <StockProductForm />
      {showSelectData ?
        <DataSelectModal
          title={'Chọn hoặc thêm sản phẩm'}
          searchColumns={["code", "name"]}
          displayColumns={["code", "name", "price"]}
          initData={currentValue}
          okLabel={'OK'}
          // tableId="5fe7fb4d2136a764ac573c1e"
          tableId={commonStore?.company?.companyData?.product_table}
          onSave={(v) => {
            // console.log(`vvvv`, v)
            if (v.ref) {
              // let t = currentValue
              // t.ref = v.ref
              // t.code = v.ref.code
              // t.id = v.id
              // setCurrentValue(t)
              setCurrentValue({ ...currentValue, ref: v.ref, code: v.ref.code, id: v.id })
            }
            setShowSelectData(false)
            // console.log('v', v)
            // saveNewCode(v)
          }}
          onClose={() => {
            setShowSelectData(false)
          }}
        // onSearchSelect={(v, o) => {
        //   // console.log('o', o)
        // }}
        />
        : ""
      }
    </Modal>
  )
}
export default inject("datatableStore", "commonStore")(observer(StockProductModal))