import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useAsyncQuery } from '../../../../../ultils'
import { price_k } from '../../../../../ultils/datatableUtils'
import DataTableServices from '../../../../../services/datatableServices'
import { inject, observer } from 'mobx-react'
import { Tags_render } from '../../DataTools/DataField'
import moment from 'moment'
import {
    Form,
    Button,
    Input,
    Space,
    Modal,
    message,
} from 'antd';

import {
    PhoneOutlined,
    CalendarOutlined,
    SmileFilled,
    FileTextOutlined,
    DollarOutlined,
    ExclamationCircleOutlined,
    CarOutlined,
    BarcodeOutlined
} from '@ant-design/icons';

import { nanoid } from 'nanoid'
import { flatMap } from 'lodash-es'
const { Search } = Input;
const { confirm } = Modal;

const OrderSearchBox = ({
    datatableStore,
    title,
    table_id,
    initOrder,
    defaultValue,
    placeholder = "Tìm kiếm",
    notInStatus = ['Đã hoàn thành', "Hủy đơn", "Chờ vận chuyển", "Đã in", "Đơn cũ", "Đã bị gộp"],
    width = 550,
    onClose,
    onOk,
}) => {

    const [rows, setRows] = useState(false)
    const [selectedKey, setSelectedKey] = useState([])
    const [infoText, setInfoText] = useState("")
    const [loading, setLoading] = useState(false)

    function myFlat(v) {
        return Object.assign(v.row_data, { key: v.id })
    }
    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

    // const [mergeOrder] = useMutation(
    //     DataTableServices.MERGE_ORDER(),
    //     {
    //         errorPolicy: 'all'
    //     }
    // )


    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data

        if (!results?.items) return false
        // let statusArr = ['Đã hoàn thành', "Hủy đơn", "Chờ vận chuyển", "Đã in", "Đơn cũ", "Đã bị gộp"]
        var items = flatMap(results.items, myFlat)
        return items.filter(o => o.status?.name && !notInStatus.includes(o.status?.name))
    }

    const doSearch = async (value) => {
        let items = await findRowsByFilter(
            {
                tableId: table_id,
                hasPreSearch: true,
                limit: 100,
                filter: {
                    txt: value,
                    limit: 20,
                    _sort: 'ngay_tao:-1'
                }
            }
        )
        setRows(items)
        // console.log(`items`, items)
    }

    useEffect(() => {
        async function init() {
            await doSearch(initOrder?.customer?.mobile)
        }
        init()
    }, [])

    useEffect(() => {
        setInfoText(`${selectedKey.length} đơn hàng được chọn.`)
    }, [selectedKey.length])

    const ItemCard = ({ row, rowColor }) => {
        // console.log(`initOrder`, initOrder)
        // console.log(`row.key`, row.key)
        let color = row.status?.color ? row.status.color : "lightgray"
        let className = 'o-compact-render'
        if (row.key === initOrder.key) {
            className += ' disabled'
            rowColor = "#ccc"
        }
        return (
            <div className={className} style={{ background: rowColor }} onClick={() => {
                // const index = selectedKey.indexOf(row.key);
                // if (index > -1) {
                //     setSelectedKey(selectedKey.filter(item => item == row.key));
                // }
                if (selectedKey.includes(row.key)) {
                    setSelectedKey(selectedKey.filter(item => item !== row.key));
                } else {
                    setSelectedKey([...selectedKey, row.key]);
                }
            }}>
                <Space>
                    <span style={{ fontWeight: "bold", color: row?.customer?.ref ? "green" : "#b59324" }}>
                        <SmileFilled style={{ marginRight: 8, color: color }} />    {row?.customer?.name}
                    </span>
                    {row?.customer?.mobile ?
                        <span>
                            <PhoneOutlined style={{ marginRight: 4 }} />
                            {row?.customer?.mobile}
                        </span> : ""}
                    {row?.code ?
                        <span>
                            <BarcodeOutlined style={{ marginRight: 4 }} />
                            <a href={'/row/' + row.key} target="_blank" title={row.code}>{row.code}</a>
                        </span> : ""}
                </Space>

                {row.tong_tra ?
                    <span>
                        <br />
                        <Space>
                            {row.ngay_tao ?
                                <span>
                                    <CalendarOutlined style={{ marginRight: 4 }} />
                                    {moment(row?.ngay_tao).format('DD/MM/YY HH:mm')}
                                </span> : ""}
                            <span>
                                <DollarOutlined style={{ marginRight: 8, color: color }} />
                                <>Tổng tiền: <b>{price_k(row.tong_tra, '?', 3)}</b></>
                            </span>
                            {row?.ship?.cod ?
                                <span
                                    style={{
                                        color: (row?.pay_status?.name === 'Đã thanh toán' && row.ship.cod > 0) ? "red" : "green"
                                    }}>
                                    /  COD: <strong>{price_k(row?.ship?.cod, '?', 3)}</strong>
                                </span> : ""}
                        </Space>

                    </span>
                    : ""
                }
                {row.note ?
                    <span style={{ color: "red", fontWeight: "bolder", fontSize: '12pt' }}>
                        <br />
                        <FileTextOutlined style={{ marginRight: 8, color: color }} />{row.note}
                    </span>
                    : ""
                }
                <span>
                    <br />
                    {row.status?.name ?
                        <Tags_render
                            tags={[row.status?.name]}
                            preloadTable={datatableStore.preloadTable.status}
                            extra={{
                                type: 'status'
                            }}
                        />
                        : ""
                    }
                    {row.tags ?
                        <Tags_render tags={row.tags} preloadTable={datatableStore.preloadTable.tags} />
                        : ""
                    }
                </span>
            </div>
        )
    }

    return (
        <Modal
            // title={title ? title : `Chỉnh sửa ${dataTextName}`}
            title={title ? title : false}
            visible={true}
            width={width ? width : 450}
            maskClosable={false}
            onCancel={onClose}
            centered
            footer={[
                <Button onClick={onClose}>
                    Đóng
                </Button>,
                <Button key="submit" type="primary"
                    onClick={() => {
                        onOk(selectedKey, rows.filter(r => selectedKey.includes(r.key)))
                        // let selectedRow = rows.filter(r => selectedKey.includes(r.key))
                        // // console.log(`initOrder`, initOrder)
                        // let invalidRow = selectedRow.filter(r => r.customer?.mobile !== initOrder?.customer.mobile)
                        // let txt = false
                        // if (invalidRow.length > 0) {
                        //     txt = 'Các đơn sau không cùng số điện thoại với đơn được gộp'
                        //     // invalidRow.forEach(r => {
                        //     //     txt += `${r.customer?.name} - ${r.customer?.mobile} \n`
                        //     // });
                        //     invalidRow = invalidRow.map(r => {
                        //         return { name: r.customer?.name, mobile: r.customer?.mobile }
                        //     })
                        // }
                        // confirm({
                        //     width: 480,
                        //     title: 'Bạn có chắc chắn muốn thực hiện không?',
                        //     icon: <ExclamationCircleOutlined />,
                        //     okText: 'Thực hiện',
                        //     okType: 'success',
                        //     cancelText: 'Xem lại',
                        //     confirmLoading: { loading },
                        //     content: (
                        //         <>  {txt ?
                        //             <>
                        //                 {txt}<br />
                        //                 {invalidRow.map(r => {
                        //                     return (
                        //                         <>
                        //                             {`${r.name} - ${r.mobile}`}
                        //                             <br />
                        //                         </>
                        //                     )
                        //                 })}
                        //             </> : ""}

                        //         </>
                        //     ),
                        //     async onOk() {
                        //         const input = {
                        //             data: {
                        //                 mainId: initOrder.key,
                        //                 mergeIds: selectedKey
                        //             }
                        //         }
                        //         setLoading(true)
                        //         let r = await mergeOrder({
                        //             variables: { input }
                        //         })
                        //         setLoading(false)
                        //         let result = r.data.mergeOrder?.data
                        //         if (result.status === 'OK') {
                        //             message.success('Gộp đơn thành công!!')
                        //             datatableStore.setRowDetail(result.out)
                        //             onClose()
                        //         } else {
                        //             message.error('Có lỗi khi gộp đơn. Hãy thử lại!')
                        //         }
                        //     },
                        //     onCancel() {
                        //         console.log('Cancel');
                        //     },
                        // });
                    }}>
                    Chọn
                </Button>,
            ]}
        >
            <Search
                defaultValue={defaultValue}
                placeholder={placeholder}
                onSearch={doSearch}
                enterButton />
            <div className="resultItems" style={{ minHeight: 400 }}>
                {rows && datatableStore.preloadTable.tags && datatableStore.preloadTable.status ? <>
                    {rows.map((row => {
                        return <ItemCard row={row} rowColor={selectedKey.includes(row.key) ? "#DEF7E9" : "white"} />
                    }))}
                </> : ""}
            </div>
            {infoText}
        </Modal>
    )
}
export default inject("datatableStore")(observer(OrderSearchBox))