import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Modal,
    message,
} from 'antd';

// import {
//     PlusOutlined,
//     LoadingOutlined
// } from '@ant-design/icons';

import DataTableServices from '../../../../../services/datatableServices'
// import UploadServices from '../../../../services/uploadServices'
// import useAsyncQuery from "../../../../../ultils/useAsyncQuery";
// import { removeVietnameseCharMark, imagePreset, getRevisionData } from '../../../../ultils/datatableUtils'

import DatarowSelectInput from '../../DataTools/DatarowSelectInput'

const OrderStatusSelect = ({
    datatableStore,
    commonStore,
    // tableId,
    title,
    massFilter,
    width = 400,
    placeholder,
    onSave,
    onClose,
}) => {

    // const [selectedData, setSelectedData] = useState(initData)
    const [updating, setUpdating] = useState(false)
    const [currentValue, setCurrentValue] = useState(false)
    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )


    return (
        <Modal
            title={title ? title : `Đặt giá trị hàng loạt`}
            loading={updating}
            visible={true}
            width={width}
            maskClosable={false}
            onCancel={onClose}
            centered
            // footer={false}
            footer={[
                <Button onClick={onClose} disabled={updating}>
                    Hủy chọn
                </Button>,
                <Button key="submit" type="primary"
                    loading={updating}
                    onClick={() => {
                        setUpdating(true)
                        let item = currentValue
                        let n = {}
                        n['status.color'] = item.color
                        n['status.name'] = item.name
                        n['status.ref'] = item.key
                        let rows = []
                        if (massFilter.ids) {
                            massFilter.ids.forEach(id => {
                                // n[massFilter.updateKey] = 
                                rows.push(
                                    {
                                        id: id,
                                        n: n,
                                        o: false
                                    }
                                )
                            });
                            let r = updateManyDataRows({
                                variables: {
                                    input: {
                                        data: {
                                            type: "oldnew",
                                            rows: rows
                                        }
                                    }
                                }
                            })
                            setUpdating(false)
                            onSave({
                                color: item.color,
                                name: item.name,
                                ref: item.key
                            })
                        }

                    }}>
                    Cập nhật hàng loạt
                </Button>,
            ]}
        >
            <DatarowSelectInput
                tableId={commonStore?.company?.companyData?.status_table}
                // tableId={tableId}
                rows={massFilter?.preload ? datatableStore.preloadTable[massFilter.preload] : false}
                width={100}
                emptyLabel='--chưa chọn--'
                defaultEmpty={{ id: '-', name: '--chưa chọn--', color: '#ffffff' }}
                // initFilter={{ match: { "category": 'Trạng thái đơn' } }}
                initFilter={massFilter?.filter}
                // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                // initValue={orderData?.status?.ref ? orderData?.status?.ref : '-'}
                initValue={'-'}
                onOk={(item) => {
                    setCurrentValue(item)
                    // let n = {}
                    // n['status.color'] = item.color
                    // n['status.name'] = item.name
                    // n['status.ref'] = item.key
                    // let rows = []
                    // if (massFilter.ids) {
                    //     massFilter.ids.forEach(id => {
                    //         // n[massFilter.updateKey] = 
                    //         rows.push(
                    //             {
                    //                 id: id,
                    //                 n: n,
                    //                 o: false
                    //             }
                    //         )
                    //     });
                    //     let r = updateManyDataRows({
                    //         variables: {
                    //             input: {
                    //                 data: {
                    //                     type: "oldnew",
                    //                     rows: rows
                    //                 }
                    //             }
                    //         }
                    //     })
                    //     onSave({
                    //         color: item.color,
                    //         name: item.name,
                    //         ref: item.key
                    //     })
                    // }
                }}
            />
        </Modal>
    )
}
export default inject("datatableStore", "commonStore")(observer(OrderStatusSelect))