import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import moment from 'moment'
import {
    Modal,
    Dropdown,
    Menu,
    Space,
    Button,
    Row,
    Col,
    Card,
    Image,
    message,
    notification,
    Divider
} from 'antd'
import {
    PictureOutlined,
} from '@ant-design/icons'
import useAsyncQuery from '../../../../ultils/useAsyncQuery'
// import moment from 'moment'
import { flatMap, merge as LMerge } from 'lodash-es'
// import { autorun, set, toJS } from 'mobx'

// import { nanoid } from 'nanoid'

import { imagePreset, removeVietnameseCharMark } from '../../../../ultils/datatableUtils'
// import DefaultLayout from '../..,/../layouts/DefaultLayout'
// import StyledLoadingOverlay from '../../../common/StyledLoadingOverlay'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'

// import SelectManyItemModal from '../../Tools/SelectManyItemModal'
// import ProductList from '../DataView/ProductList'
import ManyProducts from '../DataCard/ManyProducts'
import ResourceList from '../../Resource/ResourceList'

const { confirm } = Modal;

function myFlat(v) {
    return Object.assign(v.row_data, { key: v.id })
}

const TopBarContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`
// const defaultLimit = 50

const DataRowPCollection = ({
    usersStore,
    commonStore,
    datatableStore,
    rowData }) => {

    const [showSelectMany, setShowSelectMany] = useState(false)
    const [products, setProducts] = useState([])

    const [getProductByCodes] = useMutation(DataTableServices.GET_PRODUCTS_BY_CODES(), {
        errorPolicy: 'all',
    })

    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )

    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, myFlat)
        return items
    }

    // const findResQuery = useAsyncQuery(DataTableServices.FIND_RESOURCES())
    // const getResources = async (dataInput) => {
    //     const { data, error } = await findResQuery({
    //         input: {
    //             data: dataInput
    //         }
    //     })
    //     let result = data?.findResources?.data
    //     if (result.status === 'OK')
    //         return result.items
    //     return false
    // }

    // const { id } = useParams()
    // const getRow = useAsyncQuery(DataTableServices.GET_ROW_BY_ID(id))

    useEffect(() => {
        async function init() {
            if (rowData?.row_data?.products) {
                let pRef = await findRowsByFilter(
                    {
                        tableId: commonStore?.company?.companyData?.product_table,
                        // tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
                        hasPreSearch: false,
                        limit: 100,
                        filter: { match: { "_id": rowData?.row_data?.products.filter(p => p !== null).join('|') } }
                    }
                )
                if (pRef) {
                    setProducts(pRef)
                }
            }
        }
        init()
        commonStore.setCurrentPageTitle('Bộ sưu tập')
    }, [])

    return (
        <>
            <TopBarContainer>
                <h2>{rowData?.row_data.name}</h2>
            </TopBarContainer>
            <div id="rowOrder">
                {/* <Button onClick={() => {
                    setShowEditCustomer(true)
                }}>abc</Button> */}

                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        {products &&
                            <ManyProducts products={products}
                                onSave={async (items) => {
                                    let product_keys = items.map(p => p.key || p.id)
                                    let se = rowData?.row_data?.name || ""
                                    if (items.length > 0) {
                                        se += items.map(p => p.code).join(' ')
                                        // items.forEach(p => {
                                        //     let code = (p.code || "").toLowerCase()
                                        //     if (!se.includes(code)) se += ' ' + code
                                        // });
                                    }
                                    se = removeVietnameseCharMark(se)
                                    se = se.toLowerCase()
                                    let r = await updateManyDataRows({
                                        variables: {
                                            input: {
                                                data: {
                                                    type: "oldnew",
                                                    rows: [{
                                                        id: rowData.id,
                                                        n: { 'products': product_keys },
                                                        o: false,
                                                        se: se
                                                    }]
                                                }
                                            }
                                        }
                                    })
                                    if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
                                        setProducts(items)
                                    } else {
                                        message.error('Lỗi cập nhật dữ liệu!')
                                    }
                                    // setProducts(items)
                                }} />
                        }
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <Card size="small"
                            title={<div><PictureOutlined style={{ marginRight: 10 }} />Tài nguyên</div>}
                            extra={<Button size={'small'} onClick={() => {
                            }}>
                                Thêm</Button>}>
                            {products &&
                                <ResourceList row_ids={products.map(p => p.key)} />
                            }
                        </Card>

                    </Col>
                </Row>
            </div>
            {/* {showSelectMany &&
                <SelectManyItemModal
                    rowKey={'code'}
                    tableId={commonStore?.company?.companyData?.product_table}
                    title='Chọn nhiều sản phẩm'
                    columns={['code', 'name']}
                    displayColumn={['image', 'code', 'ngay_kiem', 'sl_tonkho', 'name']}
                    itemRender={
                        (item) => {
                            let thumbnail = imagePreset(item.image, 'xsmall')
                            const customLabel = (
                                <span className="list-compact">
                                    <div>
                                        <Image
                                            width={40}
                                            height={40}
                                            src={thumbnail}
                                            preview={{
                                                src: item.image,
                                            }}
                                        />
                                    </div>

                                    <span className="content">
                                        {item.code} {item.name}
                                    </span>
                                </span>
                            );
                            return {
                                label: customLabel,
                                value: item.code,
                            };
                        }
                    }
                    onClose={() => {
                        setShowSelectMany(false)
                    }} />
            } */}
        </>
    )
}

export default inject(
    'usersStore',
    'commonStore',
    'datatableStore'
)(observer(DataRowPCollection))
