import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import {
    Button,
    Modal,
} from 'antd';
import {
    PrinterOutlined
} from '@ant-design/icons';

// import { autorun, toJS } from 'mobx'
import styled, { createGlobalStyle } from 'styled-components'
// import { head } from 'lodash';
import { price_k } from '../../../ultils/datatableUtils';

const PrintableBodyWrapper = styled.div`
 {
    margin: 1cm;
    font-size:10pt;
    table {
        width:85vw;
        font-size:9pt;
        tr {
            border:1px solid #ccc;
            margin:0;
            padding:10px;
            text-align:left;
                td {
                    border-right:1px solid #ccc;
                    padding:5px;
                }
                th {
                    padding: 10px 5px;
                    font-weight:bold;
                }
            }
    }
    .summary {
        font-size:12pt;
        padding: 10px 5px;
    }
  }
`;

class DatatableView extends React.PureComponent {

    render() {
        return (
            <PrintableBodyWrapper>
                {this.props.headerPrint ? this.props.headerPrint : ""}
                <table>
                    <tbody>
                        {
                            this.props.rows.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            this.props.columns.map(col => {
                                                let v = row[col]
                                                if (index === 0) {
                                                    let align = 'left'
                                                    let width = 'auto'
                                                    let colStyle = this.props.colStyle
                                                    if (colStyle) {
                                                        if (colStyle[col]) {
                                                            align = colStyle[col].align || align
                                                            width = colStyle[col].width || width
                                                        }
                                                    }
                                                    return (<th style={{ textAlign: align, width: width }} key={`${index}-${col}`}>{v}</th>)
                                                }
                                                return (<td key={`${index}-${col}`}>{v}</td>)
                                            })
                                        }
                                    </tr>
                                )
                            }
                            )
                        }
                    </tbody>
                </table>
            </PrintableBodyWrapper>
        );
    }
}

const PrintManyItems = ({
    columns,
    title,
    printData,
    headerRow,
    icon = <PrinterOutlined />,
    colStyle
    // table,
    // onClose,
}) => {

    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const rowsToPrint = () => {
        if (!printData.items) return []

        let rows = []
        printData.items.forEach(item => {
            let pRowData = {}
            columns.forEach(col => {
                if (col === 'product_compact') {
                    let r = (
                        <div class="container__text">
                            <span>{item.name}</span>
                            {/* {item.note &&
                                <>
                                    <br />
                                    <strong style={{ marginRight: 8 }}>Ghi chú:</strong>
                                    <em>{item.note}</em>
                                </>} */}
                            {(item.tag_group || item.tag_vitri) && <>
                                <br />
                                <strong style={{ marginRight: 8 }}>Tag:</strong>
                            </>}
                            {item.tag_vitri && item.tag_vitri.length > 0 && <>
                                {item.tag_vitri.join(',')}
                            </>}
                            {item.tag_group && item.tag_group.length > 0 && <>
                                {item.tag_vitri && ' | '}
                                {item.tag_group.join(',')}
                            </>}
                        </div>
                    )
                    pRowData[col] = r
                }
                else if (col === 'gia_von') {

                    let priceBase = item.gia_von
                    let g0
                    let no_giavon = false
                    if (!priceBase || priceBase === 0) {
                        priceBase = item?.gia_nhap * 1.3 || 0
                        no_giavon = true
                    }
                    priceBase = parseInt(priceBase)
                    // pRowData[col] = price_k(priceBase, '?', 3)
                    if (priceBase > 0) {
                        g0 = parseFloat(priceBase * 2 / 1000).toFixed(0)
                        if (no_giavon) g0 = '(' + g0 + ')'
                        let g1 = (priceBase * 2) * 0.95
                        let g2 = (priceBase * 2) * 0.9
                        let g3 = (priceBase * 2) * 0.85
                        g1 = parseFloat(g1 / 1000).toFixed(0)
                        g2 = parseFloat(g2 / 1000).toFixed(0)
                        g3 = parseFloat(g3 / 1000).toFixed(0)
                        let txt = g1 + '-' + g2 + '-' + g3
                        pRowData[col] = (
                            <>
                                {g0} k
                                <br />
                                {txt}
                                <br />
                                live trc: {price_k(item.price_live || 0, '?', 1)}
                            </>
                        )
                    } else {
                        pRowData[col] = "?"
                    }
                }
                else if (col === 'image') {
                    pRowData[col] = <img width={55} height={55} src={item[col]} />
                }
                else if (col === 'price') {
                    pRowData[col] = parseInt(item[col])
                    if (pRowData[col] > 0) {
                        pRowData[col] = (pRowData[col] / 1000).toString() + ' k'
                    } else {
                        pRowData[col] = ""
                    }
                } else if (col === 'cotheban') {
                    let gh = item['sl_giuhang'] > 0 ? item['sl_giuhang'] : 0
                    pRowData[col] = item['sl_tonkho'] - item['sl_giuhang']
                } else {
                    pRowData[col] = item[col]
                }
            });
            rows.push(pRowData)
        });
        return [headerRow, ...rows]
    }

    const headerPrint = () => {
        return (
            <>
                <strong>Tên lượt in: </strong> {printData.title ? printData.title : "..."}
                <br />
                <strong>Ngày in: </strong> {moment().format('L')}
                <br />
                <br />
            </>
        )
    }

    return (
        <>
            <div style={{ display: 'none' }}>
                <DatatableView
                    ref={printRef}
                    title={'In'}
                    columns={columns}
                    rows={rowsToPrint()}
                    headerPrint={headerPrint()}
                    colStyle={colStyle}
                />
            </div>
            <Button shape="round" size={'small'} onClick={handlePrint}
                icon={icon}
            >{title}</Button>
        </>
    )
}
export default inject("datatableStore")(observer(PrintManyItems))

