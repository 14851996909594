import React, { useEffect, useState, useMemo } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Button,
    Input,
    Table,
    Empty,
    Select,
    Badge,
} from 'antd';

// import { autorun, set, toJS } from 'mobx'
// import { debounce } from "lodash-es";
import { flatMap } from "lodash-es"
import { contrastColor } from 'contrast-color'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
import { toJS } from 'mobx';
const { Option } = Select;

const CustomSelect = styled(Select)`
min-width: 120px;
background-color:${props => props.color} !important;
border-color:${props => props.color};
.ant-select-selector {
  background-color:${props => props.color} !important;
  color:${props => contrastColor({ bgColor: props.color })};
}
`;
function myFlat(v) {
    return Object.assign(v.row_data, { key: v.id })
}

const TagsInput = ({
    commonStore, datatableStore,
    placeholder = "chọn thẻ",
    value = [], onChange, initValue, tagTable, tagFilter, selectMode = 'tags',
    tagData = false,
    size = "small"
}) => {
    // selectMode = 'multiple'
    // console.log(`initValue`, initValue)
    const [tags, setTags] = useState(value || [])
    const [options, setOptions] = useState(false)
    const triggerChange = (changedValue) => {
        // onChange?.({
        //     tags,
        //     ...value,
        //     ...changedValue,
        // });
        onChange?.(changedValue);
    };
    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
    const findOptionRow = async (initFilter, tableId) => {
        let dataInput = {
            tableId: tableId,
            hasPreSearch: true,
        }
        if (initFilter)
            dataInput.filter = initFilter
        // if (datatableStore.filterData.sort) dataInput.sort = datatableStore.filterData.sort
        // const { data, error } = await findRowsQuery({
        //     input: {
        //         data: dataInput
        //     }
        // })

        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        return flatMap(results.items, myFlat)
    }

    useEffect(() => {
        async function init() {
            if (tagData) {
                setOptions(tagData)
            } else if (tagTable) {
                let rows = await findOptionRow(tagFilter, tagTable)
                // console.log(`rows`, rows)
                setOptions(rows)
            }
            if (initValue) {
                if (typeof initValue === 'string')
                    setTags(initValue.split('|'))
                else
                    setTags(initValue)
            }
        }
        init()
    }, [])


    const onChangeTag = (newValue) => {
        // console.log(`newValue`, newValue)
        setTags(newValue)
        triggerChange(newValue)
    }

    return (
        <Select mode={selectMode}
            size={size}
            style={{ width: '100%', minWidth: 100 }}
            placeholder={placeholder || 'chọn thẻ'}
            value={tags}
            onChange={onChangeTag}
        >
            {options ? (
                options.map(row => {
                    return (
                        <Option key={row.key} value={row.name} >
                            <Badge color={row.color} text={row?.name} />
                        </Option>
                    )
                })
            ) : ""}
        </Select>
    )

}


// const TagsInput = ({
//     commonStore, datatableStore,
//     preloadKey,
//     tableId,
//     columns,
//     limit,
//     width = 150,
//     initValue,
//     initRow,
//     viewType = "select",
//     emptyLabel = '--trạng thái--',
//     onOk,
//     onSelect,
//     rows
// }) => {
//     // const [matchItems, setMatchItems] = useState([])
//     // const [inputValue, setInputValue] = useState("")

//     const [options, setOptions] = useState(rows)
//     const [inputValue, setInputValue] = useState(initValue ? initValue : '-')
//     // const [currentText, setCurrentText] = useState("")
//     function myFlat(v) {
//         return Object.assign(v.row_data, { key: v.id })
//     }

//     const findRows = useAsyncQuery(DataTableServices.FIND_TABLE_ROW())
//     const findTableRows = async () => {
//         const { data, error } = await findRows({ where: { datatable: tableId } })
//         const results = data ? data.datarowsConnection : ''
//         const values = results?.values || ''
//         if (values && values.length > 0) {
//             return flatMap(values, myFlat)
//         }
//         return false
//     }

//     // useEffect(async () => {
//     //   if (!rows && tableId) {
//     //     rows = await findTableRows()
//     //     setOptions(rows)
//     //   }
//     //   if (liveStore.currentLiveOrder.status) {
//     //     setInputValue(liveStore.currentLiveOrder.status.ref?.key)
//     //     setCurrentColor(liveStore.currentLiveOrder.status.ref.color)
//     //     // doSearch(initValue)
//     //   }
//     // }, [liveStore.currentLiveOrder.status])

//     useEffect(async () => {
//         if (!rows && tableId) {
//             rows = await findTableRows()
//             setOptions(rows)
//         }
//         // if (liveStore.currentLiveOrder.status) {
//         //   setInputValue(liveStore.currentLiveOrder.status.ref?.key)
//         //   setCurrentColor(liveStore.currentLiveOrder.status.ref.color)
//         //   // doSearch(initValue)
//         // }
//     }, [])

//     function onChange(value) {
//         if (value && value != inputValue) {
//             // console.log(`options`, options)
//             let selectedItem = options.find(item => item.key === value)
//             if (selectedItem) {
//                 if (selectedItem.color)
//                     setCurrentColor(selectedItem.color)
//                 setInputValue(value)
//                 onOk(selectedItem)
//             }
//         }
//     }

//     // const renderItem = (row) => {
//     //   return (
//     //     <></>
//     //   )
//     // };


//     if (viewType === 'select')
//         return (
//             // <SearchBoxContainer>
//             <CustomSelect
//                 // style={{ width: width ? width : 200 }}
//                 // defaultValue={initValue?.key ? initValue.key : ""}
//                 // defaultValue={liveStore.currentLiveOrder?.status?.ref?.key}
//                 // defaultValue="60a0c51b9ed797535064a649"
//                 size={'small'}
//                 color={currentColor}
//                 onChange={onChange}
//                 defaultValue={'-'}
//                 value={inputValue}
//             >
//                 <Option key={'-'} value={'-'} style={{ background: 'white', color: "black" }}>
//                     {emptyLabel ? emptyLabel : "--chưa chọn--"}
//                 </Option>
//                 {options ? (
//                     options.map(row => {
//                         return (
//                             <Option key={row.key} value={row.key} style={{ background: row.color, color: contrastColor({ bgColor: row.color }) }}>
//                                 {row.name}
//                             </Option>
//                         )
//                     })
//                 ) : ""}
//             </CustomSelect>
//             // {/* </SearchBoxContainer> */}
//         )

//     if (viewType === 'tag') {
//         return (
//             <>
//             </>
//         )
//     }

// }

export default inject("commonStore", "datatableStore")(observer(TagsInput))
// export default SearchBoxItem