import { makeAutoObservable, autorun, runInAction, toJS } from "mobx"
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { nanoid } from 'nanoid'
import { set as LSet, isEqual, sum } from 'lodash-es'
import { getOrderSummary, getOrderSearch } from '../ultils/datatableUtils'

class DatatableStore {
  dataBoards = false
  currentDataBoard = false
  currentDataTable = false
  currentDatarows = false
  currentJobs = false
  currentRow = false

  updateInterval = null
  changedRows = []
  rowEditingKey = ''
  // rowEditingKey_old = ''
  somethingUnsave = false
  showAddModal = false
  dataTextName = 'dữ liệu'
  selectedRowKeys = []
  preloadTable = {
    status: false,
    tags: false
  }
  newRowData = false
  unSavedData = []
  tempValue = false

  rowDetail = false
  // filterData = {
  //   start: 0,
  //   limit: 50,
  //   sort: [['ngay_tao', -1]],
  //   txt: false
  // }

  filterUpdated = { _sort: false }
  // filterUpdated = false
  rowAction = {
    action: false,
    actionData: false
  }

  order_cart = []
  product_cart = []
  product_print_qty = {}
  showOrderCart = false
  showProductCart = false

  modalFilter = {
    limit: 100,
  }

  modalRows = false

  tagVitriKiemSL = false
  doFocusKiemSL = false

  resetNgaykiem = true

  usersRef = {}

  constructor() {
    makeAutoObservable(this)
  }

  // setInputNumberData = (o) => {
  //   this.inputNumberData = Object.assign(this.inputNumberData, o)
  // }

  get getOrderSummary() {
    let summary = { price: 0, qtyD: 0 }
    if (!this.rowDetail || !this.rowDetail?.items) return summary
    summary.qty = summary.qty || 0
    this.rowDetail.items.forEach(row => {
      let qty = (row.qty ? parseInt(row.qty) : 0)
      if (qty > 0 && row.price) {
        summary.qty += qty
        summary.price += (row.price ? row.price * qty : 0)
      }
    });
    if (this.rowDetail.ship?.ship_fee) summary.ship_fee = this.rowDetail.ship.ship_fee
    if (this.rowDetail.giam_gia) summary.giam_gia = parseInt(this.rowDetail.giam_gia)
    if (this.rowDetail.chuyenkhoan) summary.chuyenkhoan = parseInt(this.rowDetail.chuyenkhoan)
    // if (this.rowDetail.tong_tra) summary.tong_tra = parseInt(this.rowDetail.tong_tra)
    let finalPrice = summary.price
    if (summary.giam_gia) finalPrice -= summary.giam_gia
    if (summary.chuyenkhoan) finalPrice -= summary.chuyenkhoan
    if (summary.ship_fee) finalPrice += summary.ship_fee
    summary.tong_tra = parseInt(finalPrice)
    return summary
  }

  setProductPrintQty = (pq, product = false) => {
    if (!product) this.product_print_qty = pq
    else
      this.product_print_qty = Object.assign(this.product_print_qty, product)
  }

  setResetNgayKiem = (b) => {
    this.resetNgaykiem = b
  }

  setCurrentJobs = (jobs) => {
    this.currentJobs = jobs
  }

  requestSkipJob = async (data) => {
    let index = this.currentJobs.findIndex(j => j.id === data.id)
    if (index > -1) {
      // if (data.note) {
      //   let newNote = {
      //     title: `Hoãn đơn [${data.row?.cname}] ${data.row?.mobile} `,
      //     content: data.note,
      //     res_id: this.currentJobs[index].id
      //   }

      // }
      if (!this.currentJobs[index].jdata) this.currentJobs[index].jdata = {}
      this.currentJobs[index].jdata.requestSkip = {
        creator: data.creator,
        note: data?.note,
        status: data?.status || "chờ xác nhận"
      }

      // if (saveJob) {
      //   let s = await saveJob({
      //     variables: {
      //       input: {
      //         data: {
      //           id: data.id,
      //           updateData: {
      //             requestSkip: this.currentJobs[index].requestSkip
      //           }
      //         }
      //       }
      //     }
      //   })
      // }
    }
  }

  saveRequestSkipJob = async (data, saveJob, addNote) => {
    this.requestSkipJob(data)
    let index = this.currentJobs.findIndex(j => j.id === data.id)
    if (saveJob) {
      let s = await saveJob({
        variables: {
          input: {
            data: {
              id: data.id,
              updateData: {
                "jdata.requestSkip": this.currentJobs[index].jdata.requestSkip
              }
            }
          }
        }
      })
    }
  }

  setUsersRef = (uref) => {
    // console.log('uref', uref)
    this.usersRef = Object.assign(this.usersRef, uref)
  }

  setTagVitriKiemSL = (tags) => {
    this.tagVitriKiemSL = tags
  }

  setDoFocusKiemSL = (v) => {
    this.doFocusKiemSL = v
  }

  setModalFilter = (f) => {
    this.modalFilter = Object.assign(this.modalFilter, f)
    // this.modalFilter = f
  }

  setCurrentRow = (row) => {
    this.currentRow = row
  }

  setModalRows = (rows) => {
    this.modalRows = rows
  }

  add2cart = (orders) => {
    orders.forEach(o => {
      if (!this.order_cart.find(item => item.id === o.id)) {
        this.order_cart.push(o)
      }
    });
  }
  addProduct2Cart = (products) => {
    let pp = products.filter(p => !this.product_cart.find(item => item.id === p.id))
    this.product_cart = pp.concat(this.product_cart)
    // products.forEach(p => {
    //   if (!this.product_cart.find(item => item.id === p.id)) {
    //     this.product_cart.push(p)
    //   }
    // });
  }

  emptyCart = () => {
    this.order_cart = []
  }

  updateProductCart = (products, product = false) => {
    if (product) {
      console.log('product', product)
      let index = this.product_cart.findIndex(p => p.id === product.id)
      if (index > -1) {
        this.product_cart[index] = Object.assign(this.product_cart[index], product)
      }
    } else
      this.product_cart = products
  }

  setShowOrderCart = (b) => {
    this.showOrderCart = b
  }

  setShowProductCart = (b) => {
    this.showProductCart = b
  }


  massUpdateRows = (key, value, ids, rows = false) => {
    // let keyUpdate = 'row_data.' + key
    let temp = toJS(this.currentDatarows)
    for (let index = 0; index < temp.length; index++) {
      if (rows) {
        let matchRow = rows.find(row => row.id === temp[index].key)
        if (matchRow) {
          LSet(temp[index], key, matchRow.n[key]);
        }
      } else if (ids.includes(temp[index].key)) {
        LSet(temp[index], key, value);
      }
    }
    this.currentDatarows = temp
  }

  updateManyRowsByField = (updateItems, fieldUpdate, useQuickList = false) => {
    let temp = false
    if (useQuickList) {
      temp = toJS(this.product_cart)
    } else
      temp = toJS(this.currentDatarows)

    if (updateItems) {
      for (let index = 0; index < updateItems.length; index++) {
        let u = updateItems[index]
        let fIndex = temp.findIndex(t => (t.id || t.key) === u.id)
        if (fIndex > -1) {
          let v = u.udata['row_data.' + fieldUpdate]
          if (v) {
            temp[fIndex][fieldUpdate] = v
          }
        }
      }
    }

    if (useQuickList) {
      this.product_cart = temp
    }
    else
      this.currentDatarows = temp
  }

  massUpdateSL_giuhang = (update) => {
    let temp = toJS(this.currentDatarows)
    for (let index = 0; index < temp.length; index++) {
      let matchRow = update.find(row => row.id === temp[index].key)
      if (matchRow) {
        // LSet(temp[index], 'sl_giuhang', matchRow.qty);
        LSet(temp[index], 'giuhang', matchRow.giuhang);
      }
    }
    this.currentDatarows = temp
  }

  updateOrderItem = async (newItem, orderId, updateManyDataRows, actionType = 'update') => {
    let temp = toJS(this.rowDetail)
    let index = temp?.items.findIndex(item => item.code === newItem.code)
    if (index >= 0) {
      if (actionType === 'add') {
        alert('Trùng mã')
        return false
      }
      let oldItem = temp.items[index]
      let n = {
        'items': {}
      }
      let o = {
        items: {}
      }
      if (actionType === 'delete') {
        temp.items.splice(index, 1);
        n.items = {
          _arr: temp.items,
          item: newItem
        }
        n.pcount =
          temp.items.length

        n.tong_sl = 0
        temp.items.forEach(it => {
          n.tong_sl += it?.qty || 0
        });

        o.items = {
          delete: oldItem
        }
      } else if (actionType === 'update') {
        if (oldItem.qty == newItem.qty && oldItem.price == newItem.price && oldItem.note == newItem.note && oldItem.weight == newItem.weight) {
          return false
        }
        temp.items[index] = newItem
        n.items = {
          _arr: temp.items,
          item: newItem
        }
        o.items = {
          update: oldItem
        }
      }
      // if (isEqual(oldItem, newItem)) return false

      // let n = {
      //   'items': {
      //     _arr: temp.items,
      //     item: newItem
      //   }
      // }
      // let o = {
      //   items: {
      //     update: oldItem
      //   }
      // }
      let se = getOrderSearch(temp)
      // console.log(`se`, se)
      let summary = getOrderSummary(temp)
      // console.log(`summary`, summary)
      n.tong_tra = summary.tong_tra
      n.tong_sl = summary.qty
      n.pcount = summary.pcount
      o.tong_tra = temp.tong_tra
      o.tong_sl = temp.tong_sl

      if (o.tong_tra === n.tong_tra) {
        delete n.tong_tra
        delete o.tong_tra
      }

      if (o.tong_sl === n.tong_sl) {
        delete n.tong_sl
        delete o.tong_sl
      }

      let d = {
        n: n,
        o: o,
        t: 'array',
        id: orderId
      }
      if (se != '') d.se = se
      let r = await updateManyDataRows({
        variables: {
          input: {
            data: {
              type: "oldnew",
              rows: [d]
            }
          }
        }
      })
      if (r) {
        return {
          tong_tra: summary.tong_tra,
          tong_sl: summary.tong_sl,
          pcount: summary.pcount
        }
      } else {
        return false
      }

    } else {
      // Add new item
      if (actionType !== 'add') return false
      temp.items.unshift(newItem)
      let n = {
        'items': {
          _arr: temp.items,
          item: newItem
        }
      }
      let o = {
        items: {
          add: true
        }
      }
      let se = getOrderSearch(temp)
      // console.log(`se`, se)
      let summary = getOrderSummary(temp)
      // console.log(`summary`, summary)
      n.tong_tra = summary.tong_tra
      n.tong_sl = summary.qty
      n.pcount = summary.pcount
      o.tong_tra = temp.tong_tra
      o.tong_sl = temp.tong_sl

      if (o.tong_tra === n.tong_tra) {
        delete n.tong_tra
        delete o.tong_tra
      }

      if (o.tong_sl === n.tong_sl) {
        delete n.tong_sl
        delete o.tong_sl
      }

      let d = {
        n: n,
        o: o,
        t: 'array',
        id: orderId
      }
      if (se != '') d.se = se
      let r = await updateManyDataRows({
        variables: {
          input: {
            data: {
              type: "oldnew",
              rows: [d]
            }
          }
        }
      })
      if (r) {
        return {
          tong_tra: summary.tong_tra,
          tong_sl: summary.tong_sl
        }
      } else {
        return false
      }
    }
  }

  setRowDetail = (r) => {
    this.rowDetail = r
  }

  setFilterUpdated = (f) => {
    this.filterUpdated = f
  }

  setTempValue = (t) => {
    this.tempValue = t
  }

  setPreloadTable = (key, value) => {
    if (!key)
      this.preloadTable = value
    else
      this.preloadTable[key] = value
  }

  setShowAddModal = (b) => {
    this.showAddModal = b
  }

  setAction = (a, ad) => {
    this.action = a
    this.actionData = ad
  }



  // setFilter = (f) => {
  //   this.filterData = Object.assign(this.filterData, f)
  //   // console.log(`this.filterData`, this.filterData)
  // }

  setSelectedRowKeys = (srk) => {
    this.selectedRowKeys = srk
  }

  resetFilter = () => {
    // this.filterData = {
    //   start: 0,
    //   limit: 50,
    //   sort: [['ngay_tao', -1]],
    //   txt: false
    // }
    this.filterUpdated = { _sort: false }
    // this.filterUpdated = false
  }
  // getOrder = (sid) => {
  //   return this.liveOrders.find(item=>item.rid === )
  // }

  setSomethingUnsave = (v) => {
    this.somethingUnsave = v
  }

  resetTableData = () => {
    this.rowEditingKey = ''
    this.changedRows = []
    this.currentDataTable = false
    this.currentDatarows = []
  }

  setCurrentDataBoard = (data) => {
    this.currentDataBoard = data
  }

  setDataBoards = (data) => {
    this.dataBoards = data
  }

  setRowEditingKey = (k) => {
    // if (k !== '')
    //   this.rowEditingKey_old = this.rowEditingKey
    // else
    //   this.rowEditingKey_old = ''

    this.rowEditingKey = k
  }

  setCurrentDatarows = (rs) => {
    this.currentDatarows = rs
  }

  setCurrentDatatable = (dt) => {
    this.currentDataTable = dt
  }

  quickAddRow = (table, numberAdd = 1) => {
    // console.log(`toJS(rows`, toJS(rows))
    let columns = table.table_columns ? table.table_columns : table.data_table_template.table_columns
    let quickAddColumn = table.table_data?.column_quick
    let out = []

    for (let index = 0; index < numberAdd; index++) {
      let sid = nanoid(10)
      let newData = { sid: sid, key: sid };
      columns.forEach(col => {
        if (quickAddColumn) {
          quickAddColumn.forEach(qac => {
            qac.forEach(qacCol => {
              if (col.dataIndex === qacCol.field) {
                if (!col.type || col.type == 'text')
                  newData[col.dataIndex] = ""
                else if (col.type == 'number') {
                  newData[col.dataIndex] = 0
                }
                else if (col.type == 'image') {
                  newData[col.dataIndex] = '/noimage.jpg'
                }
              }
            });
          });
          // if (quickAddColumn.includes(col.dataIndex)) {
          //   if (!col.type || col.type == 'text')
          //     newData[col.dataIndex] = ""
          //   else if (col.type == 'number') {
          //     newData[col.dataIndex] = 0
          //   }
          //   else if (col.type == 'image') {
          //     newData[col.dataIndex] = '/noimage.jpg'
          //   }
          // }
        }
        if (col.dataIndex == 'ngay_tao') {
          newData['ngay_tao'] = new Date()
          newData['ngay_tao'] = newData['ngay_tao'].toISOString()
        }
      })
      // quickAddColumn.forEach(qc => {
      //   console.log(`qc`, qc)
      //   if (!columns[qc]?.type || columns[qc]?.type == 'text')
      //     newData[qc] = ""
      //   else {
      //     if (columns[qc]?.type == 'number') {
      //       newData[qc] = 0
      //     }
      //   }
      //   if (qc === 'ngay_tao') {
      //     newData['ngay_tao'] = new Date()
      //     newData['ngay_tao'] = newData['ngay_tao'].toISOString()
      //   }
      // });
      out.push(newData)
    }
    // console.log(`out`, out)
    this.newRowData = out
    // setRows([newData, ...rows])
  }

  // updateRows = (updateRows) => {
  //   let temp = toJS(this.currentDatarows)
  //   updateRows.forEach(row => {
  //     let index = temp.findIndex(item => item.code === row.code)
  //     if (index >= 0) {
  //       temp[index] = row
  //     }
  //   });
  //   // for (let index = 0; index < temp.length; index++) {
  //   //   if (ids.includes(temp[index].key)) {
  //   //     LSet(temp[index], key, value);
  //   //   }
  //   // }
  //   this.currentDatarows = temp
  // }

  updateRow = (row, sid) => {
    const newData = [...this.currentDatarows]
    const index = newData.findIndex((item) => sid === item.sid);

    if (index > -1) {
      const item = newData[index]
      newData.splice(index, 1, { ...item, ...row })
      this.currentDatarows = newData
      if (!this.changedRows.includes(sid)) {
        console.log('push save' + sid)
        this.changedRows.push(sid)
      }
    } else {
      newData.push(row);
      this.currentDatarows = newData
    }
    this.rowEditingKey = ''
  }

  setChangedRows = (v) => {
    this.changedRows = v
  }

}

export default new DatatableStore()
