import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
// import { useMutation } from '@apollo/react-hooks'
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import axios from 'axios'
// import Cookies from 'js-cookie'
// import config from '../../../config'

import {
    List,
    Drawer,
    Button,
    Image,
    message,
    Space,
    Row,
    Col
} from 'antd';
// import moment from 'moment'
// import MarkdownIt from 'markdown-it';
import {
    CopyOutlined,
} from '@ant-design/icons';

// import { imagePreset } from '../../../ultils/datatableUtils'
import ResourceListItem from './ResourceListItem'
import ResourceDetail from './ResourceDetail'

import DataTableServices from '../../../services/datatableServices'
import useAsyncQuery from '../../../ultils/useAsyncQuery'

// const mdParser = new MarkdownIt(/* Markdown-it options */);

const ResourceList = ({
    datatableStore,
    commonStore,
    usersStore,
    rows,
    row_ids = false,
    currentResTag = ['all'],
    currentResType = 'all'
}) => {

    const [showResDetail, setShowResDetail] = useState(false)
    const [resources, setResources] = useState(rows || [])

    const findResQuery = useAsyncQuery(DataTableServices.FIND_RESOURCES())
    const getResources = async (dataInput) => {
        const { data, error } = await findResQuery({
            input: {
                data: dataInput
            }
        })
        let result = data?.findResources?.data
        if (result.status === 'OK')
            return result.items
        return false
    }

    useEffect(() => {
        async function init() {
            if (row_ids) {
                let res = await getResources({ row_ids: row_ids })
                setResources(res)
            } else {
                setResources(rows)
            }
        }
        init()
    }, [row_ids, rows])

    // useEffect(() => {
      
    
    //   return () => {
        
    //   }
    // }, [showResDetail])
    

    // let title = truncate(resource?.name || '', 35)
    return (
        <>
            <List
                className={'list-resources'}
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3
                }}
                dataSource={resources.filter(t => {
                    if (!currentResTag.includes('all')) {
                        let rtags = t?.rdata?.tags
                        for (let index = 0; index < currentResTag.length; index++) {
                            const cTag = currentResTag[index];
                            if (rtags && !rtags.includes(cTag)) return false
                        }
                    }
                    if (currentResType === 'all') return true
                    return t?.type === currentResType
                })}
                renderItem={item => <ResourceListItem resource={item} onClick={() => {
                    if (!showResDetail) {
                        setShowResDetail(item)
                    }
                    else {
                        setShowResDetail(false)
                    }
                }} />}
            />

            {/* <Drawer
                width="100%"
                // closable={true}
                style={{ maxWidth: 960 }}
                visible={showResDetail ? true : false}
                title='Chi tiết tài nguyên'
                placement="right"
                onClose={() => {
                    setShowResDetail(false)
                }}
            >
                <ResourceDetail resource={showResDetail} />
            </Drawer> */}

            {showResDetail &&
                <Drawer
                    width="100%"
                    closable={true}
                    style={{ maxWidth: 960 }}
                    // visible={showResDetail ? true : false}
                    visible={true}
                    title='Chi tiết tài nguyên'
                    placement="right"
                    onClose={() => {
                        setShowResDetail(false)
                    }}
                >
                    <ResourceDetail resource={showResDetail}
                        onDelete={(id) => {
                            setResources(resources.filter(r => r.id !== id))
                            setShowResDetail(false)
                        }}
                        updateResources={(res) => {
                            let index = resources.find(r => r.id === res.id)
                            let rr = resources
                            if (index > -1) rr[index] = res
                            setResources(rr)
                        }}
                    />
                </Drawer>}
        </>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(ResourceList))