// import DataTableServices from '../services/datatableServices'
// import useAsyncQuery from './useAsyncQuery'
/** App utils */

import config from '../config'
import { merge as LMerge, isObject, isEqual, isPlainObject, reduce, isEmpty, isArray } from 'lodash-es'
import moment from 'moment'

const str2number = {
  "một": 1, "mot": 1,
  "hai": 2,
  "ba": 3,
  "bon": 4, "bốn": 4,
  "nam": 5, "năm": 5, "lăm": 5,
  "sau": 6, "sáu": 6,
  "bay": 7, "bảy": 7,
  "tam": 8, "tám": 8,
  "chin": 9, "chín": 9,
  "muoi": 10, "mười": 10,
}

const removeVietnameseCharMark = str => {
  if (str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
    str = str.replace(/Đ/g, 'D')
    return str
  }
  return str
}

function valueByPath(row, c) {
  let path = c.split('.')
  // console.log(`path`, path)
  if (c.length == 1) return row[c]
  let out = row
  path.forEach(p => {
    out = out[p]
  });
  if (isObject(out)) return ""
  if (!out) return ""
  return out
}

const build_search_by_column = (row_data, column_search) => {
  let se = false
  // let column_search = datatable.table_data?.column_search
  if (column_search) {
    column_search.forEach(s => {
      let key = s?.key
      // let v = row_data[key]
      let v = valueByPath(row_data, key)
      if (v !== undefined) {
        if (s.extra) {
          if (s.extra.type === "stock_products") {
            let s = ""
            Object.keys(v).forEach(code => {
              s = s + " " + code
            })
            v = s
          }
        }
        if (!se) se = v
        else
          se += " " + v
      }
    });
  }
  if (se) se = removeVietnameseCharMark(se.toLowerCase())
  return se
}

const colorByQuanity = qty => {
  if (qty === 0) return "#DDDDDD"
  let color = "#D6AD5C"
  if (!qty) qty = 1
  if (qty > 20) color = "#52BE7A"
  else if (qty > 10) color = "#5296BE"
  return color
}

const price_k = (price, nanValue = '?', type = 1) => {
  if (price === undefined) return nanValue
  let out = nanValue
  if (price || price === 0) {
    out = parseInt(price)
    if (isNaN(out)) {
      return nanValue
    }
    switch (type) {
      case 1:
        out = out / 1000
        if (out < 1) out = 1
        out = out.toString() + ' k'
        return out
      case 2:
        return out.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
      case 3:
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(out)
      default:
        return out
    }

  }
}

const date_str = (date, nanValue = '?', type = 1, format = "DD/MM/YY HH:mm") => {
  if (date === undefined || !date || date === "") return nanValue
  switch (type) {
    case 1:
      return moment(date).format(format)
    case 2:
    case 3:
    default:
      return nanValue
  }
}

const makeCodeTmpRegex = (arr, column) => {
  var newArr = arr.filter(item => item[column])
  var out = []
  // var noCode = []
  // var hasCode = []
  newArr.forEach((item) => {
    // console.log('item', item)
    let tempPattern = []
    item[column].forEach((t) => {
      // console.log('removeVietnameseCharMark(t)', removeVietnameseCharMark(t))
      let words = (removeVietnameseCharMark(t)).match(/\w+/gi);
      if (words.length > 0) words = words.join('.+')
      else words = words[0]
      tempPattern.push(words)
    });
    item.pattern = tempPattern.join('|')
    delete item['code_tmp']
    out.push(item)
    // if (item.code) {
    //   out.push(
    //     {
    //       code: item.code,
    //       pattern: tempPattern.join('|'),
    //       so_luong: item.so_luong
    //     })
    // } else {
    //   out.push(
    //     {
    //       pattern: tempPattern.join('|'),
    //       so_luong: item.so_luong
    //     })
    //   // console.log('tempPattern', tempPattern)
    //   // noCode = noCode.concat(tempPattern)
    // }
  });
  // console.log('out', out)
  return out
  // out = {
  //   hasCode,
  //   noCode: noCode.join('|')
  // }
  // console.log('out', out)
  // return out
}

const string2number = (txt) => {
  return str2number[txt] ? str2number[txt] : txt
}

// const rowsByText = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

// const findRowsByText = async (txt, columns, tableId, limit) => {
//   const { data, error } = await rowsByText({
//     input: {
//       data: {
//         txt: txt,
//         columns: columns,
//         tableId: tableId,
//         limit: limit ? limit : 50
//       }
//     }
//   })
//   var results = data?.findDataRow?.data?.items
//   if (results) return results
//   return []
// }

const imagePreset = (str, preset) => {
  if (!str) return "/xsmall-noimage.jpg"
  // if (!str.includes(config.ASSET_URL)) return str
  if (!str.includes('https://storage.googleapis.com')) return str
  var res = str.replace(/^.*(\\|\/|\:)/, '');
  return str.replace(res, preset + '_' + res);
}

const getRevisionData = (oldRow, newRow, table_id) => {
  let out = {
    o: {},
    n: {},
    id: oldRow.key
  }
  if (out.id && out.id.length <= 10) { // tu hieu la them moi
    out.add = LMerge({ ...oldRow }, newRow)
    out.add.table = table_id
    return out
  }
  let notFound = true
  for (const [key, value] of Object.entries(newRow)) {
    if (oldRow[key] !== value) {
      notFound = false
      out.o[key] = oldRow[key]
      out.n[key] = newRow[key]
    }
  }
  // console.log(`out`, out)
  return (notFound ? false : out)
}

const getDiffInfo = (oldData, newData) => {
  // console.log(`oldData`, oldData)
  // console.log(`newData`, newData)
  let diffObject = diff(newData, oldData)
  // console.log(`diffObject`, diffObject)
  let updateData = flattenKeys(diffObject)
  let oldDif = diff(oldData, newData)
  let out = {
    n: updateData,
    o: flattenKeys(oldDif),
    id: newData.key
  }
  return out
}

const buildFilterByParam = (params) => {
  if (params != "") {
    let q = params.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
    let updateFilter = {}
    if (q.limit) {
      updateFilter.limit = parseInt(q.limit)
      delete q.limit
    } else {
      updateFilter.limit = 40
    }
    if (q.start) {
      updateFilter.start = parseInt(q.start)
      delete q.start
    } else {
      updateFilter.start = 0
    }
    if (q._sort) {
      updateFilter._sort = q._sort
      delete q._sort
    }
    if (q.txt) {
      updateFilter.txt = q.txt
      delete q.txt
    }

    updateFilter.match = q
    if (Object.keys(updateFilter).length > 0) {
      return updateFilter
    }
  }
  return false
}

const updateFilterParam = (updateFilter, data) => {
  // console.log(`data`, data)
  function buildParamUrl(uFilter) {
    let search = '?'
    for (const [key, value] of Object.entries(uFilter)) {
      if (key === 'match') {
        for (const [k, v] of Object.entries(value)) {
          if (search === '?') {
            search += k + '=' + v
          } else {
            search += '&' + k + '=' + v
          }
        }
      } else {
        if (key === "limit" || key === "start" || key === "_sort" || key === 'txt') {
          if (search === '?') {
            search += key + '=' + value
          } else {
            search += '&' + key + '=' + value
          }
        }
      }
    }
    return search
  }
  if (!updateFilter) updateFilter = {}
  if (!updateFilter.match) updateFilter.match = {}

  for (const [key, value] of Object.entries(data)) {
    if (key === 'limit' || key === 'start' || key === 'txt' || key === '_sort') {
      if (!value && updateFilter[key] && value != 0)
        delete updateFilter[key]
      else
        updateFilter[key] = value
    }
    else {
      // console.log(`key`, key)
      // console.log(`value`, value)
      // console.log(`updateFilter.match`, updateFilter.match)
      if (!value && value !== 0) {
        if (updateFilter.match[key]) delete updateFilter.match[key]
      } else {
        updateFilter.match[key] = value
      }
      if (updateFilter.match[key + '_ne']) delete updateFilter.match[key + '_ne']
    }
  }
  // console.log(`updateFilter`, updateFilter)
  return buildParamUrl(updateFilter)
}

const getOrderSummary = (orderRowData) => {
  let summary = { price: 0, qty: 0 }
  if (!orderRowData || !orderRowData?.items) return summary
  orderRowData.items.forEach(row => {
    let qty = (row.qty ? parseInt(row.qty) : 0)
    if (qty > 0 && row.price) {
      // summary.qty += qty
      summary.price += (row.price ? row.price * qty : 0)
    }
    if (qty > 0) summary.qty += qty
  });
  if (orderRowData.ship?.ship_fee) summary.ship_fee = orderRowData.ship.ship_fee
  if (orderRowData.giam_gia) summary.giam_gia = parseInt(orderRowData.giam_gia)
  if (orderRowData.chuyenkhoan) summary.chuyenkhoan = parseInt(orderRowData.chuyenkhoan)
  // if (orderRowData.tong_tra) summary.tong_tra = parseInt(orderRowData.tong_tra)
  let finalPrice = summary.price
  if (summary.giam_gia) finalPrice -= summary.giam_gia
  if (summary.chuyenkhoan) finalPrice -= summary.chuyenkhoan
  if (summary.ship_fee) finalPrice += summary.ship_fee
  summary.tong_tra = parseInt(finalPrice)
  if (orderRowData.items)
    summary.pcount = orderRowData.items.length || 0
  return summary
}

// const getTotalWeight = async (orderRowData, table_id, getProductByCodes) => {
//   let summary = { weight: 0, noWeight: [] }
//   if (!orderRowData || !orderRowData?.items) return summary

//   orderRowData.items.forEach(p => {
//     if (p.weight && p.weight > 0) summary.weight += p.weight * p.qty
//     else
//       summary.noWeight.push(p)
//   });
//   // if (summary.weight === 0) {
//   //   let codes = orderRowData.item.map(r => r.code)
//   // }
//   if (summary.noWeight.length > 0) {
//     let codes = summary.noWeight.map(r => r.code)
//     const input = {
//       data: {
//         table_id: table_id,
//         codes: codes
//       }
//     }
//     let pRef = await getProductByCodes({
//       variables: { input }
//     })
//     pRef = pRef?.data?.getProductByCodes?.data
//     if (pRef?.status === 'OK') {
//       summary.noWeight.forEach((nw, index) => {
//         if (pRef.products[nw.code] && pRef.products[nw.code].weight) {
//           summary.weight += pRef.products[nw.code].weight * nw.qty
//           summary.noWeight[index] = false
//         }
//       });
//       summary.noWeight = summary.noWeight.filter(nw => nw != false)
//     }
//   }
//   console.log(`summary`, summary)
//   return summary
// }

const recalcWeight = (items) => {
  let total = 0
  items.forEach(p => {
    if (p.weight && p.weight > 0) total += p.weight
  });
  return total
}

// const getAllWeight = async (orderRowData, table_id, getProductByCodes) => {
//   if (!orderRowData || !orderRowData?.items) return false
//   let totalWeight = 0
//   let noWeight = false
//   orderRowData.items.forEach((p, index) => {
//     if (p.weight && p.weight > 0) totalWeight += p.weight * p.qty
//     else {
//       orderRowData.items[index].weight = 0
//       totalWeight += 250 * p.qty
//       // noWeight = true
//     }
//   });

//   if (noWeight) {
//     let codes = orderRowData.items.filter(p => !(p.weight && p.weight > 0)).map(r => r.code)

//     const input = {
//       data: {
//         table_id: table_id,
//         codes: codes
//       }
//     }
//     let pRef = await getProductByCodes({
//       variables: { input }
//     })
//     pRef = pRef?.data?.getProductByCodes?.data
//     if (pRef?.status === 'OK') {
//       orderRowData.items.forEach((p, index) => {
//         orderRowData.items[index].pref = pRef.products[p.code]
//         if (!(p.weight && p.weight > 0)) {
//           if (pRef.products[p.code] && pRef.products[p.code].weight > 0) {
//             orderRowData.items[index].weight = pRef.products[p.code].weight
//             totalWeight += pRef.products[p.code].weight * p.qty
//           } else {
//             orderRowData.items[index].weight = 0
//           }
//         }
//       });
//     }
//   }
//   orderRowData.autoWeight = totalWeight
//   console.log('orderRowData', orderRowData)
//   return totalWeight
// }

const getPRefByOrderItems = async (orderRowData, table_id, getProductByCodes) => {
  if (!orderRowData || !orderRowData?.items) return false
  let codes = orderRowData.items.map(p => p.code)
  const input = {
    data: {
      table_id: table_id,
      codes: codes
    }
  }
  let prefs = await getProductByCodes({
    variables: { input }
  })
  prefs = prefs?.data?.getProductByCodes?.data
  if (prefs?.status === 'OK') {
    prefs = prefs.products
  } else {
    prefs = {}
  }
  let totalWeight = 0
  // let noWeight = false
  orderRowData.items.forEach((p, index) => {
    if (p.weight && p.weight > 0) totalWeight += p.weight * p.qty
    else {
      orderRowData.items[index].weight = 0
      if (prefs[p.code] && prefs[p.code].weight > 0) {
        orderRowData.items[index].weight = prefs[p.code].weight
        totalWeight += prefs[p.code].weight * p.qty
      } else {
        orderRowData.items[index].weight = 0
        totalWeight += 250 * p.qty
      }
    }
  });
  orderRowData.autoWeight = totalWeight
  return prefs
}



const getOrderSearch = (orderData) => {
  let se = orderData.code
  if (orderData.customer?.mobile) se += ' ' + orderData.customer?.mobile
  if (orderData.customer?.name) se += ' ' + orderData.customer?.name

  if (!orderData.ship?.nguoinhan_name)
    if (orderData.customer?.sl_user) se += ' ' + orderData.customer?.sl_user

  if (orderData.ship?.nguoinhan_name) se += ' ' + orderData.ship?.nguoinhan_name
  if (orderData.ship?.nguoinhan_mobile) se += ' ' + orderData.ship?.nguoinhan_mobile
  // if (orderData.province && orderData.)
  let province = orderData.province ? orderData.province.ProvinceName : orderData.ship?.sl_province
  let district = orderData.district ? orderData.district.DistrictName : orderData.ship?.sl_district
  let ward = orderData.ward ? orderData.ward.WardName : orderData.ship?.sl_ward

  if (province && district && ward) {
    let addr_all = (province + ' ' + district + ' ' + ward).replace(/Thành phố|Thành Phố|Phường|Quận|Huyện|Xã|Thị trấn|Tỉnh/gi, '')
    se += ' ' + addr_all
  } else
    if (orderData.ship?.sl_address_all) se += ' ' + orderData.ship.sl_address_all.replace(/Thành phố|Thành Phố|Phường|Quận|Huyện|Xã|Thị trấn|Tỉnh/gi, '')

  if (orderData.order_code) se += ' ' + orderData.order_code

  orderData.items.forEach(row => {
    let qty = (row.qty ? parseInt(row.qty) : 0)
    if (qty > 0 && row.price) {
      se += ' ' + row.code + ' ' + row.name
    }
  });
  if (se) {
    return removeVietnameseCharMark(se).toLowerCase()
  }
  return ""
}

const flattenKeys = (obj, path = []) => {
  if (isArray(obj)) return { [path.join('.')]: obj }
  return !isObject(obj)
    ? { [path.join('.')]: obj }
    : reduce(obj, (cum, next, key) => LMerge(cum, flattenKeys(next, [...path, key])), {});
}

const diff = function (obj1, obj2) {
  return reduce(obj1, function (result, value, key) {
    // if (key === 'tags') {
    //   result[key] = value
    //   console.log(`result`, result)
    //   return result
    //   // debugger  
    //   console.log(`vv`, value)
    //   console.log(`kk`, key)
    // }
    // if (!obj2[key]) {
    //   result[key] = value
    //   return result
    // }
    // if (key === 'tong_sl') {
    //   debugger
    // }
    try {
      if (!obj2[key]) {
        if (obj2[key] !== 0) {
          if (value !== undefined)
            result[key] = value
        } else {
          result[key] = value
        }
        // if (obj2[key]===undefined) return result
        return result
      }
      else if (isPlainObject(value)) {
        result[key] = diff(value, obj2[key]);
        if (isEmpty(result[key])) delete result[key]
      }
      else if (!isEqual(value, obj2[key])) {
        result[key] = value;
      }
      return result;
    } catch (error) {
      console.log(`error`, error)
    }
    return result;
  }, {});
};

const correctCode = (code) => {
  if (code) {
    // code = code.replace(' ', '')
    // code = code.replace('  ', '')
    code = code.replace(/\s/g, '');
    code = code.replace(/\+|_/g, '-');
    // code = code.replace('+', '-')
    // code = code.replace('_', '-')
    code = code.toUpperCase()
    return code.trim()
  }
  return code
}

export {
  string2number,
  removeVietnameseCharMark,
  build_search_by_column,
  makeCodeTmpRegex,
  colorByQuanity,
  imagePreset,
  getRevisionData,
  price_k,
  buildFilterByParam,
  updateFilterParam,
  valueByPath,
  getOrderSummary,
  getOrderSearch,
  getPRefByOrderItems,
  recalcWeight,
  diff,
  flattenKeys,
  getDiffInfo,
  correctCode,
  date_str
  // findRowsByText
}