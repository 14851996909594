import React, { useState } from 'react'
import { Card, Avatar } from 'antd'

const { Meta } = Card
const StyledCard = () => {
  const [loading, setLoading] = useState(false)

  return (
    <div>
      {/* <Switch checked={!loading} onChange={onChange} /> */}
      <Card style={{ width: '100%', marginTop: 16 }} loading={loading}>
        <Meta
          avatar={
            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          }
          title="Card title"
          description="This is the description"
        />
      </Card>
    </div>
  )
}

export default StyledCard
