import React, { useEffect, useRef, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
// import DataTableServices from '../../../../../services/datatableServices'

import {
    // Empty,
    Card,
    Image,
    Button,
    Space
} from 'antd';
import {
    CloseOutlined,
    LinkOutlined,
    BarcodeOutlined,
    PlusOutlined
} from '@ant-design/icons';

// import { unionBy, flatMap, merge as LMerge } from "lodash-es"
// import { autorun, toJS, get } from 'mobx'
import { imagePreset } from '../../../../ultils/datatableUtils'
import ProductList from '../DataView/ProductList'
import SelectManyItemModal from '../../Tools/SelectManyItemModal'
import DataSelectModal from '../DataTools/DataSelectModal'

// const { confirm } = Modal;

const ManyProducts = ({ datatableStore, commonStore, products, onSave, view = "default" }) => {
    const [showSelectMany, setShowSelectMany] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    // const [products, setProducts] = useState(rows)
    return (
        <>
            <Card size="small"
                title={<div><BarcodeOutlined style={{ marginRight: 10 }} />Sản phẩm</div>}
                extra={
                    <Space>
                        <Button icon={<LinkOutlined />} size={'small'} onClick={() => {
                            let keys = products.map(hi => hi.code) || []
                            if (keys.length > 0) {
                                let keyStr = keys.join(',')
                                keyStr = "https://hub.nhayeu.com/table/" + commonStore?.company?.companyData?.product_table + '?limit=300&codes=' + keyStr
                                window.open(keyStr, '_blank')
                            }
                        }}>HUB</Button>
                        <Button icon={<LinkOutlined />} size={'small'} onClick={() => {
                            let keys = products.map(hi => hi.code) || []
                            if (keys.length > 0) {
                                let keyStr = keys.join(',')
                                keyStr = "https://we.nhayeu.com/table/" + commonStore?.company?.companyData?.product_table + '?limit=300&codes=' + keyStr
                                window.open(keyStr, '_blank')
                            }
                        }}>WE</Button>
                        <Button icon={<PlusOutlined />} size={'small'} onClick={() => {
                            setShowSelectMany(true)
                        }}> nhiều</Button>
                        <Button type='primary' icon={<PlusOutlined />} size={'small'}
                            onClick={() => {
                                setShowAddModal(true)
                            }}
                        > nhanh</Button>
                    </Space>

                }

            >
                {products &&
                    <ProductList rows={products} onSave={onSave} view={view} />
                }
            </Card>


            {showSelectMany &&
                <SelectManyItemModal
                    rowKey={'code'}
                    tableId={commonStore?.company?.companyData?.product_table}
                    title='Chọn nhiều sản phẩm'
                    columns={['code', 'name']}
                    displayColumn={['image', 'code', 'ngay_kiem', 'sl_tonkho', 'name']}
                    itemRender={
                        (item) => {
                            let thumbnail = imagePreset(item.image, 'xsmall')
                            const customLabel = (
                                <span className="list-compact">
                                    <div>
                                        <Image
                                            width={40}
                                            height={40}
                                            src={thumbnail}
                                            preview={{
                                                src: item.image,
                                            }}
                                        />
                                    </div>

                                    <span className="content">
                                        {item.code} {item.name}
                                    </span>
                                </span>
                            );
                            return {
                                label: customLabel,
                                value: item.code,
                            };
                        }
                    }
                    onClose={() => {
                        setShowSelectMany(false)
                    }}

                    onSave={(v) => {
                        setShowSelectMany(false)
                        let newProducts = products.filter(p => !v.keys.includes(p.code))
                        onSave([...newProducts, ...v.items])
                    }}
                />
            }

            {showAddModal &&
                <DataSelectModal
                    title={'Chọn sản phẩm thêm'}
                    searchColumns={["code", "name", "match_code"]}
                    initData={''}
                    tableId={commonStore?.company?.companyData?.product_table}
                    closeOnSelect={true}
                    onSave={(v) => {
                        if (v.ref) {
                            v.ref.key = v.id
                            setShowAddModal(false)
                            let newProducts = products.filter(p => p.code !== v.ref?.code)
                            onSave([...newProducts, v.ref])
                        }
                    }}
                    onClose={() => {
                        setShowAddModal(false)
                    }}
                    // component={ProductItem}
                    onSearchSelect={(v, o) => {
                        // console.log('o', o)
                    }}
                />}

        </>

    )
}

export default inject("datatableStore", "commonStore")(observer(ManyProducts))