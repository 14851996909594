import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useReactToPrint } from "react-to-print";
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import {
    Button,
    Modal,
} from 'antd';
import {
    PlusOutlined,
    CloseCircleOutlined,
    PrinterOutlined
} from '@ant-design/icons';

import { autorun, toJS } from 'mobx'
import styled, { createGlobalStyle } from 'styled-components'
import { price_k } from '../../../ultils/datatableUtils'
import DataTableServices from '../../../services/datatableServices'
import { sum } from 'lodash';

const PrintableBodyWrapper = styled.div`
 {
    .container {
        display:grid;
        grid-template-columns: auto auto auto 60px;
    }
    @page {
        size: auto;
        margin: auto;
      }
    @media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    }
    
    @media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
    }


    font-size:10pt;
    table {
        width:100vw;
        font-size:9pt;
        tr {
        width:100%;
        border:1px solid #333;
        margin:0;
        padding:5px;
        text-align:left;
            td {
                padding:3px;
            }
            th {
                padding: 10px 5px;
            }
            td:not(:last-child), th:not(:last-child) {
                border-right:1px solid #333;
            }
        }
    }
    .summary {
        font-size:11pt;
        padding: 6px 5px;
    }
 
  }
`;

function sort_product(a, b) {
    return b.qty - a.qty
}

class DatatableView extends React.PureComponent {

    render() {

        return (
            <PrintableBodyWrapper>
                {this.props.headerPrint ? this.props.headerPrint : ""}
                <table>
                    <tbody>
                        <tr>
                            <th align="center">Ảnh</th>
                            <th>Tên</th>
                            <th align="center">SL</th>
                        </tr>
                        {
                            this.props.pdata.rows.map((row, index) => {
                                return (
                                    <tr key={"tr" + index}>
                                        <td align="center">
                                            {row.image ? <img width={40} height={40} src={row.image} /> : "-"}
                                        </td>
                                        <td>{row.label}</td>
                                        <td align="center">
                                            {row.qty > 1 ? <strong>{row.qty}</strong> : <span style={{ color: "#666", fontSize: '8pt' }}>{row.qty}</span>}
                                        </td>
                                    </tr>
                                )
                            }
                            )
                        }
                    </tbody>
                </table>
                <br />
                <br />
                <strong>Só đơn nhặt :  </strong>{this.props.pdata.order_count}
                <br />
                <strong>Só sản phẩm nhặt :  </strong>{this.props.pdata.productSum}
                <br />
                <strong>Các khách hàng :  </strong>{this.props.pdata.customers}
            </PrintableBodyWrapper>
        );
    }
}

const PrintOrderDetail = ({
    commonStore,
    columns,
    title,
    ordersData,
    table,
    onClose,
}) => {

    const printRef = useRef()
    const [printData, setPrintData] = useState(false)
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const clickPrint = async () => {
        let pData = await flatPrintData()
        if (pData.rows) {
            setPrintData(pData)
        }
        handlePrint()
    }

    const [getProductByCodes] = useMutation(DataTableServices.GET_PRODUCTS_BY_CODES(), {
        errorPolicy: 'all',
    })

    const flatPrintData = async () => {
        let out = []
        let customers = []
        let productSum = 0
        ordersData.items.forEach(order => {
            if (order.items) {
                let cname = order?.customer?.name
                if (cname) customers.push(cname)
                order.items.forEach(p => {
                    p.qty = parseInt(p.qty)
                    if (isNaN(p.qty)) p.qty = 0
                    let o = out.find(item => item.code === p?.match_code || item.code === p?.code)
                    if (!o) {
                        if (p.qty > 0) {
                            out.push({
                                code: p?.match_code || p?.code,
                                qty: p.qty,
                                name: p.name,
                                image: p.image,
                                hasStock: p?.match_code ? true : false
                            })
                        }
                    } else {
                        if (p.qty > 0) {
                            o.qty += p.qty
                        }
                    }
                });
            }
        });
        out.forEach(o => {
            productSum += o.qty
        });
        let codes = out.filter(item => item.hasStock).map(item => item.code)
        // console.log(`codes`, codes)
        // console.log(`commonStore?.company?.companyData?.product_table`, commonStore?.company?.companyData?.product_table)
        const input = {
            data: {
                table_id: commonStore?.company?.companyData?.product_table,
                codes: codes
            }
        }
        let pRef = await getProductByCodes({
            variables: { input }
        })
        pRef = pRef?.data?.getProductByCodes?.data
        if (pRef?.status === 'OK') {
            out.forEach(o => {
                if (pRef.products[o.code]) {
                    // console.log(`pRef.products[o.code]`, pRef.products[o.code])
                    if (pRef.products[o.code].tag_vitri && pRef.products[o.code].tag_vitri.length > 0) {
                        o.vitriLabel = pRef.products[o.code].tag_vitri.join(',')
                    }
                    o.stockLabel = pRef.products[o.code].stockLabel
                    o.note = pRef.products[o.code].note ? pRef.products[o.code].note : false
                    if (!o.stockLabel) o.hasStock = false
                }
            });
        }

        let rows = []
        out.forEach(o => {
            // let br = (o.note || o.vitriLabel) ? <br /> : ""
            let r = (
                <div class="container__text">
                    <span><strong>[{o.code}]</strong>-{o.name}</span>
                    {/* {o.stockLabel ?
                        <>
                            Kho :<strong>{o.stockLabel}</strong>
                            {o.note ? ' , ' : ""}
                        </>
                        : ""} */}

                    {o.note &&
                        <>
                            <br />
                            <strong style={{ marginRight: 8 }}>Ghi chú:</strong>
                            <em>{o.note}</em>
                        </>}
                    {o.vitriLabel && <>
                        <br />
                        <strong style={{ marginRight: 8 }}>Tag vị trí:</strong>
                        {o.vitriLabel}
                    </>}
                </div>
            )
            rows.push({
                label: r,
                qty: o.qty,
                image: o.image
            })
        });
        rows.sort(sort_product)
        return {
            rows: rows,
            order_count: customers.length,
            customers: customers.join(', '),
            productSum: productSum
        }
    }

    const headerPrint = () => {
        return (
            <>
                <div style={{ display: 'flex' }}>
                    <div style={{ textAlign: 'left', width: "50%" }}>Người nhặt:</div>
                    <div style={{ fontSize: '14pt', textAlign: 'right', width: "50%" }}>DANH SÁCH NHẶT ĐƠN</div>
                </div>
                <strong>Ngày in: </strong> {moment().format('L')}
                <br />
            </>
        )
    }

    // let ooo = flatPrintData()

    return (
        <>
            <div style={{ display: 'none' }}>
                {printData ? <DatatableView
                    ref={printRef}
                    title={'In'}
                    columns={columns}
                    pdata={printData}
                    headerPrint={headerPrint()}
                /> : ""}

            </div>
            <Button shape="round" size={'small'} onClick={clickPrint}
                icon={<PrinterOutlined />}
            >{title}</Button>
        </>
    )
}
export default inject("commonStore")(observer(PrintOrderDetail))

