import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Table,
  Typography
} from 'antd';
import {
  ControlOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SearchOutlined
} from '@ant-design/icons';

import styled from 'styled-components'

const { Text } = Typography;

const OverviewContainer = styled.div`
  width:100%;
  padding:8px 15px;  
  `

const LiveOverview = ({ liveStore }) => {

  const CodeRender = (value, row) => {
    let cls = 'noProduct'
    if (liveStore.findRefProduct(row.code, row.match_code)) cls = 'hasProduct'

    // const index = datatableStore.liveProducts.findIndex((item) => item.code === row.code)
    const liveIndex = liveStore.liveProducts.findIndex((item) => (item.code === row.code || (item.match_code === row.code)))

    if (liveIndex >= 0) cls = 'inLive'
    return (
      <div className='tagCode'>
        <span className={cls}>{row.code}</span>
      </div>
    )
  }

  const QuanityRender = (value, row, index) => {
    return (
      <div>
        {row.customers ? row.customers.length : 0}
      </div>
    )
  }

  const NameRender = (value, row, index) => {
    let name = liveStore.findRefProduct(row.code, row.match_code)?.name
    return (
      <div>
        {}
        {name ? name : "??"}
      </div>
    )
  }

  const PriceRender = (value, row, index) => {
    let price = '-'
    if (row.price_live)
      price = row.price_live.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
    return price
  }

  const columns = [
    {
      title: 'Mã',
      dataIndex: 'code',
      width: 80,
      render: CodeRender
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'name',
      key: 'code',
      width: 'auto',
      render: NameRender
    },
    {
      title: 'Giá LIVE',
      dataIndex: 'price_live',
      key: 'price_live',
      width: 100,
      render: PriceRender
    },
    {
      title: 'SL',
      dataIndex: 'quanity',
      key: 'quanity',
      width: 60,
      render: QuanityRender
    }
  ];

  // console.log('detailData', detailData)
  return (
    <OverviewContainer>
      <Table
        id="overviewTable"
        style={{
          height: 'calc(100vh - 100px)',
          maxHeight: 'calc(100vh - 100px)',
          marginBottom: 10,
          border: '1px solid #fcfcfc'
        }}
        dataSource={liveStore.suggestionCodes}
        columns={columns}
        // scroll={{ y: 'calc(100vh - 235px)', scrollToFirstRowOnChange: true }}
        bordered
        size={'small'}
        pagination={false}

        summary={pageData => {
          let totalQuanity = 0;
          let totalPrice = 0;

          pageData.forEach(({ customers, price_live }) => {
            if (customers)
              customers.forEach(({ quanity }) => {
                if (quanity) {
                  totalQuanity += quanity
                  if (price_live)
                    totalPrice += (price_live * quanity)
                }
              });
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>Tổng số</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Text type="success">{totalQuanity} sản phẩm</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell>Tổng giá</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Text type="danger">{totalPrice.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}

      />
    </OverviewContainer>
  )
}

export default inject("liveStore")(observer(LiveOverview))