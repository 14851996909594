import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'

const LoobyNameWrapper = styled.div`
  margin-right: 32px;
`
const LobbyName = () => {
  return (
    <LoobyNameWrapper>
      <Button>
        Lobby 1
      </Button>
    </LoobyNameWrapper>
  )
}

export default LobbyName
