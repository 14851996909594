import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Input,
  AutoComplete,
  Badge,
  Image,
  Space,
  Avatar
} from 'antd';
// import { autorun, toJS } from 'mobx'
import { debounce, isObject } from "lodash-es";

import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
import { price_k, imagePreset } from '../../../../ultils/datatableUtils';
import datatableStore from '../../../../stores/datatableStore';

// import { findRowsByText } from "../../../../ultils/datatableUtils";
const SearchBoxContainer = styled.div`
 
  `

const SearchBoxItem = ({
  tableId,
  columns,
  displayColumn = false,
  limit,
  width,
  placeholder,
  clearAfterSelect,
  initValue,
  hasSearchIcon,
  hasPreSearch = true,
  filter,
  keyField = "name",
  onOk,
  onSelect,
  onEnter,
  filterOnDropdown,
  search_type = 'keyword',
  searchConfig,
  className,
  autoFocus = false,
  quickSelectOne,
  hasImage = false,
  enterWithData = false
}) => {

  const [matchItems, setMatchItems] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [isSearching, setIsSearching] = useState(false)
  const [doEnterSearch, setDoEnterSearch] = useState(false)
  const [updateByFilter, setUpdateByFilter] = useState(false)

  const inputRef = useRef(null)
  // const [currentText, setCurrentText] = useState("")

  // function useDebounce(value, delay) {
  //   // State and setters for debounced value
  //   const [debouncedValue, setDebouncedValue] = useState(value);
  //   useEffect(
  //     () => {
  //       // Update debounced value after delay
  //       const handler = setTimeout(() => {
  //         setDebouncedValue(value);
  //       }, delay);
  //       // Cancel the timeout if value changes (also on delay change or unmount)
  //       // This is how we prevent debounced value from updating if value is changed ...
  //       // .. within the delay period. Timeout gets cleared and restarted.
  //       return () => {
  //         clearTimeout(handler);
  //       };
  //     },
  //     [value, delay] // Only re-call effect if value or delay changes
  //   );
  //   return debouncedValue;
  // }

  useEffect(() => {
    // async function search() {
    //   await handleSearch(inputValue)
    // }
    if (!isSearching && onEnter && doEnterSearch) {
      if (enterWithData && matchItems) {
        let rr = matchItems.map(item =>
          Object.assign(item?.data?.row_data, { key: item?.data?.id }))
        onEnter({
          txt: inputValue,
          // rows: matchItems.map(item => item?.data?.row_data)
          rows: rr
        })
        setDoEnterSearch(false)
      }
      else
        onEnter(inputValue)
    }
  }, [doEnterSearch, isSearching, inputValue])

  const searchTxt = useCallback(
    debounce(async (name) => {
      await handleSearch(name)
    }, 600),
    []
  );

  useEffect(async () => {
    if (inputValue && !doEnterSearch) {
      searchTxt(inputValue)
    }
  }, [inputValue, doEnterSearch])

  useEffect(() => {
    if (keyField === 'id') {

    } else {
      if (initValue) {
        doSearch(initValue)
      }
    }
  }, [initValue])

  function valueByPath(row, c) {
    let path = c.split('.')
    // console.log(`path`, path)
    if (c.length == 1) return row[c]
    let out = row
    path.forEach(p => {
      out = out[p]
    });
    if (isObject(out)) return ""
    if (c === 'price_live' || c === 'price') out = price_k(out, '?', 3)
    return out
  }

  function getRowText(row) {
    var s = ''
    if (!displayColumn) displayColumn = columns
    displayColumn.forEach((c, index) => {
      // s += row.row_data[c]
      let vp = valueByPath(row.row_data, c)
      // console.log('c', c)
      // console.log('vp', vp)
      // if (c === 'price_live' || c === 'price') vp = price_k(vp, '?', 3)
      if (index == 0) s = vp
      else
        if (vp != "") s += ' ' + vp
    });
    // console.log('s', s)
    return s
  }


  const textColor = [
    '#03BF68', "333333", "blue"
  ]

  const renderItem = (row) => {
    return {
      key: row.id,
      // value: clearAfterSelect ? "" : getRowText(row),
      value: getRowText(row),
      data: row,
      label: (
        <div
          className='searchBoxItem'
        >
          <Space size={[6, 20]} >
            {(displayColumn || columns).map((column, index) => {
              if (column === 'image') {
                let thumbnail = imagePreset(row.row_data['image'], 'xsmall')
                return <Avatar
                  shape="square"
                  size={50}
                  src={thumbnail}
                />
              } else
                if (index === 0 || (hasImage && index === 1))
                  return (<strong className="boxItem" style={{ color: textColor[index % 3] }}>{valueByPath(row.row_data, column)}</strong>)
                else {
                  if (column === 'ngay_kiem') {
                    return <Badge status={(row.row_data.ngay_kiem || row.row_data?.sl_kiem) ? 'success' : "error"} style={{ color: row.sl_kiem ? 'green' : "#777" }} />
                  } else
                    return (<span className="boxItem" style={{ color: textColor[index % 3] }}>{valueByPath(row.row_data, column)}</span>)
                }
            })}
          </Space>
        </div>
      ),
    };
  };

  const rowsByText = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
  const findRowsByText = async (txt, id) => {
    let params = {
      // txt: txt,
      columns: columns,
      tableId: tableId,
      limit: limit ? limit : 50,
      hasPreSearch: hasPreSearch,
      search_type: search_type
    }
    if (id) {
      params.id = id
    } else {
      params.txt = txt
    }
    if (filter) {
      params.filter = filter
      if (params.txt) params.filter.txt = params.txt
    }

    const { data, error } = await rowsByText({
      input: {
        data: params
      }
    })
    var results = data?.findDataRow?.data?.items
    if (!results) return []
    return results
  }

  const doSearch = async (s) => {
    let out = []
    if (!s) return []
    s = s.toLowerCase()
    var filterData = await findRowsByText(s)
    // console.log('filterData', filterData)
    if (filterData.length > 0) {
      setMatchItems(filterData.map((r) => {
        return renderItem(r)
      }
      ))
      if (filterData.length === 1 && quickSelectOne) {

        if (clearAfterSelect) {
          setInputValue("")
          setMatchItems([])
        }
        if (onSelect)
          // onSelect(v, option)

          onSelect(true, {
            key: filterData[0].id,
            data: filterData[0]
          })
      }
    }
    else {
      setMatchItems([])
    }
    // datatableStore.setCurrentDatarows(unionBy(filterDataName, filterDataCodeCustomer, 'sid'))
    return out
  }

  const handleSearch = async (value) => {
    // setInputValue(value)
    // if (!inputValue) return
    setIsSearching(true)
    await doSearch(value)
    setIsSearching(false)
    // datatableStore.setFilter({txt:value})
    // onOk(value)
  }

  const onKeyDown = async e => {
    // if (onKeypress) onKeypress(e, inputValue)
    if (e.key == "Enter") {
      if (filterOnDropdown) {
        e.preventDefault()
        setDoEnterSearch(true)
        if (inputRef?.current) {
          inputRef.current.blur()
        }
        // if (!isSearching)
        // let v = inputValue
        // setInputValue(false)
        // if (onEnter) onEnter(e, v)
        // datatableStore.setFilter({ txt: inputValue })

      }
    }
    // else if (e.code == "Escape") {
    //   if (filterOnDropdown) {
    //     setInputValue("")
    //     datatableStore.setFilter({ txt: "" })
    //     e.preventDefault()
    //   }
    // }
  }


  useEffect(() => {
    if (datatableStore.doFocusKiemSL) {
      if (inputRef?.current) {
        inputRef.current.focus()
        datatableStore.setDoFocusKiemSL(false)
      }
    }
  }, [datatableStore.doFocusKiemSL])


  return (
    // <SearchBoxContainer>
    <AutoComplete
      ref={inputRef}
      autoFocus={autoFocus}
      className={className}
      style={{ width: width ? width : 150 }}
      defaultValue={initValue ? initValue : ""}
      // notFoundContent="không tìm thấy"
      // dropdownMatchSelectWidth={252}
      // style={{ width: "auto" }}
      dropdownMatchSelectWidth={350}
      // onBlur={() => {
      //   datatableStore.setFilter({ txt: inputValue })
      //   // onOk(inputValue)
      // }}
      size={'small'}
      allowClear={true}
      // autoClearSearchValue={true}
      onFocus={(event) => event.target.select()}
      value={inputValue}
      onSelect={
        (v, option) => {
          if (clearAfterSelect) {
            setInputValue("")
            setMatchItems([])
          }
          if (onSelect)
            onSelect(v, option)
          // console.log('inputRef?.current', inputRef?.current)
          // inputRef.current.value = ""
          inputRef?.current.focus()
          // if (clearAfterSelect) setInputValue("")
        }
      }
      // onChange={(v) => {
      //   setInputValue(v)
      // }}
      onKeyDown={onKeyDown}
      // onDropdownVisibleChange={
      //   (open) => {
      //     if (!filterOnDropdown) return
      //     if (!open) {
      //       {
      //         setUpdateByFilter(true)
      //         // console.log(`inputValue`, inputValue)
      //         // datatableStore.setFilter({ txt: inputValue })
      //         // console.log(`datatableStore.filterData`, datatableStore.filterData)
      //       }
      //     }
      //   }
      // }

      onChange={(v) => {
        if (v === undefined) {
          setInputValue("")
          onSelect("")
        }
        else
          setInputValue(v)
      }}
      // onSearch={
      //   debounce(handleSearch, 600)
      // }


      // onSearch={(s) => {
      //   setInputValue(s)
      //   searchTxt(s)
      //   // debounce(
      //   //   function () {
      //   //     handleSearch(s)
      //   //   }
      //   //   , 600)
      // }}
      options={matchItems}
      placeholder={placeholder ? placeholder : "nhập từ khóa tìm kiếm"}
    >
      {hasSearchIcon ? <Input.Search
        allowClear
        size="small"
      /> : ""}

    </AutoComplete>
    // {/* </SearchBoxContainer> */}
  )
}

export default SearchBoxItem
// export default SearchBoxItem