/**
 * Find a key in a nested object
 * @param {*} obj - Root object
 * @param {*} key - key name
 */
function extractObjectKey(obj, key) {
  let result

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (property === key) {
        return obj[key] // returns the value
      }
      else if (typeof obj[property] === 'object') {
        // in case it is an object
        result = extractObjectKey(obj[property], key)

        if (typeof result !== 'undefined') {
          return result
        }
      }
    }
  }
}

export default extractObjectKey


