import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '../../layouts/DefaultLayout'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'
import DataTableServices from '../../../services/datatableServices'
import { useMutation } from '@apollo/react-hooks'
import useAsyncQuery from '../../../ultils/useAsyncQuery'
// import QuantityModal from './QuantityModal'
// import DatarowSelectInput from '../DataBoard/DataTools/DatarowSelectInput'
import { imagePreset, price_k } from '../../../ultils/datatableUtils'
import { Tags_render } from '../DataBoard/DataTools/DataField'
import DataEditItem from '../DataBoard/DataTools/DataEditItem'
import ResourceModal from '../Resource/ResourceModal'
import ProductList from '../DataBoard/DataView/ProductList'

import QRReader from 'react-qr-scanner'

// import fileDownload from 'js-file-download';
// import Axios from 'axios';
// import moment from 'moment'

import {
    message,
    Space,
    Button,
    Tag,
    Tabs,
    Drawer,
    Image,
    // List, 
    Divider,
    Row, Col, Grid,
    // Avatar

} from 'antd'

import {
    PlusOutlined,
    // HeartOutlined,
    BuildOutlined,
    EyeOutlined,
    TagOutlined,
    CameraOutlined,
    FilterOutlined,
    // HistoryOutlined,
    // ShoppingOutlined,
    EditOutlined,
    YoutubeOutlined,
    InstagramOutlined,
    LinkOutlined,
    AlignLeftOutlined,
    BarcodeOutlined
} from '@ant-design/icons'
import { flatMap } from 'lodash-es'
import TagSelectModal from '../DataBoard/DataTools/TagSelectModal'



// import moment from 'moment'
// import MarkdownIt from 'markdown-it';


// import SelectManyItemModal from '../Tools/SelectManyItemModal'

// import ResourceDetail from '../Resource/ResourceDetail'
import ResourceList from '../Resource/ResourceList'
import { toJS } from 'mobx'


const { TabPane } = Tabs;
const { useBreakpoint } = Grid;
// const mdParser = new MarkdownIt(/* Markdown-it options */);
const ProductSearchPage = ({ usersStore, commonStore, datatableStore, history }) => {
    // const [updateManyDataRows] = useMutation(
    //     DataTableServices.UPDATE_MANY_ROWS(),
    //     {
    //         errorPolicy: 'all'
    //     }
    // )
    const [massUpdateTag] = useMutation(
        DataTableServices.MASS_UPDATE_TAG(),
        {
            errorPolicy: 'all'
        }
    )
    const [cameras, setCameras] = useState([])
    const [camId, setCamId] = useState(false)
    const [showQrcode, setShowQrcode] = useState(false)
    const [initData, setInitData] = useState(" ")

    const [rows, setRows] = useState([])
    const [currentResources, setCurrentResources] = useState([])
    // const [currentFilter, setCurrentFilter] = useState({})
    const [showFilter, setShowFilter] = useState(false)
    const [currentTab, setCurrentTab] = useState('info')
    const [currentEditRecord, setCurrentEditRecord] = useState(false)
    const [currentTagFilter, setCurrentTagFilter] = useState(false)


    const [resTagData, setResTagData] = useState(false)
    // const [showResDetail, setShowResDetail] = useState(false)
    const [currentResType, setCurrentResType] = useState('all')
    const [currentResTag, setCurrentResTag] = useState(['all'])
    const [showResourceModal, setShowResourceModal] = useState(false)
    // const [showSelectMany, setShowSelectMany] = useState(false)
    // const [recentProducts, setRecentProducts] = useState([])
    const screens = useBreakpoint();
    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, (v) => { return Object.assign(v.row_data, { key: v.id }) })
        return items
    }

    const findResQuery = useAsyncQuery(DataTableServices.FIND_RESOURCES())
    const getResources = async (dataInput) => {
        const { data, error } = await findResQuery({
            input: {
                data: dataInput
            }
        })
        let result = data?.findResources?.data
        if (result.status === 'OK')
            return result.items
        return false
    }

    const { id } = useParams()
    const getRow = useAsyncQuery(DataTableServices.GET_ROW_BY_ID(id))

    let searchColumns = ['code', 'name']
    useEffect(() => {
        // commonStore.setCurrentPageTitle('Tài nguyên')
        async function init() {
            if (datatableStore.product_cart.length === 0 && id) {
                let r = await getRow()
                let row = r?.data?.datarow?.row_data
                if (row) {
                    row.key = r?.data?.datarow?.id
                    row.id = r?.data?.datarow?.id
                    row.se = r?.data?.datarow?.se
                    // datatableStore.setCurrentDatarows([row])
                    datatableStore.setCurrentRow(row)
                    datatableStore.addProduct2Cart([row])
                    // setRows([row])
                    commonStore.setCurrentPageTitle('Mã: ' + row.code)
                }
            } else {
                commonStore.setCurrentPageTitle('Tài nguyên')
            }
            if (!datatableStore?.preloadTable?.tag) {
                let tagData = await findRowsByFilter(
                    {
                        tableId: commonStore?.company?.companyData?.tag_table,
                        hasPreSearch: false,
                        limit: 500,
                        filter: { match: { "category": ['Đơn hàng', 'Sản phẩm', "Resource"] } }
                    }
                )
                datatableStore.setPreloadTable('tags', tagData)
                if (tagData)
                    setResTagData(tagData.filter(t => t.category === 'Resource'))
            }
            else
                setResTagData(datatableStore.preloadTable.tag.filter(t => t.category === 'Resource'))
        }
        init()
        if (cameras.length < 1) {
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const videoSelect = []
                    devices.forEach((device) => {
                        if (device.kind === 'videoinput') {
                            videoSelect.push(device)
                        }
                    })
                    return videoSelect
                })
                .then((devices) => {
                    // console.log('devices', devices)
                    setCameras(devices)
                    // console.log('devices[devices.length - 1].groupId', devices[devices.length - 1].groupId)
                    setCamId(devices[devices.length - 1].deviceId || devices[devices.length - 1].groupId)
                    // console.log('camId', camId)
                    // this.setState({
                    //     cameraId: devices[0].deviceId,
                    //     devices,
                    //     loading: false,
                    // })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [commonStore.company])

    // const addResource = <Button type={'primary'} icon={<PlusOutlined />}>tài nguyên</Button>;

    const resType = [
        { name: 'Ảnh', type: 'image', icon: < InstagramOutlined /> },
        { name: 'Link', type: 'link', icon: < LinkOutlined /> },
        { name: 'Text', type: 'text', icon: < AlignLeftOutlined /> },
        { name: 'Video', type: 'video', icon: < YoutubeOutlined /> },
    ]

    const resTag = [
        { name: 'Chưa phân loại', tag: 'other', icon: < TagOutlined /> },
        { name: 'Feedback', tag: 'feedback', icon: < TagOutlined /> },
        { name: 'Ảnh web', tag: 'web_image', icon: < TagOutlined /> },
    ]
    // const resTag = ['Chưa phân loại','Feedback','Ảnh web']
    // const resTypeClick = (

    const ProductDetail = ({ product }) => {
        // useEffect(() => {


        //     return () => {

        //     }
        // }, [datatableStore.currentDatarows])
        // let product = datatableStore.currentRow
        // console.log('product', product)
        let thumbnail = imagePreset(product.image, 'xsmall')
        let cotheban = product.sl_tonkho - product.sl_giuhang
        if (isNaN(cotheban) || cotheban < 0) cotheban = 0
        let slColor = (product.sl_qty === cotheban) ? 'green' : 'blue'
        let ctbColor = cotheban > 0 ? slColor : 'red'
        let nameColor = product.co_mau_live ? '#60CC7B' : '#333'

        if (product.sl_qty === 0) slColor = 'red'
        return (<>
            <h2 style={{ color: nameColor }}><strong style={{ marginRight: 5 }}>[{product.code}]</strong>{product.name}</h2>
            <Row>
                <Col flex="100px">
                    <Image
                        width={90}
                        height={90}
                        src={thumbnail}
                        preview={{
                            src: product.image,
                        }}
                    // src={row[c.dataIndex] ? row[c.dataIndex] : ""}

                    /></Col>
                <Col flex="auto" style={{ fontSize: '11pt' }}>
                    <strong style={{ marginRight: 5 }}>SL kho / giữ hàng:</strong> {product.sl_tonkho} / {product.sl_giuhang}
                    <br />
                    <strong style={{ marginRight: 5 }}>CTB / SLine:</strong>
                    <span style={{ fontWeight: 'bold', color: ctbColor }}> {cotheban}</span>/<span style={{ fontWeight: 'bold', color: slColor }}>{product.sl_qty}</span>
                    <br />
                    <strong style={{ marginRight: 5 }}>Giá niêm yết:</strong>{price_k(product.price, '?', 3)}
                    <br />
                    <strong style={{ marginRight: 5 }}> Giá live:</strong>{price_k(product.price_live, '?', 3)}
                </Col>
                <Divider />
                {product?.tag_vitri && datatableStore.preloadTable.tags &&
                    <>
                        < p ><b>Thẻ vị trí : </b><Tags_render tags={product?.tag_vitri} preloadTable={datatableStore.preloadTable.tags} subCate="Tag vị trí" hasColor={true} /></p>
                        <Divider />
                    </>
                }
                <Space>
                    <Button
                        size={'large'}
                        icon={<EditOutlined />}
                        onClick={() => {
                            setCurrentEditRecord(product)
                        }}
                    >
                        Sửa sản phẩm
                    </Button>
                    {/* <Button
                        size={'large'}
                        icon={<HeartOutlined />}
                        onClick={() => {
                            // setShowFilter(!showFilter)
                        }}
                    >
                    </Button> */}
                </Space>
            </Row>


        </>)
    }


    function truncate(input, maxLength = 20) {
        if (input.length > maxLength) {
            return input.substring(0, maxLength) + '...';
        }
        return input;
    };


    useEffect(() => {
        async function init() {
            if (id) {
                let res = await getResources({ row_ids: [id] })
                setCurrentResources(res)
            }
        }
        init()
    }, [id])

    return (
        <DefaultLayout>
            <Space>
                <SearchBoxItem
                    // hasPreSearch={false}
                    autoFocus={true}
                    clearAfterSelect={true}
                    className='searchMedium'
                    autoClearSearchValue={true}
                    width={200}
                    tableId={commonStore?.company?.companyData?.product_table}
                    filterOnDropdown={true}
                    hasImage={true}
                    columns={searchColumns}
                    displayColumn={['image', 'code', 'ngay_kiem', 'sl_tonkho', 'name']}
                    quickSelectOne={true}
                    enterWithData={true}
                    initValue={initData}
                    onSelect={async (v, o) => {
                        let rData = o?.data?.row_data
                        if (rData) {
                            rData.id = o.key
                            rData.se = o?.data?.se
                        }
                        if (rData?.id) {
                            history.replace('/tool/search_product/' + rData.id)
                            datatableStore.setCurrentRow(rData)
                            // setRows([rData])
                            datatableStore.addProduct2Cart([rData])

                            commonStore.setCurrentPageTitle('Mã: ' + rData.code)
                            setCurrentTab('info')
                        }
                        // window.location.reload(false);
                    }}
                    onEnter={(v) => {
                        if (v?.rows) {
                            // setRows(v.rows)
                            // console.log('v.rows', v.rows)
                        }
                    }}
                />
                <Button
                    style={{ width: 40, height: 40 }}
                    size={'large'}
                    icon={<BarcodeOutlined />}
                    onClick={() => {
                        if (!showQrcode) setShowQrcode('rear')
                        else setShowQrcode(false)
                        setCamId(cameras[cameras.length - 1].deviceId || cameras[cameras.length - 1].groupId)
                    }}></Button>
                <Button
                    style={{ width: 40, height: 40 }}
                    size={'large'}
                    icon={<CameraOutlined />}
                    onClick={() => {
                        if (!showQrcode) setShowQrcode('user')
                        else setShowQrcode(false)
                        setCamId(cameras[0].deviceId || cameras[0].groupId)


                        // datatableStore.setCurrentDatarows(1)
                        // setShowFilter(!showFilter)
                    }}
                ></Button>
            </Space>

            {showQrcode && camId && cameras.length > 0 &&
                <div>
                    <QRReader
                        delay={100}

                        style={{ width: "100%", margin: "10px 0 10px 0" }}
                        // onError={this.handleError}
                        facingMode={showQrcode}
                        // constraints={{ audio: false, video: true }}
                        legacyMode={true}
                        constraints={camId && ({ audio: false, video: { deviceId: camId } })}
                        onScan={(data) => {
                            if (data && data.text) {

                                // console.log('data', data)
                                // alert(data)
                                setShowQrcode(false)
                                setInitData(data.text)
                            }
                            // console.log('data', data)
                        }}
                    />
                    <select
                        onChange={e => {
                            const value = e.target.value
                            setCamId(value)
                        }}
                    >
                        {cameras.map((deviceInfo, index) => (
                            <React.Fragment key={deviceInfo.deviceId}><option value={deviceInfo.deviceId}>{deviceInfo.label || `camera ${index}`}</option></React.Fragment>
                        ))}
                    </select>
                </div>

            }
            {/* {rows && rows.length > 1 &&
                <List
                    style={{ padding: 10 }}
                    itemLayout="horizontal"
                    dataSource={rows}
                    renderItem={item => <ProductListItem product={item} />}
                />
            } */}
            {datatableStore.currentRow &&
                // {rows && rows.length === 1 &&
                <Tabs activeKey={currentTab} onChange={(t) => { setCurrentTab(t) }}>
                    <TabPane tab="Thông tin" key="info">
                        <ProductDetail product={datatableStore.currentRow} />
                        {/* <ProductDetail /> */}
                    </TabPane>
                    <TabPane tab="Tài nguyên" key="resource">
                        <div>
                            <h3>Danh mục</h3>

                            {resType.map(rt => {
                                let bgColor = currentResType === rt.type ? "#03BF68" : "#C5E8D8"
                                let textColor = currentResType === rt.type ? "#fff" : "#000"
                                // let textColor = {rt.type==='image'}
                                return (
                                    <Button
                                        style={{ background: bgColor, color: textColor, marginRight: 6, marginBottom: 10 }}
                                        size={'middle'}
                                        icon={rt.icon}
                                        onClick={() => {
                                            if (currentResType === rt.type) {
                                                setCurrentResType('all')
                                            } else {
                                                setCurrentResType(rt.type)
                                            }
                                        }}
                                    >
                                        {screens.xs === false && rt.name}
                                    </Button>
                                )
                            })}
                            {currentResType !== 'all'
                                //  && ['chu-cong-ty', 'nv-online', 'leader1', 'quan-ly'].includes(usersStore.currentUser.company_role) 
                                &&
                                <Button
                                    style={{ marginBottm: 10, marginRight: 6 }}
                                    size={'middle'}
                                    icon={<PlusOutlined />}
                                    type={'dashed'}
                                    onClick={() => {
                                        setShowResourceModal(true)
                                    }}
                                >
                                    thêm
                                </Button>

                            }
                            <br />
                            <h3>Thẻ phân loại</h3>

                            {resTagData && resTagData.map(rt => {
                                if (rt.type !== 'system') return ''
                                let bgColor = currentResTag.includes(rt.name) ? "#2070B1" : "#83BBE9"
                                // let textColor = {rt.type==='image'}
                                return (
                                    <Tag
                                        className="big-tag"
                                        color="white"
                                        style={{ background: bgColor }}
                                        onClick={() => {
                                            if (currentResTag.includes(rt.name)) {
                                                setCurrentResTag(currentResTag.filter(t => t !== rt.name));
                                            } else {
                                                // let tags = currentResTag.filter(t => t !== 'all')
                                                // setCurrentResTag([...tags, rt.name])
                                                setCurrentResTag([rt.name])
                                            }
                                        }}
                                    >
                                        {rt.icon || <TagOutlined />}  {rt.name}
                                    </Tag>
                                )
                            })}

                            <Tag
                                className="big-tag"
                                style={{ background: "#fff", color: "#000" }}
                                onClick={() => {
                                    setShowFilter(true)
                                }}
                            >
                                <FilterOutlined /> Lọc thêm
                            </Tag>
                        </div>
                        <Divider />
                        {currentResTag && currentResources && <ResourceList rows={currentResources} currentResTag={currentResTag} currentResType={currentResType} />}
                    </TabPane>
                </Tabs>
            }
            <Drawer
                visible={showFilter}
                title='Bộ lọc'
                placement="right"
                onClose={() => {
                    setShowFilter(false)
                }}
            >
                <h3>Tag phân loại</h3>
                {/* <Tag
                    className="big-tag"
                    color="white"
                    style={{ background: currentResTag.includes('Tất cả') ? "#2070B1" : "#83BBE9" }}
                    onClick={() => {
                        if (currentResTag.includes('Tất cả')) {
                            setCurrentResTag(currentResTag.filter(t => t !== 'Tất cả'));

                        } else {
                            setCurrentResTag(['Tất cả'])
                        }
                        setShowFilter(false)
                    }}
                >
                    <TagOutlined /> Tất cả
                </Tag> */}
                {resTagData && resTagData.map(rt => {
                    let bgColor = currentResTag.includes(rt.name) ? "#2070B1" : "#83BBE9"
                    // let textColor = {rt.type==='image'}
                    return (
                        <Tag
                            className="big-tag"
                            color="white"
                            style={{ background: bgColor }}
                            onClick={() => {
                                if (currentResTag.includes(rt.name)) {
                                    setCurrentResTag(currentResTag.filter(t => t !== rt.name));
                                } else {
                                    // let tags = currentResTag.filter(t => t !== 'all')
                                    // setCurrentResTag([...tags, rt.name])
                                    setCurrentResTag([rt.name])
                                }
                                setShowFilter(false)
                            }}
                        >
                            {rt.icon || <TagOutlined />}  {rt.name}
                        </Tag>
                    )
                })}
            </Drawer>
            {currentEditRecord && datatableStore.currentRow && (
                <DataEditItem
                    title={'Chỉnh sửa chi tiết'}
                    initData={currentEditRecord}
                    table={commonStore?.company?.companyData?.product_table}
                    rows={datatableStore.currentDatarows || [datatableStore.currentRow]}
                    onSave={v => {
                        if (v?.data && v.data.length === 1) {
                            setRows(v.data)
                            datatableStore.setCurrentRow(v.data[0])
                            datatableStore.updateProductCart(toJS(datatableStore.product_cart), v.data[0])
                        }
                        setCurrentEditRecord(false)
                    }}
                    onClose={() => {
                        setCurrentEditRecord(false)
                    }}
                />
            )}
            {showResourceModal && datatableStore.currentRow &&
                <ResourceModal
                    resType={currentResType}
                    resTag={currentResTag.filter(t => t !== 'all') || []}
                    tagData={resTagData}
                    rowData={datatableStore.currentRow}
                    initData={{
                        resType: currentResType
                    }}
                    // row_id={rows[0].key}
                    onSave={(v) => {
                        // console.log('v', v)
                        // let newRes = v?.data?.data?.addResource?.data?.resource
                        // console.log('currentResources', currentResources)
                        if (v?.status === "OK" && v?.data) {
                            setCurrentResources([...v.data, ...currentResources])
                        }
                        setShowResourceModal(false)
                    }}
                    onClose={() => {
                        setShowResourceModal(false)
                    }}
                />}

            {/* {showResDetail &&
                <Drawer
                    visible={showResDetail}
                    title='Chi tiết tài nguyên'
                    placement="right"
                    onClose={() => {
                        setShowResDetail(false)
                    }}
                >
                    <ResourceDetail resource={showResDetail} />
                </Drawer>} */}


            <Divider />
            {datatableStore.product_cart && datatableStore.product_cart.length > 0 &&
                <div style={{ padding: 5, backgroundColor: '#fafafa' }}>
                    <h3>Sản phẩm vừa xem</h3>
                    <Space style={{ paddingTop: 8 }}>
                        <Button
                            size='small'
                            icon={<BuildOutlined />}
                            onClick={() => {
                                setCurrentTagFilter({
                                    filter: { match: { "sub_cate": 'Tag vị trí' } },
                                    preload: 'tags',
                                    ids: datatableStore.product_cart.map(p => p.key || p.id),
                                    updateKey: 'tag_vitri'
                                })
                            }}
                        >
                            Sửa vị trí
                        </Button>
                        <Button
                            size='small'
                            icon={<EyeOutlined />}
                            onClick={() => {
                                let searchIds = datatableStore.product_cart.map(o => o.id)
                                searchIds = searchIds.join('|')
                                history.push({
                                    pathname: '/table/' + commonStore?.company?.companyData?.product_table,
                                    search: '?limit=300&_id=' + searchIds,
                                })
                                datatableStore.setShowProductCart(false)
                            }} >
                            Xem list
                        </Button>
                        <Button size='small' type="default" danger onClick={() => {
                            datatableStore.updateProductCart([])
                        }} >
                            Xóa list
                        </Button>
                        <Button size='small' type="default" onClick={() => {
                        }} >
                            SL in
                        </Button>
                    </Space>
                    <Divider />
                    <ProductList rows={toJS(datatableStore.product_cart)}
                        onSave={(rows => {
                            datatableStore.updateProductCart(rows)
                        })}
                        view={false}
                        type='quickPList' />
                </div>

            }

            {currentTagFilter &&
                <TagSelectModal
                    title='Sửa vị trí hàng loạt'
                    massFilter={currentTagFilter}
                    onSave={async (v) => {
                        message.loading('Đang xử lý..', 0);
                        let mut = await massUpdateTag({
                            variables: {
                                input:
                                {
                                    data: {
                                        ids: currentTagFilter.ids,
                                        action: 'update_tag',
                                        field: currentTagFilter.updateKey,
                                        tags: v.tags,
                                        override: v.override
                                    }
                                }
                            }
                        })
                        message.destroy()
                        if (mut?.data?.massUpdateTag?.data?.status === 'OK') {
                            datatableStore.updateManyRowsByField(mut?.data?.massUpdateTag?.data.update, currentTagFilter.updateKey, true)
                            message.success('Cập nhật vị trí thành công', 2);
                        }
                        setCurrentTagFilter(false)
                    }}
                    onDelete={async (v) => {
                        message.loading('Đang xử lý..', 0);
                        let mut = await massUpdateTag({
                            variables: {
                                input:
                                {
                                    data: {
                                        ids: currentTagFilter.ids,
                                        action: 'remove_tag',
                                        field: currentTagFilter.updateKey,
                                        tags: v.tags,
                                        override: v.override
                                    }
                                }
                            }
                        })
                        message.destroy()
                        if (mut?.data?.massUpdateTag?.data?.status === 'OK') {
                            datatableStore.updateManyRowsByField(mut?.data?.massUpdateTag?.data.update, currentTagFilter.updateKey, true)
                            message.success('Xóa vị trí thành công', 2);
                        }
                        setCurrentTagFilter(false)
                    }}
                    onClose={() => {
                        setCurrentTagFilter(false)
                    }}
                />
            }

        </DefaultLayout>
    )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(ProductSearchPage))