import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import TriggerButton from '../../custom/TriggerButton'
import HeaderNav from './HeaderNav'
import {
  Layout,
  Row,
  Col,
  Typography,
  Divider
} from 'antd'
// import StyledBreadCrumb from '../../common/StyledBreadCrumb'

// import Notification from '../../pages/Notification/Notification'

const { Header } = Layout
const { Title } = Typography

const HeaderWrapper = styled.div`
  & .site-layout-background {
    background-color: ${({ theme }) => theme.colors.background};
  }
  box-shadow: 4px 4px 40px 0 rgba(0,0,0,.05);
`
const FlexStyled = styled.div`
  display: flex;
  align-items: center;
`

const HeaderDefault = ({ commonStore, collapsed, toggle, extraComponent }) => {

  return (
    <HeaderWrapper>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Row>
          <Col span={12}>
            <FlexStyled>
              <TriggerButton collapsed={collapsed} toggle={toggle} />
              <Title level={5} style={{ margin: '0 15px 0 0' }}>{commonStore.currentPageTitle}</Title>
              <Divider type="vertical" />
              {/* <StyledBreadCrumb /> */}
              {extraComponent}
            </FlexStyled>
          </Col>
          <Col span={12}>
            <HeaderNav />
          </Col>
        </Row>
      </Header>
    </HeaderWrapper>
  )
}

export default inject("commonStore")(observer(HeaderDefault));
