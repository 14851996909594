import React from 'react'
import config from '../config'
import Cookies from 'js-cookie'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { notification } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { extractObjectKey } from '../ultils'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
})

const cache = new InMemoryCache({
  fragmentMatcher
})

const request = async (operation) => {
  const token = await Cookies.get('access_token')
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })
}

const uploadLink = createUploadLink({ uri: config.GRAPHQL_API_ENDPOINT })

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      })
      .catch(observer.error.bind(observer))

    return () => {
      if (handle) handle.unsubscribe()
    }
  })
)

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        let extractedMessage = extractObjectKey(graphQLErrors, 'messages')
        let extractedCode = extractObjectKey(graphQLErrors, 'code')

        if (extractedMessage) {
          extractedMessage = extractedMessage[0]
        } else {
          extractedMessage = extractObjectKey(graphQLErrors, 'message')
          if (!extractedMessage) {
            extractedMessage = 'Unknown Error'
          }
        }

        if (!extractedCode) {
          extractedCode = 'Unknown Error'
        }

        notification.error({
          message: 'Error',
          description: extractedMessage.message || extractedMessage,
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        })
      }
      if (networkError) {
        notification.error({
          message: 'Server Error',
          description: 'The server is not responding. This may be because of a scheduled maintenance, please try again later.',
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        })
      }
    }),
    requestLink,
    uploadLink
  ]),
  cache,
})

cache.writeData({
  data: {
    isConnected: true
  }
})

export default apolloClient