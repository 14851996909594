import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '../../layouts/DefaultLayout'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'
import DataTableServices from '../../../services/datatableServices'
import { useMutation } from '@apollo/react-hooks'

import {
  message
} from 'antd'
const QuickSyncPriceLivePage = ({ usersStore, commonStore }) => {
  const [updateManyDataRows] = useMutation(
    DataTableServices.UPDATE_MANY_ROWS(),
    {
      errorPolicy: 'all'
    }
  )

  const [bulkAssignSLProductCate] = useMutation(
    DataTableServices.BULK_ASSIGN_SL_PCATE(),
    {
      errorPolicy: 'all'
    }
  )

  let searchColumns = ['code', 'sl_kwo', 'name']
  return (
    <DefaultLayout>
      <h3>Cập nhật nhanh giá LIVE</h3>
      <SearchBoxItem
        hasPreSearch={false}
        autoFocus={true}
        // clearAfterSelect={true}
        className='searchSyncPrice'
        width={'100%'}
        tableId={commonStore?.company?.companyData?.product_table}
        filterOnDropdown={true}
        columns={searchColumns}
        displayColumn={['code', 'sl_kwo', 'price_live', 'name']}
        onSelect={async (v, o) => {
          let rData = o?.data?.row_data
          if (rData) rData.id = o.key
          if (rData?.sline && rData?.id) {
            let newPrice = prompt('Giá live:', rData.price_live)
            newPrice = parseInt(newPrice)
            if (newPrice > 1 && newPrice < 3000) newPrice = newPrice * 1000
            // if (newPrice && newPrice !== rData.price_live) {
            if (newPrice && !isNaN(newPrice)) {
              rData.price_live = newPrice
              let r = await updateManyDataRows({
                variables: {
                  input: {
                    data: {
                      type: "oldnew",
                      rows: [{
                        id: rData.id,
                        n: { 'price_live': newPrice },
                        o: false
                      }]
                    }
                  }
                }
              })
              let data = {
                hub_products: [rData],
                sl_ids: [rData.sl_id],
                syncType: 'price',
                subType: 'quick_price'
              }
              let u = await bulkAssignSLProductCate({
                variables: {
                  input:
                  {
                    data: data
                  }
                }
              })
              if (u?.data?.bulkAssignSLProductCate?.data?.status === 'OK') {
                message.success('Cập nhật giá shopline thành công!!')
                window.location.reload(false);
              } else {
                message.error('Cập nhật giá shopline lỗi')
              }
            }
            window.location.reload(false);
          } else {
            alert('SP chưa có dữ liệu shopline!')
          }
        }}
        onEnter={(v) => {
          console.log('v', v)
          // alert(v)
        }}
      />
    </DefaultLayout>
  )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(QuickSyncPriceLivePage))