import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Layout, Avatar, Image, Skeleton } from 'antd'
import SidebarManager from './Sidebar/SidebarManager'
// import SidebarBoothOwner from './Sidebar/SidebarBoothOwner'
import Logo from '../custom/Logo'
import Header from './Header'
import ImageLogo from './logo-ni-be3.png'
import { ContainerWrapper } from '../styles'


const { Sider, Content } = Layout

const SiderWrapper = styled(Sider)`
  box-shadow: 0 0 28px 0 rgba(144,144,144,.1);
  border-right: 1px solid #ccc;
  &.ant-layout-sider {
    background: ${({ theme }) => theme.colors.sidebarBgColor};
    overflow-x: hidden;
    overflow-y: auto;
  }
`
const MainWrapper = styled(Content)`
  background-color: ${({ theme }) => theme.colors.background};
  height: calc(100% - 50px);
`

// const LoadingWrapper = styled(Content)`
//   background-color: ${({ theme }) => theme.colors.background};
//   min-height: 100%;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const StyledContainerWrapper = styled(ContainerWrapper)`

`

const LogoBlock = styled.div`
  width:100%;
  padding-top:10px;
  text-align:center;
  h3 {
    color:#fff;
  }
`

const DefaultLayout = (props) => {
  const { usersStore, commonStore, uiStore, extraComponent, children } = props
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    console.log('theme changed!')
  }, [commonStore.theme.v])

  const toggle = () => {
    setCollapsed(!collapsed)
  }
  // if (commonStore.initializing) {
  //   return (
  //     <LoadingWrapper>
  //       <Spin delay={1000} />
  //     </LoadingWrapper>
  //   )
  // }
  if (usersStore.currentUser && commonStore.company) {
    let logoUrl = usersStore.currentUser?.user_profile?.avatar?.url
    if (!logoUrl) logoUrl = commonStore.company?.logo?.url
    if (!logoUrl) logoUrl = '/no-image-80.jpg'
    return (
      <Skeleton loading={commonStore.initializing}>
        <Layout style={{ height: '100%', minHeight: '100vh' }}>
          <Helmet>
            <title>{commonStore.currentPageTitle}</title>
          </Helmet>
          <SiderWrapper id="main-sidebar"
            //trigger={null}
            // collapsible
            collapsed={collapsed}
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={broken => {
              console.log(broken)
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed)
            }}
          >
            {/* <Logo linkTo='/' src={Image} alt={'Logo'} collapsed={collapsed} /> */}
            <LogoBlock>
              <Avatar
                size={80}
                src={<Image src={logoUrl} />}
              />
              <h1 style={{ letterSpacing: 3, marginTop: 5 }}>{commonStore.company?.name.toUpperCase()}</h1>
              {/* <Logo linkTo='/' src={ImageLogo} alt={'Logo'} collapsed={collapsed} /> */}
            </LogoBlock>
            {/* {user.role.type === 'organizer' ?
            <SidebarOrganizer collapsed={collapsed} /> :
            <SidebarBoothOwner collapsed={collapsed} />
          } */}
            <SidebarManager collapsed={collapsed} />
          </SiderWrapper>
          <Layout className='site-layout'  >
            <Header collapsed={collapsed} toggle={toggle} extraComponent={extraComponent} />
            <MainWrapper>
              <StyledContainerWrapper>
                {children}
              </StyledContainerWrapper>
            </MainWrapper>
          </Layout>
        </Layout>
      </Skeleton>
    )
  } else {
    return <Layout>{children}</Layout>
  }
}
export default inject('usersStore', 'commonStore', 'uiStore')(observer(DefaultLayout))