import { observable, action, decorate } from 'mobx'
import Cookies from 'js-cookie'
import { makeAutoObservable, autorun, runInAction } from "mobx"
import { buildASTSchema } from 'graphql'

class CommonStore {
  // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYTI5NWE5ZTE3NjIyMmQwY2UxN2IyYyIsImlhdCI6MTU3MzYzMzk2MiwiZXhwIjoxNTc2MjI1OTYyfQ.7EE-EsBPasCMO--xSOugZts3o2eoencz176KsDzYbDc'
  token = localStorage.getItem('jwt')
  initializing = true
  isLoading = false
  isRemember = true
  currentPage = 'dashboard'
  currentPageTitle = 'Nihub'
  company = {
    "id": "5fe7f855b6244128941ecc23",
    "name": "Nhà iu",
    'slug': 'nhaiu'
  }
  theme = {
    v: 0,
    colors: {
      main: '#37474f',
      dark: '#001529',
      white: '#FFFFFF',
      lighter: '#b0bec5',
      text: '#fafafa',
      menuTextColor: '#333',
      menuTextColorSelect: "green",
      greenLight: '#52C41A',
      grayLight: '#E8E8E8',
      textGrayLight: '#9EA0A5',
      darkBlue: '#00008B',
      headingColor: 'rgba(0, 0, 0, 0.85)',
      sidebarBgColor: '#433E3A',
      sidebarBgColorSelected: '#4C4743',
      textColor: 'rgba(0, 0, 0, 0.65)',
      textColorSecondary: 'rgba(0, 0, 0, 0.45)',
      disabledColor: 'rgba(0, 0, 0, 0.25)',
      dividers: 'rgba(0, 0, 0, 0.09)',
      background: '#fff',
      infoColor: '#5085B5',
      successColor: '#529640',
      processingColor: '#749EC4',
      errorColor: '#F5222D',
      highlightColor: '#F5222D',
      warningColor: '#FAAD14',
      normalColor: '#D9D9D9',
      tableHeader: 'rgba(0, 0, 0, 0.02)',
      disabledColorDark: 'rgba(255, 255, 255, 0.35)',
      bgColorBase: '#F3F3FA',
      borderColorBase: '#D9D9D9',
      primaryColor: '#03BF68',
      primaryColor2: 'rgba(187, 17, 118, 0.9)',
      primaryColor3: '#FDEDBC',
    },
    mediaQueries: {
      xs: '480px',
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
      xxl: '1600px',
    }
  }

  constructor() {
    makeAutoObservable(this)
  }

  setCompany = (c) => {
    this.company = c
  }

  setInitializing = (b) => {
    this.initializing = b
  }

  setThemeColor = (colors) => {
    Object.assign(this.theme.colors, colors);
    this.theme.v += 1
  }

  setTheme = (theme) => {
    Object.assign(this.theme, theme);
    this.theme.v += 1
  }

  checkToken = () => {
    if (this.token) return true
    return !!(localStorage.jwt || sessionStorage.jwt)
  }

  setRemember(isRemember) {
    this.isRemember = isRemember
  }

  setCurrentPage(pageName) {
    this.currentPage = pageName
  }

  setCurrentPageTitle(pageTitle) {
    this.currentPageTitle = pageTitle
  }

  clearToken = () => {
    localStorage.clear()
    sessionStorage.clear()
    Cookies.remove('access_token')
  }

  setToken(token) {
    this.token = token
  }
}

export default new CommonStore()
