import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '../../layouts/DefaultLayout'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'
import DataTableServices from '../../../services/datatableServices'
import { useMutation } from '@apollo/react-hooks'
import useAsyncQuery from '../../../ultils/useAsyncQuery'
import QuantityModal from './QuantityModal'
import DatarowSelectInput from '../DataBoard/DataTools/DatarowSelectInput'
import { imagePreset } from '../../../ultils/datatableUtils'

import {
    message,
    Space,
    Button,
    Switch,
    Radio,
    Drawer,
    Image,
    List, Divider,
    Row, Col
} from 'antd'

import {
    PlusOutlined,
    MinusOutlined,
    ReloadOutlined,
    HistoryOutlined
} from '@ant-design/icons'
import { flatMap, merge as LMerge, intersection } from 'lodash-es'
import moment from 'moment'

const QuickAdjustProductQty = ({ usersStore, commonStore, datatableStore, history }) => {
    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )
    const [typeUpdate, setTypeUpdate] = useState('add')
    const [currentData, setCurrentData] = useState(false)
    const [currentBranch, setCurrentBranch] = useState(false)

    // const [tagVitri, setTagVitri] = useState(false)
    const [isResetTag, setIsResetTag] = useState(false)
    // const [resetNgaykiem, setResetNgaykiem] = useState(true)
    const [showRecent, setShowRecent] = useState(false)
    const [branches, setBranches] = useState(false)


    const [recentProducts, setRecentProducts] = useState([])

    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, (v) => { return Object.assign(v.row_data, { key: v.id }) })
        return items
    }



    let searchColumns = ['code', 'name']
    useEffect(() => {
        commonStore.setCurrentPageTitle('Cập nhật SL')
        async function init() {
            let branchData = await findRowsByFilter(
                {
                    tableId: commonStore?.company?.companyData?.branch_table,
                    hasPreSearch: false,
                    limit: 100,
                }
            )
            setBranches(branchData)
            // let r = await getRow()
            // if (r) setRData(r.data.datarow)
            // console.log(`r`, r)
        }
        if (!branches && commonStore.company)
            init()
    }, [commonStore.company])


    // useEffect(() => {

    //     console.log('tagVitri', tagVitri)
    //     return () => {

    //     }
    // }, [tagVitri, branches])


    return (
        <DefaultLayout>
            {branches && datatableStore.tagVitriKiemSL &&
                <SearchBoxItem
                    hasPreSearch={false}
                    autoFocus={true}
                    clearAfterSelect={true}
                    className='searchSyncPrice'
                    autoClearSearchValue={true}
                    width={'100%'}
                    tableId={commonStore?.company?.companyData?.product_table}
                    filterOnDropdown={true}
                    columns={searchColumns}
                    displayColumn={['image', 'code', 'ngay_kiem', 'sl_tonkho', 'name']}
                    quickSelectOne={true}
                    hasImage={true}
                    onSelect={async (v, o) => {
                        // if (!currentBranch) {
                        //     alert('Dữ liệu chi nhánh chưa đúng!')
                        //     return
                        // }
                        let rData = o?.data?.row_data
                        if (rData) rData.id = o.key
                        if (rData?.id) {
                            // console.log('rData', rData)
                            rData.tag_vitri = rData?.tag_vitri || []
                            // branches.forEach(br => {
                            //     console.log('br.name', br.name)
                            //     let its = intersection(br.tag_vitri, rData.tag_vitri)
                            //     console.log('its', its)
                            // });

                            if (isResetTag) {
                                rData.new_tag_vitri = datatableStore.tagVitriKiemSL
                            } else {
                                rData.new_tag_vitri = rData.tag_vitri
                                datatableStore.tagVitriKiemSL.forEach(tag => {
                                    if (!rData.tag_vitri.includes(tag)) rData.new_tag_vitri.push(tag)
                                });
                            }

                            if (datatableStore.resetNgaykiem) {
                                if (!rData.ngay_kiem) {
                                    rData.new_tag_vitri = datatableStore.tagVitriKiemSL
                                    if (!rData.sl_kiem) {
                                        rData.sl_tonkho = 0
                                    }
                                } else {
                                    // let startdate = moment();
                                    let ngay_kiem = moment(rData.ngay_kiem)
                                    var today = moment().startOf('day');
                                    var days = Math.round(moment.duration(today - ngay_kiem).asDays());
                                    // console.log('days', days)
                                    // startdate.subtract(5, 'd');
                                    if (days > 20) {
                                        console.log('ok reset')
                                        rData.new_tag_vitri = datatableStore.tagVitriKiemSL
                                        rData.sl_tonkho = 0
                                    }
                                }
                            }
                            // console.log('rData', rData)
                            setCurrentData(rData)

                        }
                        // window.location.reload(false);
                    }}
                    onEnter={(v) => {
                        //   console.log('v', v)
                        //   alert(v)
                    }}
                />
            }
            <br />
            <br />
            {<DatarowSelectInput
                tableId={commonStore?.company?.companyData?.tag_table}
                placeholder={'thẻ vị trí'}
                width={'100%'}
                viewType={'tag'}
                initValue={datatableStore.tagVitriKiemSL || []}
                initFilter={{
                    "match": {
                        "category": "Sản phẩm",
                        "sub_cate": "Tag vị trí"
                    }
                }}
                size={'large'}
                // initValue={updateFilter.match && updateFilter.match[af.filterPath] ? updateFilter.match[af.filterPath] : false}
                onOk={(item) => {
                    // console.log('item', item)
                    let bb = {}
                    let branchCount = 0

                    branches.forEach(br => {
                        bb[br.name] = br.tag_vitri.some((v) => item.includes(v))
                        if (bb[br.name]) {
                            branchCount++
                            setCurrentBranch(br)
                        }
                        if (bb[br.name] && !item.includes(br.map_tag)) item.unshift(br.map_tag)
                    });
                    if (branchCount > 1) {
                        setCurrentBranch(false)
                        alert('Không được chọn vị trí ở 2 chi nhánh cùng lúc!')
                    }
                    if (branchCount = 0) {
                        setCurrentBranch(false)
                        alert('Phải chọn ít nhất 1 tag vị trí')
                    }
                    datatableStore.setTagVitriKiemSL(item)
                }}
            />}
            <br />
            <br />
            <Space>
                <Button type={typeUpdate === "add" ? "primary" : "dashed"} size='large' value="add" icon={<PlusOutlined />}
                    onClick={() => {
                        setTypeUpdate('add')
                    }}

                >thêm vào</Button>
                <Button style={{ backgroundColor: typeUpdate === "sub" ? "red" : "white" }} type={typeUpdate === "sub" ? "primary" : "dashed"} size='large' value="sub" icon={<MinusOutlined />}
                    onClick={() => {
                        setTypeUpdate('sub')
                    }}
                >bỏ ra</Button>
                <Button style={{ backgroundColor: typeUpdate === "update" ? "blue" : "white" }} type={typeUpdate === "update" ? "primary" : "dashed"} size='large' value="update" icon={<ReloadOutlined />}
                    onClick={() => {
                        setTypeUpdate('update')
                    }}
                >cập nhật</Button>

            </Space>
            <Divider />
            {/* <Radio.Group
                onChange={() => {
                    setIsResetTag(!isResetTag)
                }}
                value={isResetTag}>
                <Radio value={false}>Thêm thẻ vị trí</Radio>
                <Radio value={true}>Reset thẻ vị trí</Radio>
            </Radio.Group> */}
            <Row>
                <Col flex="55px">
                    <Switch
                        defaultChecked={datatableStore.resetNgaykiem}
                        onChange={(checked) => {
                            datatableStore.setResetNgayKiem(checked)
                        }} />
                </Col>
                <Col flex="auto">Reset theo ngày kiểm</Col>

            </Row>
            <Divider />
            <Row>
                <Col flex="55px">
                    <Switch
                        defaultChecked={isResetTag}
                        onChange={(checked) => {
                            setIsResetTag(checked)
                        }} />
                </Col>
                <Col flex="auto">Reset thẻ vị trí</Col>
            </Row>
            <Divider />
            <Button type={"default"} size='large' value="update" icon={<HistoryOutlined />}
                onClick={() => {
                    setShowRecent(true)
                }}
            >Lịch sử tìm kiếm</Button>



            {/* <Switch checkedChildren="thêm thẻ vị trí" unCheckedChildren="reset thẻ vị trí" /> */}
            {currentData && branches && currentBranch &&
                <QuantityModal
                    typeUpdate={typeUpdate}
                    isResetTag={isResetTag}
                    resetNgaykiem={datatableStore.resetNgaykiem}
                    branch={currentBranch}
                    defaultBranch={branches[0]}
                    onSave={async (v) => {
                        setCurrentData(false)
                        let qty = v?.quanityLogs?.extra?.qty
                        // let sl_kiem = rData.sl_kiem
                        let sl_tonkho = v?.quanityLogs?.n?.sl_tonkho
                        if (isNaN(qty)) {
                            alert('Dữ liệu đầu vào sai!')
                        } else {
                            // qty = parseInt(qty)
                            // if (sl_kiem) {
                            //     sl_kiem += qty
                            //     sl_tonkho = sl_kiem
                            // } else {
                            //     sl_kiem = qty
                            //     sl_tonkho = qty
                            // }

                            if (sl_tonkho >= 0) {
                                let r = await updateManyDataRows({
                                    variables: {
                                        input: {
                                            data: {
                                                type: "oldnew",
                                                rows: [{
                                                    id: v?.quanityLogs?.row_id,
                                                    n: {
                                                        'sl_kiem': sl_tonkho,
                                                        'sl_tonkho': sl_tonkho,
                                                        'tag_vitri': v.tagUpdate,
                                                        'branches': v?.quanityLogs?.n?.branches,
                                                        ngay_kiem: (new Date()).toISOString()
                                                    },
                                                    o: false
                                                }]
                                            }
                                        }
                                    }
                                })
                            }
                        }
                        datatableStore.setDoFocusKiemSL(true)
                        // let tag_vitri = v.data.tag_vitri
                        // console.log('tag_vitri', tag_vitri)
                        // console.log('tagVitri', tagVitri)
                        setRecentProducts([v, ...recentProducts])
                    }}
                    onClose={() => {
                        setCurrentData(false)
                    }}
                    initData={currentData}
                />

            }
            <Drawer
                visible={showRecent}
                title='Mã gần đây'
                placement="right"
                onClose={() => {
                    setShowRecent(false)
                }}
            >
                {/* {recentProducts.map(p => {
                    return p.code
                })} */}
                <List
                    style={{ padding: 10 }}
                    itemLayout="horizontal"
                    dataSource={recentProducts}
                    renderItem={item => {
                        let label = ""
                        let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
                        let imageSrc = item.image
                        if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
                        else {
                            thumbnail = imagePreset(imageSrc, 'xsmall')
                        }
                        // console.log('item.data', item.data)
                        switch (item.quanityLogs?.extra?.type) {
                            case 'add':
                                label = ` thêm ${item.quanityLogs?.extra?.qty} sp (${item.quanityLogs?.o?.sl_tonkho || '?'} → ${item.quanityLogs?.n?.sl_tonkho})`
                                break;
                            case 'sub':
                                label = ` bỏ ${item.quanityLogs?.extra?.qty} sp (${item.quanityLogs?.o?.sl_tonkho || '?'} → ${item.quanityLogs?.n?.sl_tonkho})`
                                break;
                            case 'update':
                                label = ` cập nhật ${item.quanityLogs?.o?.sl_tonkho || '?'} → ${item.quanityLogs?.n?.sl_tonkho}`
                                break;
                            default:
                                break;
                        }
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Image
                                        width={40}
                                        height={40}
                                        src={thumbnail}
                                        preview={{
                                            src: imageSrc,
                                        }}
                                    />}
                                    title={item?.quanityLogs?.extra?.code + '-' + item?.quanityLogs?.extra?.name}
                                    description={
                                        <>
                                            SL : <strong style={{ color: item.color }}>{label}</strong>
                                        </>
                                    }
                                />
                            </List.Item>
                        )

                    }

                    }
                />
            </Drawer>
        </DefaultLayout>
    )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(QuickAdjustProductQty))