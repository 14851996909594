import React, { useEffect, useState } from 'react'
import useAsyncQuery from '../../../../../ultils/useAsyncQuery'
import { Tags_render } from '../../DataTools/DataField'
import DataTableServices from '../../../../../services/datatableServices'
import { price_k } from '../../../../../ultils/datatableUtils'
import {
    Space,
    Timeline,
    Tree
} from 'antd';
import moment from 'moment'
import {
    ShoppingOutlined,
    SmileFilled,
} from '@ant-design/icons';

const RowHistories = ({
    preloadTable,
    row_id,
    table_id,
}) => {

    const [rowHistories, setRowHistories] = useState(false)

    const getRowHistories = useAsyncQuery(DataTableServices.GET_ROW_HISTORIES())
    const findHistories = async (dataInput) => {
        const { data, error } = await getRowHistories({
            input: {
                data: dataInput
            }
        })
        let result = data?.getRowHistories?.data
        if (result.status === 'OK')
            return result.out

        return false
    }


    const Histories = ({ logItem }) => {
        // console.log(`logItem`, logItem)
        // let address = customer?.address
        // if (!address)
        //   address = customer?.ref?.address
        let treeData = false
        if (logItem.hdata && logItem.hdata.type === "merge_order") {
            treeData = {
                title: "Đơn gộp #" + logItem.hdata.code + " từ:",
                key: logItem.hdata.code,
                icon: <ShoppingOutlined />,
                children: []
            }
            logItem.hdata.data.forEach(h => {
                treeData.children.push({
                    title:
                        (
                            <>
                                <a href={'/row/' + h.id} target="_blank" title={h.code}>#{h.code}</a> : <strong style={{ color: "#608EBF" }}>{price_k(h.tong_tien, '?', 3)}</strong> ({h.tong_sl})
                            </>
                        ),
                    key: h.code,
                    icon: <SmileFilled />
                })
            });
        }

        return (
            <div>
                {moment(logItem?.ngay_tao).format('DD/MM/YY HH:mm')} - {logItem.user}
                {logItem.change ?
                    <>
                        <br />
                        {logItem.change.map(item => {
                            if (item.content) {
                                return (
                                    <>
                                        <strong>{item.label}</strong> : {item.content}
                                        <br />
                                    </>
                                )
                            }
                            return (
                                <>
                                    <strong>{item.label}</strong> : {item.o} → {item.n}
                                    <br />
                                </>
                            )
                        })}
                    </>
                    : ""}
                {logItem.content && !logItem.hdata ?
                    <>
                        <br />
                        {logItem.content}
                    </>
                    : ""}
                {treeData &&
                    (<div>
                        <Tree
                            style={{ backgroundColor: "#DFE6E6" }}
                            showIcon
                            defaultExpandAll
                            selectable={false}

                            // defaultSelectedKeys={[logItem.hdata.code]}
                            treeData={[treeData]}
                        />
                    </div>)
                }
                {logItem.hdata && logItem.hdata.type === "be_merged" && (
                    <div
                        style={{ backgroundColor: "#E6DFE4", padding: 10 }}
                    >
                        Gộp vào đơn : <strong><a href={'/row/' + logItem.hdata.to_id} target="_blank" title={logItem.hdata.to_code}>#{logItem.hdata.to_code}</a></strong>
                    </div>
                )
                }
            </div>
        )
    }


    useEffect(() => {
        async function init() {
            let ch = await findHistories({ row_id: row_id, table_id: table_id })
            setRowHistories(ch)
        }
        if (row_id && table_id)
            init()
    }, [row_id, table_id])

    return (
        <>
            {rowHistories ?
                <>
                    <Timeline>
                        {rowHistories.map(item => {
                            return (
                                <Timeline.Item>
                                    <Histories logItem={item} />
                                </Timeline.Item>
                            )
                        }
                        )}
                    </Timeline>
                </> : ""}
        </>
    )
}
export default RowHistories