import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Input,
    Transfer,
    Modal,
    Image,
    message
} from 'antd';
import { imagePreset, price_k } from '../../../../../ultils/datatableUtils'
import DataTableServices from '../../../../../services/datatableServices'
const OrderSplitModal = ({
    datatableStore,
    title,
    // width = "90%",
    orderData,
    onSave,
    onClose,
}) => {

    const [targetKeys, setTargetKeys] = useState([])
    const [loading, setLoading] = useState(false)
    const [desc1, setDesc1] = useState('')
    const [desc2, setDesc2] = useState('')
    // const onFinish = async (values) => {

    // };
    const [splitOrder] = useMutation(
        DataTableServices.SPLIT_ORDER(),
        {
            errorPolicy: 'all'
        }
    )

    const renderItem = item => {
        let thumbnail = imagePreset(item.image, 'xsmall')
        const customLabel = (
            <span className="list-compact">
                <div>
                    <Image
                        width={40}
                        height={40}
                        src={thumbnail}
                        preview={{
                            src: item.image,
                        }}
                    />
                </div>

                <span className="content">
                    {item.code} {item.name.replace(item.code, "")}
                    {/* <span className="text">

                    </span>
                    <span className="text">
                        Số lượng : {item.qty}, Giá : {item.price}
                    </span> */}
                </span>
            </span>
        );
        return {
            label: customLabel, // for displayed item
            value: item.code, // for title and filter matching
        };
    };

    const handleChange = (targetKeys, direction, moveKeys) => {
        setTargetKeys(targetKeys)
        let item1 = orderData.row_data.items.filter(o => !targetKeys.includes(o.code))
        let item2 = orderData.row_data.items.filter(o => targetKeys.includes(o.code))
        let sum1 = 0
        let sum2 = 0
        let weight1 = 0
        let weight2 = 0
        item1.forEach(o => {
            if (o.qty) {
                sum1 += o.price * o.qty
                if (o.weight) weight1 += o.qty * o.weight
            }
        });

        item2.forEach(o => {
            if (o.qty) {
                sum2 += o.price * o.qty
                if (o.weight) weight2 += o.qty * o.weight
            }
        });

        setDesc1(<span>Nặng:<strong style={{ color: 'green', marginRight: 10 }}>{weight1 / 1000} kg</strong>  Giá: <strong style={{ color: 'green' }}>{price_k(sum1, '?', 3)}</strong></span>)
        setDesc2(<span>Nặng:<strong style={{ color: 'green', marginRight: 10 }}>{weight2 / 1000} kg</strong>  Giá: <strong style={{ color: 'green' }}>{price_k(sum2, '?', 3)}</strong></span>)
        // setDesc2('Nặng:' + weight2 / 1000 + 'KG - Giá:' + price_k(sum2, '?', 3))


    };


    return (
        <Modal
            title={title ? title : false}
            visible={true}
            width={'auto'}
            // width={width ? width : "90%"}
            maskClosable={false}
            onCancel={onClose}
            centered
            footer={[
                <Button onClick={onClose} >
                    Đóng
                </Button>,
                <Button key="submit" type="primary"
                    loading={loading}
                    onClick={async () => {
                        if (targetKeys.length < 1) {
                            alert('Phải chọn sp cho đơn tách!')
                            return false
                        }
                        const input = {
                            data: {
                                mainId: orderData.id,
                                itemArr: [targetKeys]
                            }
                        }
                        setLoading(true)
                        let r = await splitOrder({
                            variables: { input }
                        })
                        setLoading(false)
                        let result = r.data.splitOrder?.data
                        if (result.status === 'OK') {
                            message.success('Tách thành công!!')
                            datatableStore.setRowDetail(result.old)
                            if (result.newIds.length === 1) {
                                var win = window.open('/row/' + result.newIds[0], '_blank');
                                win.focus();
                            }
                            onClose()
                        } else {
                            message.error('Có lỗi khi gộp đơn. Hãy thử lại!')
                        }
                    }}>
                    Tách đơn
                </Button>,
            ]}
        >
            <Transfer
                titles={[desc1, desc2]}
                rowKey={record => record.code}
                listStyle={{
                    width: 420,
                    height: 400,
                    align: "center"
                }}
                dataSource={orderData.row_data.items}
                targetKeys={targetKeys}
                render={renderItem}
                onChange={handleChange}

            />
        </Modal>
    )
}
export default inject("datatableStore")(observer(OrderSplitModal))