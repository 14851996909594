import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

const PrivateRoute = (props) => {
  const { usersStore, component: Component, role, ...rest } = props
  let token
  try {
    if (!usersStore.currentUser) {
      token = Cookies.get('access_token')
      // console.log('token', token)
      let decodedUser = jwtDecode(token)
      // console.log('decodedUser', decodedUser)
      // switch (decodedUser.user.role.type) {
      //   case 'organizer':
      //     if (!lobby || !booth) throw Error
      //     break
      //   case 'booth_owner':
      //     if (!booth) throw Error
      //     break
      //   default:
      //     throw Error

      // }
      if (role.includes(decodedUser.user.company_role)) {
        usersStore.setCurrentUser(decodedUser.user, token)
        // dispatch(loginSuccess(decodedUser.user, token))
        return <Route {...rest} render={routeProps => <Component />} />
      } else {
        throw Error
      }
    } else {
      if (role.includes(usersStore.currentUser.company_role)) {
        // console.log('usersStore.currentUser', usersStore.currentUser)
        return <Route {...rest} render={routeProps => <Component />} />
      } else {
        throw Error
      }
    }
  } catch (e) {
    Cookies.remove('access_token')
    return <Redirect to={{ pathname: '/auth/login' }} />
  }
}
export default inject("usersStore")(observer(PrivateRoute));