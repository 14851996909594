import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Dropdown,
  Menu,
  Space,
  Modal,
  Select,
  Button,
  DatePicker,
  message,
  Form,
  Upload,
  Drawer,
  Tag
} from 'antd'
import {
  SyncOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  PictureOutlined,
  PlusOutlined,
  FilterOutlined,
  TagOutlined,
  CarOutlined,
  ShoppingOutlined,
  LinkOutlined,
  PrinterOutlined,
  BarChartOutlined,
  FileExcelOutlined,
  DropboxOutlined,
  CodepenCircleOutlined,
  SplitCellsOutlined,
  FolderAddOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FireOutlined
} from '@ant-design/icons'
// import moment from 'moment'
// import { flatMap, merge as LMerge } from 'lodash-es'
import { toJS } from 'mobx'
import QuickAddParent from '../DataTools/QuickAddParent'

// import { nanoid } from 'nanoid'

import { buildFilterByParam, updateFilterParam, price_k } from '../../../../ultils/datatableUtils'
import DefaultLayout from '../../../layouts/DefaultLayout'
// import StyledLoadingOverlay from '../../../common/StyledLoadingOverlay'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'

import DatarowSelectInput from '../DataTools/DatarowSelectInput'
import OrderStatusSelect from '../DataTable/Order/OrderStatusSelect'

import TagSelectModal from '../DataTools/TagSelectModal'
import DataSelectModal from '../DataTools/DataSelectModal'

// import DataAutoCompleteInput from '../DataTools/DataAutoCompleteInput'

// import { price_render } from '../DataTools/DataField'
// import DataRowDetail from '../DataTools/DataRowDetail'
import SearchBoxItem from '../DataTools/SearchBoxItem'
// import DataNewItem from '../DataTools/DataNewItem'

import useAsyncQuery from '../../../../ultils/useAsyncQuery'
import DataTableRows from './DataTableRows'
import DataFileUpload from './DataFileUpload'

import PrintOrderList from '../../../printer/Product/PrintOrderList'
import PrintListOrderDetail from '../../../printer/Product/PrintListOrderDetail'
import PrintManyItems from '../../../printer/Product/PrintManyItems'
import PrintProductsQRCode from '../../../printer/Product/PrintProductsQRCode'

import FilterDetail from './Filter/FilterDetail'
import moment from 'moment'
import RowsSearchModal from './RowsSearchModal'
import KiemhangModal from '../../Tools/KiemhangModal'

import ProductList from '../DataView/ProductList'
import BooleanModal from '../../Tools/BooleanModal'
import QuickSetSLByBlock from '../../Tools/QuickSetSLByBlock'
import QuickSetWeightBlock from '../../Tools/QuickSetWeightBlock'
// const { Search } = Input
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;
const TopBarContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`
// const defaultLimit = 50

const DataTable = ({ usersStore, commonStore, datatableStore }) => {
  const [massLoading, setMassLoading] = useState(false)
  const [printLinks, setPrintLinks] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [currentMassFilter, setCurrentMassFilter] = useState(false)
  const [currentTagFilter, setCurrentTagFilter] = useState(false)

  const [showImportSLModal, setShowImportSLModal] = useState(false)
  const [showImportKHSLModal, setShowImportKHSLModal] = useState(false)
  const [showKiemHangModal, setShowKiemHangModal] = useState(false)
  const [showBooleanModal, setShowBooleanModal] = useState(false)

  const [showUpdateSLBlock, setShowUpdateSLBlock] = useState(false)
  const [showWeightModal, setShowWeightModal] = useState(false)

  let history = useHistory();
  const [updateFilter, setUpdateFilter] = useState(false)
  const { id } = useParams()
  const location = useLocation();
  useEffect(() => {
    let k = buildFilterByParam(location.search)
    setUpdateFilter(k)
  }, [location.search])


  // useEffect(() => {
  //   async function fetchData() {
  //     if (table.id) {
  //       if (table.type === 'order') {
  //         let statusData = await findRowsByFilter(
  //           {
  //             tableId: commonStore?.company?.companyData?.status_table,
  //             // tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
  //             hasPreSearch: false,
  //             limit: 100,
  //             // filter: { match: { "category": 'Trạng thái đơn' } }
  //           }
  //         )
  //         let orderStatus = statusData.filter(item => item.category === 'Trạng thái đơn')
  //         let shipStatus = statusData.filter(item => item.category === 'Vận chuyển')
  //         let paymentStatus = statusData.filter(item => item.category === 'Thanh toán')
  //         // console.log(`paymentStatus`, paymentStatus)
  //         let tagData = await findRowsByFilter(
  //           {
  //             tableId: commonStore?.company?.companyData?.tag_table,
  //             // tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
  //             hasPreSearch: false,
  //             limit: 100,
  //             filter: { match: { "category": 'Đơn hàng' } }
  //           }
  //         )
  //         datatableStore.setPreloadTable('tags', tagData)
  //         datatableStore.setPreloadTable('status', orderStatus)
  //         datatableStore.setPreloadTable('shipStatus', shipStatus)
  //         datatableStore.setPreloadTable('paymentStatus', paymentStatus)

  //       }
  //       datatableStore.setCurrentDatarows([])
  //       // setDefaultCurrent(1)
  //       initColumn()
  //       stopAutoSync()
  //       datatableStore.resetFilter()
  //       datatableStore.setSelectedRowKeys([])
  //     }
  //     startAutoSync()
  //   }
  // }, [id])


  const [currentDT, setCurrentDT] = useState(false)
  const [searchColumns, setSearchColumns] = useState([])
  // const [showSyncPriceQty, setShowSyncPriceQty] = useState(false)

  const [syncType, setSyncType] = useState(false)

  // const [currentEditRecord, setCurrentEditRecord] = useState(false)
  // const [form] = Form.useForm()

  const [importRows] = useMutation(
    DataTableServices.IMPORT_ROWS(),
    {
      errorPolicy: 'all'
    }
  )

  const [updateManyDataRows] = useMutation(
    DataTableServices.UPDATE_MANY_ROWS(),
    {
      errorPolicy: 'all'
    }
  )

  const [fillDataToTable] = useMutation(DataTableServices.FILL_TO_TABLE(), {
    errorPolicy: 'all'
  })

  const [deleteManyRows] = useMutation(DataTableServices.DELETE_MANY_ROWS(), {
    errorPolicy: 'all'
  })

  const [getProductsByOrders] = useMutation(DataTableServices.GET_PRODUCTS_BY_ORDERS(), {
    errorPolicy: 'all'
  })

  const [getPrintToken] = useMutation(
    DataTableServices.GET_PRINT_TOKEN(),
    {
      errorPolicy: 'all'
    }
  )

  const [doShip] = useMutation(
    DataTableServices.DO_SHIP(),
    {
      errorPolicy: 'all'
    }
  )

  const [exportSLineLive] = useMutation(
    DataTableServices.EXPORT_SLINE_LIVE(),
    {
      errorPolicy: 'all'
    }
  )

  const [exportProductExcel] = useMutation(
    DataTableServices.EXPORT_PRODUCTS_EXCEL(),
    {
      errorPolicy: 'all'
    }
  )

  const [exportRowsExcel] = useMutation(
    DataTableServices.EXPORT_ROWS_EXCEL(),
    {
      errorPolicy: 'all'
    }
  )

  const [exportOrderExcel] = useMutation(
    DataTableServices.EXPORT_ORDERS_EXCEL(),
    {
      errorPolicy: 'all'
    }
  )

  const [exportOrderExcelDetail] = useMutation(
    DataTableServices.EXPORT_ORDERS_EXCEL_DETAIL(),
    {
      errorPolicy: 'all'
    }
  )


  const [createReport] = useMutation(
    DataTableServices.CREATE_REPORT(),
    {
      errorPolicy: 'all'
    }
  )

  const [refreshDataOrder] = useMutation(
    DataTableServices.REFRESH_ORDER_DATA(),
    {
      errorPolicy: 'all'
    }
  )

  const [recalcProductQty] = useMutation(
    DataTableServices.RECALC_PRODUCT_QTY(),
    {
      errorPolicy: 'all'
    }
  )

  const [removeTagRows] = useMutation(
    DataTableServices.REMOVE_TAG_ROWS(),
    {
      errorPolicy: 'all'
    }
  )

  const [massUpdateTag] = useMutation(
    DataTableServices.MASS_UPDATE_TAG(),
    {
      errorPolicy: 'all'
    }
  )

  const [syncOrdersFromSL] = useMutation(
    DataTableServices.SYNC_SL_ORDERS(),
    {
      errorPolicy: 'all'
    }
  )

  const [syncCustomerFromSL] = useMutation(
    DataTableServices.SYNC_SL_CUSTOMERS(),
    {
      errorPolicy: 'all'
    }
  )

  const [syncSLCate] = useMutation(
    DataTableServices.SYNC_SL_CATE(),
    {
      errorPolicy: 'all'
    }
  )

  const [removeSLProducts] = useMutation(
    DataTableServices.REMOVE_SL_PRODUCTS(),
    {
      errorPolicy: 'all'
    }
  )

  const [bulkAssignSLProductCate] = useMutation(
    DataTableServices.BULK_ASSIGN_SL_PCATE(),
    {
      errorPolicy: 'all'
    }
  )

  const getSLProducts = useAsyncQuery(DataTableServices.GET_SL_PRODUCTS())


  const importOptionsData =
  {
    pending: 'Mở',
    confirmed: 'Đã xác nhận',
    completed: 'Đã hoàn thành',
    cancelled: 'Đã hủy',
    temp: 'Nháp'
  }


  const getSLOrders = useAsyncQuery(DataTableServices.GET_SL_ORDERS())
  const getSLCustomers = useAsyncQuery(DataTableServices.GET_SL_CUSTOMERS())

  // const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

  const massAssign2SLProduct = async (stype, v = false, syncData = false) => {
    if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
      // let valid_rows = datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key) && item.sl_id)
      let valid_rows = datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key))
      let data = {
        hub_products: valid_rows,
        sl_ids: valid_rows.map(p => p.sl_id),
        syncType: stype
      }

      if (syncData) {
        data.syncData = syncData
      }
      if (v) {
        data.categories = [{
          name: v?.ref?.name,
          id: v?.ref?.sl_id
        }]
      }
      let u = await bulkAssignSLProductCate({
        variables: {
          input:
          {
            data: data
          }
        }
      })
      let result = u?.data?.bulkAssignSLProductCate.data
      if (result.status === 'OK') {
        if (result?.msg) alert(result?.msg)
        window.location.reload()
      } else if (result.status === 'ERROR') {
        alert(result?.msg)
      }
    }
  }

  const getShoplineOrders = async (dataInput) => {
    const { data, error } = await getSLOrders({
      input: {
        data: dataInput
      }
    })
    let results = data?.getShoplineOrders?.data
    if (!results?.items) return false
    // var items = flatMap(results.items, myFlat)
    return results
  }

  const getShoplineCustomers = async (dataInput) => {
    const { data, error } = await getSLCustomers({
      input: {
        data: dataInput
      }
    })
    let results = data?.getShoplineCustomers?.data
    if (!results?.items) return false
    // var items = flatMap(results.items, myFlat)
    return results
  }


  function saveAsXlsxFile(fileName, bufferBase64) {
    var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var a = document.createElement('a');
    a.href = mediaType + bufferBase64;
    a.download = fileName;
    document.body.appendChild(a);
    a.style.display = 'none'
    a.click()
    document.body.removeChild(a)
  }


  async function doPrint(order_codes) {
    if (printLinks) {
      setPrintLinks(false)
    }
    let input = {
      data: {
        order_codes: order_codes
      }
    }
    let r = await getPrintToken({
      variables: { input }
    })
    let pLinks = r?.data?.getPrintToken?.data?.printLinks
    // console.log(`printLink`, printLink)
    if (pLinks) {
      // let link = "https://dev-online-gateway.ghn.vn/a5/public-api/printA5?token=" + r?.data?.getPrintToken?.data?.token
      setPrintLinks(pLinks)
    }
  }

  const SendShipReturn = ({ shipRetData }) => {
    // console.log(`shipRetData`, shipRetData)
    return (
      <>
        {shipRetData.map(ret => {
          return (
            <>
              <span style={{ color: ret.row_data ? 'green' : 'red', marginBottom: 8 }}>
                {ret.row_data ?
                  <><strong>{ret.code} - {ret.name}</strong> - OK</>
                  :
                  <>
                    <strong> {ret.code} - {ret.name}</strong> - ERROR: {ret.msg}
                  </>
                }
              </span>
              < br />
            </>
          )

        })}
      </>
    )
  }

  async function sendManyShip() {
    message.loading('Đang gửi vận chuyển...', 0);
    let input = {
      data: {
        o_ids: toJS(datatableStore.selectedRowKeys)
      }
    }
    let r = await doShip({
      variables: { input }
    })
    message.destroy()
    if (r.data?.doShip?.data?.status === 'OK') {
      let updateOrders = r.data.doShip.data.orders
      // console.log(`updateOrders`, updateOrders)
      if (updateOrders.length === 1) {
        if (updateOrders[0].row_data) {
          // orderData.row_data = updateOrders[0].row_data
          datatableStore.setRowDetail(updateOrders[0].row_data)
        } else {
          // openNotification('Lỗi gửi vận chuyển', updateOrders[0].msg, 'error')
          // notification.open({
          //   message: title,
          //   description: msg,
          //   duration: 0,
          //   icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          //   placement: 'bottomRight'
          // });
          message.error(updateOrders[0].msg);
        }
      }
      else if (updateOrders.length > 1) {
        let temp = toJS(datatableStore.currentDatarows)
        updateOrders.forEach(u => {
          if (u.row_data) {
            let index = temp.findIndex(item => item.code === u.code)
            // console.log(`index`, index)
            if (index >= 0) {
              temp[index] = u.row_data
            }
          }
        });
        datatableStore.setCurrentDatarows(temp)
        Modal.info({
          title: 'Kết quả gửi hàng',
          content: (
            <SendShipReturn shipRetData={updateOrders} />
          ),
          onOk() { },
        });

        // sendShipReturn(updateOrders)
      }
      // console.log(`rData`, rData)
      // orderData.row_data = rData
      // datatableStore.setRowDetail(rData)
      // console.log(`orderData`, orderData)
    } else {
      message.error(r?.data?.doShip?.data?.msg);
    }

  }

  useEffect(() => {
  }, [datatableStore.currentDatarows])

  const quickAdd = () => {
    datatableStore.quickAddRow(currentDT, 1)
  }


  const onSyncClick = async () => {
    if (!currentDT) return
    const input = {
      data: currentDT
    }
    let rdata = await fillDataToTable({
      variables: { input }
    })
    // console.log(`rdata`, rdata)
    // datatableStore.resetFilter()
    // console.log('rdata', rdata)
    // datatableStore.setCurrentDatarows(ret)
  }

  // function two_digit(v) {
  //   return ('0' + v).slice(-2)
  // }

  useQuery(DataTableServices.GET_TABLE(id), {
    onCompleted: tb => {
      if (tb.datatable) {
        commonStore.setCurrentPageTitle(tb.datatable.name)
        if (!tb.datatable.table_columns) tb.datatable.table_columns = tb.datatable?.data_table_template?.table_columns
        if (!tb.datatable.table_data) tb.datatable.table_data = tb.datatable?.data_table_template?.table_data_default
        setCurrentDT(tb.datatable)
        if (tb.datatable?.table_data?.column_search) {
          setSearchColumns(tb.datatable.table_data.column_search.map(item => item.key))
        }
      }
    }
  })

  async function exportExcel() {

    let collection = prompt('Danh mục shopline:');
    if (collection) {
      let input = {
        data: {
          ids: toJS(datatableStore.selectedRowKeys),
          collection: collection
        }
      }
      let r = await exportProductExcel({
        variables: { input }
      })
      let result = r.data?.exportProductExcel?.data
      if (result?.status === 'OK') {
        saveAsXlsxFile(result?.fileOut, result?.buffer)
      }
    }
  }

  // async function export_rows(data) {
  //   let input = {
  //     data: {
  //       ids: toJS(datatableStore.selectedRowKeys),
  //       collection: collection
  //     }
  //   }
  //   let r = await exportProductExcel({
  //     variables: { input }
  //   })
  //   console.log('r', r)
  //   let result = r.data?.exportProductExcel?.data
  //   if (result?.status === 'OK') {
  //     saveAsXlsxFile(result?.fileOut, result?.buffer)
  //   }
  // }

  async function exportSlineLiveProduct() {
    if (datatableStore.selectedRowKeys.length > 1) {
      alert('Ko dc export qua 1 live')
      return false
    }
    let id = datatableStore.selectedRowKeys[0]
    let sc = datatableStore.currentDatarows.find(r => r.key === id)

    if (id && sc) {
      // let sc = datatableStore.currentDatarows.find(r=>r.key===id)
      let input = {
        data: {
          sl_cate: sc.name,
          table: commonStore?.company?.companyData?.product_table
        }
      }
      let r = await exportSLineLive({
        variables: { input }
      })
      let result = r.data?.exportSLineLive?.data
      if (result?.status === 'OK') {
        saveAsXlsxFile(result?.fileOut, result?.buffer)
      }
    }

  }

  async function exportSlineLiveProductNoPrice() {
    if (datatableStore.selectedRowKeys.length > 1) {
      alert('Ko dc export qua 1 live')
      return false
    }
    let id = datatableStore.selectedRowKeys[0]
    let sc = datatableStore.currentDatarows.find(r => r.key === id)

    if (id && sc) {
      // let sc = datatableStore.currentDatarows.find(r=>r.key===id)
      let input = {
        data: {
          sl_cate: sc.name,
          table: commonStore?.company?.companyData?.product_table,
          no_price: true
        }
      }
      let r = await exportSLineLive({
        variables: { input }
      })
      let result = r.data?.exportSLineLive?.data
      if (result?.status === 'OK') {
        saveAsXlsxFile(result?.fileOut, result?.buffer)
      }
    }

  }


  async function exportExcelNT() {
    let input = {
      data: {
        ids: toJS(datatableStore.selectedRowKeys)
      }
    }
    let r = await exportOrderExcel({
      variables: { input }
    })
    let result = r.data?.exportOrderNTExcel?.data
    if (result?.status === 'OK') {
      saveAsXlsxFile(result?.fileOut, result?.buffer)
    }
  }

  async function exportExcelDetail() {
    let input = {
      data: {
        ids: toJS(datatableStore.selectedRowKeys)
      }
    }
    let r = await exportOrderExcelDetail({
      variables: { input }
    })
    let result = r.data?.exportOrderExcelDetail?.data
    if (result?.status === 'OK') {
      saveAsXlsxFile(result?.fileOut, result?.buffer)
    }
  }

  const handleMassAction = async e => {
    switch (e.key) {
      case 'edit-tag-chung-loai':
        setCurrentTagFilter({
          filter: { match: { "sub_cate": 'Tag chủng loại' } },
          preload: 'tags',
          ids: datatableStore.selectedRowKeys,
          updateKey: 'tag_chungloai'
        })
        break
      case 'edit-tag-dac-tinh':
        setCurrentTagFilter({
          filter: { match: { "sub_cate": 'Tag đặc tính' } },
          preload: 'tags',
          ids: datatableStore.selectedRowKeys,
          updateKey: 'tag_attr'
        })
        break
      case 'edit-tag-vi-tri':
        setCurrentTagFilter({
          filter: { match: { "sub_cate": 'Tag vị trí' } },
          preload: 'tags',
          ids: datatableStore.selectedRowKeys,
          updateKey: 'tag_vitri'
        })
        break
      case 'edit-tag-nhom':
        setCurrentTagFilter({
          filter: { match: { "sub_cate": 'Tag nhóm' } },
          preload: 'tags',
          ids: datatableStore.selectedRowKeys,
          updateKey: 'tag_group'
        })
        break
      case 'edit-status':
        // console.log(`datatableStore.selectedRowKeys`, datatableStore.selectedRowKeys)
        setCurrentMassFilter({
          filter: { match: { "category": 'Trạng thái đơn' } },
          preload: 'status',
          ids: datatableStore.selectedRowKeys,
          updateKey: 'status'
        })
        break
      case 'print-ship':
        let orderCodes = (datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key) && item.order_code)).map(o => o.order_code)
        doPrint(orderCodes)
        break
      case 'send-ship':
        if (['nv-online', 'nhan-vien', 'dong-hang'].includes(usersStore.currentUser.company_role)) {
          alert('Chức năng này của bạn chưa được kích hoạt')
          break;
        }
        await sendManyShip()
        break
      case 'export-excel':
        await exportExcel()
        break
      case 'export-excel-order':
        await exportExcelNT()
        break


      case 'export-kh':
        let inputKH = {
          data: {
            ids: toJS(datatableStore.selectedRowKeys),
            type: "export_sline_kh"
          }
        }
        let r = await exportRowsExcel({
          variables: { input: inputKH }
        })
        let rr = r.data?.exportRowsExcel?.data
        if (rr?.status === 'OK') {
          saveAsXlsxFile(rr?.fileOut, rr?.buffer)
        }
        break

      case 'export-excel-sline-live':
        await exportSlineLiveProduct()
        break

      case 'export-excel-sline-live-no-price':
        await exportSlineLiveProductNoPrice()
        break

      case 'export-excel-order-detail':
        await exportExcelDetail()
        break

      case 'delete':

        if (['nv-online', 'nhan-vien', 'dong-hang'].includes(usersStore.currentUser.company_role)) {
          alert('Chức năng này của bạn chưa được kích hoạt')
        } else
          confirm({
            title: 'Bạn có chắc chắn muốn xóa các sản phẩm này không?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xóa',
            okType: 'danger',
            cancelText: 'Bỏ qua',
            async onOk() {
              const input = {
                data: {
                  tableId: currentDT.id,
                  ids: datatableStore.selectedRowKeys
                }
              }
              setMassLoading(true)
              let delReturn = await deleteManyRows({
                variables: { input }
              })
              setMassLoading(false)
              if (delReturn.data?.deleteManyDataRows?.data?.ok === 1) {
                datatableStore.resetFilter()
              }
              datatableStore.setSelectedRowKeys([])
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        break

      case 'refresh-vitri':
        let vitri = await refreshDataOrder({
          variables: {
            input:
            {
              data: {
                tableId: currentDT.id,
                ids: datatableStore.selectedRowKeys,
                typeData: {
                  type: 'vitri'
                }
              }
            }
          }
        })
        // refreshDataOrder
        break

      case 'refresh-sl':
        let sl = await refreshDataOrder({
          variables: {
            input:
            {
              data: {
                tableId: currentDT.id,
                ids: datatableStore.selectedRowKeys,
                typeData: {
                  type: 'so_luong'
                }
              }
            }
          }
        })
        // refreshDataOrder
        break

      case 'refresh-sl_detail':
        let sl_detail = await refreshDataOrder({
          variables: {
            input:
            {
              data: {
                tableId: currentDT.id,
                ids: datatableStore.selectedRowKeys,
                typeData: {
                  type: 'so_luong_detail'
                }
              }
            }
          }
        })
        // refreshDataOrder
        break

      case 'update-sl-live':
        if (['nv-online', 'nhan-vien', 'dong-hang'].includes(usersStore.currentUser.company_role)) {
          alert('Chức năng này của bạn chưa được kích hoạt')
          break;
        }
        if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
          let rows = []
          let reducePerent = prompt('Phần trăm dự phòng:', 0)
          let percent = 1 - reducePerent / 100
          datatableStore.selectedRowKeys.forEach(id => {
            let r = datatableStore.currentDatarows.find(item => item.key === id)
            if (r) {
              let gh = r.sl_giuhang > 0 ? r.sl_giuhang : 0
              let sl_live = r.sl_tonkho - gh
              let days = 999
              if (r.ngay_kiem) {
                let ngay_kiem = moment(r.ngay_kiem)
                var today = moment().startOf('day');
                days = Math.round(moment.duration(today - ngay_kiem).asDays());
              }
              // if (r.sl_id && r.sl_qty < sl_live && r.sl_cate && r.sl_cate.length > 0) {
              //   sl_live = r.sl_qty
              // }
              if (sl_live < 0) sl_live = 0
              sl_live = Math.round(sl_live * percent)
              rows.push(
                {
                  id: id,
                  n: { 'so_luong': sl_live },
                  o: false
                }
              )
            }
          });

          let r = await updateManyDataRows({
            variables: {
              input: {
                data: {
                  type: "oldnew",
                  rows: rows
                }
              }
            }
          })
          if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
            datatableStore.massUpdateRows("so_luong", false, datatableStore.selectedRowKeys, rows)
          }
          // setUpdating(false)

        }

        break

      case 'update-gia-live':
        if (['nv-online', 'nhan-vien', 'dong-hang'].includes(usersStore.currentUser.company_role)) {
          alert('Chức năng này của bạn chưa được kích hoạt')
          break;
        }
        let reducePerent = prompt('Phần trăm giảm giá live:', 0)
        let percent = 1 - reducePerent / 100
        if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
          let rows = []
          datatableStore.selectedRowKeys.forEach(id => {
            let r = datatableStore.currentDatarows.find(item => item.key === id)
            if (r) {
              let pLive = Math.round(r.price * percent / 1000) * 1000
              rows.push(
                {
                  id: id,
                  n: { 'price_live_base': pLive },
                  o: false
                }
              )
            }
          });
          let r = await updateManyDataRows({
            variables: {
              input: {
                data: {
                  type: "oldnew",
                  rows: rows
                }
              }
            }
          })
          if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
            datatableStore.massUpdateRows("price_live_base", false, datatableStore.selectedRowKeys, rows)
          }
          // setUpdating(false)

        }

        break

      case 'add-2-shopline-both':
        setSyncType('both')
        break

      case 'add-2-shopline-price':
        massAssign2SLProduct('price')
        break

      case 'add-2-shopline-qty':
        massAssign2SLProduct('qty')
        break

      case 'sync-2-shopline-image':
        massAssign2SLProduct('image')
        break

      case 'hidden-2-shopline':
        massAssign2SLProduct('field', false, { status: "hidden" })
        break

      case 'active-2-shopline':
        massAssign2SLProduct('field', false, { status: "active" })
        break


      case 'get-from-shopline':
        if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
          let valid_rows = datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key) && item.sl_id)
          let sl_ids = valid_rows.map(v => v.sl_id)
          const { data, error } = await getSLProducts({
            input: {
              data: {
                params: {
                  id: 'in:' + sl_ids.join(','),
                  per_page: 300,
                  // 'fields[]': 'items.price_sale',
                  // 'fields[]': 'items.id',
                  // 'fields[]': 'items.sku',
                  // 'fields[]': 'items.title_translations',
                  // 'fields[]': 'items.price',
                  // 'fields[]': 'items.quantity',
                  // 'fields[]': 'items.updated_at',
                  // 'fields[]': 'items.created_at',
                  // 'fields[]': 'items.status',
                  // 'fields[]': 'items.medias'
                }
              }
            }
          })
          let results = data?.getShoplineProducts?.data
          if (results?.items) {
            let uRows = []
            results.items.forEach(item => {
              let matchRow = valid_rows.find(v => v.sl_id === item.sl_id)
              uRows.push({
                id: matchRow.key,
                n: {
                  sl_qty: item.qty,
                  price_live: item.price_live
                },
                o: false
              })
            });
            let r = await updateManyDataRows({
              variables: {
                input: {
                  data: {
                    type: "oldnew",
                    rows: uRows
                  }
                }
              }
            })
            if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
              datatableStore.massUpdateRows("price_live", false, datatableStore.selectedRowKeys, uRows)
              datatableStore.massUpdateRows("sl_qty", false, datatableStore.selectedRowKeys, uRows)
            }
          }
        }
        break


      case 'quick-sync-slid':
        if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
          let valid_rows = datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key) && !item.sl_id)
          let codes = valid_rows.map(v => v.code)
          const { data, error } = await getSLProducts({
            input: {
              data: {
                params: {
                  sku: 'in:' + codes.join(','),
                  per_page: 500,
                  // 'fields[]': 'items.price_sale',
                  // 'fields[]': 'items.id',
                  // 'fields[]': 'items.sku',
                  // 'fields[]': 'items.title_translations',
                  // 'fields[]': 'items.price',
                  // 'fields[]': 'items.quantity',
                  // 'fields[]': 'items.updated_at',
                  // 'fields[]': 'items.created_at',
                  // 'fields[]': 'items.status',
                  // 'fields[]': 'items.medias'
                }
              }
            }
          })
          let results = data?.getShoplineProducts?.data
          if (results?.items) {
            let uRows = []
            let sl_ids_delete = []
            valid_rows.forEach(vd => {
              let matchRows = results.items.filter(v => v.code === vd.code)
              if (matchRows && matchRows.length > 0) {
                uRows.push({
                  id: vd.key,
                  n: {
                    sl_qty: matchRows[0].qty,
                    price_live: matchRows[0].price_live,
                    sl_id: matchRows[0].sl_id,
                    sl_cate: matchRows[0].categories
                  },
                  o: false
                })

                if (matchRows.length > 1) {
                  for (let index = 1; index < matchRows.length; index++) {
                    sl_ids_delete.push(matchRows[index].sl_id)
                  }
                }
              }
            });

            if (sl_ids_delete.length > 0) {
              let d = await removeSLProducts({
                variables: {
                  input: {
                    data: {
                      ids: sl_ids_delete
                    }
                  }
                }
              })
            }
            let r = await updateManyDataRows({
              variables: {
                input: {
                  data: {
                    type: "oldnew",
                    rows: uRows
                  }
                }
              }
            })
            if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
              datatableStore.massUpdateRows("price_live", false, datatableStore.selectedRowKeys, uRows)
              datatableStore.massUpdateRows("sl_qty", false, datatableStore.selectedRowKeys, uRows)
              datatableStore.massUpdateRows("sl_id", false, datatableStore.selectedRowKeys, uRows)
            }
          }
        }
        break


      case 'calc-qty-product':
        // tạm khóa
        // if (['nv-online', 'nhan-vien', 'dong-hang'].includes(usersStore.currentUser.company_role)) {
        //   alert('Chức năng này của bạn chưa được kích hoạt')
        //   break;
        // }
        // message.loading('Đang xử lý..', 0);
        // let rp = await recalcProductQty({
        //   variables: {
        //     input:
        //     {
        //       data: {
        //         ids: datatableStore.selectedRowKeys,
        //       }
        //     }
        //   }
        // })
        // message.destroy()
        // if (rp?.data?.recalcProductQty?.data?.status === 'OK') {
        //   // console.log(`rp.data.recalcProductQty.data`, rp.data.recalcProductQty.data)
        //   let update = rp.data.recalcProductQty.data.update
        //   datatableStore.massUpdateSL_giuhang(update)
        // }
        // message.success('Tính số lượng thành công', 2);
        break

      case 'cap-nhat-kiem-hang':
        setShowKiemHangModal(true)
        break

      case 'cap-nhat-so-luong':
        if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
          let rows = []
          let ton_kho = prompt('Đặt số lượng tồn kho:', 0)
          message.loading('Đang xử lý..', 0);
          if (!isNaN(ton_kho) && ton_kho !== null) {
            ton_kho = parseInt(ton_kho)
            let mut = await massUpdateTag({
              variables: {
                input:
                {
                  data: {
                    ids: datatableStore.selectedRowKeys,
                    action: 'update_value',
                    // field: 'sl_tonkho',
                    update_keyvalue: { 'row_data.sl_tonkho': ton_kho || 0 }
                  }
                }
              }
            })
            if (mut?.data?.massUpdateTag?.data?.status === 'OK') {
              message.success('Cập nhật SL thành công', 2);
              datatableStore.massUpdateRows("sl_tonkho", ton_kho, datatableStore.selectedRowKeys)
            }
          }
          message.destroy()

          // if (mut?.data?.updateManyDataRows?.data?.status === 'OK') {
          //   datatableStore.massUpdateRows("so_luong", false, datatableStore.selectedRowKeys, rows)
          // }
          // setUpdating(false)

        }

        // setShowKiemHangModal(true)
        break

      case 'reset-giu-hang':
        if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
          let rows = []
          message.loading('Đang xử lý..', 0);
          let mut = await massUpdateTag({
            variables: {
              input:
              {
                data: {
                  ids: datatableStore.selectedRowKeys,
                  action: 'update_value',
                  update_keyvalue: { 'row_data.sl_giuhang': 0 }
                }
              }
            }
          })
          if (mut?.data?.massUpdateTag?.data?.status === 'OK') {
            message.success('Cập nhật SL thành công', 2);
            datatableStore.massUpdateRows("sl_giuhang", 0, datatableStore.selectedRowKeys)
          }
          message.destroy()

          // if (mut?.data?.updateManyDataRows?.data?.status === 'OK') {
          //   datatableStore.massUpdateRows("so_luong", false, datatableStore.selectedRowKeys, rows)
          // }
          // setUpdating(false)

        }

        // setShowKiemHangModal(true)
        break


      case 'cap-nhat-sl-theo-ke':
        datatableStore.setTagVitriKiemSL(false)
        setShowUpdateSLBlock(true)
        // setShowBooleanModal(true)
        // setShowKiemHangModal(true)
        break

      case 'cap-nhat-can-nang':
        // setShowUpdateSLBlock(true)
        setShowWeightModal(true)
        break

      case 'cap-nhat-mau-live':
        setShowBooleanModal(true)
        // setShowKiemHangModal(true)
        break
      // case 'add2cart':
      //   if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
      //     let rows = []
      //     datatableStore.selectedRowKeys.forEach(id => {
      //       let r = datatableStore.currentDatarows.find(item => item.key === id)
      //       if (r) {
      //         rows.push(
      //           {
      //             id: id,
      //             code: r.code,
      //             name: r?.customer?.name,
      //             mobile: r?.customer?.mobile
      //           }
      //         )
      //       }
      //     });
      //     datatableStore.add2cart(rows)
      //     datatableStore.setSelectedRowKeys([])
      //     datatableStore.setShowOrderCart(true)
      //   }
      //   break

      case 'remove-prelive-rows':
        message.loading('Đang xử lý..', 0);
        let rtr = await removeTagRows({
          variables: {
            input:
            {
              data: {
                ids: datatableStore.selectedRowKeys,
                action: 'livestream'
              }
            }
          }
        })
        message.destroy()
        if (rtr?.data?.removeTagRows?.data?.status === 'OK') {
          datatableStore.massUpdateRows("livestream", "", datatableStore.selectedRowKeys)
          // console.log(`rp.data.recalcProductQty.data`, rp.data.recalcProductQty.data)
          // let update = rp.data.recalcProductQty.data.update
          message.success('Xóa list livestream thành công', 2);
        }
        break

      default:
        break
    }
  }
  const MassActionMenu2 = (
    <Menu onClick={handleMassAction}>
      {commonStore?.company?.companyData?.product_table === id &&
        <>
          {/* <Menu.Item key="add-2-shopline-cate" icon={<FolderAddOutlined />} style={{ color: 'orange' }}>
            Đồng bộ gi sang Shopline
          </Menu.Item> */}
          {usersStore.currentUser.company_role !== 'nv-online' &&
            <>
              <Menu.Item key="add-2-shopline-price" icon={<FolderAddOutlined />} style={{ color: 'orange' }}>
                Đồng bộ GIÁ sang Shopline
              </Menu.Item>
              <Menu.Item key="add-2-shopline-qty" icon={<FolderAddOutlined />} style={{ color: 'orange' }}>
                Đồng bộ SỐ LƯỢNG sang Shopline
              </Menu.Item>
              <Menu.Item key="sync-2-shopline-image" icon={<FolderAddOutlined />} style={{ color: 'orange' }}>
                Đồng bộ ẢNH sang Shopline
              </Menu.Item>
              <Menu.Item key="add-2-shopline-both" icon={<FolderAddOutlined />} style={{ color: 'red' }}>
                Đồng bộ Giá,SL,Danh mục sang Shopline
              </Menu.Item>
              <Menu.Item key="hidden-2-shopline" icon={<EyeInvisibleOutlined />} style={{ color: '#888' }}>
                Ẩn toàn bộ sp bên Shopline
              </Menu.Item>
              <Menu.Item key="active-2-shopline" icon={<EyeOutlined />} style={{ color: '#888' }}>
                Active sp bên Shopline
              </Menu.Item>
              <Menu.Item key="get-from-shopline" icon={<FolderAddOutlined />} style={{ color: 'green' }}>
                Lấy SL, giá live từ Shopline
              </Menu.Item>
              <Menu.Item key="quick-sync-slid" icon={<FolderAddOutlined />} style={{ color: 'blue' }}>
                Tự động tìm id Shopline
              </Menu.Item>
              <Menu.Item key="update-sl-live" icon={<SyncOutlined />} style={{ color: 'black' }}>
                SỐ LƯỢNG live theo có thể bán
              </Menu.Item>
              <Menu.Item key="update-gia-live" icon={<SyncOutlined />} style={{ color: 'black' }}>
                % giảm giá live
              </Menu.Item>
              <Menu.Item key="remove-prelive-rows" icon={<DeleteOutlined />} style={{ color: 'orange' }}>
                Xóa livestream (list chuẩn bị)
              </Menu.Item>
            </>}
        </>}
    </Menu>
  )

  const MassActionMenu = (
    <Menu onClick={handleMassAction}>
      {commonStore?.company?.companyData?.order_table === id ?
        <>
          {
            usersStore.currentUser.company_role !== 'nv-online' &&
            <>
              <Menu.Item key="edit-status" icon={<ShoppingOutlined />}>
                Sửa trạng thái đơn hàng
              </Menu.Item>
              <Menu.Item key="edit-tag" icon={<TagOutlined />}>
                Sửa thẻ
              </Menu.Item>

              {datatableStore.currentDatarows ? <PrintListOrderDetail
                orderDatas={{
                  items: toJS(datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key)))
                }}
                columns={['name', 'qty', 'price', 'priceItems']}
              /> : ""}
              <Menu.Item key="print-ship" icon={<PrinterOutlined />}>
                In đơn vận chuyển
              </Menu.Item>
              <Menu.Item key="send-ship" icon={<CarOutlined />} style={{ color: 'orange' }}>
                Gửi GHN
              </Menu.Item>
              <Menu.Item key="export-excel-order" icon={<CarOutlined />} style={{ color: 'green' }}>
                Xuất excel nội thành
              </Menu.Item>
              <Menu.Item key="export-excel-order-detail" icon={<CarOutlined />} style={{ color: 'blue' }}>
                Xuất excel chi tiết
              </Menu.Item>
            </>
          }
          {/* <Menu.Item key="add2cart" icon={<CarOutlined />} style={{ color: 'red' }}>
            Thêm giỏ hàng
          </Menu.Item> */}
          {/* <Menu.Item key="print-detail" icon={<PrinterOutlined />}>
            In chi tiết
          </Menu.Item> */}
          {usersStore.currentUser.company_role === 'chu-cong-ty' &&
            <>
              <Menu.Item key="refresh-vitri" icon={<PrinterOutlined />}>
                Làm mới vị trí
              </Menu.Item>

              <Menu.Item key="refresh-sl" icon={<PrinterOutlined />}>
                Cập nhật lại số lượng
              </Menu.Item>

              <Menu.Item key="refresh-sl_detail" icon={<PrinterOutlined />}>
                Cập nhật thông tin sl
              </Menu.Item>
            </>
          }
        </>
        : ""}

      {commonStore?.company?.companyData?.customer_table === id &&
        <>
          <Menu.Item key="export-kh" icon={<FireOutlined />} style={{ color: 'orange' }}>
            Xuất kh
          </Menu.Item>
        </>

      }
      {commonStore?.company?.companyData?.product_table === id &&
        <>
          {
            usersStore.currentUser.company_role !== 'nv-online' &&
            <>

              <Menu.Item key="export-excel" icon={<FireOutlined />} style={{ color: 'blue' }}>
                Xuất them sp shopline
              </Menu.Item>

              <Menu.Item key="edit-tag-nhom" icon={<TagOutlined />}>
                Sửa thẻ nhóm
              </Menu.Item>
              <Menu.Item key="edit-tag-chung-loai" icon={<TagOutlined />}>
                Sửa thẻ chủng loại
              </Menu.Item>
              <Menu.Item key="edit-tag-dac-tinh" icon={<TagOutlined />}>
                Sửa thẻ đặc tính
              </Menu.Item>
              <Menu.Item key="edit-tag-vi-tri" icon={<TagOutlined />} style={{ color: 'blue' }}>
                Sửa thẻ vị trí
              </Menu.Item>
              <Menu.Item key="cap-nhat-mau-live" icon={<FireOutlined />} style={{ color: '#60CC7B' }}>
                cập nhật có mẫu live
              </Menu.Item>
              <Menu.Item key="cap-nhat-kiem-hang" icon={<DropboxOutlined />} style={{ color: 'black' }}>
                cập nhật kiểm hàng
              </Menu.Item>
              <Menu.Item key="cap-nhat-so-luong" icon={<CodepenCircleOutlined />} style={{ color: '#D56490' }}>
                cập nhật số lượng hàng loạt
              </Menu.Item>
              {/* <Menu.Item key="calc-qty-product" icon={<CodepenCircleOutlined />} style={{ color: 'black' }}>
                Tính lại số lượng giữ hàng
              </Menu.Item> */}

              <Menu.Item key="cap-nhat-sl-theo-ke" icon={<CodepenCircleOutlined />} style={{ color: 'orange' }}>
                Cập nhật sl theo kệ
              </Menu.Item>
              <Menu.Item key="cap-nhat-can-nang" icon={<CodepenCircleOutlined />} style={{ color: '#BB33FF' }}>
                Cập nhật cân nặng sp
              </Menu.Item>
              <Menu.Item key="reset-giu-hang" icon={<CodepenCircleOutlined />} style={{ color: 'black' }}>
                Xóa giữ hàng
              </Menu.Item>

            </>
          }
        </>}
      {commonStore?.company?.companyData?.scate_table === id &&
        <>
          <Menu.Item key="export-excel-sline-live" icon={<CarOutlined />} style={{ color: 'green' }}>
            Xuất excel livestream
          </Menu.Item>
          <Menu.Item key="export-excel-sline-live-no-price" icon={<CarOutlined />} style={{ color: 'green' }}>
            Xuất excel livestream (chưa có giá base)
          </Menu.Item>
        </>
      }



      {
        usersStore.currentUser.company_role !== 'nv-online' &&
        <>
          <Menu.Item key="delete" icon={<DeleteOutlined />} style={{ color: 'red' }}>
            Xóa dữ liệu
          </Menu.Item>
        </>
      }

    </Menu>
  )

  const handleAdminActionClick = async e => {
    let input
    switch (e.key) {
      case 'sync-datasource':
        onSyncClick()
        break
      case 'recheck-order':
        // const input = {
        //   data: {
        //     tableId: commonStore?.company?.companyData?.order_table
        //   }
        // }
        break
      case 'delete-all':
        input = {
          data: {
            tableId: currentDT.id,
            removeAll: true
          }
        }
        let delReturn = await deleteManyRows({
          variables: { input }
        })
        if (delReturn.data?.deleteManyDataRows?.data?.ok === 1) {
          datatableStore.resetFilter()
        }
        break
      case 'delete-all-slive':
        input = {
          data: {
            tableId: currentDT.id,
            where: { src: 'slive' }
          }
        }
        let delSlive = await deleteManyRows({
          variables: { input }
        })
        if (delSlive.data?.deleteManyDataRows?.data?.ok === 1) {
          datatableStore.resetFilter()
        }
        break
      default:
        break
    }
  }

  const inputFile = useRef(null)
  const inputExcel = useRef(null)

  const [formImport] = Form.useForm();


  async function beforeUpload(file) {
    // console.log('file', file)
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   message.error('Bạn chỉ có thể tải lên JPG/PNG file!');
    // }
    const sizeOK = file.size / 1024 / 1024 < 20;
    if (!sizeOK) {
      message.error('Kích thước không được quá 20 MB!');
      return false
    }
    let f = await file
    let r = await importRows({
      variables: {
        input: {
          data: {
            table: id
          },
          file: f
        }
      }
    })

    // let ok = isJpgOrPng && sizeOK;
    // if (ok) {
    //   getBase64(file, imageUrl => {
    //     setImageUrl(imageUrl)
    //     setUploading(false)
    //   });
    // }
    formImport.submit()
    return true
  }

  const onFinishImport = async (values) => {
    for (const [key, value] of Object.entries(values)) {
      if (value?.file) {
        var f = await value.file.originFileObj
        // console.log(`f`, f)
        // let r = await importRows({
        //   variables: {
        //     input: {
        //       data: {
        //         abc: 'xyz'
        //       },
        //       file: f
        //     }
        //   }
        // })
        // console.log(`r`, r)

        // const { data } = await upload({
        //   variables: { file: f },
        // })
        // if (data.upload)
        //   row_data[key] = data.upload.url
      }
      else {
      }
    }

    // console.log(`values`, values)
  }


  const AdminActionMenu = () => {

    return (
      <Menu onClick={handleAdminActionClick}>
        <Menu.Item key="sync-datasource" icon={<SyncOutlined />}>
          Đồng bộ excel
        </Menu.Item>
        <Menu.Item key="import-datasource" icon={<SyncOutlined />}
          onClick={() => {
            inputFile.current.click();
          }}
        >
          Import dữ liệu
        </Menu.Item>

        <Menu.Item key="import-excel" icon={<FileExcelOutlined />}
          onClick={() => {
            // alert('ac')
            inputExcel.current.click();
          }}
        >
          Import excel
        </Menu.Item>


        <Menu.Item key="delete-all" icon={<DeleteOutlined />}>
          Xóa toàn bộ dữ liệu
        </Menu.Item>
        {/* <Form
          onFinish={onFinishImport}
          form={formImport}
          style={{ display: 'none' }}>
          <Form.Item
            name={'ifile'}
            label={'abc'}
          >
            <Upload
              listType="picture-card"
              showUploadList={false}
              beforeUpload={beforeUpload}
              multiple={true}
              // accept="image/*"
              // capture="environment"
              // capture="user"
              // onChange={handleChange}
              maxCount={3}
            >
              <div ref={inputFile}>Import dữ liệu</div>
            </Upload>
          </Form.Item>
        </Form> */}
        {/* <form method="post" enctype="multipart/form-data">
          <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
        </form> */}

      </Menu>
    )
  }




  const NewActionMenu = (
    <Menu onClick={() => { }}>
      <Menu.Item key="1" icon={<PlusOutlined />}
        onClick={quickAdd}
      >
        Thêm nhanh 1{currentDT?.data?.dataLabel}
      </Menu.Item>
      <Menu.Item key="2" icon={<PlusOutlined />}
        onClick={() => {
          datatableStore.quickAddRow(currentDT, 5)
        }}
      >
        Thêm nhanh 5 {currentDT?.data?.dataLabel}
      </Menu.Item>
      {/* <Menu.Item key="3" icon={<PlusOutlined />}>
        Thêm nhanh 10 {currentDT?.data?.dataLabel}
      </Menu.Item> */}
      <Menu.Item key="4" icon={<PictureOutlined />}>
        Thêm {currentDT?.data?.dataLabel} bằng nhiều ảnh
      </Menu.Item>
    </Menu>
  )

  const AdminTopBar = () => (
    <Space>
      <Dropdown overlay={AdminActionMenu}>
        <Button size="small">
          Hệ thống <DownOutlined />
        </Button>
      </Dropdown>
      {/* <Button shape="round" icon={<SyncOutlined />} size='small' onClick={onSyncClick} >
        Sync datasource
      </Button> */}
    </Space>
  )

  const RightActionTopMenu = () => {
    return (
      <Menu>
        {/* <Menu onClick={handleAdminActionClick}> */}
        <Menu.Item key="create-report" icon={<BarChartOutlined />}
          onClick={async () => {
            updateFilter.table = commonStore?.company?.companyData?.order_table
            let input = {
              data: {
                filter_data: updateFilter,
                group_data: {
                  fields: ["customer.mobile"],
                  extraField: [
                    {
                      key: "tong_mua",
                      fomular: {
                        type: "sum",
                        field: "tong_tien"
                      }
                    }
                  ]
                },

              }
            }
            let r = await createReport({
              variables: { input }
            })
            let result = r.data?.createReport?.data
            if (result?.status === 'OK') {
              // console.log(`resultzzzzzz`, result)
            }
            // inputFile.current.click();
          }}
        >
          Tạo báo cáo bộ lọc
        </Menu.Item>
        {/* <Menu.Item key="delete-all" icon={<DeleteOutlined />}>
          Xóa toàn bộ dữ liệu
        </Menu.Item> */}
      </Menu>
    )
  }

  const RightTopMenu = () => (
    <Space>
      <Dropdown overlay={RightActionTopMenu}>
        <Button size="small">
          Công cụ <DownOutlined />
        </Button>
      </Dropdown>
      {/* <Button shape="round" icon={<SyncOutlined />} size='small' onClick={onSyncClick} >
        Sync datasource
      </Button> */}
    </Space>
  )

  // useEffect(() => {
  //   // console.log(`rows`, rows)
  // }, [rows.length])

  // const MyComponent = React.memo(SearchBoxItem);

  useEffect(() => {

    // console.log(`datatableStore.selectedRowKeys`, datatableStore.selectedRowKeys)
  }, [datatableStore.selectedRowKeys])

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const FilterPanel = () => {
    return (
      <Space
        style={{ width: '100%', marginBottom: 10, background: "#fafafa", padding: 10, border: '1px solid #ccc' }}
      >
        {currentDT?.table_data?.advance_filter &&
          <>
            {currentDT?.table_data?.advance_filter.map(af => {
              if (af.type === 'button')
                return (
                  <Button
                    size="small"
                    onClick={() => {
                      let search = false
                      let inputNum = false
                      if (af?.data?.type === 'number_input') {
                        inputNum = prompt(af?.data?.title || 'Nhập số', af?.data?.default || 0);
                        inputNum = parseInt(inputNum);
                        af.filter.match[af.data.key] = inputNum
                      }
                      if (af?.filter?.match) {
                        search = updateFilterParam(updateFilter, af.filter.match)
                        history.push({
                          pathname: '/table/' + id,
                          search: search,
                        })
                      } else if (af?.fcode) {

                      }
                    }}
                  >{af.label}</Button>
                )

              if (af.type === 'select') {
                return (
                  <DatarowSelectInput
                    tableId={af.table}
                    width={120}
                    dropdownMatchSelectWidth={true}
                    emptyLabel={af.label}
                    labelKey={af.labelKey || 'name'}
                    matchField={af.matchField || 'key'}
                    defaultEmpty={af.defaultEmpty}
                    // defaultEmpty={{ id: '-', name: '--vận chuyển--', color: '#ffffff' }}
                    // initFilter={{ match: { "category": 'Vận chuyển' } }}
                    initFilter={af.filter}
                    // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                    initValue={updateFilter.match ? updateFilter.match[af.filterPath] ? updateFilter.match[af.filterPath] : '-' : '-'}
                    onOk={(item) => {
                      let search = false
                      let updateData = {}
                      updateData[af.filterPath] = false
                      // if (item?.key && item?.key != '-') 
                      if (item[af.filterKey] && item[af.filterKey] !== '-') {
                        updateData[af.filterPath] = item[af.filterKey]
                      }
                      search = updateFilterParam(updateFilter, updateData)
                      history.push({
                        pathname: '/table/' + id,
                        search: search,
                      }
                      )
                    }}
                  />
                )
              }

              if (af.type === 'searchbox') {
                if (!commonStore?.company?.companyData || !af.table_alias) return ""
                let tableId = commonStore?.company?.companyData[af.table_alias]
                return (
                  <SearchBoxItem
                    tableId={tableId}
                    initValue={updateFilter.match ? updateFilter.match[af.filterPath] ? updateFilter.match[af.filterPath] : '' : ''}
                    filterOnDropdown={true}
                    placeholder={af.label}
                    columns={af.columns}
                    filter={af.filter}
                    onSelect={(v, o) => {
                      let updateData = { start: 0 }
                      updateData[af.filterPath] = false
                      if (v !== '') updateData[af.filterPath] = v
                      else
                        if (updateData[af.filterPath]) delete updateData[af.filterPath]

                      let search = updateFilterParam(updateFilter, updateData)
                      sleep(800)
                        .then(() => {
                          history.push({
                            pathname: '/table/' + id,
                            search: search,
                          })
                        })
                    }}
                    onOk={(v) => {
                    }}
                    onEnter={(v) => {
                      console.log(`v`, v)
                    }}
                  />
                )
              }

              if (af.type === 'tags') {
                if (!commonStore?.company?.companyData || !af.table_alias) return ""
                let tableId = commonStore?.company?.companyData[af.table_alias]

                return (
                  <DatarowSelectInput
                    tableId={tableId}
                    placeholder={af.label}
                    width={'100%'}
                    viewType={'tag'}
                    initFilter={af.filter}
                    advance={af.filter_advance}
                    // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                    // initValue={updateFilter?.match?.tags ? updateFilter.match.tags : false}
                    initValue={updateFilter.match && updateFilter.match[af.filterPath] ? updateFilter.match[af.filterPath] : false}
                    onOk={(item) => {
                      let updateData = { start: 0 }
                      updateData[af.filterPath] = false
                      if (item && item.length > 0) {
                        updateData[af.filterPath] = item.join('|')
                      } else {
                        if (updateData[af.filterPath]) delete updateData[af.filterPath]
                      }
                      let search = updateFilterParam(updateFilter, updateData)
                      history.push({
                        pathname: '/table/' + id,
                        search: search,
                      }
                      )
                    }}
                  />
                )
              }

            })}
          </>}
      </Space>
    )
  }

  const BooleanFilter = ({ filter, onSelect, initValue }) => {

    return (
      <Select
        size="small"
        labelInValue
        defaultValue={{ value: initValue }}
        style={{ width: 'auto' }}
        onChange={onSelect}
      >
        {Object.entries(filter.options).map(
          item => {
            return (
              <Option value={item[1]}>{item[0]}</Option>
            )
          }
        )}
      </Select>
    )
  }

  const DefaultFilter = ({ filters }) => {
    if (filters.length < 1) return ""
    return (
      <>
        {filters.map(f => {
          if (f.type === 'boolean') {
            let initValue = updateFilter.match ? updateFilter.match[f.key] ? updateFilter.match[f.key] : -1 : -1
            if (initValue !== -1) initValue = initValue == 'false' ? 0 : 1
            return <BooleanFilter
              initValue={initValue}
              filter={f}
              onSelect={
                (v) => {
                  let u = { start: 0 }
                  if (v.value !== -1) {
                    u[f.key] = v.value === 1 ? 'true' : 'false'
                  } else {
                    if (updateFilter.match && updateFilter.match[f.key]) delete updateFilter.match[f.key]
                  }
                  let search = updateFilterParam(updateFilter, u)
                  // console.log(`search`, search)
                  sleep(800)
                    .then(() => {
                      history.push({
                        pathname: '/table/' + id,
                        search: search,
                      })
                    })
                }
              } />
          }
          else return ""
        })}
      </>
    )
  }

  const LeftBar = () => {
    function onDateChange(value, dateString) {
      let gte = moment(value[0]).toISOString()
      let lte = moment(value[1]).toISOString()
      let search = updateFilterParam(updateFilter, { 'ngay_tao_gte': gte, 'ngay_tao_lte': lte })
      history.push({
        pathname: '/table/' + id,
        search: search,
      }
      )
    }

    // let dataTextName = currentDT?.data?.dataLabel
    // if (!dataTextName) dataTextName = 'dữ liệu'
    // useEffect(() => {
    //   console.log('render')
    // }, [])

    // return (<div>abc</div>)
    // const [removeLoading, setRemoveLoading] = useState(false)
    // let dataTextName = 'dữ liệu'
    return (
      <div style={{ display: 'flex' }}>
        <Space>
          <SearchBoxItem
            tableId={id}
            filterOnDropdown={true}
            columns={searchColumns}
            onSelect={(v, o) => {
              let u = { start: 0 }
              if (v !== '') {
                u.txt = v
              }
              else
                if (updateFilter.txt) {
                  delete updateFilter.txt
                }
              let search = updateFilterParam(updateFilter, u)
              //xoa doan tren sau
              if (o?.key) search = '?_id=' + o.key

              history.push({
                pathname: '/table/' + id,
                search: search,
              })
            }}
            onOk={(v) => {
              // console.log(`v`, v)
              // setTextSearch(v)
              // setFilter({a:1})
            }}
            onEnter={(v) => {
              let u = { start: 0 }
              if (v !== '') {
                u.txt = v
                if (updateFilter?.match?._id) {
                  delete updateFilter.match._id
                }
              }
              else
                if (updateFilter.txt) delete updateFilter.txt

              let search = updateFilterParam(updateFilter, u)
              sleep(800)
                .then(() => {
                  history.push({
                    pathname: '/table/' + id,
                    search: search,
                  })
                })

              // history.push({
              //   pathname: '/table/' + id,
              //   search: search,
              // })
            }}
          />
          {usersStore.currentUser.company_role !== 'nv-online' && currentDT?.table_data?.add_button &&
            <Dropdown.Button
              size={'small'}
              onClick={() => {
                datatableStore.setShowAddModal(true)
              }}
              // onClick={() => {
              //   datatableStore.setShowAddModal(true)
              // }}
              overlay={NewActionMenu}
            >
              Thêm mới
            </Dropdown.Button>
          }

          {currentDT?.table_data?.filters ?
            <DefaultFilter filters={currentDT?.table_data?.filters} />
            : ""
          }

          {/* {commonStore?.company?.companyData?.live_product_table == id ?
            "live"
            : ""
          } */}
          <Space>
            {commonStore?.company?.companyData?.order_table === id && currentDT ?
              <>
                <DataFileUpload label="Nhập từ excel"
                  tableId={id}
                  inputFile={inputExcel}
                  onOk={(v) => {
                    if (v && v.status === 'OK') {
                      if (v.sourceArr) {
                        let s = ''
                        for (const [key, value] of Object.entries(v.sourceArr)) {
                          s += `\nMã: ${key} , SL giữ hàng thêm vào: ${value.qty}`
                        }
                        // let s = Object.keys(v.sourceArr).join(',')
                        alert(`Có ${Object.keys(v.sourceArr).length} sản phẩm trùng mã : ${s}`)
                        window.location.reload()
                      } else {
                        window.location.reload()
                      }
                    }
                  }}
                />
                <DatarowSelectInput
                  tableId="607d523459c61c2d08f434b2"
                  width={100}
                  emptyLabel='--trạng thái--'
                  defaultEmpty={{ id: '-', name: '--trạng thái--', color: '#ffffff' }}
                  initFilter={{ match: { "category": 'Trạng thái đơn' } }}
                  // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                  initValue={updateFilter.match ? updateFilter.match['status.ref'] ? updateFilter.match['status.ref'] : '-' : '-'}
                  onOk={(item) => {
                    let search = false
                    if (item?.key && item?.key !== '-') {
                      search = updateFilterParam(updateFilter, { 'status.ref': item.key })
                    } else {
                      search = updateFilterParam(updateFilter, { 'status.ref': false })
                    }
                    history.push({
                      pathname: '/table/' + id,
                      search: search,
                    }
                    )
                  }}
                />

                <DatarowSelectInput
                  tableId={commonStore?.company?.companyData?.tag_table}
                  width={'100%'}
                  viewType={'tag'}
                  initFilter={{ match: { "category": 'Đơn hàng', "sub_cate_ne": "Tag vị trí|user|live" } }}
                  // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
                  initValue={updateFilter?.match?.tags ? updateFilter.match.tags : false}
                  onOk={(item, tags) => {
                    let search = false
                    // let tags = updateFilter.match?.tags
                    // if (tags) tags = tags.split('|')

                    // if (item && item.length > 0) {
                    //   if (!tags) tags = []
                    //   item.forEach(t => {
                    //     if (tags.includes(t))
                    //       tags = tags.filter(tt => tt !== t)
                    //   });
                    //   tags = tags.concat(item || [])
                    //   tags = tags.join('|')
                    //   search = updateFilterParam(updateFilter, { 'tags': tags })
                    // } else {
                    //   if (tags) tags = tags.join('|')
                    //   else tags = false
                    //   search = updateFilterParam(updateFilter, { 'tags': tags })
                    //   // search = updateFilterParam(updateFilter, { 'tags': false })
                    // }

                    if (item && item.length > 0) {
                      search = updateFilterParam(updateFilter, { 'tags': item.join('|') })
                    } else {
                      search = updateFilterParam(updateFilter, { 'tags': false })
                    }

                    history.push({
                      pathname: '/table/' + id,
                      search: search,
                    }
                    )
                  }}
                />

                <RangePicker
                  size="small"
                  onChange={onDateChange}
                  format="DD-MM-YYYY"
                  ranges={{
                    'Hôm nay': [moment(), moment()],
                    'Tuần này': [moment().startOf('week'), moment().endOf('week')],
                    'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                  }}
                />



              </>
              : ""
            }

          </Space>

          {commonStore?.company?.companyData?.product_table === id && currentDT &&
            <>
              <SearchBoxItem
                tableId={commonStore?.company?.companyData?.livestream_table}
                initValue={updateFilter.match ? updateFilter.match['livestream'] ? updateFilter.match['livestream'] : '' : ''}
                filterOnDropdown={true}
                placeholder={'Livestream'}
                columns={['name']}
                filter={{ _sort: 'ngay_tao:-1' }}
                onSelect={(v, o) => {
                  let u = { start: 0 }
                  if (v !== '') u.livestream = v
                  else
                    if (updateFilter.livestream) delete updateFilter.livestream

                  let search = updateFilterParam(updateFilter, u)
                  sleep(800)
                    .then(() => {
                      history.push({
                        pathname: '/table/' + id,
                        search: search,
                      })
                    })
                }}
                onEnter={(v) => {
                  if (!v) {
                    if (updateFilter.match?.livestream) {
                      let u = { start: 0 }
                      delete updateFilter.match.livestream
                      let search = updateFilterParam(updateFilter, u)
                      sleep(800)
                        .then(() => {
                          history.push({
                            pathname: '/table/' + id,
                            search: search,
                          })
                        })
                    }
                  }
                  // alert('ec')
                }}
              />
              <SearchBoxItem
                tableId={commonStore?.company?.companyData?.pick_table}
                initValue={updateFilter.match ? updateFilter.match['luot_nhat'] ? updateFilter.match['luot_nhat'] : '' : ''}
                filterOnDropdown={true}
                placeholder={'Lượt nhặt'}
                columns={['name']}
                filter={{ _sort: 'ngay_tao:-1' }}
                onSelect={async (v, o) => {
                  let r = await getProductsByOrders({
                    variables: {
                      input: {
                        data: {
                          luot_nhat: v
                        }
                      }
                    }
                  })
                  if (r?.data?.getProductsByOrders?.data?.status === 'OK') {
                    let pdata = r?.data?.getProductsByOrders?.data?.pdata
                    let pid = []
                    for (const [key, value] of Object.entries(pdata)) {
                      pid.push(value.id)
                    }
                    if (pid.length > 0) {
                      let u = { start: 0, _id: pid.join('|') }
                      let search = updateFilterParam(updateFilter, u)
                      sleep(800)
                        .then(() => {
                          history.push({
                            pathname: '/table/' + id,
                            search: search,
                          })
                        })
                    }
                    // datatableStore.massUpdateRows("luot_nhat", v.ref.name, datatableStore.selectedRowKeys)
                  }

                  // let u = { start: 0 }
                  // if (v !== '') u.livestream = v
                  // else
                  //   if (updateFilter.livestream) delete updateFilter.livestream

                  // let search = updateFilterParam(updateFilter, u)
                  // sleep(800)
                  //   .then(() => {
                  //     history.push({
                  //       pathname: '/table/' + id,
                  //       search: search,
                  //     })
                  //   })
                }}
                onEnter={(v) => {
                  // if (!v) {
                  //   if (updateFilter.match?.livestream) {
                  //     let u = { start: 0 }
                  //     delete updateFilter.match.livestream
                  //     let search = updateFilterParam(updateFilter, u)
                  //     sleep(800)
                  //       .then(() => {
                  //         history.push({
                  //           pathname: '/table/' + id,
                  //           search: search,
                  //         })
                  //       })
                  //   }
                  // }
                  // alert('ec')
                }}
              />
              <SearchBoxItem
                tableId={commonStore?.company?.companyData?.scate_table}
                initValue={updateFilter.match ? updateFilter.match['sl_cate.name'] ? updateFilter.match['sl_cate.name'] : '' : ''}
                filterOnDropdown={true}
                placeholder={'danh mục SLine'}
                columns={['name']}
                filter={{ _sort: 'ngay_tao:-1' }}
                onSelect={(v, o) => {
                  let u = { start: 0 }
                  if (v !== '') u['sl_cate.name'] = v
                  else
                    if (updateFilter['sl_cate.name']) delete updateFilter['sl_cate.name']

                  let search = updateFilterParam(updateFilter, u)
                  sleep(800)
                    .then(() => {
                      history.push({
                        pathname: '/table/' + id,
                        search: search,
                      })
                    })
                }}
                onEnter={(v) => {
                  if (!v) {
                    if (updateFilter.match['sl_cate.name']) {
                      let u = { start: 0 }
                      delete updateFilter.match['sl_cate.name']
                      let search = updateFilterParam(updateFilter, u)
                      sleep(800)
                        .then(() => {
                          history.push({
                            pathname: '/table/' + id,
                            search: search,
                          })
                        })
                    }
                  }
                  // alert('ec')
                }}
              />
            </>
          }
          {commonStore?.company?.companyData?.order_table === id && currentDT &&
            <SearchBoxItem
              tableId={commonStore?.company?.companyData?.sale_event_table}
              initValue={updateFilter.match ? updateFilter.match['sale_event.sale_event_name'] ? updateFilter.match['sale_event.sale_event_name'] : '' : ''}
              filterOnDropdown={true}
              placeholder={'Buổi live'}
              columns={['name']}
              filter={{ _sort: 'ngay_tao:-1' }}
              onSelect={(v, o) => {
                let u = { start: 0 }
                if (v !== '') u['sale_event.sale_event_name'] = v
                else
                  if (updateFilter['sale_event.sale_event_name']) delete updateFilter['sale_event.sale_event_name']

                let search = updateFilterParam(updateFilter, u)
                sleep(800)
                  .then(() => {
                    history.push({
                      pathname: '/table/' + id,
                      search: search,
                    })
                  })
              }}
              onEnter={(v) => {
                if (!v) {
                  if (updateFilter.match['sale_event.sale_event_name']) {
                    let u = { start: 0 }
                    delete updateFilter.match['sale_event.sale_event_name']
                    let search = updateFilterParam(updateFilter, u)
                    sleep(800)
                      .then(() => {
                        history.push({
                          pathname: '/table/' + id,
                          search: search,
                        })
                      })
                  }
                }
                // alert('ec')
              }}
            />
          }
          {currentDT?.table_data?.advance_filter &&
            <Button
              size={'small'}
              icon={<FilterOutlined />}
              onClick={() => {
                setShowFilter(!showFilter)
              }}
            >Lọc bổ sung</Button>
          }

          {['chu-cong-ty', 'leader2', 'leader1', 'quan-ly', 'nv-kho'].includes(usersStore.currentUser.company_role) &&

            commonStore?.company?.companyData?.order_table === id &&
            <Button
              size={'small'}
              icon={<SplitCellsOutlined />}
              onClick={() => {
                setShowImportSLModal(true)
              }}
            >Import Shopline</Button>
          }

          {usersStore.currentUser.company_role === 'chu-cong-ty' && commonStore?.company?.companyData?.scate_table === id &&
            <Button
              size={'small'}
              icon={<SyncOutlined />}
              onClick={async () => {
                let u = await syncSLCate({
                  variables: {
                    input:
                    {
                      data: {}
                    }
                  }
                })
                let result = u?.data?.syncSLCate.data
                if (result.status === 'OK') {
                  window.location.reload()
                } else if (result.status === 'ERROR') {
                  alert(result?.msg)
                }
              }}
            >Đồng bộ SLine</Button>
          }

          {usersStore.currentUser.company_role === 'chu-cong-ty' && commonStore?.company?.companyData?.customer_table === id &&
            <Button
              size={'small'}
              icon={<SplitCellsOutlined />}
              onClick={() => {
                setShowImportKHSLModal(true)
              }}
            >Import Shopline</Button>
          }

          {/* <Button
            icon={<PlusOutlined />}
            size={'small'}
            onClick={() => {
              setShowAddModal(true)
            }}
          ></Button> */}

          {/* {showAddModal ? (
            <DataNewItem
              title={`Thêm ${dataTextName}`}
              table={toJS(currentDT)}
              // tableId={id}
              onSave={v => {
                // console.log('v', v)
                if (v.status === 'OK') {
                  var newItem = myFlat(v.data)
                  if (newItem) setRows([newItem, ...rows])
                }
                setShowAddModal(false)
              }}
              onClose={() => {
                setShowAddModal(false)
              }}
            />
          ) : (
            ''
          )} */}
          {/* <Search
            placeholder="input search text"
            allowClear
            size="small"
            // onSearch={onSearch}
            style={{ width: 150, margin: '0 15px 0 0' }}
          /> */}
          {/* <ActionBar /> */}
        </Space>
      </div>
    )
  }

  const ActionBar = () => {
    // const [removeLoading, setRemoveLoading] = useState(false)
    // const [massLoading, setMassLoading] = useState(false)
    return (
      <>
        {datatableStore.selectedRowKeys.length > 0 ?
          <Space>
            {commonStore?.company?.companyData?.product_table === id &&
              <>
                <Button style={{ background: "blue" }} type="primary" size="small" icon={<ShoppingOutlined />} onClick={() => {
                  if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                    let rows = []
                    datatableStore.selectedRowKeys.forEach(id => {
                      let r = datatableStore.currentDatarows.find(item => item.key === id)
                      if (r) {
                        rows.push(
                          {
                            id: id,
                            code: r.code,
                            image: r?.image,
                            name: r?.name,
                            sl_tonkho: r?.sl_tonkho,
                            sl_giuhang: r?.sl_giuhang,
                            sl_qty: r?.sl_qty,
                          }
                        )
                      }
                    });
                    datatableStore.addProduct2Cart(rows)
                    datatableStore.setSelectedRowKeys([])
                    datatableStore.setShowProductCart(true)
                  }
                }}>
                  Ghi nhớ sp
                </Button>
              </>
            }
            <Dropdown overlay={MassActionMenu}>
              <Button loading={massLoading} size="small">
                Hành động 1<DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={MassActionMenu2}>
              <Button loading={massLoading} size="small">
                Hành động 2<DownOutlined />
              </Button>
            </Dropdown>
            {commonStore?.company?.companyData?.order_table === id &&
              <>
                <PrintOrderList title='In đơn nhặt'
                  ordersData={{
                    items: toJS(datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key)))
                  }}
                  columns={['code', 'image', 'qty', 'name', 'stock']}
                />
                <QuickAddParent
                  label='lượt nhặt'
                  autoLabel={'mã nhặt'}
                  tableId={commonStore?.company?.companyData?.pick_table}
                  onSave={async (v) => {
                    if (v?.ref?.name) {
                      if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                        let rows = []
                        datatableStore.selectedRowKeys.forEach(id => {
                          rows.push(
                            {
                              id: id,
                              n: { 'luot_nhat': v.ref.name },
                              o: false
                            }
                          )
                        });
                        let r = await updateManyDataRows({
                          variables: {
                            input: {
                              data: {
                                type: "oldnew",
                                rows: rows
                              }
                            }
                          }
                        })
                        // console.log(`r`, r)
                        if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
                          datatableStore.massUpdateRows("luot_nhat", v.ref.name, datatableStore.selectedRowKeys)
                        }
                        // setUpdating(false)

                      }

                    }
                  }}
                />
                <QuickAddParent
                  label='chi nhánh'
                  autoLabel={'chi nhánh'}
                  tableId={commonStore?.company?.companyData?.branch_table}
                  onSave={async (v) => {
                    if (v?.ref?.name) {
                      if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                        let rows = []
                        datatableStore.selectedRowKeys.forEach(id => {
                          rows.push(
                            {
                              id: id,
                              n: { 'branch': v.ref.name },
                              o: false
                            }
                          )
                        });
                        let r = await updateManyDataRows({
                          variables: {
                            input: {
                              data: {
                                type: "oldnew",
                                rows: rows
                              }
                            }
                          }
                        })
                        // console.log(`r`, r)
                        if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
                          datatableStore.massUpdateRows("branch", v.ref.name, datatableStore.selectedRowKeys)
                        }
                        // setUpdating(false)

                      }

                    }
                  }}
                />

                <Button size="small" icon={<LinkOutlined />} onClick={() => {
                  if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                    datatableStore.selectedRowKeys.forEach(id => {
                      // window.open('/row/' + id, '_blank');
                      let link = document.createElement('a');
                      link.href = '/row/' + id;
                      link.target = '_blank';
                      link.click();
                    });
                  }
                }}>
                  mở nhanh
                </Button>

                <Button type="primary" size="small" icon={<ShoppingOutlined />} onClick={() => {
                  if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                    let rows = []
                    datatableStore.selectedRowKeys.forEach(id => {
                      let r = datatableStore.currentDatarows.find(item => item.key === id)
                      if (r) {
                        rows.push(
                          {
                            id: id,
                            code: r.code,
                            name: r?.customer?.name,
                            mobile: r?.customer?.mobile
                          }
                        )
                      }
                    });
                    datatableStore.add2cart(rows)
                    datatableStore.setSelectedRowKeys([])
                    datatableStore.setShowOrderCart(true)
                  }
                }}>
                  Ghi nhớ đơn
                </Button>

              </>
            }

            {commonStore?.company?.companyData?.product_table === id &&
              <>
                <PrintManyItems title='In sp được chọn'
                  printData={{
                    items: toJS(datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key)))
                  }}
                  colStyle={{
                    'image': { align: 'center', 'width': 50 },
                    'product_compact': { align: 'left' },
                    'cotheban': { align: 'right', 'width': 30 },
                    'note': { align: 'left', 'width': 150 },
                  }}
                  columns={[
                    'image',
                    'code',
                    'product_compact',
                    'cotheban',
                    'sl_tonkho',
                    'note'
                  ]
                  }
                  headerRow={
                    {
                      image: 'Ảnh',
                      code: 'Mã',
                      product_compact: 'Tên',
                      cotheban: 'CTB',
                      sl_tonkho: 'Kho',
                      note: 'Ghi chú'
                    }
                  }
                />
                <PrintProductsQRCode title='In nhãn'
                  printData={{
                    items: toJS(datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key)))
                  }}
                  columns={[
                    'image',
                    'code',
                    'product_compact',
                    'so_luong',
                    'price',
                    'cotheban',
                    'sl_tonkho']
                  }
                />
                <PrintManyItems title='In live'
                  icon={<FireOutlined />}
                  printData={{
                    items: toJS(datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key)))
                  }}
                  colStyle={{
                    'image': { align: 'center', 'width': 60 },
                    'product_compact': { align: 'left' },
                    'gia_von': { align: 'right', 'width': 120 },
                    'cotheban': { align: 'right', 'width': 30 },
                    'note': { align: 'left', 'width': 150 },
                  }}
                  columns={[
                    'image',
                    'code',
                    'sl_kwo',
                    'product_compact',
                    'gia_von',
                    'cotheban',
                    'note'
                  ]
                  }
                  headerRow={
                    {
                      image: 'Ảnh',
                      code: 'Mã',
                      sl_kwo: 'Mã live',
                      product_compact: 'Tên',
                      gia_von: 'Giá',
                      cotheban: 'CTB',
                      note: 'Ghi chú'
                    }
                  }
                />
                <QuickAddParent
                  label='livestream'
                  autoLabel="livestream"
                  tableId={commonStore?.company?.companyData?.livestream_table}
                  onSave={async (v) => {
                    let updateValue = (v?.ref?.name) ? v.ref.name : ""
                    // console.log(`v`, v)
                    if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                      let rows = []
                      datatableStore.selectedRowKeys.forEach(id => {
                        // n[massFilter.updateKey] = 
                        rows.push(
                          {
                            id: id,
                            n: { 'livestream': updateValue },
                            o: false
                          }
                        )
                      });
                      let r = await updateManyDataRows({
                        variables: {
                          input: {
                            data: {
                              type: "oldnew",
                              rows: rows
                            }
                          }
                        }
                      })
                      if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
                        datatableStore.massUpdateRows("livestream", updateValue, datatableStore.selectedRowKeys)
                        datatableStore.setSelectedRowKeys([])
                      } else {
                        message.error('Lỗi cập nhật dữ liệu!')
                      }
                      // setUpdating(false)
                    }
                  }}
                />

                <QuickAddParent
                  label='danh mục Shopline'
                  autoLabel="danh mục sl"
                  tableId={commonStore?.company?.companyData?.scate_table}
                  onSave={async (v) => {
                    if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                      let valid_rows = datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key) && item.sl_id)
                      let u = await bulkAssignSLProductCate({
                        variables: {
                          input:
                          {
                            data: {
                              hub_products: valid_rows,
                              sl_ids: valid_rows.map(p => p.sl_id),
                              categories: [{
                                name: v?.ref?.name,
                                id: v?.ref?.sl_id
                              }]
                            }
                          }
                        }
                      })
                      let result = u?.data?.bulkAssignSLProductCate.data
                      if (result.status === 'OK') {
                        window.location.reload()
                      } else if (result.status === 'ERROR') {
                        alert(result?.msg)
                      }
                    }
                  }}
                />


                {/* <QuickAddParent label='danh mục' /> */}
              </>
            }

            {/* <Button loading={massLoading} type="primary" danger size="small" icon={<DeleteOutlined />}
              onClick={async () => {

                confirm({
                  title: 'Bạn có chắc chắn muốn xóa các sản phẩm này không?',
                  icon: <ExclamationCircleOutlined />,
                  okText: 'Xóa',
                  okType: 'danger',
                  cancelText: 'Bỏ qua',
                  async onOk() {
                    const input = {
                      data: {
                        tableId: currentDT.id,
                        ids: datatableStore.selectedRowKeys
                      }
                    }
                    setRemoveLoading(true)
                    let delReturn = await deleteManyRows({
                      variables: { input }
                    })
                    setRemoveLoading(false)
                    if (delReturn.data?.deleteManyDataRows?.data?.ok === 1) {
                      datatableStore.resetFilter()
                    }
                    datatableStore.setSelectedRowKeys([])
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                });
              }}
            >
              Xóa
            </Button> */}

          </Space>
          : ""
        }
      </>
    )
  }

  const RightBar = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Space>
          {/* <Button
            size={'small'}
            disabled={!datatableStore.somethingUnsave}
            onClick={() => { }}
            icon={<SaveOutlined />}
          >
            Lưu thay đổi
          </Button> */}
          {usersStore.currentUser.company_role === 'chu-cong-ty' && <AdminTopBar />}
          {(usersStore.currentUser.company_role === 'quan-ly' || usersStore.currentUser.company_role === 'chu-cong-ty') && <RightTopMenu />}


          {datatableStore?.order_cart?.length > 0 && commonStore?.company?.companyData?.order_table === id &&
            <Button
              type="primary"
              shape="circle"
              icon={<ShoppingOutlined />}
              size={'small'}
              onClick={() => {
                datatableStore.setShowOrderCart(true)
              }} />
          }

          {datatableStore?.product_cart?.length > 0 && commonStore?.company?.companyData?.product_table === id &&
            <Button
              style={{ background: "blue" }}
              type="primary"
              shape="circle"
              icon={<ShoppingOutlined />}
              size={'small'}
              onClick={() => {
                datatableStore.setShowProductCart(true)
              }} />
          }

        </Space>
      </div>
    )
  }

  return (
    <DefaultLayout>
      <>
        {datatableStore.selectedRowKeys.length > 0 ?
          <ActionBar /> :
          <>
            <TopBarContainer>
              <LeftBar />
              <RightBar />
            </TopBarContainer>
            {showFilter && <FilterPanel />}
            {currentDT?.table_data?.filter_label && updateFilter.match && datatableStore.preloadTable ?
              < FilterDetail table_id={currentDT.id} updateFilter={updateFilter} filterLabel={currentDT?.table_data.filter_label} preloadTable={datatableStore.preloadTable} />
              : ""}
          </>}
        <DataTableRows table={currentDT} updateFilter={updateFilter} />
        {/* {(columns && datatableStore.currentDatarows && aggregate) ? */}
        {/* {columns && id ? (
          <Form form={form} component={false}>
            <Table
              loading={loading}
              className={'mainTable'}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: event => {
                    edit(record)
                    event.preventDefault()
                  } // double click row
                }
              }}
              components={{
                body: {
                  cell: EditableCell
                }
              }}
              rowClassName="editable-row"
              columns={columns}
              dataSource={rows}
              scroll={{ x: 800 }}
              rowSelection={selectedRowKeys}
              pagination={{
                defaultCurrent: 1,
                pageSize: limit,
                showSizeChanger: true,
                total: aggregate ? aggregate.count : 0,
                showTotal: total => `Total ${total} row`
              }}
              onChange={onPageChange}
              size="small"
            />
          </Form>
        ) : (
          ''
        )}
        {currentEditRecord ? (
          <DataRowDetail
            title={'Chỉnh sửa chi tiết'}
            initData={currentEditRecord}
            tableData={currentDT}
            onSave={v => {
              console.log('v', v)
            }}
            onClose={() => {
              setCurrentEditRecord(false)
            }}
          />
        ) : (
          ''
        )} */}

        {commonStore?.company?.companyData?.order_table === id &&
          <>
            {
              printLinks &&
              <>
                {printLinks.map(printLink => {
                  return <iframe src={printLink} title="title" hidden></iframe>
                })}
              </>
            }
            {currentMassFilter &&
              <OrderStatusSelect
                title='Cập nhật trạng thái hàng loạt'
                massFilter={currentMassFilter}
                onSave={v => {
                  // console.log(`v`, v)
                  if (currentMassFilter) {
                    datatableStore.massUpdateRows(currentMassFilter.updateKey, v, currentMassFilter.ids)
                  }
                  setCurrentMassFilter(false)
                }}
                onClose={() => {
                  setCurrentMassFilter(false)
                }}
              />
            }
          </>}

        <Form
          onFinish={onFinishImport}
          form={formImport}
          style={{ display: 'none' }}>
          <Form.Item
            name={'ifile'}
            label={'abc'}
          >
            <Upload
              listType="picture-card"
              showUploadList={false}
              beforeUpload={beforeUpload}
              multiple={true}
              // accept="image/*"
              // capture="environment"
              // capture="user"
              // onChange={handleChange}
              maxCount={3}
            >
              <div ref={inputFile}>Import dữ liệu</div>
            </Upload>
          </Form.Item>
        </Form>

        {datatableStore.showOrderCart && (
          <Drawer
            title="Ghi nhớ đơn hàng"
            width={650}
            placement={'right'}
            closable={false}
            onClose={() => {
              datatableStore.setShowOrderCart(false)
            }}
            visible={true}
            extra={
              <Space>
                <Button type="default" danger onClick={() => {
                  datatableStore.emptyCart()
                }} >
                  Xóa hết
                </Button>
                <Button type="primary" onClick={() => {
                  let searchIds = datatableStore.order_cart.map(o => o.id)
                  searchIds = searchIds.join('|')
                  history.push({
                    pathname: '/table/' + commonStore?.company?.companyData?.order_table,
                    search: '?_id=' + searchIds,
                  })
                  datatableStore.setShowOrderCart(false)
                }} >
                  Xem tất cả
                </Button>
              </Space>
            }
          >
            {datatableStore.order_cart.map(o => {
              return (
                <>
                  {o.code} - {o.name} - {o.mobile}
                  <br />
                </>
              )
            })}
          </Drawer>
        )}

        {datatableStore.showProductCart && (
          <Drawer
            style={{ maxWidth: 650 }}
            width={"100%"}
            title={"Nhớ sp " + datatableStore.product_cart.length + " sp"}
            placement={'right'}
            closable={true}
            onClose={() => {
              datatableStore.setShowProductCart(false)
            }}
            visible={true}
            extra={
              <Space>
                <Button type="default" danger onClick={() => {
                  datatableStore.updateProductCart([])
                }} >
                  Xóa hết
                </Button>
                <Button type="primary" onClick={() => {
                  let searchIds = datatableStore.product_cart.map(o => o.id)
                  searchIds = searchIds.join('|')
                  history.push({
                    pathname: '/table/' + commonStore?.company?.companyData?.product_table,
                    search: '?_id=' + searchIds,
                  })
                  datatableStore.setShowProductCart(false)
                }} >
                  Xem tất cả
                </Button>
              </Space>
            }
          >
            {datatableStore.product_cart &&
              <ProductList rows={datatableStore.product_cart}
                onSave={(rows => {
                  datatableStore.updateProductCart(rows)
                })}
                view={false}
                type='quickPList' />
            }
            {/* {datatableStore.product_cart.map(o => {
              return (
                <>
                  {o.code} - {o.name}
                  <br />
                </>
              )
            })} */}
          </Drawer>
        )}

        {showImportSLModal &&

          <RowsSearchModal
            rowKey={'sl_oid'}
            dataType='sl_order'
            columns={[
              {
                title: "Mã nháp",
                dataIndex: "code",
                key: "code",
                width: 100,
                render: (value, row, index) => {
                  // console.log('row', row)
                  if (row.dup) {
                    return <span style={{
                      color: 'red'
                    }}>{value}</span>
                  }
                  return value
                }
              },
              {
                title: "Tên khách",
                dataIndex: "customer_name",
                key: "customer_name",
                width: 200
              },
              {
                title: "Mobile",
                dataIndex: "customer_phone",
                key: "customer_phone",
                width: 150
              },
              // {
              //   title: "Địa chỉ",
              //   dataIndex: "customer_phone",
              //   key: "delivery_address",
              //   width: "auto",
              //   render: (value, row, index) => {
              //     return row.delivery_address.address_1
              //   }
              // },
              {
                title: "Tổng tiền",
                dataIndex: "total",
                key: "total",
                width: 150,
                render: (value, row, index) => {
                  let pr = price_k(value, '?', 3)
                  if (value % 1000 > 0) {
                    return <span style={{ color: 'red' }}>{pr}</span>
                  }
                  return pr
                }
              },
            ]}
            okLabel="Đồng bộ dữ liệu"
            filterData={[
              {
                type: 'text',
                key: 'ids',
                defaultValue: "",
                placeholder: "Mã,Tên KH, SĐT"
              },
              {
                type: 'date_range',
                key: 'created_date',
                dateAfterKey: 'updated_at_min',
                dateBeforeKey: 'updated_at_max'
              },
              // {
              //   type: 'select',
              //   key: 'status',
              //   optionsData: importOptionsData
              // }
            ]}
            onClose={() => {
              datatableStore.setModalFilter(false)
              setShowImportSLModal(false)
            }}
            onOk={
              async (items) => {
                console.log('items', items)
                return false
                let u = await syncOrdersFromSL({
                  variables: {
                    input:
                    {
                      data: {
                        sl_orders: items
                      }
                    }
                  }
                })
                let result = u?.data?.syncOrdersFromSL.data
                if (result.status === 'OK') {
                  datatableStore.setModalFilter(false)
                  setShowImportSLModal(false)
                  window.location.reload()
                } else if (result.status === 'ERROR') {
                  alert(result?.msg)
                }
              }
            }
            onOk2={
              async (items) => {
                console.log('items2', items)
                return false
                let u = await syncOrdersFromSL({
                  variables: {
                    input:
                    {
                      data: {
                        sl_orders: items,
                        update_sale_event: true
                      }
                    }
                  }
                })
                let result = u?.data?.syncOrdersFromSL.data
                if (result.status === 'OK') {
                  datatableStore.setModalFilter(false)
                  setShowImportSLModal(false)
                  window.location.reload()
                } else if (result.status === 'ERROR') {
                  alert(result?.msg)
                }
              }
            }
            findItems={getShoplineOrders}
            defaultValue={
              {}
            }
          >
          </RowsSearchModal>}

        {showImportKHSLModal &&
          <RowsSearchModal
            rowKey={'sl_id'}
            dataType='sl_customer'
            columns={[
              {
                title: "Tên khách",
                dataIndex: "name",
                key: "name",
                width: 200,
                render: (value, row, index) => {
                  let color = "black"
                  if (row.dup_mb) {
                    color = "orange"
                  }
                  if (row.dup) color = "red"
                  return <span style={{ color: color }}>{value}</span>
                }
              }, {
                title: "Mobile",
                dataIndex: "mobile_phone",
                key: "mobile_phone",
                width: 150
              }, {
                title: "Email",
                dataIndex: "email",
                key: "email",
                width: 150
              }, {
                title: "Số đơn",
                dataIndex: "order_count",
                key: "order_count",
                width: 60
              }, {
                title: "Địa chỉ",
                dataIndex: "delivery_addresses",
                key: "delivery_addresses",
                width: "auto",
                render: (value, row, index) => {
                  if (value && value[0])
                    return value[0].address_1
                  else return ""
                }
              },

              // order_count
            ]}
            okLabel="Đồng bộ dữ liệu"
            filterData={[
              {
                type: 'text',
                key: 'query',
                defaultValue: "",
                placeholder: "Mã,Tên KH, SĐT"
              },
              {
                type: 'date_customer_lte',
                key: 'created_at'
              }
            ]}
            onClose={() => {
              // datatableStore.setModalFilter(false)
              // setShowImportSLModal(false)
              setShowImportKHSLModal(false)
            }}
            onOk={
              async (items) => {
                let u = await syncCustomerFromSL({
                  variables: {
                    input:
                    {
                      data: {
                        sl_customers: items
                      }
                    }
                  }
                })
                let result = u?.data?.syncCustomerFromSL.data
                if (result.status === 'OK') {
                  datatableStore.setModalFilter(false)
                  setShowImportKHSLModal(false)
                  window.location.reload()
                } else if (result.status === 'ERROR') {
                  alert(result?.msg)
                }
              }
            }
            findItems={getShoplineCustomers}
          // defaultValue={
          //   { status: 'pending' }
          // }
          >
          </RowsSearchModal>}

        {
          commonStore?.company?.companyData?.product_table === id &&
          <>
            {currentTagFilter &&
              <TagSelectModal
                title='Cập nhật tags hàng loạt'
                massFilter={currentTagFilter}
                onSave={async (v) => {
                  message.loading('Đang xử lý..', 0);
                  let ids = toJS(datatableStore.selectedRowKeys)
                  let mut = await massUpdateTag({
                    variables: {
                      input:
                      {
                        data: {
                          ids: ids,
                          action: 'update_tag',
                          field: currentTagFilter.updateKey,
                          tags: v.tags,
                          override: v.override
                        }
                      }
                    }
                  })
                  message.destroy()
                  if (mut?.data?.massUpdateTag?.data?.status === 'OK') {
                    if (mut?.data?.massUpdateTag?.data.update) {
                      datatableStore.updateManyRowsByField(mut?.data?.massUpdateTag?.data.update, currentTagFilter.updateKey)
                    } else if (v.override && mut?.data?.massUpdateTag?.data.update_value) {
                      let updates = []
                      ids.forEach(uid => {
                        let udata = {}
                        udata["row_data." + currentTagFilter.updateKey] = v.tags || []
                        updates.push({
                          id: uid,
                          udata: udata
                        })
                      });
                      datatableStore.updateManyRowsByField(updates, currentTagFilter.updateKey)
                    }
                    message.success('Cập nhật tags thành công', 2);
                  }
                  setCurrentTagFilter(false)
                }}
                onDelete={async (v) => {
                  message.loading('Đang xử lý..', 0);
                  let mut = await massUpdateTag({
                    variables: {
                      input:
                      {
                        data: {
                          ids: datatableStore.selectedRowKeys,
                          action: 'remove_tag',
                          field: currentTagFilter.updateKey,
                          tags: v.tags,
                          override: v.override
                        }
                      }
                    }
                  })
                  message.destroy()
                  if (mut?.data?.massUpdateTag?.data?.status === 'OK') {
                    datatableStore.updateManyRowsByField(mut?.data?.massUpdateTag?.data.update, currentTagFilter.updateKey)
                    message.success('Cập nhật tags thành công', 2);
                  }
                  setCurrentTagFilter(false)
                }}
                onClose={() => {
                  setCurrentTagFilter(false)
                }}
              />
            }
          </>
        }


        {syncType &&
          <DataSelectModal
            title={`Đặt danh mục shopline`}
            dataTextName={'danh mục'}
            autoLabel={'mã'}
            searchColumns={["name"]}
            displayColumns={["name"]}
            searchText={false}
            // initData={customerSelect}
            // tableId="5fe7fb4d2136a764ac573c1e"
            tableId={commonStore?.company?.companyData?.scate_table}
            onSave={async (v) => {
              if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                let valid_rows = datatableStore.currentDatarows.filter(item => datatableStore.selectedRowKeys.includes(item.key) && item.sline && item?.sl_tonkho > 0)

                valid_rows.forEach(vr => {
                  if (!vr.price || vr.price === 0 || isNaN(vr.price)) vr.price = 1
                  if (!vr.price_live_base || vr.price_live_base === 0 || isNaN(vr.price_live_base)) vr.price_live_base = 1
                  if (!vr.price_live || vr.price_live === 0 || isNaN(vr.price_live)) vr.price_live = 1
                });

                let u = await bulkAssignSLProductCate({
                  variables: {
                    input:
                    {
                      data: {
                        hub_products: valid_rows,
                        sl_ids: valid_rows.map(p => p.sl_id),
                        sl_cate: {
                          name: v?.ref?.name,
                          sl_id: v?.ref?.sl_id
                        },
                        // syncType: syncType,
                        syncType: "cate",
                        subType: 'quick_price'
                      }
                    }
                  }
                })
                let result = u?.data?.bulkAssignSLProductCate.data
                if (result.status === 'OK') {
                  alert("Done!")
                  // window.location.reload()
                } else if (result.status === 'ERROR') {
                  alert(result?.msg)
                }
              }
            }}
            onClose={() => {
              setSyncType(false)
            }}
            // component={ProductItem}
            onSearchSelect={(v, o) => {
              // console.log('o', o)
            }}
          />
        }

        {showKiemHangModal &&
          <KiemhangModal
            onSave={async (v) => {
              let ngay_kiem = false
              if (v.da_kiem && v.ngay_kiem) ngay_kiem = moment(v.ngay_kiem).toISOString()
              if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                let r = await updateManyDataRows({
                  variables: {
                    input: {
                      data: {
                        type: 'reset-value',
                        ids: datatableStore.selectedRowKeys,
                        values: { 'row_data.ngay_kiem': ngay_kiem }
                      }
                    }
                  }
                })
                setShowKiemHangModal(false)
                window.location.reload()
              }
            }}
            onClose={() => {
              setShowKiemHangModal(false)
            }}
          />
        }

        {
          showBooleanModal &&
          <BooleanModal
            label="Có mẫu live"
            field="co_mau_live"
            onSave={async (v) => {
              if (datatableStore.selectedRowKeys && datatableStore.selectedRowKeys.length > 0) {
                let r = await updateManyDataRows({
                  variables: {
                    input: {
                      data: {
                        type: 'reset-value',
                        ids: datatableStore.selectedRowKeys,
                        values: { 'row_data.co_mau_live': v.co_mau_live }
                      }
                    }
                  }
                })
                setShowBooleanModal(false)
                window.location.reload()
              }
            }}
            onClose={() => {
              setShowBooleanModal(false)
            }}
          />
        }

        {showUpdateSLBlock &&

          <QuickSetSLByBlock
            rows={datatableStore.currentDatarows.filter(r => datatableStore.selectedRowKeys.includes(r.key))}
            onClose={() => {
              setShowUpdateSLBlock(false)
            }}
            onSave={async (v) => {
              setShowUpdateSLBlock(false)
              window.location.reload()
            }
            }
          />

        }

        {showWeightModal &&
          <QuickSetWeightBlock
            rows={datatableStore.currentDatarows.filter(r => datatableStore.selectedRowKeys.includes(r.key))}
            onClose={() => {
              setShowWeightModal(false)
            }}
            onSave={async (v) => {
              setShowWeightModal(false)
              window.location.reload()
            }
            }
          />
        }

        {/* {datatableStore.inputNumberData && datatableStore.inputNumberData.show &&
          <Modal
            title={datatableStore.inputNumberData.title}
            visible={true}
            onCancel={() => {
              datatableStore.setInputNumberData(false)
            }}
            footer={[
              <Button key="back" onClick={() => {
                datatableStore.setInputNumberData({ number: 0, show: false })
              }}>
                Hủy
              </Button>,
              <Button key="submit" type="primary" onClick={
                () => {
                  datatableStore.setInputNumberData({ number: 11, show: false })
                }
              }>
                OK
              </Button>
            ]}
          >
            hihi xin chao
          </Modal>
        } */}
      </>
    </DefaultLayout >
  )
}

export default inject(
  'usersStore',
  'commonStore',
  'datatableStore'
)(observer(DataTable))
