import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useMutation } from '@apollo/react-hooks'
import {
    Form,
    Button,
    Image,
    message,
    Input,
    Modal,
    Card,
    Upload,
    Select,
    Tag,
    Space,
    Row,
    Col
} from 'antd';
import moment from 'moment'

import {
    PlusOutlined,
    LoadingOutlined,
    LinkOutlined,
    UploadOutlined
} from '@ant-design/icons';

import { imagePreset } from '../../../ultils/datatableUtils'
import UploadServices from '../../../services/uploadServices'
import DataTableServices from '../../../services/datatableServices'
import TagsInput from '../DataBoard/DataTools/TagsInput'
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import { removeVietnameseCharMark } from '../../../ultils/datatableUtils'


const { Option } = Select;
const mdParser = new MarkdownIt(/* Markdown-it options */);
// import { autorun, toJS } from 'mobx'
// import { nanoid } from 'nanoid'

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


const ResourceModal = ({
    datatableStore,
    commonStore,
    rowData = false,
    initData = false,
    isEdit = false,
    usersStore,
    resType = "image",
    resTag = false,
    width = 480,
    onSave,
    onClose
}) => {
    const [updating, setupdating] = useState(false)

    const [uploading, setUploading] = useState(false)
    const [imageUrl, setImageUrl] = useState(false)
    const [videoUrl, setVideoUrl] = useState(false)

    const [fileImageList, setFileImageList] = useState([])

    const [currentResType, setCurrentResType] = useState(resType)
    const [currentText, setCurrentText] = useState("")
    const refEditor = useRef(null)

    const [form] = Form.useForm();
    const closeModal = () => {
        onClose()
        // alert('ac')
    }

    const [upload] = useMutation(
        UploadServices.UPLOAD()
    )
    const [addResource] = useMutation(
        DataTableServices.ADD_RESOURCE(),
        {
            errorPolicy: 'all'
        }
    )

    function beforeUpload(file) {
        console.log('file', file)
        // setFileImageList(file)
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Bạn chỉ có thể tải lên JPG/PNG file!');
        }
        const sizeOK = file.size / 1024 / 1024 < 20;
        if (!sizeOK) {
            message.error('Kích thước không được quá 20 MB!');
        }
        let ok = isJpgOrPng && sizeOK;
        // if (ok) {
        //     getBase64(file, imageUrl => {
        //         setImageUrl(imageUrl)
        //         setUploading(false)
        //     });
        // }
        // form.setFieldsValue({ name: file.name })
        return ok
    }

    function beforeUploadVideo(file) {
        const isTypeOk = file.type === 'video/mp4' || file.type === 'video/avi';
        if (!isTypeOk) {
            message.error('Bạn chỉ có thể tải lên MP4/AVI file!');
        }
        const sizeOK = file.size / 1024 / 1024 < 60;
        if (!sizeOK) {
            message.error('Kích thước không được quá 60 MB!');
        }
        let ok = isTypeOk && sizeOK;
        if (ok) {
            getBase64(file, videoUrl => {
                setVideoUrl(videoUrl)
                setUploading(false)
            });
        }
        form.setFieldsValue({ name: file.name })
        return ok
    }

    const createResource = async (values) => {
        let rdata = {
            ngay_tao: (new Date()).toISOString(),
            tags: values?.tags
        }

        if (values.file) {
            if (!values.name) values.name = values.file.name
            let f = await values.file.originFileObj
            const { data } = await upload({
                variables: { file: f },
            })
            if (data.upload) {
                rdata["image"] = data.upload.url
                rdata["image_info"] = {
                    hash: data.upload.hash,
                    ext: data.upload.ext,
                    size: data.upload.size,
                }
            }
        }

        let newData = {
            rdata: rdata,
            row_ids: [rowData.key || rowData.id],
            name: values.name,
            type: resType,
            se: values.name || ''
        }

        const input = {
            data: newData
        }
        // newData 
        if (rowData.se) input.data.se += ' ' + rowData.se
        input.data.se = removeVietnameseCharMark(input.data.se.toLowerCase())

        // console.log('input', input)

        var newRow = await addResource({
            variables: { input },
        })
        console.log('newRow', newRow)
        return newRow?.data?.addResource?.data?.resource
        // onSave({
        //     status: 'OK',
        //     data: newRow
        // })
    }


    const onFinish = async (values) => {
        // console.log('values', values)
        // console.log('values?.image?.fileList', values?.image?.fileList)
        // return false
        let outResources = []
        if (values?.image?.fileList) {
            for (let index = 0; index < values?.image.fileList.length; index++) {
                const ff = values?.image.fileList[index];
                let v = {
                    name: values.name,
                    resType: values.resType,
                    tags: values.tags,
                    file: ff,
                }
                // console.log('v', v)
                // v.file = ff
                let rr = await createResource(v)
                outResources.push(rr)
            }
            // console.log('outResources', outResources)
            onSave({
                status: 'OK',
                data: outResources
            })
            return
        } else {

        }

        // console.log(`initData`, initData)
        // let changeData = false
        // for (const [key, value] of Object.entries(values)) {
        //     // let col = datatable.table_columns.findIndex(c => c.dataIndex === key)
        //     if (value?.file) {
        //         var f = await value.file.originFileObj
        //         const { data } = await upload({
        //             variables: { file: f },
        //         })
        //         if (data.upload) {
        //             values[key] = data.upload.url
        //             values[key + "_info"] = {
        //                 hash: data.upload.hash,
        //                 ext: data.upload.ext,
        //                 size: data.upload.size,
        //             }
        //         }
        //     }
        //     else if (key === 'name') {
        //         // if (value?.color) {
        //         //     values[key] = value.color
        //         // }
        //         // if (col?.type === 'number') values[key] = parseInt(value)

        //     }
        //     // else if (key==='name') {
        //     // }
        // }

        // if (currentResType === 'text' && refEditor?.current) {
        //     console.log('refEditor?.current', refEditor?.current)
        // }
        // console.log(`values2`, values)
        if (!isEdit) {
            // add new resource
            let rdata = {
                ngay_tao: (new Date()).toISOString(),
                tags: values?.tags
            }
            switch (resType) {
                // case "image":
                //     rdata.image = values.image
                //     rdata.image_info = values.image_info
                //     break;
                case "link":
                    rdata.link = values.link
                    break;
                case "text":
                    rdata.text = refEditor?.current?.state?.text
                case "video":
                    rdata.video = values.video
                    break;
                default:
                    break;
            }

            let newData = {
                rdata: rdata,
                row_ids: [rowData.key || rowData.id],
                name: values.name,
                type: resType,
                se: values.name || ''
            }

            const input = {
                data: newData
            }
            // newData 
            if (rowData.se) input.data.se += ' ' + rowData.se
            input.data.se = removeVietnameseCharMark(input.data.se.toLowerCase())

            // console.log('input', input)

            var newRow = await addResource({
                variables: { input },
            })

            console.log('newRow', newRow)
            onSave({
                status: 'OK',
                data: [newRow?.data?.data?.addResource?.data?.resource]
            })
        } else {
            onSave({
                status: 'ERROR',
                msg: 'error',
                data: [newRow?.data?.data?.addResource?.data?.resource]
            })
        }
    };


    const uploadButton = (
        <div>
            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Tải lên</div>
        </div>
    );

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    function handleEditorChange({ html, text }) {
        const newValue = text.replace(/\d/g, "");
        // console.log(newValue);
        // setCurrentText(newValue);

        // setCurrentText(text)
        // console.log('handleEditorChange', html, text);
    }

    const ResForm = () => {

        return (
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // initialValues={{
                //     resType: currentResType
                // }}
                initialValues={initData}
                onFinish={onFinish}>
                <Form.Item
                    name="resType"
                    label="Kiểu tài nguyên"
                >
                    <Select
                        onChange={(v) => {
                            setCurrentResType(v)
                        }}
                    // value={getCarrierByWeight(form.getFieldValue('weight'))?.name}
                    >
                        <Option key={'image'} value={'image'}>Ảnh</Option>
                        <Option key={'link'} value={'link'}>Link</Option>
                        <Option key={'text'} value={'text'}>Văn bản</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='name'
                    label='Tên tài nguyên'
                    initialValue={""}
                >
                    <Input autocomplete="off" />
                </Form.Item>
                {currentResType === 'link' &&
                    <Form.Item
                        name='link'
                        label='URL'
                        initialValue={""}
                        rules={[{ type: 'url', message: 'Hãy nhập URL hợp lệ' }]}
                    >
                        <Input addonBefore={<LinkOutlined />} autocomplete="off" />
                    </Form.Item>
                }
                {currentResType === 'image' &&
                    <Form.Item
                        name={'image'}
                        label={'Ảnh'}
                    >

                        <Upload
                            listType="picture"
                            // fileList={fileImageList}
                            // listType="picture"
                            showUploadList={true}
                            // beforeUpload={beforeUpload}
                            // accept="image/x-png,image/jpeg"
                            accept="image/*"
                            // maxCount={3}
                            // multiple={true}
                            // capture="environment"
                            // onChange={handleChange}
                            maxCount={10}
                            multiple
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                            {/* {imageUrl ? <img src={imagePreset(imageUrl, 'thumbnail')} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                        </Upload>
                    </Form.Item>
                }
                {currentResType === 'video' &&
                    <Form.Item
                        name={'video'}
                        label={'Video'}
                    >
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={beforeUploadVideo}
                            // accept="image/x-png,image/jpeg"
                            accept="video/*"
                            // capture="environment"
                            // onChange={handleChange}
                            maxCount={1}
                        >
                            {videoUrl ? <img src={imagePreset(videoUrl, 'thumbnail')} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>
                }

                {/* {currentResType === 'text' &&
                    <Form.Item
                        name='text'
                        label='URL'
                    >
                    <MdEditor
                        // ref={refEditor}
                        value={currentText}
                        style={{ height: '300px' }}
                        renderHTML={text => mdParser.render(text)}
                        onChange={handleEditorChange}
                        view={{ menu: true, md: true, html: false }}
                    />
                    </Form.Item>
                } */}

                <Form.Item
                    name='tags'
                    label={'Tags'}
                    initialValue={initData['tags'] || resTag || []}
                >
                    <TagsInput

                        tagTable={commonStore?.company?.companyData?.tag_table}
                        tagFilter={{ match: { "category": 'Resource' } }}
                        selectMode={'tags'}
                        size="middle"

                    // tagTable={commonStore?.company?.companyData?.tag_table}
                    // tagFilter={{ match: { "category": 'resource' } }}
                    // selectMode={'tags'}
                    />
                </Form.Item>

            </Form>
        )
    }

    return (
        <Modal
            title={
                <>
                    <Row justify="space-between">
                        <Col span={10} ><span style={{ lineHeight: "35px" }}>Tài nguyên</span></Col>
                        <Col span={14} align="right">
                            <Button key="submit" type="primary"
                                loading={updating}
                                onClick={() => {
                                    setupdating(true)
                                    form.submit()
                                }}>
                                Xác nhận
                            </Button>
                            <Button
                                style={{ marginLeft: 5 }}
                                onClick={closeModal}
                                disabled={updating}>
                                Đóng
                            </Button>
                        </Col>
                    </Row>

                    {/* <Space>
                        <span>Tài nguyên</span>
                        <Button key="submit" type="primary"
                            loading={updating}
                            onClick={() => {
                                setupdating(true)
                                form.submit()
                            }}>
                            Xác nhận
                        </Button>
                        <Button
                            onClick={closeModal}
                            disabled={updating}>
                            Hủy
                        </Button>
                    </Space> */}
                </>
            }
            closable={false}
            visible={true}
            width={width}
            maskClosable={false}
            onCancel={closeModal}
            centered
            // footer={false}
            footer={[

            ]}
        >
            <ResForm />
            {currentResType === 'text' &&
                <MdEditor
                    ref={refEditor}
                    style={{ height: '300px' }}
                    renderHTML={text => mdParser.render(text)}
                    // onChange={handleEditorChange}
                    view={{ menu: true, md: true, html: false }}
                />}
        </Modal>
    )
}
export default inject("datatableStore", "commonStore", "usersStore")(observer(ResourceModal))