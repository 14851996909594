import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Image,
  Button,
  Tabs,
  Input,
  Table,
  Empty,
  Modal,
  Card,
  Space,
} from 'antd';
import {
  PlusOutlined,
  DeleteFilled,
  CloseCircleOutlined,
  PlusSquareFilled
} from '@ant-design/icons';
// import { autorun, toJS } from 'mobx'
// import { debounce } from "lodash-es";
import SearchBoxItem from './SearchBoxItem'
import DataNewItem from './DataNewItem'
import styled from 'styled-components'
import { split } from 'apollo-boost';

const { TabPane } = Tabs;
const { Meta } = Card;

const SelectModal = styled(Modal)`
 
  `

function getDateString(label, splitText = '-') {
  var today = new Date();
  if (label === 'livestream') splitText = "_"
  let out = {
    day: today.getDate() + splitText + (today.getMonth() + 1) + splitText + today.getFullYear(),
    second: today.getMilliseconds()
  }
  if (label === 'livestream') {
    return `Live_${out.day}`
  }
  if (label) {
    return `Ngày ${out.day} ${label}-${out.second}`
  }
  return out
}
const DataSelectModal = ({
  datatableStore,
  // liveStore,
  title,
  initData,
  tableId,
  searchColumns,
  searchText,
  dataTextName = 'dữ liệu',
  autoLabel = 'mã',
  autoInitType,
  // displayColumns,
  // view,
  component: Component,
  // limit,
  // width,
  // placeholder,
  // clearAfterSelect,
  // initValue,
  // hasSearchIcon,
  onSave,
  onClose,
  onDelete,
  onSearchSelect,
  okLabel = 'Lưu',
  closeOnSelect,
  hasAddButton = true,
  searchConfig
}) => {

  const [selectedData, setSelectedData] = useState(initData ? initData : {})
  const [showAddModal, setShowAddModal] = useState(false)
  const closeModal = () => {
    onClose()
  }

  const onSelect = (v, o) => {
    // console.log(`o`, o)
    // console.log(`v`, v)
    if (o?.data?.row_data) {
      let newSelectData = {
        ...selectedData,
        // code: o?.data?.row_data.code,
        rid: o?.data?.rid,
        ref: o?.data?.row_data,
        id: o?.data?.id
      }
      if (closeOnSelect) {
        onSave(newSelectData)
        if (onSearchSelect) onSearchSelect(v, o)
        onClose()
      }
      else {
        setSelectedData(newSelectData)
        // onClose()
      }

    } else {
      console.log('select invalid!')
    }
  }

  const ProductItem = ({ row }) => {
    let name = row?.ref?.name
    if (!name) name = row?.name
    // if (!name) {
    //   let p = liveStore.findRefProduct(row.code, row.match_code)
    //   if (p) {
    //     name = p.name
    //   } else
    //     return ''
    // }    
    let pName = row?.ref?.code || row?.match_code
    if (pName) pName = '[' + pName + ']-' + name
    else pName = name

    let imageSrc = row?.ref?.image
    if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
    return (
      <Card
        bordered
      >
        <Meta
          avatar={
            <Image src={imageSrc} width={40} height={40} />
          }
          title={pName}
          description={name}
        />
      </Card>
    )
  }

  if (!Component) Component = ProductItem
  return (
    <Modal
      title={title ? title : `Chỉnh sửa ${dataTextName}`}
      visible={true}
      maskClosable={false}
      onCancel={closeModal}
      centered
      footer={[
        <Button onClick={closeModal}>
          Hủy thay đổi
        </Button>,
        onDelete ? <Button key="remove" danger onClick={onDelete}>
          Xóa
        </Button> : ""
        ,
        <Button key="submit" type="primary"
          onClick={() => {
            onSave(selectedData)
            onClose()
          }}>
          {okLabel}
        </Button>
      ]}
    >
      {`Chọn ${dataTextName} khác`}
      <br />
      <Space style={{ margin: "8px 0 15px 0" }}>
        {/* {selectedData ?
          <SearchBoxItem
            tableId={tableId}
            columns={searchColumns}
            initValue={searchText ? searchText : selectedData[searchColumns[0]]}
            onSelect={onSearchSelect}
          /> : ''} */}
        <SearchBoxItem
          tableId={tableId}
          columns={searchColumns}
          initValue={searchText ? searchText : selectedData[searchColumns[0]]}
          onSelect={onSelect}
          searchConfig={searchConfig}
        />
        {hasAddButton &&
          <>
            <Button icon={<PlusOutlined />} size={'small'}
              onClick={() => {
                setShowAddModal(true)
              }}
            >
              {dataTextName}
            </Button>

            <Button
              icon={<DeleteFilled />}
              size={'small'}
              disabled={!selectedData?.ref}
              onClick={() => {
                setSelectedData({
                  ...selectedData,
                  ref: false
                })
              }}
            >
              Bỏ liên kết
            </Button>
          </>
        }

      </Space>
      {(selectedData?.ref) ? <Component row={selectedData} /> : ""}
      {showAddModal ?
        <DataNewItem
          title={`Thêm ${dataTextName}`}
          initData={autoInitType ? { 'name': getDateString(autoLabel) } : false}
          table={tableId}
          onSave={(v) => {
            // console.log('newV', v)
            // console.log('selectedData', selectedData)
            if (v.status === 'OK') {
              let newSelectData = {
                ...selectedData,
                id: v.data?.id,
                code: v.data?.row_data.code,
                rid: v.data?.rid,
                ref: v.data?.row_data,
              }
              setSelectedData(newSelectData)
              setShowAddModal(false)
              if (closeOnSelect) {
                onSave(newSelectData)
                onClose()
              }
            } else {
              setShowAddModal(false)
            }
          }}
          onClose={() => {
            setShowAddModal(false)
          }}
        />
        : ""
      }
    </Modal>
  )
}
export default inject("datatableStore", "liveStore")(observer(DataSelectModal))