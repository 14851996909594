import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '../../layouts/DefaultLayout'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'
import DataTableServices from '../../../services/datatableServices'
import { useMutation } from '@apollo/react-hooks'

import {
    message
} from 'antd'
const QuickSetKeywordLivePage = ({ usersStore, commonStore, history }) => {
    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )

    let searchColumns = ['code', 'sl_kwo', 'name']
    return (
        <DefaultLayout>
            <h3>Đặt từ khóa đặt hàng</h3>
            <SearchBoxItem
                hasPreSearch={false}
                autoFocus={true}
                clearAfterSelect={true}
                className='searchSyncPrice'
                autoClearSearchValue={true}
                width={'100%'}
                tableId={commonStore?.company?.companyData?.product_table}
                filterOnDropdown={true}
                quickSelectOne={true}
                columns={searchColumns}
                onSelect={async (v, o) => {
                    let rData = o?.data?.row_data
                    if (rData) rData.id = o.key
                    if (rData?.id) {
                        let kwo = prompt(`Từ khóa sp ${rData.code} (${rData.sl_kwo}):`, rData.sl_kwo)
                        if (kwo) kwo = kwo.toUpperCase()
                        if (kwo && kwo !== rData.sl_kwo) {
                            rData.sl_kwo = kwo
                            let r = await updateManyDataRows({
                                variables: {
                                    input: {
                                        data: {
                                            type: "oldnew",
                                            rows: [{
                                                id: rData.id,
                                                n: { 'sl_kwo': kwo },
                                                o: false
                                            }]
                                        }
                                    }
                                }
                            })

                        }
                    }
                    window.location.reload(false);
                }}
                onEnter={(v) => {
                    //   console.log('v', v)
                    //   alert(v)
                }}
            />
        </DefaultLayout>
    )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(QuickSetKeywordLivePage))