import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
// import useAsyncQuery from '../../../../../ultils/useAsyncQuery'
// import { Tags_render } from '../../DataTools/DataField'
import { inject, observer } from 'mobx-react'
// import DataTableServices from '../../../../../services/datatableServices'
import { updateFilterParam } from '../../../../../ultils/datatableUtils'
// import { buildFilterByParam, updateFilterParam } from '../../../../ultils/datatableUtils'
import {
    Space,
    Tag,
    Button,
    Modal,
    Switch
} from 'antd';
import moment from 'moment'
import {
    FunnelPlotOutlined,
} from '@ant-design/icons';
// import { validateSDL } from 'graphql/validation/validate';
// import { endsWith } from 'lodash-es';
import { isArray } from 'lodash-es';

const FilterDetail = ({
    datatableStore,
    table_id,
    preloadTable,
    filterLabel,
    updateFilter
}) => {

    let history = useHistory();
    const [rKey, setRKey] = useState(false)

    const [isOr, setIsOr] = useState(false)
    // const getCustomerHistories = useAsyncQuery(DataTableServices.GET_CUSTOMER_HISTORIES())
    // const findHistories = async (dataInput) => {
    //     const { data, error } = await getCustomerHistories({
    //         input: {
    //             data: dataInput
    //         }
    //     })
    //     let result = data?.getCustomerHistories?.data
    //     if (result.status === 'OK')
    //         return result.out

    //     return false
    // }

    const [keySelected, setKeySelected] = useState(false)

    // useEffect(() => {

    //     console.log('isOr', isOr)
    //   return () => {

    //   }
    // }, [isOr])


    const FilterItem = ({ filterKey, value, color = 'green', setIsOr }) => {
        // console.log(`filterKey`, filterKey)
        let diff = ""
        let v = value
        if (isArray(v)) v = v.join(',')
        let realKey = filterKey
        let eWidth = false
        if (filterKey.endsWith('_ne')) {
            realKey = filterKey.replace('_ne', '')
            color = "red"
            diff = (<strong>khác</strong>)
        }

        let compareSuff = ['_gte', '_gt', '_lte', '_lt']
        compareSuff.forEach(suff => {
            if (filterKey.endsWith(suff)) {
                eWidth = suff
                realKey = filterKey.replace(suff, '')
                color = "orange"
                let label = 'lớn hơn'
                if (eWidth === '_lt' || eWidth === '_lte') label = 'bé hơn'
                diff = (<strong>{label}</strong>)
                if (moment(v, moment.ISO_8601, true).isValid())
                    v = moment(v).format('DD/MM/YY')
            }
        });

        if (realKey === 'tags' || realKey === 'tag_group') {
            let hasOr = value.includes("||")
            if (hasOr) {
                v = value.replaceAll('||', ',')
                setIsOr(true)
            } else {
                v = v.replaceAll('|', ',')
                setIsOr(false)
            }
        }
        if (realKey === 'status.ref') {
            // console.log(`preloadTable`, preloadTable)
            if (preloadTable.status) {
                let s = preloadTable.status.find(st => st.key === v)
                if (s) {
                    v = s.name
                }
            }
        }
        setRKey(realKey)
        if (filterLabel[realKey]) {
            let vv = v.length > 20 ? v.substring(0, 20) + "..." : v
            return (
                <Tag
                    color={color}
                    closable
                    closeIcon={(<FunnelPlotOutlined />)}
                    onClose={e => {
                        e.preventDefault();
                        setKeySelected(filterKey)
                    }}
                >
                    {filterLabel[realKey]} : {diff} {vv}
                </Tag>
            )
        }
        return ""
    }


    if (!updateFilter.txt && (Object.keys(updateFilter.match).length < 1)) return ""
    else {
        // console.log('updateFilter.match', updateFilter.match)
    }
    // console.log('updateFilter.match[keySelected]', updateFilter.match[keySelected])
    return (
        <>
            <Space
                style={{ width: '100%', marginBottom: 10, background: "#fafafa", padding: 10, border: '1px solid #ccc' }}
            >
                {updateFilter?.txt ?
                    <FilterItem filterKey={'txt'} value={updateFilter?.txt} color={'blue'} />
                    : ""
                }
                {Object.entries(updateFilter.match).map(item => {
                    return <FilterItem filterKey={item[0]} value={item[1]} setIsOr={setIsOr} />
                })}

                {keySelected ?
                    <Modal
                        title={filterLabel[keySelected]}
                        visible={true}
                        onCancel={() => {
                            setKeySelected(false)
                        }}
                        footer={false}
                        width={400}>
                        <Space>
                            <Button type={'primary'} disabled={rKey === 'txt' || rKey === 'ngay_tao'} onClick={() => {
                                let v = updateFilter.match[keySelected]
                                if (updateFilter.match[keySelected]) {
                                    delete updateFilter.match[keySelected]
                                    // if (keySelected!='tags')
                                    let k = keySelected + '_ne'
                                    if (keySelected.endsWith('_ne'))
                                        k = keySelected.replace('_ne', '')
                                    updateFilter.match[k] = v

                                    let search = updateFilterParam(updateFilter, false)
                                    history.push({
                                        pathname: '/table/' + table_id,
                                        search: search,
                                    })
                                    setKeySelected(false)
                                }
                            }}>Đảo chiều</Button>
                            <Button type={'primary'} danger onClick={() => {
                                // console.log(`keySelected`, keySelected)
                                if (keySelected == 'txt') {
                                    if (updateFilter.txt) delete updateFilter.txt
                                    // }
                                    // else  if (keySelected == 'tags') {
                                    //     if (updateFilter.match?.tags) delete updateFilter.match.tags

                                } else {
                                    if (updateFilter.match[keySelected]) delete updateFilter.match[keySelected]
                                }
                                // console.log(`updateFilter`, updateFilter.txt)
                                let search = updateFilterParam(updateFilter, false)
                                // console.log(`search`, search)
                                // if (item?.key && item?.key != '-') {
                                //     search = updateFilterParam(updateFilter, { 'ship.ship_status.ref': item.key })
                                // } else {
                                //     search = updateFilterParam(updateFilter, { 'ship.ship_status.ref': false })
                                // }
                                history.push({
                                    pathname: '/table/' + table_id,
                                    search: search,
                                })
                                setKeySelected(false)
                            }}>Xóa bộ lọc</Button>
                            <Button onClick={() => {
                                setKeySelected(false)
                            }}>Đóng</Button>
                            {keySelected.startsWith('tag') &&
                                <Switch onChange={(v) => {
                                    setIsOr(v)
                                    let tags = updateFilter.match[keySelected]
                                    let t = tags.replaceAll('||', ',')
                                    t = t.replaceAll('|', ',')
                                    t = t.split(',')
                                    if (v) {
                                        updateFilter.match[keySelected] = t.join("||")
                                    } else {
                                        updateFilter.match[keySelected] = t.join("|")

                                    }
                                    let search = updateFilterParam(updateFilter, false)
                                    history.push({
                                        pathname: '/table/' + table_id,
                                        search: search,
                                    })
                                    setKeySelected(false)
                                    // setOverride(v)
                                }}
                                    checkedChildren="Hoặc"
                                    unCheckedChildren="Hoặc" defaultChecked={isOr} />
                            }
                        </Space>
                    </Modal>

                    : ""}
            </Space>
        </>
    )
}
export default inject(
    'datatableStore'
)(observer(FilterDetail))
