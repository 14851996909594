import React, {useEffect} from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { LogoutOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import { Link, useHistory } from 'react-router-dom';
const LogoutIcon = styled.div`
  font-size: 18px;
  color: #909090;
  cursor:pointer;
  transition: all 0.2s;
  &:hover {
    color: #c22883;
  }
  display:flex;
`

const Logout = (props) => {
  const { usersStore,commonStore } = props
  const history = useHistory()

  const logoutHandler = () => {
    usersStore.userLogout()
      .then(() => {
        Cookies.remove('access_token')
        history.push(`/`)
      })
  }

  return (
    <LogoutIcon onClick={() => logoutHandler()}>
      <LogoutOutlined />
    </LogoutIcon>
  )
}

export default inject('usersStore','commonStore')(observer(Logout))
