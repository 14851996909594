import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'

import {
    Divider,
    Button,
    List,
    Typography,
    Image,
    Avatar,
    Tag,
    message,
    Space,
    Row,
    Col
} from 'antd';
// import moment from 'moment'
import {
    AlignLeftOutlined,
    LinkOutlined,
} from '@ant-design/icons';

import { imagePreset, date_str } from '../../../ultils/datatableUtils'
import { jobType, jobStatus } from './JobType'

const JobNhatHang = ({
    datatableStore,
    commonStore,
    usersStore,
    onClick,
    job,
    rowData,
}) => {

    let jt = jobType.find(j => j.type === job.type)
    let jstatus = jobStatus.find(j => j.type === job.status)
    let uref = datatableStore.usersRef[job?.owner?.id]
    let ava = uref?.avatar
    return (
        <>
            {/* <strong>Nhiệm vụ: </strong> {jt?.name}
            <br />
            <Space>
                <Avatar size={32} src={ava} />
                {uref?.name}
                <Tag title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>
            </Space>
            <br />
            <br />
            <strong>Bắt đầu: </strong> {date_str(job?.createdAt)}
            <br />
            <strong>Kết thúc: </strong> {date_str(job?.jdata?.done_time)}

            <br /> */}

            <List
                header={<div style={{ backgroundColor: jt.bgcolor, color: "#fff", padding: 10, borderRadius: 16 }}> <strong>Nhiệm vụ: </strong> {jt?.name}</div>}
                footer={
                    usersStore.currentUser.id === job.owner.id || ['quan-ly', 'chu-cong-ty'].includes(usersStore.currentUser.company_role) ?
                        <div>
                            <Space>
                                <Button size={"small"} type={"primary"}>Hoàn thành</Button>
                                <Button size={"small"} style={{ backgroundColor: "orange" }}>Tạm hoãn</Button>
                                <Button size={"small"} danger
                                    onClick={() => {
                                        if (usersStore.currentUser.id !== job.owner.id) {
                                            alert('Chỉ có người tạo task mới đc xóa!')
                                            return false
                                        }
                                        alert('ok')
                                    }}
                                >Xóa</Button>
                            </Space>
                        </div> : false
                }
                bordered
            >
                <List.Item>
                    <Space>
                        <Avatar size={32} src={ava} />
                        {uref?.name}
                        <Tag title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>
                    </Space>
                </List.Item>
                <List.Item>
                    <strong>Bắt đầu: </strong> {date_str(job?.createdAt)}
                </List.Item>
                <List.Item>
                    <strong>Kết thúc: </strong> {date_str(job?.jdata?.done_time)}
                </List.Item>
            </List>
        </>
        // <List.Item>
        //     <List.Item.Meta
        //         onClick={onClick}
        //         avatar={<Avatar style={{ backgroundColor: jt.bgcolor }} shape="square" size={64}>{jt.name}</Avatar>}
        //         // avatar={<Avatar style={{ backgroundColor: jt.bgcolor } } shape="square" size={64}>{jt.name}</Avatar>}
        //         // avatar={
        //         //     <Image
        //         //         width={64}
        //         //         height={64}
        //         //         // src={config.ASSET_URL + "users/" + job.owner.id + ".jpg"}
        //         //         src={uref?.avatar}
        //         //     />}
        //         // title={jt.name}
        //         title={
        //             <Space>
        //                 <Avatar size={32} src={ava} />
        //                 {uref?.name}
        //             </Space>

        //         }
        //         description={
        //             <>
        //                 <strong>Trạng thái: </strong> <Tag title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>
        //                 <br />
        //                 <strong>Bắt đầu: </strong> {date_str(job?.createdAt)}
        //                 <br />
        //                 <strong>Kết thúc: </strong> {date_str(job?.jdata?.done_time)}
        //             </>

        //         }
        //     />
        // </List.Item>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(JobNhatHang))