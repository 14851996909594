import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import moment from 'moment'
import {
    Modal,
    Dropdown,
    Menu,
    Space,
    Button,
    Row,
    Col,
    Card,
    Tag,
    message,
    notification
} from 'antd'
import {
    ExclamationCircleOutlined,
    SyncOutlined,
    DeleteOutlined,
    DownOutlined,
    ClockCircleOutlined,
    CopyOutlined,
    BarcodeOutlined,
    IdcardOutlined,
    PlusOutlined,
    SendOutlined,
    CarOutlined,
    CloseCircleOutlined,
    DeploymentUnitOutlined,
    PrinterOutlined,
    CommentOutlined
} from '@ant-design/icons'
import { contrastColor } from 'contrast-color'
import { Tags_render } from '../DataTools/DataField'

import useAsyncQuery from '../../../../ultils/useAsyncQuery'
// import moment from 'moment'
import { flatMap, merge as LMerge } from 'lodash-es'
// import { autorun, set, toJS } from 'mobx'

// import { nanoid } from 'nanoid'

// import { buildFilterByParam, updateFilterParam } from '../../../../ultils/datatableUtils'
// import DefaultLayout from '../../../layouts/DefaultLayout'
// import StyledLoadingOverlay from '../../../common/StyledLoadingOverlay'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'

import OrderItems from './Order/OrderItems'
import OrderEditCustomer from './Order/OrderEditCustomer'
import OrderEditInfo from './Order/OrderEditInfo'
import AssignUser from '../DataTools/AssignUser'
import { price_k, getDiffInfo, getPRefByOrderItems } from '../../../../ultils/datatableUtils'
import CustomerHistories from './Order/CustomerHistories'
import RowHistories from './Order/RowHistories'
import OrderSearchBox from './Order/OrderSearchBox'
import OrderSplitModal from './Order/OrderSplitModal'
import CustomerPanel from './Order/CustomerPanel'

const { confirm } = Modal;

function myFlat(v) {
    return Object.assign(v.row_data, { key: v.id })
}

const TopBarContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`
// const defaultLimit = 50

const DataRowOrder = ({
    usersStore,
    commonStore,
    datatableStore,
    orderData }) => {

    const [showEditCustomer, setShowEditCustomer] = useState(false)
    const [printLink, setPrintLink] = useState(false)

    const [showEditInfo, setShowEditInfo] = useState(false)
    const [showMergeOrder, setShowMergeOrder] = useState(false)
    const [showSplitOrder, setShowSplitOrder] = useState(false)


    const [mergeLoading, setMergeLoading] = useState(false)
    const [newLoading, setNewLoading] = useState(false)

    const [prefs, setPrefs] = useState({})
    // const [datatableStore.rowDetail, setdatatableStore.rowDetail] = useState(orderData.row_data)
    const [getProductByCodes] = useMutation(DataTableServices.GET_PRODUCTS_BY_CODES(), {
        errorPolicy: 'all',
    })

    // const [copyOrder] = useMutation(
    //     DataTableServices.COPY_ORDER(),
    //     {
    //         errorPolicy: 'all'
    //     }
    // )

    const [cleanCopyOrder] = useMutation(
        DataTableServices.CLEAN_COPY_ORDER(),
        {
            errorPolicy: 'all'
        }
    )


    const [doShip] = useMutation(
        DataTableServices.DO_SHIP(),
        {
            errorPolicy: 'all'
        }
    )

    const [cancelShip] = useMutation(
        DataTableServices.CANCEL_SHIP(),
        {
            errorPolicy: 'all'
        }
    )

    const [getPrintToken] = useMutation(
        DataTableServices.GET_PRINT_TOKEN(),
        {
            errorPolicy: 'all'
        }
    )

    const [mergeOrder] = useMutation(
        DataTableServices.MERGE_ORDER(),
        {
            errorPolicy: 'all'
        }
    )

    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, myFlat)
        return items
    }


    useEffect(() => {

        async function fetchData() {
            let statusData = await findRowsByFilter(
                {
                    tableId: commonStore?.company?.companyData?.status_table,
                    // tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
                    hasPreSearch: false,
                    limit: 500,
                    // filter: { match: { "category": 'Trạng thái đơn' } }
                }
            )


            let tagData = await findRowsByFilter(
                {
                    tableId: commonStore?.company?.companyData?.tag_table,
                    // tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
                    hasPreSearch: false,
                    limit: 500,
                    filter: { match: { "category": ['Đơn hàng', 'Sản phẩm', 'Resource'] } }
                }
            )

            let branchData = await findRowsByFilter(
                {
                    tableId: commonStore?.company?.companyData?.branch_table,
                    // tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
                    hasPreSearch: false,
                    limit: 500,
                    // filter: { match: { "category": '' } }
                }
            )

            datatableStore.setPreloadTable('status', statusData)
            datatableStore.setPreloadTable('tags', tagData)
            datatableStore.setPreloadTable('shipStatus', statusData.filter(item => item.category === 'Vận chuyển'))
            // datatableStore.setPreloadTable('paymentStatus', statusData.filter(item => item.category === 'Thanh toán'))
            datatableStore.setPreloadTable('branches', branchData)
            let outPref = await getPRefByOrderItems(orderData.row_data, commonStore?.company?.companyData?.product_table, getProductByCodes)
            // console.log('outPref', outPref)
            setPrefs(outPref)
            // if (orderData.row_data?.customer?.sl_code) {
            // console.log('orderData', orderData)
            // }
            datatableStore.setRowDetail(orderData.row_data)
        }
        let pageTitle = orderData.row_data?.customer?.name
        if (orderData.row_data?.customer?.mobile) pageTitle += ' : ' + orderData.row_data?.customer?.mobile
        commonStore.setCurrentPageTitle(pageTitle)
        // console.log(`orderData`, orderData)
        fetchData()

    }, [])

    useEffect(() => {
    }, [datatableStore.rowDetail])

    let history = useHistory();

    // const location = useLocation();

    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )

    const AdminActionMenu = (
        <Menu>
            <Menu.Item key="sync-datasource" icon={<SyncOutlined />}>
                Đồng bộ excel
            </Menu.Item>
            <Menu.Item key="delete-all" icon={<DeleteOutlined />}>
                Xóa toàn bộ dữ liệu
            </Menu.Item>
        </Menu>
    )

    const AdminTopBar = () => (
        <Space>
            <Dropdown overlay={AdminActionMenu}>
                <Button size="small">
                    Hệ thống <DownOutlined />
                </Button>
            </Dropdown>
        </Space>
    )

    const LeftBar = () => {
        const [removeLoading, setRemoveLoading] = useState(false)
        return (
            <div style={{ display: 'flex' }}>
            </div>
        )
    }

    const RightBar = () => {
        const [loading, setLoading] = useState(false)
        return (
            <div style={{ display: 'flex' }}>
                <Space>
                    {/* <AssignUser /> */}
                    {/* <Button
                        size={'small'}
                        onClick={() => {
                            confirm({
                                width: 480,
                                title: 'Bạn có chắc chắn sao chép đơn này không?',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Sap chép đơn',
                                okType: 'success',
                                cancelText: 'Xem lại',
                                confirmLoading: { loading },
                                // content: (
                                //     <>  {txt ?
                                //         <>
                                //             {txt}<br />
                                //             {invalidRow.map(r => {
                                //                 return (
                                //                     <>
                                //                         {`${r.name} - ${r.mobile}`}
                                //                         <br />
                                //                     </>
                                //                 )
                                //             })}
                                //         </> : ""}

                                //     </>
                                // ),
                                async onOk() {
                                    const input = {
                                        data: {
                                            mainId: orderData.id
                                        }
                                    }
                                    setLoading(true)
                                    let r = await copyOrder({
                                        variables: { input }
                                    })
                                    setLoading(false)
                                    let result = r.data.copyOrder?.data
                                    if (result.status === 'OK') {
                                        let id = result.out.id
                                        if (id) {
                                            var win = window.open('/row/' + id, '_blank');
                                            win.focus();
                                        }
                                        // message.success('Gộp đơn thành công!!')
                                        // datatableStore.setRowDetail(result.out)

                                    } else {
                                        message.error('Có lỗi khi copy đơn. Hãy thử lại!')
                                    }
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}
                        icon={<CopyOutlined />}
                    >
                        Sao chép
                    </Button> */}
                    {/* <Button
                        size={'small'}
                        onClick={() => {
                            setShowSplitOrder(true)
                        }}
                        icon={<CopyOutlined />}
                    >
                        Tách đơn
                    </Button> */}
                    <Button
                        size={'small'}
                        onClick={() => {
                            setShowMergeOrder(true)
                        }}
                        icon={<DeploymentUnitOutlined />}
                    >
                        Gộp đơn
                    </Button>
                    {/* <Button
                        size={'small'}
                        onClick={() => {
                            confirm({
                                width: 480,
                                title: 'Bạn có chắc muốn tạo đơn mới với cùng khách hàng này ko?',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Tạo mới',
                                okType: 'success',
                                cancelText: 'Xem lại',
                                confirmLoading: { newLoading },
                                // content: (
                                //     <>  {txt ?
                                //         <>
                                //             {txt}<br />
                                //             {invalidRow.map(r => {
                                //                 return (
                                //                     <>
                                //                         {`${r.name} - ${r.mobile}`}
                                //                         <br />
                                //                     </>
                                //                 )
                                //             })}
                                //         </> : ""}

                                //     </>
                                // ),
                                async onOk() {
                                    const input = {
                                        data: {
                                            mainId: orderData.id
                                        }
                                    }
                                    setNewLoading(true)
                                    let r = await cleanCopyOrder({
                                        variables: { input }
                                    })
                                    setNewLoading(false)
                                    let result = r.data.cleanCopyOrder?.data
                                    if (result.status === 'OK') {
                                        let id = result.out.id
                                        if (id) {
                                            var win = window.open('/row/' + id, '_blank');
                                            win.focus();
                                        }
                                        // message.success('Gộp đơn thành công!!')
                                        // datatableStore.setRowDetail(result.out)

                                    } else {
                                        message.error('Có lỗi khi tạo đơn. Hãy thử lại!')
                                    }
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}
                        icon={< PlusOutlined />}
                    >
                        Thêm mới cùng KH
                    </Button> */}
                </Space>
            </div>
        )
    }

    function showEdit() {
        setShowEditCustomer(true)
    }

    function onCloseEdit() {
        setShowEditCustomer(false)
    }

    const openNotification = (title, msg) => {
        const args = {
            message: title,
            description: msg,
            duration: 0,
            icon: <CloseCircleOutlined style={{ color: 'red' }} />,
            placement: 'bottomRight'
        };
        notification.open(args);
    };



    async function sendToCarrier() {
        let input = {
            data: {
                order_id: orderData.id
            }
        }
        let r = await doShip({
            variables: { input }
        })
        if (r.data?.doShip?.data?.status === 'OK') {
            let updateOrders = r.data.doShip.data.orders
            if (updateOrders.length === 1) {
                if (updateOrders[0].row_data) {
                    orderData.row_data = updateOrders[0].row_data
                    datatableStore.setRowDetail(updateOrders[0].row_data)
                    return updateOrders[0].row_data
                } else {
                    openNotification('Lỗi gửi vận chuyển', updateOrders[0].msg, 'error')
                    // message.error(updateOrders[0].msg);
                }
            }
        } else {
            message.error(r?.data?.doShip?.data?.msg);
        }
    }

    async function cancelOrderShip() {
        let input = {
            data: {
                order_id: orderData.id
            }
        }
        let r = await cancelShip({
            variables: { input }
        })
        if (r.data?.cancelShip?.data?.status === 'OK') {
            let updateRow = r.data.cancelShip.data.row_data
            if (updateRow) {
                // console.log(`updateRow`, updateRow)
                orderData.row_data = updateRow
                datatableStore.setRowDetail(updateRow)
                return true
            } else {
                openNotification('Lỗi hủy vận chuyển', r?.data?.cancelShip?.data?.msg, 'error')
            }
        } else {
            message.error(r?.data?.cancelShip?.data?.msg);
        }
        return false
    }


    // useEffect(() => {
    //     console.log(`datatableStore.rowDetail`, datatableStore.rowDetail)
    // }, [datatableStore.rowDetail.order_code])

    const SendButton = () => {
        const [loading, setLoading] = useState(false)
        if (datatableStore.rowDetail?.order_code) {
            return (
                <Space>
                    <Button
                        icon={<PrinterOutlined />}
                        size={'small'}
                        onClick={doPrint}>
                    </Button>

                    <Button
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        size={'small'}
                        onClick={() => {
                            // setLoading(true)
                            // await cancelOrderShip()
                            // Modal.destroyAll();
                            // setLoading(false)
                            confirm({
                                title: 'Bạn có chắc chắn muốn hủy đơn vận chuyển này không?',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Hủy vận chuyển',
                                okType: 'danger',
                                cancelText: 'Bỏ qua',
                                async onOk() {
                                    setLoading(true)
                                    await cancelOrderShip()
                                    Modal.destroyAll();
                                    setLoading(false)
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}>
                        Hủy vận chuyển
                    </Button>

                    <a style={{ background: "#03BF68", color: "#ffffff", display: "block", padding: '2px 5px' }} href={`https://donhang.ghn.vn/?order_code=${datatableStore.rowDetail?.order_code}`} target="_blank"> {datatableStore.rowDetail?.order_code}</a>
                    {/* <Button
                        icon={<RocketOutlined />}
                        type={'primary'}
                        size={'small'}
                        onClick={() => {

                        }}>
                        {datatableStore.rowDetail?.order_code}
                    </Button> */}
                </Space>
            )
        }
        return (
            <Button
                loading={loading}
                disabled={!datatableStore.rowDetail?.ship?.weight || !datatableStore.rowDetail?.district || !datatableStore.rowDetail?.ward || loading}
                icon={<SendOutlined />}
                size={'small'}
                onClick={async () => {
                    if (datatableStore.rowDetail.ship.cod !== datatableStore.rowDetail.tong_tra) {
                        confirm({
                            title: 'COD khác tổng tiền phải trả ! Bạn vẫn muốn gửi đơn ?',
                            icon: <ExclamationCircleOutlined />,
                            okText: 'Gửi đơn',
                            okType: 'danger',
                            cancelText: 'Bỏ qua',
                            async onOk() {
                                setLoading(true)
                                await sendToCarrier()
                                setLoading(false)
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    } else {
                        setLoading(true)
                        await sendToCarrier()
                        setLoading(false)
                    }
                }}
            >
                Gửi hàng
            </Button >
        )
    }

    async function doPrint() {
        if (printLink) {
            setPrintLink(false)
        }
        if (datatableStore.rowDetail?.order_code) {
            let input = {
                data: {
                    order_codes: [datatableStore.rowDetail?.order_code]
                }
            }
            let r = await getPrintToken({
                variables: { input }
            })
            let pLink = false
            if (r?.data?.getPrintToken?.data?.printLinks && r.data.getPrintToken.data.printLinks.length == 1) {
                pLink = r.data.getPrintToken.data.printLinks[0]
            }
            // console.log(`printLink`, printLink)
            if (pLink) {
                // let link = "https://dev-online-gateway.ghn.vn/a5/public-api/printA5?token=" + r?.data?.getPrintToken?.data?.token
                setPrintLink(pLink)
            }
        }

    }
    // useEffect(() => {
    //     console.log(`orderData.row_data.order_code`, orderData.row_data.order_code)

    // }, [orderData.row_data.order_code])

    // const OrderTags = ({ tags }) => {
    //     return (
    //         <>
    //             {tags.map(tag => <Tag color={tag.color} key={tag.name || tag}>{tag.name || tag}</Tag>)}
    //         </>
    //     )
    // }
    // if (error) return <StyledLoadingOverlay />
    return (
        <>
            <TopBarContainer>
                {/* <LeftBar /> */}
                <RightBar />
                <hr />
            </TopBarContainer>
            <div id="rowOrder">
                {/* <Button onClick={() => {
                    setShowEditCustomer(true)
                }}>abc</Button> */}

                {datatableStore.preloadTable?.tags ?
                    <Row gutter={[16, 16]}>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Card size="small"
                                title={<div><BarcodeOutlined style={{ marginRight: 10 }} />Thông tin đơn hàng</div>}
                                extra={<Button size={'small'} onClick={() => {
                                    setShowEditInfo(true)
                                }}>
                                    Sửa</Button>}>
                                <p><b>Mã đơn: </b>{datatableStore.rowDetail?.code}
                                    {orderData?.row_data?.src === 'slive' ?
                                        <span style={{ marginLeft: 10 }}>
                                            / <a href={`https://admin.shoplineapp.com/admin/nhaiuvn797/orders?page=1&limit=100&search_fields=default&createdBy=admin&query=${datatableStore.rowDetail?.code}`} target="_blank">link Shopline</a>
                                        </span>
                                        :
                                        ""}
                                </p>

                                <p><b>Ngày tạo: </b>{moment(datatableStore.rowDetail?.ngay_tao).format('DD/MM/YY HH:mm')} </p>
                                <p><b>Trạng thái: </b>
                                    <Tag color={datatableStore.rowDetail?.status?.color} style={{ color: contrastColor({ bgColor: datatableStore.rowDetail?.status?.color }) }}  >{datatableStore.rowDetail?.status?.name}</Tag></p>

                                {datatableStore.rowDetail?.tags ? <p><b>Thẻ đơn hàng : </b><Tags_render tags={datatableStore.rowDetail?.tags} preloadTable={datatableStore.preloadTable.tags} /></p> : ""}

                                {datatableStore.rowDetail?.tag_vitri ? <p><b>Thẻ vị trí : </b><Tags_render tags={datatableStore.rowDetail?.tag_vitri} preloadTable={datatableStore.preloadTable.tags} subCate="Tag vị trí" hasColor={true} /></p> : ""}

                                {/* {datatableStore.rowDetail?.pay_status ? (<p><b>Trạng thái thanh toán : </b>
                                    <Tag color={datatableStore.rowDetail?.pay_status?.color} style={{ color: contrastColor({ bgColor: datatableStore.rowDetail?.pay_status?.color }) }}  >{datatableStore.rowDetail?.pay_status?.name}</Tag></p>)
                                    : ""} */}

                                {datatableStore.rowDetail?.luot_nhat && <p><b>Lượt nhặt : </b>{datatableStore.rowDetail?.luot_nhat}</p>}

                                {/* {datatableStore.rowDetail?.branch && <p><b>Chi nhánh gửi : </b>{datatableStore.rowDetail?.branch}</p>} */}



                                {datatableStore.rowDetail?.note ? <p style={{ color: "orange" }}><b>Ghi chú: </b>{datatableStore.rowDetail?.note}</p> : ""}
                                {datatableStore.rowDetail?.note_vitri ? <p style={{ color: "blue" }}><b>Ghi chú vị trí: </b>{datatableStore.rowDetail?.note_vitri}</p> : ""}

                                <p>
                                    <b>Tổng tiền phải trả: </b>{price_k(datatableStore.rowDetail?.tong_tra ? datatableStore.rowDetail.tong_tra : 0, '?', 3)}
                                    {datatableStore.rowDetail?.ship?.cod ?

                                        <span
                                            style={{
                                                color: (datatableStore.rowDetail?.pay_status?.name === 'Đã thanh toán' && datatableStore.rowDetail.ship.cod > 0) ? "red" : "green"
                                            }}>
                                            /  COD: <strong>{price_k(datatableStore.rowDetail?.ship?.cod, '?', 3)}</strong>
                                        </span> : ""}
                                </p>

                                {/* <p><b>Buổi livestream: </b>{datatableStore.rowDetail?.sale_event?.sale_event_name || '?'} </p> */}
                                {datatableStore.rowDetail?.conversation_id &&
                                    <>
                                        <Button icon={<CommentOutlined />}
                                            onClick={
                                                () => {
                                                    window.open(`https://message-center.shoplineapp.com/admin/6064325faa6692002f7231bc/instant?conversation_id=${datatableStore.rowDetail?.conversation_id}&locale=vi`)
                                                }
                                            }

                                        >Chat với khách</Button>
                                    </>

                                }
                            </Card>
                            <br />
                            {datatableStore.rowDetail?.customer?.sl_code ?
                                <>
                                    <CustomerPanel
                                        row_id={orderData?.id}
                                        rowDetail={datatableStore.rowDetail}
                                        showEdit={showEdit}
                                        table_id={commonStore?.company?.companyData?.customer_table}
                                    />
                                </>
                                :
                                <Card size="small"
                                    title={<div><IdcardOutlined style={{ marginRight: 10 }} />Khách hàng</div>}
                                    extra={<Button size={'small'} onClick={showEdit}>
                                        Sửa</Button>}>
                                    <p><b>Tên khách: </b>{datatableStore.rowDetail?.customer?.name}
                                        {/* {orderData?.row_data?.customer?.sl_code ?
                                    <span style={{ marginLeft: 10 }}>
                                        / <a href={`https://admin.shoplineapp.com/admin/nhaiuvn797/users/${datatableStore.rowDetail?.customer.sl_code}`} target="_blank">link Shopline</a>
                                    </span>
                                    :
                                    ""} */}
                                    </p>
                                    {datatableStore.rowDetail?.customer?.sl_user ? <p style={{ color: "green" }}><b>Shoplive user: </b>{datatableStore.rowDetail?.customer.sl_user}                                  {orderData?.row_data?.customer?.sl_code ?
                                        <span style={{ marginLeft: 10 }}>
                                            / <a href={`https://admin.shoplineapp.com/admin/nhaiuvn797/users/${datatableStore.rowDetail?.customer.sl_code}`} target="_blank">link Shopline</a>
                                        </span>
                                        :
                                        ""}</p> : ""}

                                    <p><b>Điện thoại: </b>{datatableStore.rowDetail?.customer?.mobile}</p>
                                    <p><b>Địa chỉ: </b>{datatableStore.rowDetail?.customer?.address}</p>
                                    <p><b>Thành phố/tỉnh: </b>{datatableStore.rowDetail?.province?.ProvinceName || datatableStore.rowDetail?.ship?.sl_province}</p>
                                    <p><b>Quận/huyện: </b>{datatableStore.rowDetail?.district?.DistrictName || datatableStore.rowDetail?.ship?.sl_district}</p>
                                    <p><b>Xã/phường: </b>{datatableStore.rowDetail?.ward?.WardName || datatableStore.rowDetail?.ship?.sl_ward}</p>
                                    <br />
                                    {datatableStore.rowDetail?.branch &&
                                        <p><b>Chi nhánh gửi :</b>
                                            {datatableStore.rowDetail.branch}
                                            {/* <Tag color={datatableStore.rowDetail?.pay_status?.color} style={{ color: contrastColor({ bgColor: datatableStore.rowDetail?.pay_status?.color }) }}  >{datatableStore.rowDetail.branch}</Tag> */}
                                        </p>
                                    }
                                </Card>
                            }

                            <br />
                            <Card size="small"
                                title={<div><CarOutlined style={{ marginRight: 10 }} />Vận chuyển</div>}
                                extra={
                                    <SendButton />
                                    // orderData.row_data?.order_code ?
                                    //     <Space>
                                    //         <Button
                                    //             icon={<PrinterOutlined />}
                                    //             size={'small'}
                                    //             onClick={doPrint}>
                                    //         </Button>
                                    //         <Button
                                    //             icon={<RocketOutlined />}
                                    //             type={'primary'}
                                    //             size={'small'}
                                    //             onClick={() => {

                                    //             }}>
                                    //             {orderData.row_data?.order_code}
                                    //         </Button>
                                    //     </Space>
                                    //     :

                                    //     <Button
                                    //         disabled={!orderData.row_data?.ship?.weight || !orderData.row_data?.district || !orderData.row_data?.ward}
                                    //         icon={<SendOutlined />}
                                    //         size={'small'}
                                    //         onClick={sendToCarrier}>
                                    //         Gửi hàng
                                    //     </Button>
                                }
                            >
                                <p><b>Tình trạng giao hàng: </b>
                                    <Tag color={datatableStore.rowDetail?.ship?.ship_status?.color} style={{ color: contrastColor({ bgColor: datatableStore.rowDetail?.ship?.ship_status?.color }) }}  >{datatableStore.rowDetail?.ship?.ship_status?.name}</Tag></p>

                                <p><b>Phí ship thực: </b>{price_k(datatableStore.rowDetail?.order_ship_fee, '?', 3)}</p>
                                <p><b>Cân nặng: </b>{datatableStore.rowDetail?.ship?.weight} kg</p>
                                <p><b>Phương thức giao hàng: </b>{datatableStore.rowDetail?.ship?.ship_method ? datatableStore.rowDetail.ship.ship_method : "?"}</p>
                                <p><b>Địa chỉ giao hàng: </b>{datatableStore.rowDetail?.customer?.address || datatableStore.rowDetail?.ship?.sl_address_all}</p>
                                <p style={{ color: "orange" }} > <b>Ghi chú giao hàng: </b>{datatableStore.rowDetail?.ship?.ship_note}</p>

                            </Card>
                            <br />
                            <Card size="small"
                                title={<div><ClockCircleOutlined style={{ marginRight: 10 }} />Lịch sử đặt hàng</div>}
                            >
                                {datatableStore.preloadTable.status && datatableStore.preloadTable.tags ?
                                    <CustomerHistories customer={orderData?.row_data?.customer} preloadTable={toJS(datatableStore.preloadTable)} />
                                    : ""}
                            </Card>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <OrderItems prefs={prefs} currentOrder={datatableStore.rowDetail} orderId={orderData?.id} />
                            {/* <Card size="small"
                                        title={<div><ShoppingCartOutlined style={{ marginRight: 10 }} />Giỏ hàng</div>}
                                        extra={<a href="#">More</a>}>
                                    </Card> */}

                            <br />
                            <Card size="small"
                                title={<div><ClockCircleOutlined style={{ marginRight: 10 }} />Lịch sử thay đổi</div>}
                            >
                                {datatableStore.preloadTable.status && datatableStore.preloadTable.tags && datatableStore.rowDetail ?
                                    <RowHistories row_id={orderData?.id} table_id={orderData.datatable.id} preloadTable={toJS(datatableStore.preloadTable)} />
                                    : ""}
                            </Card>

                        </Col>
                    </Row>
                    : ""}


            </div>
            {
                showEditCustomer ? <OrderEditCustomer orderData={datatableStore.rowDetail} table={commonStore?.company?.companyData?.order_table} onClose={onCloseEdit}
                    onSave={(values) => {
                        let order = toJS(datatableStore.rowDetail)
                        order.ship.carrier = values.carrier
                        order.ship.weight = values.weight
                        order.ward = values.ward
                        order.district = values.district
                        order.province = values.province

                        order.customer.name = values.name
                        order.customer.mobile = values.mobile
                        order.customer.address = values.address
                        order.ship.cod = values.cod
                        order.branch = values.branch

                        if (values.status) {
                            order.status = values.status
                        }
                        // if (order.ship?.cod !== values.cod) {
                        //     order.isUpdateCOD = true
                        //     order.ship.cod = values.cod
                        // }
                        order.key = orderData?.id
                        // console.log(`order`, order)
                        let d = getDiffInfo(toJS(datatableStore.rowDetail), order)
                        // console.log(`d`, d)
                        let r = updateManyDataRows({
                            variables: {
                                input: {
                                    data: {
                                        type: "oldnew",
                                        rows: [d]
                                    }
                                }
                            }
                        })
                        datatableStore.setRowDetail(order)
                        setShowEditCustomer(false)
                    }}
                />
                    : ""
            }

            {
                showEditInfo ? <OrderEditInfo orderData={datatableStore.rowDetail} table={commonStore?.company?.companyData?.order_table}
                    onSave={(values) => {
                        // console.log(`values`, values)
                        let order = toJS(datatableStore.rowDetail)
                        order.status = values.status
                        order.pay_status = values.pay_status
                        order.note = values.note
                        order.note_vitri = values.note_vitri
                        order.ship.ship_fee = values.phiship
                        order.giam_gia = values.giam_gia
                        order.chuyenkhoan = values.chuyenkhoan
                        order.tags = values.tags
                        order.luot_nhat = values.luot_nhat
                        // order.tong_tra = values.tong_tra
                        // let order = orderData?.row_data
                        order.key = orderData?.id
                        let d = getDiffInfo(toJS(datatableStore.rowDetail), order)
                        // console.log(`d`, d)
                        let r = updateManyDataRows({
                            variables: {
                                input: {
                                    data: {
                                        type: "oldnew",
                                        rows: [d]
                                    }
                                }
                            }
                        })
                        // console.log(`order`, order)
                        datatableStore.setRowDetail(order)
                        setShowEditInfo(false)
                    }}

                    onClose={() => { setShowEditInfo(false) }} />
                    : ""
            }

            {
                printLink ?
                    <iframe src={printLink} title="title" hidden></iframe>
                    :
                    ""
            }

            {
                showMergeOrder ?
                    <OrderSearchBox
                        table_id={commonStore?.company?.companyData?.order_table}
                        title="Chọn đơn gộp"
                        initOrder={{ ...orderData.row_data, ...{ key: orderData.id } }}
                        placeholder="Tên KH /Mã đơn / SĐT"
                        defaultValue={orderData.row_data?.customer?.mobile}
                        onClose={() => {
                            setShowMergeOrder(false)
                        }}
                        onOk={(selectedKey, selectedRow) => {
                            // let selectedRow = rows.filter(r => selectedKey.includes(r.key))
                            // console.log(`initOrder`, initOrder)
                            let invalidRow = selectedRow.filter(r => r.customer?.mobile !== orderData.row_data?.customer.mobile)
                            let txt = false
                            if (invalidRow.length > 0) {
                                txt = 'Các đơn sau không cùng số điện thoại với đơn được gộp'
                                invalidRow = invalidRow.map(r => {
                                    return { name: r.customer?.name, mobile: r.customer?.mobile }
                                })
                            }
                            confirm({
                                width: 480,
                                title: 'Bạn có chắc chắn muốn gộp đơn không?',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Gộp đơn',
                                okType: 'success',
                                cancelText: 'Xem lại',
                                confirmLoading: { mergeLoading },
                                content: (
                                    <>  {txt ?
                                        <>
                                            {txt}<br />
                                            {invalidRow.map(r => {
                                                return (
                                                    <>
                                                        {`${r.name} - ${r.mobile}`}
                                                        <br />
                                                    </>
                                                )
                                            })}
                                        </> : ""}

                                    </>
                                ),
                                async onOk() {
                                    const input = {
                                        data: {
                                            mainId: orderData.id,
                                            mergeIds: selectedKey
                                        }
                                    }
                                    setMergeLoading(true)
                                    let r = await mergeOrder({
                                        variables: { input }
                                    })
                                    setMergeLoading(false)
                                    let result = r.data.mergeOrder?.data
                                    if (result.status === 'OK') {
                                        message.success('Gộp đơn thành công!!')
                                        datatableStore.setRowDetail(result.out)
                                        setShowMergeOrder(false)
                                    } else {
                                        message.error('Có lỗi khi gộp đơn. Hãy thử lại!')
                                    }
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}

                    />
                    :
                    ""
            }

            {
                showSplitOrder ?
                    <OrderSplitModal
                        table_id={commonStore?.company?.companyData?.order_table}
                        title="Tách đơn"
                        // initOrder={{ ...orderData.row_data, ...{ key: orderData.id } }}
                        orderData={orderData}
                        onClose={() => {
                            setShowSplitOrder(false)
                        }}
                    />
                    :
                    ""
            }

            {/* {
                printLinks &&
                <>
                    {printLinks.map(printLink => {
                        return <iframe src={printLink} title="title" hidden></iframe>
                    })}
                </>
            } */}
        </>
    )
}

export default inject(
    'usersStore',
    'commonStore',
    'datatableStore'
)(observer(DataRowOrder))
