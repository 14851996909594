import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '../../layouts/DefaultLayout'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'
import DataTableServices from '../../../services/datatableServices'
import { useMutation } from '@apollo/react-hooks'
import useAsyncQuery from '../../../ultils/useAsyncQuery'
import QuantityModal from './QuantityModal'
import DatarowSelectInput from '../DataBoard/DataTools/DatarowSelectInput'
import { imagePreset } from '../../../ultils/datatableUtils'

import {
    message,
    Space,
    Button,
    Switch,
    Radio,
    Drawer,
    Image,
    List, Divider,
    Row, Col
} from 'antd'

import {
    PlusOutlined,
    MinusOutlined,
    ReloadOutlined,
    HistoryOutlined
} from '@ant-design/icons'
import { flatMap, merge as LMerge, intersection } from 'lodash-es'
import moment from 'moment'

const QuickAddProducts = ({ usersStore, commonStore, datatableStore, history }) => {
    // const [updateManyDataRows] = useMutation(
    //     DataTableServices.UPDATE_MANY_ROWS(),
    //     {
    //         errorPolicy: 'all'
    //     }
    // )
    // const [currentData, setCurrentData] = useState(false)

    // const [tagVitri, setTagVitri] = useState(false)
    const [isResetTag, setIsResetTag] = useState(false)
    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, (v) => { return Object.assign(v.row_data, { key: v.id }) })
        return items
    }

    useEffect(() => {
        commonStore.setCurrentPageTitle('Gom sp')
        // async function init() {
        //     let branchData = await findRowsByFilter(
        //         {
        //             tableId: commonStore?.company?.companyData?.branch_table,
        //             hasPreSearch: false,
        //             limit: 100,
        //         }
        //     )
        //     setBranches(branchData)
        // }
        // if (!branches && commonStore.company)
        //     init()
    }, [commonStore.company])


    // useEffect(() => {

    //     console.log('tagVitri', tagVitri)
    //     return () => {

    //     }
    // }, [tagVitri, branches])

    return (
        <DefaultLayout>
            <SearchBoxItem
                hasPreSearch={false}
                autoFocus={true}
                clearAfterSelect={true}
                className='searchMedium'
                autoClearSearchValue={true}
                width={200}
                tableId={commonStore?.company?.companyData?.product_table}
                filterOnDropdown={true}
                columns={['code', 'name']}
                displayColumn={['code', 'ngay_kiem', 'sl_tonkho', 'name']}
                quickSelectOne={true}
                onSelect={async (v, o) => {
                    let rData = o?.data?.row_data
                    if (rData) rData.id = o.key
                    if (rData?.id) {
                        datatableStore.addProduct2Cart([rData])
                        datatableStore.setDoFocusKiemSL(true)
                    }
                    // window.location.reload(false);
                }}
                onEnter={(v) => {
                    //   console.log('v', v)
                    //   alert(v)
                }}
            />
            <Divider />
            <List
                style={{ padding: 10 }}
                itemLayout="horizontal"
                dataSource={datatableStore.product_cart}
                renderItem={item => {
                    let label = ""
                    let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
                    let imageSrc = item.image
                    if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
                    else {
                        thumbnail = imagePreset(imageSrc, 'xsmall')
                    }
                    return (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Image
                                    width={40}
                                    height={40}
                                    src={thumbnail}
                                    preview={{
                                        src: imageSrc,
                                    }}
                                />}
                                title={item?.name}
                                description={
                                    <>
                                        Vị trí : {item.tag_vitri}
                                    </>
                                }
                            />
                        </List.Item>
                    )

                }

                }
            />

        </DefaultLayout>
    )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(QuickAddProducts))