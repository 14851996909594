import React, { useState } from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const Overlay = styled.div`
  position:fixed;
  height:100%;
  width:100%;
  z-index: 1000;
`
const Spinner = styled(Spin)`
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%,-50%);
`
const StyledLoadingOverlay = () => {

  return (
    <Overlay>
      <Spinner tip='Loading...' size='large' />
    </Overlay>
  )
}

export default StyledLoadingOverlay
