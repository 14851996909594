import React, { useEffect, useRef, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import DataTableServices from '../../../../../services/datatableServices'

import {
    Button,
    Tabs,
    Input,
    Table,
    Empty,
    Modal,
    Image,
    Typography,
    Popover,
    message,
    Space,
    // Popconfirm
} from 'antd';
import {
    DeleteOutlined,
    PlusOutlined,
    FileTextOutlined,
    CloseOutlined,
    SyncOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

// import { unionBy, flatMap, merge as LMerge } from "lodash-es"
// import { autorun, toJS, get } from 'mobx'
import { toJS } from 'mobx';
import { contrastColor } from 'contrast-color'
import styled from 'styled-components'
// import useAsyncQuery from "../../../../ultils/useAsyncQuery";
// import SearchBoxItem from "../DataTools/SearchBoxItem";
import DataSelectModal from '../../DataTools/DataSelectModal'
import OrderTextModal from '../../DataTableLive/OrderTextModal'
import PrintOrderDetail from '../../../../printer/Product/PrintOrderDetail'

import OrderCustomAddItem from './OrderCustomAddItem'

import { EditableCell, EditableRow } from "../../EditableCell"
import { removeVietnameseCharMark, imagePreset, price_k, getOrderSummary, recalcWeight } from '../../../../../ultils/datatableUtils'

const { Text } = Typography
const { TextArea } = Input;
const { confirm } = Modal;

const CustomTable = styled(Table)`
.ant-table-thead > tr > th {
  background-color:${props => props.color} !important;
  color:${props => contrastColor({ bgColor: props.color })};
}
`;

const LiveOrderContainer = styled.div`
  width:100%;
  `

const OrderItems = ({ datatableStore, commonStore, currentOrder, prefs, table, orderId }) => {
    const [showAddModal, setShowAddModal] = useState(false)
    const [showOrderText, setShowOrderText] = useState(false)
    const [showCustomAdd, setShowCustomAdd] = useState(false)
    const [updating, setUpdating] = useState(false)

    // const [createDatarow] = useMutation(DataTableServices.CREATE_DATAROW(), {
    //   errorPolicy: 'all',
    //   update(cache, { data }) {
    //   }
    // })

    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )
    // const getTableById = useAsyncQuery(DataTableServices.GET_TABLE(table.id ? table.id : table))

    const QuickNote = ({ rIndex, row, onClose }) => {
        // console.log(`row`, row)
        const [loading, setLoading] = useState(false)
        const noteText = useRef(null)
        return (
            <div style={{
                width: 250
            }}>
                <TextArea defaultValue={row?.note} ref={noteText} showCount maxLength={100} />

                <Button size={'small'} type="primary" style={{ marginRight: 8 }}
                    onClick={async () => {
                        if (noteText.current?.resizableTextArea?.props?.value !== undefined) {
                            let order = toJS(datatableStore.rowDetail)
                            // console.log(`order`, order)
                            let newItem = toJS(row)
                            newItem.note = noteText.current?.resizableTextArea?.props?.value
                            // console.log(`newItem`, newItem)
                            // console.log(`datatableStore.rowDetail`, datatableStore.rowDetail)
                            let rIndex = order.items.findIndex(item => item.code == row.code)
                            if (newItem && rIndex > -1) {
                                let r = await datatableStore.updateOrderItem(newItem, orderId, updateManyDataRows)
                                if (r) {
                                    order.items[rIndex] = newItem
                                    datatableStore.setRowDetail(order)
                                }
                            }
                        }
                        onClose()
                    }}
                >Lưu ghi chú</Button>
                <Button loading={loading} type="primary" danger icon={<DeleteOutlined />} size={'small'}
                    onClick={async () => {
                        if (row) {
                            let order = toJS(datatableStore.rowDetail)
                            let rIndex = order.items.findIndex(item => item.code == row.code)
                            let newItem = toJS(row)
                            newItem.note = ""
                            if (newItem && rIndex > -1) {
                                setLoading(true)
                                let r = await datatableStore.updateOrderItem(newItem, orderId, updateManyDataRows)
                                if (r) {
                                    order.items[rIndex] = newItem
                                    datatableStore.setRowDetail(order)
                                }
                                setLoading(false)
                            }
                        }
                        onClose()
                    }}
                />
            </div>
        )
    }

    const ActionRender = (value, row, index) => {
        const [showNoteBox, setShowNoteBox] = useState(false)
        const [loading, setLoading] = useState(false)
        const hideNoteBox = () => {
            setShowNoteBox(false)
        };
        return (
            <div>
                <Space>
                    <Popover
                        content={<QuickNote onClose={hideNoteBox} rIndex={index} row={row} />}
                        placement="left"
                        title={false}
                        trigger="click"
                        visible={showNoteBox}
                        onVisibleChange={(v) => { setShowNoteBox(v) }}
                    >
                        <FileTextOutlined style={{ cursor: 'pointer' }} />
                    </Popover>
                    {/* <CloseOutlined disabled={loading} style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => {
                            confirm({
                                title: 'Bạn có chắc chắn muốn xóa sản phẩm này không?',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Xóa',
                                okType: 'danger',
                                cancelText: 'Bỏ qua',
                                async onOk() {
                                    if (row) {
                                        let order = toJS(datatableStore.rowDetail)
                                        let rIndex = order.items.findIndex(item => item.code == row.code)
                                        let deleteItem = toJS(row)
                                        deleteItem.note = ""
                                        if (deleteItem && rIndex > -1) {
                                            setLoading(true)
                                            let r = await datatableStore.updateOrderItem(deleteItem, orderId, updateManyDataRows, 'delete')
                                            if (r) {
                                                order.items.splice(rIndex, 1);
                                                order.tong_tra = r.tong_tra
                                                order.tong_sl = r.tong_sl
                                                order.autoWeight = recalcWeight(order.items)
                                                datatableStore.setRowDetail(order)
                                            }
                                            setLoading(false)
                                        }
                                    }
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });

                            //   row.qty = 0
                            //   liveStore.updateCartItem(row)
                            //   if (row) {
                            //     let order = toJS(currentOrder)

                            //     let r = updateManyDataRows({
                            //       variables: {
                            //         input: {
                            //           data: {
                            //             type: "rowkey",
                            //             rows: [order]
                            //           }
                            //         }
                            //       }
                            //     })
                            //   }
                        }}
                    /> */}
                </Space>
            </div>
        )
    }

    const NameRender = (value, row, index) => {
        let name = row?.name
        let str = false
        let color = "black"
        if (!row.code) color = "orange"
        if (row.mType) {
            if (row.mType === 1) color = "#4bb54b"
            if (row.mType === 2) color = "blue"
        }
        if (row.code) {
            str = (<><strong>{'[' + row.code.toUpperCase() + ']'}</strong> - {name}</>)
            if (row.match_code && row.match_code != row.code) {
                str = (<><strong>{'[' + row.match_code.toUpperCase() + ']'}</strong>{(row.code ? " -" + row.code : "")} - {name}</>)
            }
        } else {
            str = name
        }
        let ctb = ' ??'
        let ctb_color = "red"
        let pref = prefs[row.code]
        if (pref?.sl_tonkho && pref?.sl_giuhang) {
            ctb = pref?.sl_tonkho - pref?.sl_giuhang
            if (ctb > 0) ctb_color = 'blue'
        }
        return (
            <>
                <span style={{ color: color }}>{str}</span> (ctb:<span style={{ color: ctb_color }}>{ctb}</span>)
                {/* <strong>{'[' + row.match_code.toUpperCase() + ']'}</strong> {(row.code !== row.match_code ? " -" + row.code : "")} - {name} */}
                {row.note ?
                    (<>
                        <br /><span style={{ fontSize: 11, color: "brown" }}>{row.note} </span>
                    </>
                    )
                    : ""}
            </>
        )
    }

    const cellSave = async (row) => {
        if (row) {
            // alert('Tạm ngừng hoạt động')
            // return false
            let order = toJS(datatableStore.rowDetail)
            // order.key = orderId
            let rIndex = order.items.findIndex(item => item.code == row.code)
            if (rIndex >= 0) {
                let oldItem = order.items[rIndex]
                let pref = prefs[oldItem.code]
                let dif = oldItem.qty - row.qty
                if (dif !== 0) {
                    if (pref) {
                        let ctb = 0
                        if (pref)
                            ctb = (pref.sl_tonkho - (pref.sl_giuhang || 0)) || 0

                        if (dif + ctb >= 0) {
                            row.edit_count++
                            let updateRow = toJS(row)
                            let r = await datatableStore.updateOrderItem(updateRow, orderId, updateManyDataRows, 'update')
                            if (r) {
                                order.items[rIndex] = updateRow
                                order.tong_tra = r.tong_tra
                                order.tong_sl = r.tong_sl
                                order.autoWeight = recalcWeight(order.items)
                                prefs[row.code].sl_giuhang = prefs[row.code].sl_giuhang - dif
                                datatableStore.setRowDetail(order)
                            }
                        } else {
                            alert('Số lượng không đủ CTB (' + ctb + ' sp) !!')
                        }
                    }
                } else {
                    row.edit_count++
                    let updateRow = toJS(row)
                    let r = await datatableStore.updateOrderItem(updateRow, orderId, updateManyDataRows, 'update')
                    if (r) {
                        order.items[rIndex] = updateRow
                        order.tong_tra = r.tong_tra
                        order.tong_sl = r.tong_sl
                        order.autoWeight = recalcWeight(order.items)
                        datatableStore.setRowDetail(order)
                    }
                }

                // if (dif + ctb > 0) {
                //     row.edit_count++
                //     let updateRow = toJS(row)
                //     let r = await datatableStore.updateOrderItem(updateRow, orderId, updateManyDataRows, 'update')
                //     if (r) {
                //         order.items[rIndex] = updateRow
                //         order.tong_tra = r.tong_tra
                //         order.tong_sl = r.tong_sl
                //         order.autoWeight = recalcWeight(order.items)
                //         datatableStore.setRowDetail(order)
                //     }
                // } else {
                //     alert('Số lượng không đủ CTB (' + ctb + ' sp) !!')
                // }

                // row.edit_count++
                // let updateRow = toJS(row)
                // let r = await datatableStore.updateOrderItem(updateRow, orderId, updateManyDataRows, 'update')
                // if (r) {
                //     order.items[rIndex] = updateRow
                //     order.tong_tra = r.tong_tra
                //     order.tong_sl = r.tong_sl
                //     order.autoWeight = recalcWeight(order.items)
                //     datatableStore.setRowDetail(order)
                // }

            }
        }
    };

    const columns = [
        {
            title: 'Ảnh',
            dataIndex: 'image',
            key: 'image',
            render: (value, row, index) => {
                if (!value)
                    value = row?.ref?.image
                // if (!value) {
                //   let lp = liveStore.liveProducts.find(item => item.match_code === row.code)
                //   if (!lp) lp = liveStore.liveProducts.find(item => item.code === row.code)
                //   if (lp) {
                //     value = lp.image
                //   }
                // }
                let thumbnail = imagePreset(value, 'xsmall')
                return <Image
                    width={40}
                    height={40}
                    src={thumbnail}
                    preview={{
                        src: value,
                    }}
                />
            },
            width: 44
        },
        {
            title: 'SẢN PHẨM',
            dataIndex: 'code',
            key: 'code',
            width: 'auto',
            render: NameRender
        },
        {
            title: 'SL',
            dataIndex: 'qty',
            key: 'qty',
            align: 'center',
            width: 50,
            // editable: true,
            // onCell: (record) => ({
            //     record,
            //     editable: true,
            //     dataIndex: 'qty',
            //     inputType: 'number',
            //     title: 'SL',
            //     handleSave: cellSave,
            // })
        },
        {
            title: 'GIÁ',
            dataIndex: 'price',
            key: 'price',
            width: 90,
            // onCell: (record) => ({
            //     record,
            //     editable: true,
            //     inputType: 'number',
            //     dataIndex: 'price',
            //     title: 'GIÁ',
            //     handleSave: cellSave,
            // }),
            align: 'right',
            render: (value) => {
                let color = value == 1 ? 'red' : 'black'
                // return value ? value.toLocaleString('it-IT', { style: 'currency', currency: 'VND' }) : 0
                return <span style={{ color: color }}>{price_k(value, '?', 3)}</span>
            }
        },
        {
            title: 'Nặng (g)',
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: 70,
            editable: true,
            onCell: (record) => ({
                record,
                editable: true,
                dataIndex: 'weight',
                inputType: 'number',
                title: 'Nặng (g)',
                handleSave: cellSave,
            }),
            render: (value) => {
                return value ? value : <span style={{ color: 'red' }}>{value}</span>
            }
        },
        {
            title: '',
            dataIndex: 'code',
            width: 50,
            render: ActionRender
        },
    ];

    // useEffect(() => {
    //   console.log(`currentOrder`, currentOrder)
    // }, [currentOrder])


    // console.log('detailData', detailData)
    return (
        <LiveOrderContainer>
            <Space style={{ marginBottom: 10 }}>
                {/* <Button shape="round" icon={<PlusOutlined />} size={'small'} onClick={() => {
                    setShowAddModal(true)
                }}>
                    sản phẩm
                </Button> */}
                {/* <Button shape="round" icon={<PlusOutlined />} size={'small'} onClick={() => {
                    setShowCustomAdd(true)
                }}>
                    tùy chỉnh
                </Button> */}
                <Button shape="round" icon={<FileTextOutlined />} size={'small'} onClick={() => {
                    setShowOrderText(true)
                }}>
                    Nhắn
                </Button>
                {currentOrder?.code ?
                    <PrintOrderDetail title='In'
                        orderData={toJS(currentOrder)}
                        columns={['name', 'qty', 'price', 'priceItems']}
                    />
                    : ""}
                {/* <Button loading={updating} shape="round" icon={<SyncOutlined />} size={'small'} onClick={async () => {
                    let items = currentOrder?.items
                    items.forEach(p => {
                        let pref = prefs[p.code]
                        p.weight = pref?.weight || p.weight
                    });
                    let newItems = toJS(items)
                    newItems.forEach(p => {
                        p.pref = false
                    });
                    setUpdating(true)
                    let r = await updateManyDataRows({
                        variables: {
                            input: {
                                data: {
                                    type: "oldnew",
                                    no_history: true,
                                    rows: [{
                                        id: currentOrder.key,
                                        n: { 'items': newItems },
                                        o: false
                                    }]
                                }
                            }
                        }
                    })
                    if (r.data.updateManyDataRows?.data?.status === 'OK') {
                        message.success("Đồng bộ cân nặng thành công")
                    } else {
                        message.error("Đồng bộ cân nặng thất bại")
                    }
                    setUpdating(false)
                }}>
                    Đồng bộ cân nặng
                </Button> */}
            </Space>
            {
                currentOrder.items ?
                    <div
                        style={{
                            marginBottom: 10,
                        }}>
                        {currentOrder?.code ?
                            <CustomTable
                                color={currentOrder?.status?.color ? currentOrder.status.color : "#fafafa"}
                                // id="liveOrderTable"
                                components={{
                                    body: {
                                        row: EditableRow,
                                        cell: EditableCell,
                                    },
                                }}
                                // style={{
                                //   overflowY: 'auto',
                                //   maxHeight: 'calc(100vh - 300px)',
                                //   height: '100vh',
                                //   marginBottom: 10,
                                //   border: '1px solid #fcfcfc'
                                // }}
                                // dataSource={currentOrderItems.filter(item => item.code && item.qty > 0)}]
                                // scroll={{ x: 500 }}
                                dataSource={currentOrder.items}
                                // dataSource={currentOrderItems}
                                columns={columns}
                                // scroll={{ y: 'calc(100vh - 235px)', scrollToFirstRowOnChange: true }}
                                bordered
                                size={'small'}
                                pagination={false}
                                summary={pageData => {
                                    let totalQuanity = 0;
                                    let totalPrice = 0;

                                    pageData.forEach(({ price, qty }) => {
                                        qty = parseInt(qty)
                                        if (qty > 0 && price) {
                                            totalPrice += price * qty
                                            totalQuanity += qty
                                        }
                                    });
                                    let finalPrice = totalPrice
                                    let extraText = false

                                    let summary = datatableStore.getOrderSummary
                                    // console.log('summary', summary)
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2}>Tổng đơn hàng
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell align='center'>
                                                    <Text strong ty>{summary.qty}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell align='right' colSpan={4}>
                                                    <Text strong >{price_k(summary.price, '?', 3)}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>

                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={4}>Phí ship
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell align='right' colSpan={2}>
                                                    <Text strong  >{price_k(summary?.ship_fee, '?', 3)}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>

                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={4}>Giảm giá
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell align='right' colSpan={2}>
                                                    <Text strong >{price_k(summary.giam_gia, '-', 3)}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={4}>Trả trước / Chuyển khoản
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell align='right' colSpan={2}>
                                                    <Text strong type='warning' >{price_k(summary.chuyenkhoan, '-', 3)}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={4}>Tổng phải trả
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell align='right' colSpan={2}>
                                                    <Text strong type="success" >{price_k(summary.tong_tra ? summary.tong_tra : 0, '?', 3)}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            />
                            : ""
                        }

                    </div>

                    : ""}

            {showAddModal ?
                <DataSelectModal
                    title={'Chọn sản phẩm thêm'}
                    searchColumns={["code", "name", "match_code"]}
                    initData={''}
                    tableId={commonStore?.company?.companyData?.product_table}
                    closeOnSelect={true}
                    onSave={(v) => {
                        alert('dang nang cap')
                        return false
                        if (v.ref) {
                            let order = toJS(datatableStore.rowDetail)
                            let ctb = v.ref.sl_tonkho - (v.ref.sl_giuhang || 0)
                            if (ctb > 0) {
                                let newItem = {
                                    code: v.ref.code,
                                    edit_count: 0,
                                    price: v.ref.price,
                                    quanity: 1,
                                    qty: 1,
                                    match_code: v.ref.code,
                                    ref: v.ref.id || v.id,
                                    image: v.ref.image,
                                    name: v.ref.name,
                                    weight: v.ref.weight ? v.ref.weight : 0
                                }
                                if (newItem) {
                                    let r = datatableStore.updateOrderItem(newItem, orderId, updateManyDataRows, 'add')
                                    if (r) {
                                        order.items.unshift(newItem)
                                        order.tong_tra = r.tong_tra
                                        order.tong_sl = r.tong_sl
                                        order.autoWeight = recalcWeight(order.items)
                                        datatableStore.setRowDetail(order)
                                    }
                                }
                            }
                            else {
                                alert('Không đủ sp trong kho!')
                            }

                        }
                        // saveNewCode(v)
                    }}
                    onClose={() => {
                        setShowAddModal(false)
                    }}
                    // component={ProductItem}
                    onSearchSelect={(v, o) => {
                        // console.log('o', o)
                    }}
                />
                : ""
            }
            {/* {showOrderText ?
        <OrderTextModal
          onClose={() => {
            setShowOrderText(false)
          }}
          orders={currentOrderItems.filter(item => item.code && item.qty > 0)}
          addressInfo={currentOrder.customer?.address}
          customer={currentOrder.customer}
        />
        : ""
      } */}
            {showOrderText ?
                <OrderTextModal
                    onClose={() => {
                        setShowOrderText(false)
                    }}
                    summary={datatableStore.getOrderSummary}
                    orders={currentOrder.items.filter(item => item.code && item.qty > 0)}
                    addressInfo={currentOrder.customer?.address}
                    customer={toJS(currentOrder.customer)}
                />
                : ""
            }
            {/* {showCustomAdd ?
                <OrderCustomAddItem
                    title='Thêm sản phẩm tùy chỉnh'
                    onClose={() => {
                        setShowCustomAdd(false)
                    }}
                    onSave={
                        (newItem) => {
                            let order = toJS(datatableStore.rowDetail)
                            if (newItem) {
                                let r = datatableStore.updateOrderItem(newItem, orderId, updateManyDataRows, 'add')
                                if (r) {
                                    order.items.unshift(newItem)
                                    order.tong_tra = r.tong_tra
                                    order.tong_sl = r.tong_sl
                                    order.autoWeight = recalcWeight(order.items)
                                    datatableStore.setRowDetail(order)
                                }
                            }
                            setShowCustomAdd(false)
                        }
                    }
                />
                : ""
            } */}
        </LiveOrderContainer>
    )
}

export default inject("datatableStore", "commonStore")(observer(OrderItems))