import React from 'react'
import { Form, Input } from 'antd'
const { Search } = Input

const SearchBar = () => {
  return (
    <Form>
      <Search
        placeholder="Search..."
        onSearch={value => console.log(value)}
        style={{ width: 200 }}
      />
    </Form>
  )
}

export default SearchBar
