import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const LogoWrapper = styled.div`
  padding: 0;
  margin: 0;
  text-align: center;
`
const LogoText = styled.div`
  color: #fff;
  font-weight: bold;
`
const Img = styled.img`
  height: ${ ({ collapsed }) => collapsed ? '35px' : 'auto'};
`

const Logo = prop => {
  const { linkTo, src, alt, collapsed } = prop
  return (
    <LogoWrapper>
      <Link to={linkTo}>
        <Img collapsed={collapsed} src={src} alt={alt} />
      </Link>
    </LogoWrapper>
  )
}

export default Logo
