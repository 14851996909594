import React from 'react'
import styled from 'styled-components'
import { Select, Button } from 'antd'
const { Option } = Select

function onChange(value) {
  console.log(`selected ${value}`)
}

function onBlur() {
  console.log('blur')
}

function onFocus() {
  console.log('focus')
}

function onSearch(val) {
  console.log('search:', val)
}

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 30px;
`
const FlexStyled = styled.div`
  display: flex;
  align-items: center;
`
const SelectStyled = styled(Select)`
  margin-right: 15px;
`

const StyledPrevew = ({ source, dataSelect }) => {
  return (
    <div>
      <ImagePreview src={source} src={source} />
      <FlexStyled>
        <SelectStyled
          showSearch
          style={{ width: 200 }}
          placeholder="Select a template"
          optionFilterProp="children"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="template-1">Template 1</Option>
          <Option value="template-2">Template 2</Option>
          <Option value="template-3">Template 3</Option>
        </SelectStyled>
        <Button type="primary">Update Scene</Button>
      </FlexStyled>
    </div>
  )
}

export default StyledPrevew
