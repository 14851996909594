import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import {
  LineChartOutlined,
  SettingOutlined,
  UserOutlined,
  ToolOutlined,
  TableOutlined,
  FireOutlined
} from '@ant-design/icons'
import { useQuery, useMutation } from '@apollo/react-hooks'
import DataTableServices from '../../../../services/datatableServices'
import { useAsyncQuery } from '../../../../ultils'
const { SubMenu } = Menu

const MenuWrapper = styled.div`
  margin-top: 20px;
  & .ant-menu {
    border-right: none;
  }
  .ant-menu-item a {
    color: ${({ theme }) => theme.colors.menuTextColor};
  }
  .ant-menu-submenu > .ant-menu-submenu-title, 
  & .ant-menu,
  & .ant-menu .ant-menu-sub {
    background: ${({ theme }) => theme.colors.sidebarBgColor};
    color: ${({ theme }) => theme.colors.menuTextColor};
  }
  & .ant-menu .ant-menu-submenu-selected svg{
    font-weight:bold;
    color: ${({ theme }) => theme.colors.primaryColor};
  }
  & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color:  ${({ theme }) => theme.colors.sidebarBgColorSelected};
    a{
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
  .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    font-size:18px;
  }
`

const SidebarManager = ({ collapsed, commonStore, usersStore, datatableStore }) => {
  const rootKeys = ['dashboard', 'settings']
  const [openKey, setOpenKey] = useState(['quan-ly-noi-bo'])
  const [selectedKeys, setSelectedKeys] = useState([commonStore.currentPage])
  const [dynamicMenu, setDynamicMenu] = useState(false)

  const onOpenChange = (openKeys) => {
    // console.log('openKeys', openKeys)
    const latestOpenKey = openKeys.find((key) => {
      return openKey.indexOf(key) === -1
    })

    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKey(openKeys)
    } else {
      const checkKeys = latestOpenKey ? [latestOpenKey] : []
      setOpenKey(checkKeys)
    }
  }
  const handleClick = e => {
    setSelectedKeys(e.key)
    commonStore.setCurrentPage(e.key)
  }

  useEffect(() => {

    return () => {

    }
  }, [commonStore.currentPage])

  // const DataMenu = ({ data }) => {
  //   if (!data) return null
  //   data.map((d) => {
  //     console.log('d', d)
  //   })
  //   return (
  //     <>
  //       {data.map((databoard) => {
  //         return (
  //           <SubMenu key={databoard.slug} icon={<TableOutlined />} title='Cấu hình'>
  //             {databoard.datatables.map((dt => {
  //               console.log('dt', dt)
  //               return (
  //                 <Menu.Item key={dt.id}>
  //                   <Link to={`/table/${dt.id}`}>{dt.name}</Link>
  //                 </Menu.Item>
  //               )
  //             }))}
  //           </SubMenu>
  //         )
  //       })}
  //     </>
  //   )
  // }

  const findBoards = useAsyncQuery(DataTableServices.GET_COMPANY_BOARD())

  // const {data,loading,error} = useQuery(DataTableServices.GET_COMPANY_BOARD(), {
  //   variables: {
  //     company_slug:'nhaiu'
  //   }
  // })

  // if (data) {
  //   console.log('data', data.databoards)
  // }
  // function MenuDataTable(id) {
  //   const { loading, error, data } = useQuery(
  //     DataTableServices.GET_TABLE(id),
  //     {g
  //       onCompleted: data => {
  //         console.log('data', data)
  //         datatableStore.setCurrentDataTable(data)
  //       }
  //     }
  //   )

  //   return (

  //   )
  // }

  // useEffect(() => {
  //   if (datatableStore.currentDashboard) {
  //     if (datatableStore.currentDashboard.datatables.length > 0) {
  //       // getDataTable(datatableStore.currentDashboard.datatables[0].id)
  //       // const { loading, error, data } = useQuery(
  //       //   DataTableServices.GET_TABLE(currentDashboard.datatables[0].id, response),
  //       //   {
  //       //     onCompleted: data => {
  //       //       console.log('data', data)
  //       //     }
  //       //   })
  //     }
  //   }
  // }, [datatableStore.currentDashboard])

  const getBoardByCompany = async (company_slug) => {
    const { data } = await findBoards({ company_slug: company_slug })
    // if (data) {
    //   console.log('data', data.databoards)
    // }
    return data?.databoards
  }

  useEffect(async () => {
    if (usersStore.currentUser && !datatableStore.dataBoards) {
      // console.log('usersStore.currentUser.company_role', usersStore.currentUser.company_role)
      var bc = await getBoardByCompany(commonStore.company.slug)
      bc.forEach(b => {
        rootKeys.push(b.slug)
      });
      // console.log('rootKeys', rootKeys)
      // console.log('bc', bc)
      datatableStore.setDataBoards(bc)
      setDynamicMenu(bc)
    }
  }, [usersStore.currentUser])

  const IconByType = ({ iconType }) => {
    if (!iconType) iconType = 'common'
    // console.log('iconType', iconType)
    switch (iconType) {
      case 'live':
        return <FireOutlined />
      case 'system':
        return <ToolOutlined />
      default:
        return <TableOutlined />
    }
  }

  return (
    <MenuWrapper collapsed={collapsed}>
      <Menu
        theme="light"
        mode="inline"
        onClick={handleClick}
        openKeys={openKey}
        selectedKeys={[commonStore.currentPage]}
        defaultOpenKeys={['quan-ly-noi-bo']}
        onOpenChange={onOpenChange}
      >
        <Menu.Item key='dashboard' icon={<LineChartOutlined />}>
          <Link to='/'>Tổng quan</Link>
        </Menu.Item>
        {datatableStore.dataBoards ? datatableStore.dataBoards.map((databoard) => {
          // console.log(`databoard`, databoard)
          if (!databoard.board_data.show_menu) return ''
          if (databoard.board_data?.type === "system" && usersStore.currentUser.company_role !== 'chu-cong-ty') return ""
          // console.log('databoard.board_data', databoard.board_data)
          if (!databoard.board_data?.show_child) {
            if (databoard.board_data?.type === "live" && usersStore.currentUser.company_role !== 'chu-cong-ty') return ""

            return (
              <Menu.Item key={databoard.slug} icon={<IconByType iconType={databoard.board_data.type} />}>
                <Link to={`/board/${databoard.id}`}>{databoard.name}</Link>
              </Menu.Item>
            )
          }
          return (
            <SubMenu key={databoard.slug} icon={<IconByType iconType={databoard.board_data.type} />} title={databoard.name}>
              {databoard.datatables.map((dt => {
                // console.log('dt', dt)
                // let uri = dt.type === 'live' ? 'live' : 'table'
                let uri = 'table'
                if (dt.table_data.roles) {
                  if (!dt.table_data.roles[usersStore.currentUser.company_role]) return ""
                }
                return (
                  <Menu.Item key={dt.id}>
                    <Link to={`/${uri}/${dt.id}`}>{dt.name}</Link>
                  </Menu.Item>
                )
              }))}
            </SubMenu>
          )
        }) : ''}
        {/* <DataMenu data={dynamicMenu}/> */}
        {/* <SubMenu key='board' icon={<CodeSandboxOutlined />} title=''>
          <Menu.Item key='table-list'>
            <Link to='/product/list'>Danh sách sản phẩm</Link>
          </Menu.Item>
          <Menu.Item key='resources-hub'>
            <Link to='/Booth-Management/resources-hub'>Tìm sản phẩm</Link>
          </Menu.Item>
        </SubMenu> */}
        {/* {usersStore.currentUser.company_role === 'chu-cong-ty' ?
          <SubMenu key='settings' icon={<SettingOutlined />} title='Cấu hình'>
            <Menu.Item key='profile'>
              <Link to='/setting/profile'>Profile</Link>
            </Menu.Item>
            <Menu.Item key='password-security'>
              <Link to='/setting/password-security'>{`Password & Security`}</Link>
            </Menu.Item>
          </SubMenu>
          : ""
        } */}


        <Menu.Item key='tools' icon={<LineChartOutlined />}>
          <Link to={`/tools/`}>Công cụ</Link>
        </Menu.Item>

      </Menu>
    </MenuWrapper>
  )
}

export default inject("commonStore", "usersStore", "datatableStore")(observer(SidebarManager));