import { observable, action, decorate, toJS } from 'mobx'
import { makeAutoObservable, autorun, runInAction } from "mobx"

class UIStore {
    isLoading=false

    constructor() {
        makeAutoObservable(this)
    }

    setIsLoading(loading) {
        this.isLoading = loading
    }

}

export default new UIStore()
