import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Form,
  Button,
  Divider,
  Input,
  InputNumber,
  Table,
  Empty,
  Modal,
  Card,
  message,
  Space,
  Upload,
  Row,
  Col
} from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { autorun, toJS } from 'mobx'
// import { debounce } from "lodash-es";
import DataTableServices from '../../../../services/datatableServices'
import UploadServices from '../../../../services/uploadServices'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
import styled from 'styled-components'
import { nanoid } from 'nanoid'

import { removeVietnameseCharMark, correctCode } from '../../../../ultils/datatableUtils'

import 'rc-color-picker/assets/index.css';

// import { SketchPicker, BlockPicker, ChromePicker } from 'react-color';
import ColorPicker from 'rc-color-picker';

// const { TabPane } = Tabs;
// const { Meta } = Card;

// const SelectModal = styled(Modal)`

//   `

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}


const DataNewItem = ({
  datatableStore,
  title,
  initData,
  table,
  dataTextName = 'dữ liệu',
  width = 550,
  placeholder,
  onSave,
  onClose,
  extraData,
  buttonTop,
  keepReset
}) => {

  // const [selectedData, setSelectedData] = useState(initData)
  const [datatable, setDatatable] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [imageUrl, setImageUrl] = useState(false)
  const [adding, setAdding] = useState(false)

  const getTableById = useAsyncQuery(DataTableServices.GET_TABLE(table.id ? table.id : table))
  // let 
  const check_code = useAsyncQuery(DataTableServices.CHECK_CODE())


  const [createDatarow] = useMutation(DataTableServices.CREATE_DATAROW(), {
    errorPolicy: 'all',
    update(cache, { data }) {
    }
  })

  const [upload] = useMutation(
    UploadServices.UPLOAD()
  )

  function beforeUpload(file) {
    // console.log('file', file)
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Bạn chỉ có thể tải lên JPG/PNG file!');
    }
    const sizeOK = file.size / 1024 / 1024 < 20;
    if (!sizeOK) {
      message.error('Kích thước không được quá 20 MB!');
    }
    let ok = isJpgOrPng && sizeOK;
    if (ok) {
      getBase64(file, imageUrl => {
        setImageUrl(imageUrl)
        setUploading(false)
      });
    }
    return ok
  }

  const [form] = Form.useForm();
  const closeModal = () => {
    onClose()
  }
  useEffect(async () => {
    if (!table.id) {
      const { data } = await getTableById()
      console.log(`data`, data)
      setDatatable(data.datatable)
    } else {
      setDatatable(table)
    }
  }, [])


  const onFinish = async (values) => {
    let sid = nanoid(10)
    let row_data = values
    row_data.sid = sid
    let columns = datatable.table_columns
    let attrs = datatable.table_scheme?.attributes
    if (!columns)
      columns = datatable.data_table_template?.table_columns
    for (const [key, value] of Object.entries(values)) {
      let col = columns.find(c => c.dataIndex === key)
      if (value?.file) {
        var f = await value.file.originFileObj
        const { data } = await upload({
          variables: { file: f },
        })
        if (data.upload)
          row_data[key] = data.upload.url
      }
      else {
        if (value?.color) {
          row_data[key] = value.color
        }
        if (col?.type === 'number') {
          row_data[key] = parseInt(value)
          if (col.format === 'price-vn' && row_data[key] < 1000) {
            row_data[key] = row_data[key] * 1000
          }
        }
      }
    }
    // pre calc for search
    let se = false
    let column_search = datatable.table_data?.column_search
    if (column_search) {
      column_search.forEach(s => {
        let key = s?.key
        let v = row_data[key]
        if (row_data[key] !== undefined) {
          if (s.sub_type === 'product_code') {
            // check unique
          }
          if (s.extra) {
            // if (s.extra.type === "stock_products") {
            //   v = ""
            //   Object.keys(row_data[key]).forEach(code => {
            //     v = v + " " + code
            //   })
            // }
          }
          if (!se) se = v
          else
            se += " " + v
        }
      });
    }

    // if (datatable?.id) {

    // }

    // let date_column_index = columns.findIndex(c => c.dataIndex === 'ngay_tao')
    if (attrs) {
      if (attrs['ngay_tao']) {
        row_data['ngay_tao'] = new Date()
        row_data['ngay_tao'] = row_data['ngay_tao'].toISOString()
      }

      // let ngaykiem_column = columns.findIndex(c => c.dataIndex === 'ngay_kiem')
      if (attrs['ngay_kiem']) {
        row_data['ngay_kiem'] = new Date()
        row_data['ngay_kiem'] = row_data['ngay_kiem'].toISOString()
        row_data['sl_kiem'] = row_data['sl_tonkho']
      }
    }

    if (extraData) {
      Object.assign(row_data, extraData)
    }

    const input = {
      data: {
        datatable: datatable.id,
        row_data: row_data,
        rid: datatable.id + '_' + sid
      },
    }

    if (se) input.data.se = removeVietnameseCharMark(se.toLowerCase())
    var newRow = await createDatarow({
      variables: { input },
    })
    setAdding(false)
    if (newRow) {
      // let dtr = newRow.data?.createDatarow?.datarow
      // if (!dtr.id) {
      //   alert(newRow.data?.createDatarow?.msg)
      //   return
      // }
      message.success(`Thêm mới ${dataTextName} thành công!`)
      onSave({
        status: 'OK',
        data: newRow.data?.createDatarow?.datarow
      })
      if (keepReset) {
        form.resetFields();
        setImageUrl(false)
      }
    } else {
      message.error(`Thêm mới ${dataTextName} không thành công!`);
      onSave({
        status: 'ERROR'
      })
    }

    // setRedirectLink('/thankyou')
  };

  // const onReset = () => {
  //   form.resetFields();
  // };

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 19,
    },
  };

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Tải lên</div>
    </div>
  );

  const InputItem = ({ item, dataIndex }) => {
    // console.log(`item`, item)
    const [selectedColor, setSelectedColor] = useState('white')
    const [showColor, setShowColor] = useState(false)
    let dataType = item.type ? item.type : 'text'
    switch (dataType) {
      case 'text':

        if (item.sub_type === 'product_code') {
          return <Form.Item
            name={dataIndex}
            label={item.title}
            initialValue={initData && initData[dataIndex] ? initData[dataIndex] : ""}
          >
            <Input autocomplete="off"
              onBlur={async () => {
                let v = form.getFieldValue('code')
                const { data, error } = await check_code({
                  input: {
                    data: {
                      tableId: datatable.id,
                      field: 'code',
                      code: v
                    }
                  }
                })
                if (data?.check_code?.data?.status === 'OK') {
                  if (data.check_code.data.dup > 0) {
                    alert(`Có ${data.check_code.data.dup} sp trùng mã!. Hãy nhập lại`)
                    form.setFieldsValue({ 'code': "" })
                    return
                  }
                }
                // alert(x)
              }}
              onChange={(v) => {
                let vv = {}
                vv[dataIndex] = correctCode(v?.target?.value)
                form.setFieldsValue(vv)
              }} />
          </Form.Item>
        }

        return <Form.Item
          name={dataIndex}
          label={item.title}
          initialValue={initData && initData[dataIndex] ? initData[dataIndex] : ""}
        >
          <Input autocomplete="off" />
        </Form.Item>
      case 'number':
        return <Form.Item
          name={dataIndex}
          label={item.title}
          initialValue={initData && initData[dataIndex] ? initData[dataIndex] : 0}
        >
          <InputNumber />
        </Form.Item>
      case 'color':
        return <Form.Item
          // initialValue={selectedColor}
          name={dataIndex}
          label={item.title}
        >
          {/* <Input autocomplete="off" defaultValue={selectedColor} value={selectedColor} /> */}
          {/* <Input autocomplete="off" value={selectedColor} style={{ visibility: "hidden" }} type="hidden" /> */}
          {/* <div
            style={{ width: 20, height: 20, border: "1px solid #000" }}
          // onClick={() => {
          //   setShowColor(true)
          // }}
          > */}
          <ColorPicker
            animation="slide-up"
            color={selectedColor}
            enableAlpha={false}
            onChange={(color) => {
              // console.log(`color`, color)
              setSelectedColor(color.color)
              // return { value: color.color }
            }}
          />
          {/* {showColor ?
              <ColorPicker
                animation="slide-up"
                color={selectedColor}
                onChange={(color) => {
                  setSelectedColor(color.hex)
                }}
              />
              : ""} */}
          {/* </div> */}
          {/* <input type="hidden" value={selectedColor} /> */}
          {/* <BlockPicker /> */}
          {/* <SketchPicker
            color={selectedColor}
            onChangeComplete={(color) => {
              setSelectedColor(color.hex)
            }}
          /> */}

        </Form.Item>
      case 'image':
        return <Form.Item
          name={dataIndex}
          label={item.title}
        >
          <Upload
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            accept="image/*"
            // capture="environment"
            // capture="user"
            // onChange={handleChange}
            maxCount={1}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Form.Item>
      default:
        return ''
    }
  }

  const AddForm = () => {
    // let columns = datatable.table_columns ? datatable.table_columns : datatable.data_table_template.table_columns
    let scheme = datatable.table_scheme ? datatable.table_scheme : datatable.data_table_template.table_schema
    let attr = scheme?.attributes
    let quickAddColumn = datatable.table_data?.column_quick
    if (!quickAddColumn) quickAddColumn = datatable.data_table_template.table_data_default?.column_quick
    return (
      <Form
        {...layout}
        style={{ width: "100%" }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}>
        {/* {columns.map(item => {
          if (quickAddColumn) {
            if (!quickAddColumn.includes(item.dataIndex)) return ''
          }
          return <InputItem item={item} />
        })} */}

        {/* {quickAddColumn.map(col => {
          if (attr[col])
            return <InputItem item={attr[col]} dataIndex={col} />
        })} */}

        <Row gutter={[24, 16]}>
          {
            quickAddColumn.map(qac => {
              return (
                <Col span={24 / quickAddColumn.length} xs={24} sm={24} md={24} lg={24 / quickAddColumn.length}>
                  {qac.map(col => {
                    if (col.field) {
                      if (attr[col.field])
                        return <InputItem item={attr[col.field]} dataIndex={col.field} />
                    }
                    if (attr[col]) {
                      return <InputItem item={attr[col]} dataIndex={col} />
                    }
                  })}
                </Col>
              )
            }
            )
          }
        </Row>

      </Form>
    )
  }

  const TitleContent = () => {
    if (!buttonTop) return title ? title : false
    return (
      <>
        <Space align='end' split={<Divider type="vertical" />}>
          {title ? title : false}
          <div>
            <Button key="submit" type="primary"
              loading={adding}
              size='small'
              style={{ marginRight: 5 }}
              onClick={() => {
                setAdding(true)
                form.submit()
              }}>
              Thêm mới
            </Button>
            <Button size='small' onClick={closeModal} disabled={adding}>
              Đóng
            </Button>
          </div>
        </Space>
      </>
    )
  }

  return (
    <Modal
      // title={title ? title : `Chỉnh sửa ${dataTextName}`}
      title={<TitleContent />}
      visible={true}
      width={"95%"}
      maskClosable={false}
      onCancel={closeModal}
      centered
      // footer={false}
      footer={buttonTop ? "" : [
        <Button onClick={closeModal} disabled={adding}>
          Đóng
        </Button>,
        <Button key="submit" type="primary"
          loading={adding}
          onClick={() => {
            setAdding(true)
            form.submit()
          }}>
          Thêm mới
        </Button>,
      ]}
    >
      {datatable ? <AddForm /> : ""}
    </Modal>
  )
}
export default inject("datatableStore")(observer(DataNewItem))