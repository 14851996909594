import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useReactToPrint } from "react-to-print";
// import moment from 'moment'
import { price_k } from '../../../ultils/datatableUtils'
import { QRCodeCanvas } from "qrcode.react";

import {
    Button,
    Modal,
} from 'antd';
import {
    PlusOutlined,
    CloseCircleOutlined,
    PrinterOutlined
} from '@ant-design/icons';

import styled, { createGlobalStyle } from 'styled-components'

const PrintableBodyWrapper = styled.div`
 {
    @media print {
        .page-break {
            display: block;
            page-break-before: always;
            margin-bottom:20px;
        }
        .mb {
            margin-bottom:20px;
        }
    }
    padding-top: 20px;
    padding-left:30px;
    font-size:10pt;
    table {
        font-size:8pt;
        tr {
            td {
                width:150px;
                overflow: hidden;
                padding:10px;
                padding-bottom:14px;
                border:1px solid #eee;
                .short_p {
                    background:#fff;
                    display:flex;
                    .g1 {
                        padding:0px;
                    }
                    .g2 {
                        font-size: 7pt;
                        padding: 3px 3px 3px 4px;
                    }
                }
            }
        }
    }
    .summary {
        font-size:12pt;
        padding: 10px 5px;
    }
  }
`;

function cell_render(item) {
    let txt = false
    let g0 = 1
    if (item.gia_von > 0) {
        g0 = parseFloat(item.gia_von * 2 / 1000).toFixed(0)

        let g1 = (item.gia_von * 2) * 0.95
        let g2 = (item.gia_von * 2) * 0.9
        let g3 = (item.gia_von * 2) * 0.85
        g1 = parseFloat(g1 / 1000).toFixed(0)
        g2 = parseFloat(g2 / 1000).toFixed(0)
        g3 = parseFloat(g3 / 1000).toFixed(0)

        txt = g1 + '-' + g2 + '-' + g3
        // let g1 = item.gia_cao_nhat * 0.85
        // let g2 = item.gia_cao_nhat * 0.90
        // let g3 = item.gia_cao_nhat * 0.95
        // g1 = parseFloat(g1 / 1000).toFixed(0)
        // g2 = parseFloat(g2 / 1000).toFixed(0)
        // g3 = parseFloat(g3 / 1000).toFixed(0)
        // txt = g1 + '/' + g2 + '/' + g3 + '/' + item.gia_cao_nhat / 1000
    }
    return (
        <div className="short_p">
            <div className='g1'>
                <QRCodeCanvas
                    id="qrCode"
                    value={item.code}
                    size={50}
                    bgColor={"#ffffff"}
                    level={"H"}
                    style={{ margin: 0, padding: 0 }}
                />

            </div>
            <div className='g2'>
                {item.sl_kwo &&
                    <>
                        {item.code} /
                    </>
                }
                <strong>{item.sl_kwo || item.code}</strong>
                <br />
                {item?.gia_von &&
                    <>
                        <strong> {g0} k</strong>
                    </>
                }
                <br />
                {txt}
                {/* {item.gia_von > 0 &&
                    <>
                        {item.gia_thap_nhat / 1000} → {item.gia_cao_nhat / 1000}
                    </>
                } */}
            </div>
        </div>
    )
}

class DatatableView extends React.PureComponent {
    render() {
        let numCol = this.props.numCol || 5
        let rowPerPage = 8
        return (
            <PrintableBodyWrapper
                ppLeft={this.props.ppLeft}
                ppTop={this.props.ppTop}
                cardTop={this.props.cardTop}
                cardLeft={this.props.cardLeft}
            >
                <table>
                    <tbody>
                        {
                            this.props.rows.map((row, index) => {
                                // let bclass = 'page-break'
                                // if (index===)
                                return (
                                    <>
                                        <tr key={index}>
                                            {
                                                row.map((col, cindex) => {
                                                    // console.log('cindex', cindex)
                                                    let pr = (cindex === numCol - 1) ? 0 : 0
                                                    return (
                                                        <td
                                                            // style={{ paddingRight: pr }}
                                                            key={`${index}-${cindex}`}>{col}</td>
                                                    )
                                                    // let v = row[col]
                                                    // if (index === 0) {
                                                    //     return (<th key={`${index}-${col}`}>{v}</th>)
                                                    // }
                                                    // return (<td key={`${index}-${col}`}>{v}</td>)
                                                })
                                            }
                                        </tr>
                                        {
                                            (index % rowPerPage === (rowPerPage - 1)) && (index < this.props.rows.length - 1) &&
                                            <>
                                                <br />
                                                <span className='page-break' />
                                            </>

                                        }
                                    </>

                                )
                            }
                            )
                        }
                    </tbody>
                </table>
            </PrintableBodyWrapper>
        );
    }
}

const PrintProductsQRCode = ({
    columns,
    numCol = 5,
    title,
    printData,
    headerRow,
}) => {

    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const rowsToPrint = () => {
        // let cells = this.props.cells
        // let numRows = Math.floor(cells.length / column) + 1
        if (!printData.items) return []
        // let space = 10
        // let margin = 10
        let rows = []

        printData.items.forEach((item, pIndex) => {
            let pRowData = {}
            let rowIndex = Math.floor(pIndex / numCol)
            if (!rows[rowIndex]) rows[rowIndex] = []
            let col = cell_render(item)
            rows[rowIndex].push(col)
        });
        return rows
    }

    return (
        <>
            <div style={{ display: 'none' }}>
                <DatatableView
                    ref={printRef}
                    title={'In'}
                    columns={columns}
                    rows={rowsToPrint()}
                    numCol={numCol}
                    paperMargin={30}
                    paperTopMargin={23}
                    ppTop={18}
                    ppLeft={20}
                    cardTop={2}
                    cardLeft={16}
                />
            </div>
            <Button shape="round" size={'small'} onClick={handlePrint}
                icon={<PrinterOutlined />}
            >{title}</Button>
        </>
    )
}
export default inject("datatableStore")(observer(PrintProductsQRCode))

