import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useMutation } from '@apollo/react-hooks'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios'
import Cookies from 'js-cookie'
import config from '../../../config'

import {
    Divider,
    Button,
    Image,
    message,
    Space,
    Card,
    Row,
    Col
} from 'antd';
// import moment from 'moment'
import MarkdownIt from 'markdown-it';
import {
    DownloadOutlined,
    DeleteFilled,
    CopyOutlined,
} from '@ant-design/icons';

import { imagePreset } from '../../../ultils/datatableUtils'
import { flatMap, merge as LMerge } from 'lodash-es'
import useAsyncQuery from '../../../ultils/useAsyncQuery'
import DataTableServices from '../../../services/datatableServices'
import ManyProducts from '../DataBoard/DataCard/ManyProducts'
import TagsInput from '../DataBoard/DataTools/TagsInput'

const mdParser = new MarkdownIt(/* Markdown-it options */);
function myFlat(v) {
    return Object.assign(v.row_data, { key: v.id })
}



async function downloadByResId(res_id, res_link, fname = false) {
    let token = await Cookies.get('access_token')
    axios({
        url: `${config.API_ENDPOINT}/down_resource/${res_id}`,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        var today = new Date();
        var milliSeconds = today.getMilliseconds();
        let ext = res_link.split(/[#?]/)[0].split('.').pop().trim();
        let fileOut = 'res_' + milliSeconds + '.' + ext

        link.setAttribute('download', fname || fileOut); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}



const ResourceDetail = ({
    datatableStore,
    commonStore,
    usersStore,
    resource,
    onDelete,
    updateResources,

}) => {

    const [udpateResource] = useMutation(
        DataTableServices.UPDATE_RESOURCES(),
        {
            errorPolicy: 'all'
        }
    )

    const [removeResource] = useMutation(
        DataTableServices.DELETE_RESOURCE(),
        {
            errorPolicy: 'all'
        }
    )

    // let title = truncate(resource?.name || '', 35)
    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, myFlat)
        return items
    }

    let clipbardCopy = resource?.rdata?.link
    let imageSrc = resource.rdata.image
    let small = 'https://louisville.edu/history/images/noimage.jpg/image'
    if (resource.type === 'image') {
        if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
        else {
            small = imagePreset(imageSrc, 'small')
        }
        clipbardCopy = imageSrc
    } else if (resource.type === 'text') {
        clipbardCopy = resource?.rdata?.text
    }

    const [products, setProducts] = useState(false)

    useEffect(() => {
        async function init() {
            if (resource?.row_ids) {
                let pRef = await findRowsByFilter(
                    {
                        tableId: commonStore?.company?.companyData?.product_table,
                        hasPreSearch: false,
                        limit: 100,
                        filter: { match: { "_id": resource.row_ids.filter(p => p !== null).join('|') } }
                    }
                )
                if (pRef) {
                    setProducts(pRef)
                }
            }
        }
        init()
    }, [])

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                    <Card size="small"
                        title={<div>{resource?.name}</div>}

                    >
                        {/* <h2>{resource?.name}</h2> */}
                        {resource.type === 'image' &&
                            <>
                                <Image
                                    src={imageSrc}
                                    preview={{
                                        src: imageSrc,
                                    }}
                                />

                            </>
                        }
                        {resource.type === 'link' &&
                            <>
                                <a href={resource?.rdata?.link}>{resource?.rdata?.link}</a>
                            </>
                        }
                        {resource.type === 'text' &&
                            <>
                                <div dangerouslySetInnerHTML={{ __html: mdParser.render(resource?.rdata?.text) }}></div>
                            </>
                        }
                        <Divider />
                        <Space>
                            <CopyToClipboard text={clipbardCopy}
                            >
                                <Button
                                    // style={{ background: bgColor, color: textColor, marginRight: 6, marginBottom: 10 }}
                                    size={'middle'}
                                    icon={<CopyOutlined />}
                                    key="submit"
                                >
                                    Copy
                                </Button>

                            </CopyToClipboard>
                            {/* <DownloadButton url={imageSrc} /> */}

                            {resource.type === 'image' &&
                                <Button
                                    // style={{ background: bgColor, color: textColor, marginRight: 6, marginBottom: 10 }}
                                    size={'middle'}
                                    icon={<DownloadOutlined />}
                                    onClick={async () => {
                                        let d = await downloadByResId(resource.id, imageSrc, resource.name)
                                        return d
                                        // alert('Sap co')
                                    }}
                                >
                                    Tải về
                                </Button>}

                            <Button
                                // style={{ background: bgColor, color: textColor, marginRight: 6, marginBottom: 10 }}
                                size={'middle'}
                                icon={<DeleteFilled />}
                                danger
                                onClick={async () => {
                                    // let input = {
                                    //     where: {
                                    //         id: resource.id
                                    //     }
                                    // }
                                    let input = {
                                        data: {
                                            id: resource.id,
                                            rdata: resource
                                        }
                                    }
                                    let r = await removeResource({
                                        variables: { input }
                                    })
                                    if (r.data?.removeResource?.data?.status === 'OK') {
                                        onDelete(resource.id)
                                    }
                                    // if (r.data?.deleteResource?.data?.status === 'OK') {
                                    //     setProducts(items)
                                    // }
                                    // alert('Sap co')
                                    // setShowSelectMany(true)
                                }}
                            >
                                Xóa
                            </Button>
                        </Space>
                        <Divider />
                        {/* {resource.rdata?.tags} */}
                        <TagsInput
                            initValue={resource.rdata?.tags || []}
                            tagTable={commonStore?.company?.companyData?.tag_table}
                            tagFilter={{ match: { "category": "Resource" } }}
                            onChange={async (v) => {
                                let input = {
                                    data: {
                                        id: resource.id,
                                        updateData: {
                                            "rdata.tags": v
                                        }
                                    }
                                }
                                let r = await udpateResource({
                                    variables: { input }
                                })
                                if (r.data?.udpateResource?.data?.status === 'OK') {
                                    resource.rdata.tags = v
                                    if (updateResources) updateResources(resource)
                                }

                            }}
                        />
                    </Card>


                </Col>
                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                    {products &&
                        <ManyProducts products={products}
                            view={'compact'}
                            onSave={async (items) => {
                                let input = {
                                    data: {
                                        id: resource.id,
                                        updateData: {
                                            "row_ids": items.map(p => p.key)
                                        }
                                    }
                                }
                                let r = await udpateResource({
                                    variables: { input }
                                })
                                if (r.data?.udpateResource?.data?.status === 'OK') {
                                    setProducts(items)
                                }

                                resource.row_ids = items.map(p => p.key)
                                if (updateResources) updateResources(resource)
                                // let product_keys = items.map(p => p.key || p.id)
                                // let se = rowData?.row_data?.name || ""
                                // if (items.length > 0) {
                                //     se += items.map(p => p.code).join(' ')
                                // }
                                // se = removeVietnameseCharMark(se)
                                // se = se.toLowerCase()
                                // let r = await updateManyDataRows({
                                //     variables: {
                                //         input: {
                                //             data: {
                                //                 type: "oldnew",
                                //                 rows: [{
                                //                     id: rowData.id,
                                //                     n: { 'products': product_keys },
                                //                     o: false,
                                //                     se: se
                                //                 }]
                                //             }
                                //         }
                                //     }
                                // })
                                // if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
                                //     setProducts(items)
                                // } else {
                                //     message.error('Lỗi cập nhật dữ liệu!')
                                // }
                            }} />
                    }
                </Col>
            </Row>
        </>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(ResourceDetail))