import logo from './logo.svg';
import './App.less';

import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { apolloClient } from './plugins'
import { Provider } from 'mobx-react'

import { ApolloProvider } from '@apollo/react-components'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
// import { DefaultLayout } from './components/layouts'
import PrivateRoute from './components/custom/PrivateRoute/PrivateRoute'
import { createBrowserHistory } from 'history'

import commonStore from './stores/commonStore'
import usersStore from './stores/usersStore'
import uiStore from './stores/uiStore'
import datatableStore from './stores/datatableStore'
import liveStore from './stores/liveStore'

// import theme from './components/styles/theme'
import GlobalStyle from './components/styles/GlobalStyle' //as css reset
import { ThemeProvider } from 'styled-components'
import 'react-perfect-scrollbar/dist/css/styles.css'

import { toJS } from 'mobx'
import {
  NotFound,
  Login,
  Dashboard,
  DataBoard,
  DataTable,
  DataTableLive,
  DataRow,
  DataReport,
  ToolsPage,
  QuickSyncPriceLivePage,
  QuickSetKeywordLivePage,
  QuickAdjustProductQty,
  QuickSetQtyProduct,
  QuickAddProducts,
  ProductSearchPage,
  OrderSearchPage
} from './components/pages'

const commonPath = [
  '/',
  '/dashboard',
  '/table/:id',
  '/board/:id',
  '/product/list'
]

const stores = {
  commonStore,
  usersStore,
  uiStore,
  datatableStore,
  liveStore
}

const history = createBrowserHistory()

function App() {
  const { theme } = stores.commonStore
  let company_slug = 'nhaiu'
  apolloClient
    .query({
      query: gql`
        query companies { 
          companies (where:{slug:"${company_slug}"} )
          {
            id,
            slug,
            name,
            companyData,
            logo {
              url
            }
          }
        }`
    })
    .then(
      result => {
        // console.log('result', result)
        stores.commonStore.setThemeColor({
          // sidebarBgColor: '#F1F6F9',
          sidebarBgColor: '#fff',
          sidebarBgColorSelected: '#e6e6e6',
          background: '#fff'
        })
        if (result.data?.companies) { stores.commonStore.setCompany(result.data?.companies[0]) }
      }
    ).finally(
      () => {
        stores.commonStore.setInitializing(false)
      }
    );

  // 
  // useQuery(
  //   gql`
  //      query companies { 
  //       companies (where:{company:{slug:"${company_slug}"}} )
  //       {
  //         id,
  //         slug,
  //         name,
  //         companyData
  //       }
  //     }`
  //   ,
  //   {
  //     onCompleted: data => {
  //       console.log('Company data', data)
  //     }
  //   }
  // )
  return (
    <Provider {...stores}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ApolloProvider client={apolloClient}>

          <Router history={history}>
            <Switch>
              <Route exact path='/auth/:authType' component={Login} />
              {/* <Route exact path='/' component={Dashboard} /> */}
              <PrivateRoute role={['chu-cong-ty', 'quan-ly', 'nhan-vien', 'leader2', 'leader1', 'nv-kho', 'nv-online']} path={commonPath}>
                <Route exact path='/' component={Dashboard} />
                {/* <Route exact path='/product/list' component={ProductListPage} /> */}
                <Route exact path='/table/:id' component={DataTable} />
                <Route exact path='/row/:id' component={DataRow} />
                <Route exact path='/live/:id' component={DataTableLive} />
                <Route exact path='/report/:id' component={DataReport} />
                {/* <Route exact path='/live/:id/:sid' component={DataTableLive} /> */}
                <Route exact path='/board/:id' component={DataBoard} />
                <Route exact path='/tools' component={ToolsPage} />
                <Route exact path='/tool/quick_sync_price_live' component={QuickSyncPriceLivePage} />
                <Route exact path='/tool/quick_set_kwo' component={QuickSetKeywordLivePage} />
                <Route exact path='/tool/quick_adj_qty' component={QuickAdjustProductQty} />
                <Route exact path='/tool/quick_set_qty' component={QuickSetQtyProduct} />
                <Route exact path='/tool/quick_add_products' component={QuickAddProducts} />
                <Route exact path='/tool/search_product/:id' component={ProductSearchPage} />
                <Route exact path='/tool/search_product' component={ProductSearchPage} />
                <Route exact path='/tool/search_order' component={OrderSearchPage} />
                <Route exact path='/tool/search_order/:id' component={OrderSearchPage} />

                {/* <Route exact path='/board/:id' component={DataTable} /> */}
              </PrivateRoute>
            </Switch>
          </Router>

        </ApolloProvider>
      </ThemeProvider>
    </Provider >

  );
}

export default App;
