import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'

// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Avatar,
    Form,
    Button,
    Image,
    Tag,
    Input,
    InputNumber,
    Modal,
    Card,
    // message,
    Space,
    message
} from 'antd';

import DatarowSelectInput from '../DataBoard/DataTools/DatarowSelectInput'
import DataTableServices from '../../../services/datatableServices'
import { imagePreset } from '../../../ultils/datatableUtils'

const QuickSetSLByBlock = ({
    datatableStore,
    commonStore,
    usersStore,
    initData,
    typeUpdate = "add",
    width = 480,
    onSave,
    onClose,
    branch = false,
    defaultBranch = false,
    isResetTag,
    resetNgaykiem,
    tagVitri = [],
    rows
}) => {


    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )

    // const [selectedData, setSelectedData] = useState(initData)
    const [updating, setupdating] = useState(false)
    // const [value, setValue] = useState(initData)
    const [currentValue, setCurrentValue] = useState(false)
    const [showSelectData, setShowSelectData] = useState(false)

    const [currentTags, setCurrentTags] = useState(initData?.new_tag_vitri || [])

    const [qRows, setQRows] = useState(rows)
    const styleType = {
        'add': {
            title: 'Bổ sung sản phẩm vào kho',
            color: 'green',
            btn: 'Thêm vào'
        },
        'sub': {
            title: 'Bỏ bớt sản phẩm ra',
            color: 'red',
            btn: 'Bỏ ra'
        },
        'update': {
            title: 'Đặt lại số lượng sản phẩm',
            color: 'blue',
            btn: 'Đặt lại'
        }
    }

    const [form] = Form.useForm();
    const closeModal = () => {
        onClose()
    }

    const onFinish = async (values) => {
        setupdating(false)
        let uRows = qRows.filter(qq => values[qq.key] !== undefined).map(u => {
            let new_qty = parseInt(values[u.key])
            new_qty = new_qty + parseInt(u.sl_tonkho)
            return {
                id: u.key,
                n: {
                    sl_tonkho: new_qty,
                    // sl_giuhang: 0,
                    ngay_kiem: (new Date()).toISOString()
                },
                o: false
            }
        })
        // console.log('uRows', uRows)
        let nilog = {
            log_data: {
                rows: qRows.filter(qq => values[qq.key] !== undefined).map(u => {
                    let new_qty = parseInt(values[u.key])
                    new_qty = new_qty + parseInt(u.sl_tonkho)
                    return {
                        id: u.key,
                        code: u.code,
                        sl_tonkho: u.sl_tonkho,
                        sl_tonkho_new: new_qty
                    }
                }),
                tag_vi_tri: datatableStore.tagVitriKiemSL,
                user: {
                    id: usersStore.currentUser.id,
                    uname: usersStore.currentUser.username,
                    avatar: usersStore.currentUser?.user_profile?.avatar?.url
                }
            },
            type: "kiemhang_log"
        }
        if (uRows.length >= 0) {
            let r = await updateManyDataRows({
                variables: {
                    input: {
                        data: {
                            type: "oldnew",
                            rows: uRows,
                            nilog: nilog
                        }
                    }
                }
            })

            if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
                qRows.forEach(qq => {
                    let new_qty = parseInt(values[qq.key])
                    new_qty = new_qty + parseInt(qq.sl_tonkho)
                    qq.sl_tonkho = new_qty
                });
                datatableStore.massUpdateRows("sl_tonkho", false, datatableStore.selectedRowKeys, qRows)
                message.success('Cập nhật SL nhiều sp theo kệ thành công', 2);
            }
        }
        // values.qty = parseInt(values.qty)
        // initData.sl_tonkho = parseInt(initData.sl_tonkho)
        // if (isNaN(values.qty)) {
        //   alert('So khong hop le')
        //   return
        // }
        // let newQty = initData.sl_tonkho || 0
        // let branches = initData.branches || {}
        // if (!initData.branches && defaultBranch) {
        //   branches[branch.map_tag] = 0
        //   branches[defaultBranch.map_tag] = newQty
        // }

        // switch (typeUpdate) {
        //   case 'add':
        //     newQty += values.qty
        //     branches[branch.map_tag] += values.qty
        //     break;
        //   case 'sub':
        //     newQty -= values.qty
        //     if (branches[branch.map_tag] > values.qty) {
        //       branches[branch.map_tag] -= values.qty
        //     }
        //     if (newQty < 0) {
        //       alert('SL bỏ ra lớn hơn tồn kho hiện tại!')
        //       return
        //     }
        //     break;
        //   case 'update':
        //     newQty = values.qty
        //     branches[branch.map_tag] = values.qty
        //   default:
        //     break;
        // }

        // let quanityLogs = {
        //   n: { sl_tonkho: newQty, branches: branches },
        //   o: { sl_tonkho: initData.sl_tonkho },
        //   extra: {
        //     code: initData.code,
        //     name: initData.name,
        //     type: typeUpdate,
        //     qty: values.qty,
        //     branch: branch
        //   },
        //   row_id: initData?.id
        // }
        // onSave(
        //   {
        //     image: initData.image,
        //     quanityLogs: quanityLogs,
        //     color: styleType[typeUpdate].color,
        //     tagUpdate: currentTags || []
        //   }
        // )
        onSave(values)

    };

    // const onRemove = () => {
    //   let v = { ...currentValue, remove: true }
    //   setupdating(false)
    //   onSave(
    //     v
    //   )
    // }

    // const onReset = () => {
    //   form.resetFields();
    // };

    const layout = {
        labelCol: {
            span: 18,
        },
        wrapperCol: {
            span: 6,
        },
    };

    const QuantityForm = () => {
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        const PItem = ({ r }) => {
            let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
            let imageSrc = r.image
            if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
            else {
                thumbnail = imagePreset(imageSrc, 'xsmall')
            }
            return (
                <>
                    <span>
                        {r.name} - (<b style={{ color: "blue" }}>{r.sl_tonkho}</b>)
                    </span>
                    <b style={{ background: 'green', color: 'white', padding: "3px 4px", margin: "0 10px", borderRadius: 4 }}>
                        {r.code}
                    </b>
                    <Image
                        width={36}
                        height={36}
                        src={thumbnail}
                        // style={{ marginRight: 5 }}
                        preview={{
                            src: imageSrc,
                        }} />
                </>
            )
        }

        const qtyRef = useRef(null)
        useEffect(() => {
            if (qtyRef.current) {
                qtyRef.current.focus();
                // sleep(100)
                // form.setFieldsValue({ qty: "" })
            }
        }, [qtyRef])
        // let columns = datatable.table_columns ? datatable.table_columns : datatable.data_table_template.table_columns
        return (
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // initialValues={{
                //   qty: ""
                // }}
                onFinish={onFinish}>

                {qRows.map(r => {
                    return (
                        <Form.Item
                            style={{ textAlign: 'left' }}
                            name={r.key}
                            label={<PItem r={r} />}
                        >
                            <InputNumber />
                        </Form.Item>
                    )
                })}
            </Form>
        )
    }

    const SelectBlock = () => {

        const [showTag, setShowTag] = useState(true)

        useEffect(() => {
        }, [datatableStore.tagVitriKiemSL])

        return (
            <>
                <span style={{ marginRight: 8 }}>Vị trí:</span>
                {
                    !datatableStore.tagVitriKiemSL ?
                        <DatarowSelectInput
                            tableId={commonStore?.company?.companyData?.tag_table}
                            placeholder={'thẻ vị trí'}
                            width={200}
                            viewType={'tag'}
                            initValue={datatableStore.tagVitriKiemSL || []}
                            initFilter={{
                                "match": {
                                    "category": "Sản phẩm",
                                    "sub_cate": "Tag vị trí"
                                }
                            }}
                            size={'small'}
                            onOk={(item) => {
                                datatableStore.setTagVitriKiemSL(item)
                            }}
                        /> :
                        <>
                            <span style={{ background: 'black', color: 'white', padding: "3px 4px", margin: "0 10px", borderRadius: 4 }}
                                onClick={() => {
                                    datatableStore.setTagVitriKiemSL(false)
                                }}
                            >
                                {datatableStore.tagVitriKiemSL}
                            </span>
                        </>

                }
            </>
        )
    }


    return (
        <Modal
            title={<SelectBlock />}
            visible={true}
            width={"100%"}
            // height={"100%"}
            maskClosable={false}
            onCancel={closeModal}
            centered
            // footer={false}
            footer={[
                <Button
                    onClick={closeModal}
                    disabled={updating}>
                    Hủy thay đổi
                </Button>,
                <Button key="submit" type="primary"
                    disabled={!(datatableStore.tagVitriKiemSL && datatableStore.tagVitriKiemSL.length > 0)}
                    loading={updating}
                    onClick={() => {
                        setupdating(true)
                        form.submit()
                    }}>
                    Thêm vào
                </Button>,
            ]}
        >
            <QuantityForm />
        </Modal>
    )
}
export default inject("datatableStore", "commonStore", "usersStore")(observer(QuickSetSLByBlock))