import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useMutation } from '@apollo/react-hooks'
import {
    Button,
    List,
    Avatar,
    Tag,
    Space,
    Divider,
} from 'antd';
// import moment from 'moment'
import {
    AlignLeftOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';

import { date_str } from '../../../ultils/datatableUtils'
import { jobType, jobStatus } from './JobType'

import CheckQtyProductList from './CheckQtyProductList'

import NoteModal from '../Note/NoteModal'
import DataTableServices from '../../../services/datatableServices'

const JobDongHang = ({
    datatableStore,
    commonStore,
    usersStore,
    onClick,
    onDelete,
    job,
    rdata,
}) => {

    const [daxacnhan, setDaxacnhan] = useState(false)
    const [skipNoteModal, setSkipNoteModal] = useState(false)


    const [saveJob] = useMutation(
        DataTableServices.SAVE_JOB(),
        {
            errorPolicy: 'all'
        }
    )

    let jt = jobType.find(j => j.type === job.type)
    let jstatus = jobStatus.find(j => j.type === job.status)
    let uref = datatableStore.usersRef[job?.owner?.id]
    let ava = uref?.avatar

    let skipColor = "red"
    if (job?.jdata?.requestSkip?.status === "đồng ý") skipColor = "green"
    if (job?.jdata?.requestSkip?.status === "chờ xác nhận") skipColor = "orange"


    return (
        <div className='job-card'>
            {/* <strong>Nhiệm vụ: </strong> {jt?.name}
            <br />
            <Space>
                <Avatar size={32} src={ava} />
                {uref?.name}
                <Tag title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>
            </Space>
            <br />
            <br />
            <strong>Bắt đầu: </strong> {date_str(job?.createdAt)}
            <br />
            <strong>Kết thúc: </strong> {date_str(job?.jdata?.done_time)}

            <br /> */}

            <List

                header={<h3 style={{ backgroundColor: jt.bgcolor, color: "#fff", padding: 8, borderRadius: 16 }}> <strong>Nhiệm vụ: </strong> {jt?.name}</h3>}
                // footer={
                //     usersStore.currentUser.id === job.owner.id || ['quan-ly', 'chu-cong-ty'].includes(usersStore.currentUser.company_role) ?
                //         <div>
                //             <Space>
                //                 <Button size={"small"} type={"primary"}>Hoàn thành</Button>
                //                 <Button size={"small"} style={{ backgroundColor: "orange", color: "#fff" }}>Tạm hoãn</Button>
                //                 <Button size={"small"} danger
                //                     onClick={() => {
                //                         if (usersStore.currentUser.id !== job.owner.id) {
                //                             alert('Chỉ có người tạo task mới đc xóa!')
                //                             return false
                //                         }
                //                         alert('ok')
                //                     }}
                //                 >Xóa</Button>
                //             </Space>
                //         </div> : false
                // }
                bordered
            >
                {job?.jdata?.requestSkip &&
                    <List.Item>
                        <div style={{ color: "black", backgroundColor: "#eee", padding: 10, borderRadius: 6, width: "100%" }}>
                            <div style={{ marginBottom: 15 }}>
                                <Avatar
                                    width={32}
                                    height={32}
                                    // src={config.ASSET_URL + "users/" + job.owner.id + ".jpg"}
                                    src={job?.jdata?.requestSkip?.creator?.avatar || "https://joeschmoe.io/api/v1/random"}
                                    style={{ marginRight: 10 }}
                                />
                                <strong>{job?.jdata?.requestSkip?.creator.name} muốn tạm hoãn ?</strong>
                                {job?.jdata?.requestSkip?.note &&
                                    <>
                                        <br />
                                        <br />
                                        <strong>Lý do : </strong>{job?.jdata?.requestSkip?.note}
                                        <br />
                                        <div style={{ marginTop: 10, marginBottom: 20, width: "100%" }}>
                                            <strong >Trạng thái : </strong>
                                            <span style={{
                                                backgroundColor: skipColor,
                                                color: "#fff",
                                                padding: 5,
                                                borderRadius: 6
                                            }}>
                                                {job?.jdata?.requestSkip?.status}
                                            </span>
                                        </div>

                                    </>
                                }
                            </div>
                            {job?.jdata?.requestSkip?.status === "chờ xác nhận" &&
                                <>
                                    <Divider />
                                    <Space>
                                        <Button type="primary"
                                            onClick={() => {
                                                datatableStore.saveRequestSkipJob({
                                                    id: job.id,
                                                    creator: job.jdata?.requestSkip?.creator,
                                                    note: job.jdata?.requestSkip?.note,
                                                    status: "đồng ý"
                                                },
                                                saveJob,
                                                
                                                )
                                                if (usersStore.socket)
                                                    usersStore.socket.emit("acceptSkipJob", { id: job.id, status: "đồng ý" })


                                            }}

                                        >Đồng ý</Button>
                                        <Button type="primary" danger
                                            onClick={() => {
                                                datatableStore.requestSkipJob({
                                                    id: job.id,
                                                    creator: job.jdata?.requestSkip?.creator,
                                                    note: job.jdata?.requestSkip?.note,
                                                    status: "không đồng ý"
                                                })
                                                if (usersStore.socket)
                                                    usersStore.socket.emit("acceptSkipJob", { id: job.id, status: "không đồng ý" })
                                            }}
                                        >Không đồng ý</Button>
                                    </Space>
                                </>
                            }

                        </div>
                    </List.Item>
                }
                <List.Item>
                    <Space>
                        <Avatar size={32} src={ava} />
                        {uref?.name}
                        <Tag title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>
                    </Space>
                </List.Item>
                <List.Item>
                    <Space>
                        <ClockCircleOutlined style={{ fontSize: "32px" }} />  <strong>Bắt đầu: </strong> {date_str(job?.createdAt)}
                    </Space>
                </List.Item>
                <List.Item>
                    <Space>
                        <ClockCircleOutlined style={{ fontSize: "32px" }} /> <strong>Kết thúc: </strong> {date_str(job?.jdata?.done_time)}
                    </Space>
                </List.Item>
            </List>
            <br />

            <List
                header={<h3> <strong>Tổng quan</strong></h3>}
                footer={
                    <>
                        {usersStore.currentUser.id === job.owner.id || ['quan-ly', 'chu-cong-ty'].includes(usersStore.currentUser.company_role) ?
                            <Space>
                                <Button size={"small"} type={"primary"} disabled={{ daxacnhan }}>Hoàn thành</Button>


                                <Button size={"small"} style={{ backgroundColor: "orange", color: "#fff" }}
                                    onClick={() => {
                                        if (usersStore.socket)
                                            usersStore.socket.emit("requestSkipJob", { id: job.id })
                                    }}
                                >Tạm hoãn</Button>

                                <Button size={"small"} danger
                                    onClick={async () => {
                                        await onDelete(job.id)
                                        // let input = {
                                        //     data: {
                                        //         ids: [job.id]
                                        //     }
                                        // }
                                        // let r = await deleteJob({
                                        //     variables: { input }
                                        // })
                                        // let result = r?.data?.deleteJob?.data
                                        // console.log('result', result)


                                        // if (usersStore.currentUser.id !== job.owner.id) {
                                        //     alert('Chỉ có người tạo task mới đc xóa!')
                                        //     return false
                                        // }
                                        // alert('ok')

                                    }}
                                >Xóa</Button>
                            </Space> : false}

                    </>
                }
                bordered
            >
                <List.Item>
                    <strong>Tình trạng sp</strong> : Đủ hàng
                </List.Item>
                {usersStore.currentUser.id !== job.owner.id && !['quan-ly', 'chu-cong-ty'].includes(usersStore.currentUser.company_role) &&
                    <List.Item>
                        <Button size={"small"} style={{ backgroundColor: "orange", color: "#fff" }}
                            onClick={() => {
                                setSkipNoteModal(true)
                                // if (usersStore.socket) {
                                //     let odata = {
                                //         cname: rdata.customer.name,
                                //         code: rdata.code,
                                //         mobile: rdata.customer.mobile,
                                //         id: rdata.key,
                                //         tong_tien: rdata.tong_tien,
                                //     }
                                //     usersStore.socket.emit("requestSkipJob", { id: job.id, row: odata })

                                // }
                            }}
                        >Yêu cầu tạm hoãn</Button>
                    </List.Item>
                }

            </List>
            <br />
            <CheckQtyProductList products={rdata.items} hangthieu={job.hangthieu} />

            {skipNoteModal &&
                <NoteModal
                    title="Gửi yêu cầu hoãn đơn"
                    okLabel={"YC hoãn đơn"}
                    onSave={(note) => {
                        // alert(note)
                        console.log('note', note)
                        if (usersStore.socket) {
                            let odata = {
                                cname: rdata.customer.name,
                                code: rdata.code,
                                mobile: rdata.customer.mobile,
                                id: rdata.key,
                                tong_tien: rdata.tong_tien,
                            }
                            usersStore.socket.emit("requestSkipJob", { id: job.id, row: odata, note: note })
                        }
                        setSkipNoteModal(false)
                    }}
                    onClose={() => {
                        setSkipNoteModal(false)
                    }}

                />}

        </div>
        // <List.Item>
        //     <List.Item.Meta
        //         onClick={onClick}
        //         avatar={<Avatar style={{ backgroundColor: jt.bgcolor }} shape="square" size={64}>{jt.name}</Avatar>}
        //         // avatar={<Avatar style={{ backgroundColor: jt.bgcolor } } shape="square" size={64}>{jt.name}</Avatar>}
        //         // avatar={
        //         //     <Image
        //         //         width={64}
        //         //         height={64}
        //         //         // src={config.ASSET_URL + "users/" + job.owner.id + ".jpg"}
        //         //         src={uref?.avatar}
        //         //     />}
        //         // title={jt.name}
        //         title={
        //             <Space>
        //                 <Avatar size={32} src={ava} />
        //                 {uref?.name}
        //             </Space>

        //         }
        //         description={
        //             <>
        //                 <strong>Trạng thái: </strong> <Tag title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>
        //                 <br />
        //                 <strong>Bắt đầu: </strong> {date_str(job?.createdAt)}
        //                 <br />
        //                 <strong>Kết thúc: </strong> {date_str(job?.jdata?.done_time)}
        //             </>

        //         }
        //     />
        // </List.Item>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(JobDongHang))