import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import moment from 'moment'
import {
    Form,
    Button,
    DatePicker,
    Select,
    Input,
    Space,
    Modal,
    Table,
    message,
} from 'antd';

import {
    PlusOutlined,
    SearchOutlined,
} from '@ant-design/icons';

import { useMutation } from '@apollo/react-hooks'
import DataTableServices from '../../../../services/datatableServices'

import { nanoid } from 'nanoid'
const { Search } = Input;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DEFAULT_LIMIT = 100
const RowsSearchModal = ({
    datatableStore,
    dataType,
    title = "Chọn dữ liệu",
    findItems,
    width,
    columns,
    filterData,
    onOk,
    onOk2,
    onClose,
    okLabel = 'Chọn',
    defaultValue,
    rowKeyValue,
    selectType = "checkbox",
    extraData = false,
    rowKey
}) => {

    const [rows, setRows] = useState(false)
    const [dupId, setDupId] = useState([])
    const [selectedKey, setSelectedKey] = useState([])
    const [loading, setLoading] = useState(false)
    const [syncing, setSyncing] = useState(false)

    const [params, setParams] = useState({})
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(DEFAULT_LIMIT)
    const [itemCount, setItemCount] = useState(0)

    // function myFlat(v) {
    //     return Object.assign(v.row_data, { key: v.id })
    // }

    // 
    const [removeSLProducts] = useMutation(
        DataTableServices.REMOVE_SL_PRODUCTS(),
        {
            errorPolicy: 'all'
        }
    )

    // 
    const [createSLProduct] = useMutation(
        DataTableServices.CREATE_SL_PRODUCT(),
        {
            errorPolicy: 'all'
        }
    )



    const doSearch = async () => {
        setLoading(true)
        // let p = toJS(datatableStore.modalFilter)
        let result = await findItems(
            {
                params: datatableStore.modalFilter
            }
        )
        // datatableStore.setModalRows(allItems.items)

        setLoading(false)
        if (result) {
            // console.log('result.items', result.items)
            setRows(result.items)
            let dId = []
            result.items.forEach(o => {
                if (o.dup) dId.push(o.sl_oid)
            });
            setDupId(dId)
            // if (result.pagination) {
            //     setItemCount(result.items.lengh)
            // }
            setItemCount(result.items.length)

            if (selectType === 'radio') {
                let keyItem = result.items.find(p => p[rowKey] === rowKeyValue)
                if (keyItem) {
                    // console.log('keyItem[rowKey]', keyItem[rowKey])
                    setSelectedKey([keyItem[rowKey]])
                }
                else if (result.items && result.items[0])
                    setSelectedKey([result.items[0][rowKey]])
            }
        } else {
            setRows([])
        }


        // console.log(`items`, items)
    }

    useEffect(() => {
        datatableStore.setModalFilter(defaultValue)
        // async function init() {
        //     await doSearch(initOrder?.customer?.mobile)
        // }
        // init()
    }, [])

    useEffect(() => {
    }, [selectedKey.length])

    const onPageChange = (pagination, filters, sorter) => {
        let search = false
        let u = {}
        // u.page = pagination.current
        // u.per_page = pagination.pageSize
        // datatableStore.setModalFilter(u)
    }

    useEffect(() => {

    }, [datatableStore.modalRows])


    // const notDupSelectedKey = (srk) => {
    //     let k = srk.filter(s => !dupId.includes(s))
    //     return k
    // }

    const rowSelection = {
        type: selectType,
        // selectedRowKeys: notDupSelectedKey(selectedKey),
        selectedRowKeys: selectedKey,
        onChange: (srk) => {
            // datatableStore.setSelectedRowKeys(srk)
            setSelectedKey(srk)
            // console.log(`srk`, srk)
        }
    };

    useEffect(() => {
        doSearch()
    }, [datatableStore.modalFilter.page, datatableStore.modalFilter.per_page])


    const FilterPanel = () => {
        return (
            <Space>
                {filterData.map(f => {
                    if (f.type === "text") {
                        return (
                            <Search
                                style={{ width: 250 }}
                                defaultValue={datatableStore.modalFilter[f.key] ? datatableStore.modalFilter[f.key] : ""}
                                placeholder={f.placeholder}
                                onChange={(e) => {
                                    // console.log('e.target.value', e.target.value)
                                    let v = {}
                                    v[f.key] = e.target.value
                                    datatableStore.setModalFilter(v)
                                }}
                            // enterButton 
                            />
                        )
                    }
                    if (f.type === 'date_range') {
                        return (
                            <RangePicker
                                onChange={(value, dateString) => {
                                    let gte = moment(value[0]).toISOString()
                                    let lte = moment(value[1]).toISOString()
                                    // console.log('gte', gte)
                                    let v = {}
                                    v[f.dateAfterKey] = gte
                                    v[f.dateBeforeKey] = lte
                                    datatableStore.setModalFilter(v)
                                }}
                                format="DD-MM-YYYY"
                                ranges={{
                                    'Hôm nay': [moment(), moment()],
                                    'Tuần này': [moment().startOf('week'), moment().endOf('week')],
                                    'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                }}
                            />
                        )
                    }
                    if (f.type === 'date_customer_lte') {
                        return (
                            <DatePicker
                                onChange={(value, dateString) => {
                                    // let gte = moment(value[0]).toISOString()
                                    let lte = moment(value).toISOString()
                                    // console.log('gte', gte)
                                    let v = {}
                                    // v["created_at"] = "gte:"+gte
                                    v["created_at"] = "lte:" + lte
                                    datatableStore.setModalFilter(v)
                                }}
                                format="DD-MM-YYYY"
                            // ranges={{
                            //     'Hôm nay': [moment(), moment()],
                            //     'Tuần này': [moment().startOf('week'), moment().endOf('week')],
                            //     'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                            // }}
                            />
                        )
                    }

                    if (f.type === 'select') {
                        return (
                            <Select style={{ width: 200 }} defaultValue={datatableStore.modalFilter[f.key] ? datatableStore.modalFilter[f.key] : defaultValue[f.key]}
                                onChange={(value) => {
                                    let u = {}
                                    if (f.key) {
                                        u[f.key] = value
                                        datatableStore.setModalFilter(u)
                                    }

                                }}
                            >
                                {
                                    Object.entries(f.optionsData).map(s => {
                                        return (
                                            <Option value={s[0]}>{s[1]}</Option>
                                        )
                                    })
                                }
                            </Select>
                        )
                    }
                })}
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={() => {
                        doSearch()
                        // alert(datatableStore.modalFilter.query)
                    }}
                >Tìm kiếm</Button>

                {dataType === 'sl_product' &&
                    <Button
                        // type="primary"
                        icon={<PlusOutlined />}
                        onClick={async () => {
                            // console.log('extraData', extraData)
                            let w = extraData.weight || 0
                            if (extraData) {
                                let newP = await createSLProduct({
                                    variables: {
                                        input: {
                                            data: {
                                                product: {
                                                    handle: extraData.code,
                                                    // product_category: "TestTest",
                                                    vendor: "Nhaiu",
                                                    // published_scope: "web",
                                                    "title": extraData.code + '-' + extraData.name,
                                                    // "sku": extraData.code,
                                                    // "weight": Math.round(w / 1000),
                                                    "status": "active",
                                                    // "price": extraData.price || 1,
                                                    // "price_sale": extraData.price_live || 1,
                                                    "images": [
                                                        {
                                                            alt: extraData.code,
                                                            src: extraData.image || "https://hub.nhaiu.vn/xsmall-noimage.jpg"
                                                        }

                                                    ],
                                                    "variants": [
                                                        {
                                                            "inventory_quantity": extraData.so_luong,
                                                            "inventory_policy": "deny",
                                                            "inventory_tracker": true,
                                                            "sku": extraData.code,
                                                            "price": (extraData.price_live_base || 1).toString(),
                                                            "compare_at_price": extraData.price.toString(),
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    }
                                })
                                // if (newP) {
                                //     await doSearch()
                                // }
                            }
                            // console.log('first', first)
                        }}
                    >Thêm mới sp shopline</Button>
                }
            </Space>
        )
    }

    let footer = [
        <Button onClick={onClose}>
            Đóng
        </Button>,
        <Button
            loading={syncing}
            disabled={selectedKey.length < 1}
            key="submit" type="primary"
            onClick={async () => {
                if (rowKey === 'sl_oid') {
                    // let ids = notDupSelectedKey(selectedKey)
                    let ids = selectedKey
                    setSyncing(true)
                    await onOk(rows.filter(r => ids.includes(r.sl_oid)))
                    setSyncing(false)
                } else {
                    await onOk(rows.filter(r => selectedKey.includes(r[rowKey])))
                }
            }}>
            {okLabel}
        </Button>,
    ]

    if (dataType === 'sl_product') {
        footer.unshift(
            <Button
                key="submit2" type="ghost"
                onClick={async () => {
                    let ids = rows.map(r => r.sl_id)
                    if (selectedKey.length === 1) {
                        ids = ids.filter(i => i !== selectedKey[0])
                        // console.log('ids', ids)
                        if (ids.length > 0) {
                            let d = await removeSLProducts({
                                variables: {
                                    input: {
                                        data: {
                                            ids: ids
                                        }
                                    }
                                }
                            })
                        }
                        onOk(rows.filter(r => selectedKey.includes(r[rowKey])))
                    } else {
                        alert('Chưa chọn sp đồng bộ')
                    }

                }}>
                Chọn và xóa sp trùng mã
            </Button>
        )
    }

    if (dataType === 'sl_order') {
        footer.unshift(
            <Button
                key="submit2" type="ghost"
                onClick={async () => {
                    if (rowKey === 'sl_oid') {
                        onOk2(rows)
                    }
                }}>
                Chỉ đồng bộ buổi live
            </Button>
        )
    }

    if (dataType === 'sl_customer') {
        footer.unshift(
            <Button
                key="submit2" type="ghost"
                onClick={async () => {
                    // if (rowKey === 'sl_oid') {
                    //     onOk2(rows)
                    // }
                }}>
                Đồng bộ và xóa lặp
            </Button>
        )
    }

    return (
        <Modal
            // title={title ? title : `Chỉnh sửa ${dataTextName}`}
            title={title ? title : false}
            visible={true}
            width={width ? width : "95%"}
            maskClosable={false}
            onCancel={onClose}
            centered
            footer={footer}
        >
            {filterData && <FilterPanel />}
            <div className="resultItems" style={{ minHeight: 400 }}>
                {rows && <Table
                    rowKey={rowKey || 'id'}
                    columns={columns}
                    loading={loading}
                    // dataSource={rows}
                    dataSource={rows}
                    rowSelection={rowSelection}
                    pagination={{
                        // defaultCurrent: defaultCurrent,
                        // defaultCurrent: getDefaultCurrent(),
                        // defaultCurrent: Math.floor((start ? start : 0) / (limit ? limit : DEFAULT_LIMIT)) + 1,
                        defaultCurrent: datatableStore.modalFilter.page || 1,
                        pageSize: datatableStore.modalFilter.per_page ? datatableStore.modalFilter.per_page : DEFAULT_LIMIT,
                        showLessItems: true,
                        showSizeChanger: true,
                        size: 'small',
                        defaultPageSize: DEFAULT_LIMIT,
                        pageSizeOptions: [50, 100, 150, 250],
                        total: itemCount,
                        showTotal: total => `${total} dữ liệu`,
                        position: ['none', 'bottomLeft']
                    }}
                    onChange={onPageChange}
                    size="small"
                >
                </Table>}
            </div>
        </Modal>
    )
}
export default inject("datatableStore")(observer(RowsSearchModal))