import { makeAutoObservable, toJS } from "mobx"
import { nanoid } from "nanoid";
import { build_search_by_column } from '../ultils/datatableUtils'
// import { useQuery, useMutation } from '@apollo/react-hooks'

class LiveStore {
  // currentLiveOrder = false
  // currentCustomerMessages = false
  currentCustomerRecord = false
  liveProducts = false
  liveOrders = []
  suggestionCodes = false
  rejectCode = {}
  refProduct = false
  codePrefix = 'abcdhklmt'
  // codePrefix = 'a-z'
  // code_tmp_similar = ''
  orderTable = false
  updateCount = 0
  somethingUnsave = false
  liveProductUnsaved = false
  productsDeleted = []

  currentSearchCode = false
  currentMsgCode = false
  // showAllComment = true

  // currentOrder = false

  constructor() {
    makeAutoObservable(this)
  }

  // setShowAllComment = (b) => {
  //   this.showAllComment = b
  // }

  setProductsDeleted = (pd) => {
    this.productsDeleted = pd
  }

  setCurrentSearchCode = (code) => {
    if (code) code = code.toLowerCase()
    this.currentSearchCode = code
    // console.log(`aaaaa code`, code)
  }

  setSomethingUnsave = (b) => {
    this.somethingUnsave = b
  }
  setCurrentMsgCode = (code) => {
    this.currentMsgCode = code
  }

  get currentOrderItems() {
    let index = this.liveOrders.findIndex(lo => lo.customer?.lsid === this.currentCustomerRecord.sid)
    if (index > -1) return this.liveOrders[index]?.items
    return false
    // console.log(`this.liveOrders[index]`, toJS(this.liveOrders[index]))
    // console.log(`this.liveOrders[index].items`, this.liveOrders[index].items)

    // return this.liveOrders.find(item => item.sid === this.currentCustomerRecord.sid)?.items
  }

  get currentLiveOrder() {
    return this.liveOrders.find(item => item?.customer.lsid === this.currentCustomerRecord?.sid)
  }

  get allUnsavedOrder() {
    return this.liveOrders.filter(item => item.unsaved && item.code)
  }

  get allUnsavedLiveProduct() {
    return this.liveProducts.filter(item => item.unsaved && item.sid)
  }

  get visibleLiveProducts() {
    return this.liveProducts.filter(item => {
      if (!this.productsDeleted) return true
      return !this.productsDeleted.includes(item.code)
    }
    )
  }

  get someUnsaved() {
    // if (this.liveOrders) if (this.liveOrders.some(o => o.unsaved && o.code)) {
    //   // console.log(toJS(this.liveOrders))
    //   return true
    // }
    if (this.liveProducts) if (this.liveProducts.some(lp => lp.unsaved && lp.sid)) {
      return true
    }
    return false
  }
  // get allLiveProductUnsaved() {
  //   return this.liveProducts.filter(item => item.unsaved)
  // }

  allBeSaved = () => {
    this.liveOrders.forEach(item => {
      if (item.unsaved) item.unsaved = false
    });
    this.updateCurrentRecord()
    // console.log(`this.liveOrders`, toJS(this.liveOrders))
    // this.somethingUnsave = false
  }

  allLPBeSaved = () => {
    this.liveProducts.forEach(item => {
      if (item.unsaved) item.unsaved = false
    });
    this.updateCurrentRecord()
    // this.somethingUnsave = false
  }



  updateOrderItemNote = (note, itemIndex) => {
    let index = this.liveOrders.findIndex(item => item.sid === this.currentCustomerRecord.sid)
    this.liveOrders[index].items[itemIndex].note = note
    this.liveOrders[index].unsaved = true
    this.updateCurrentRecord()
    // this.somethingUnsave = true
    // this.currentLiveOrder = toJS(this.liveOrders[index])
  }

  reCalcCodeQty = (code, liveData) => {
    let liveDataIndex = -1
    liveDataIndex = this.liveProducts.findIndex(item => item.code === code)

    if (!liveData) {
      if (liveDataIndex > -1)
        liveData = this.liveProducts[liveDataIndex]
    }

    if (!liveData.customers) return
    // console.log(`liveData`, toJS(liveData))
    // console.log(`liveData`, liveData)
    let qtyRemain = liveData.qty_live
    if (!qtyRemain) qtyRemain = 0

    liveData.customers.forEach(customer => {
      customer.remain = qtyRemain
      let orderIndex = this.liveOrders.findIndex(item => item.customer?.lsid === customer.sid)
      let itemIndex = false
      if (orderIndex > -1) {
        // if (this.liveOrders[orderIndex].code == '')
        // if (customer.sid =='4072256799526569') {
        //   console.log(`customer`, customer)
        //   debugger
        // }
        itemIndex = this.liveOrders[orderIndex].items.findIndex(p => p.code.toLowerCase() === code.toLowerCase())
      }

      let qtyOrder = customer.quanity
      if (orderIndex > -1 && itemIndex > -1) {
        // neu don da duoc tao
        if (!this.liveOrders[orderIndex].ngay_tao) {
          if (qtyRemain > 0) {
            if (this.liveOrders[orderIndex].items[itemIndex].quanity > 0) {
              qtyOrder = this.liveOrders[orderIndex].items[itemIndex].quanity

              let orderRemain = qtyRemain - qtyOrder
              if (orderRemain < 0) {
                // if (this.liveOrders[orderIndex].items[itemIndex].remain != 0) {
                //   this.liveOrders[orderIndex].items[itemIndex].remain = 0
                //   this.liveOrders[orderIndex].unsaved = true
                //   // debugger
                //   console.log('11111')
                //   // this.somethingUnsave = true
                // }

                if (this.liveOrders[orderIndex].items[itemIndex].qty !== orderRemain + qtyOrder && this.liveOrders[orderIndex].items[itemIndex].edit_count < 1) {
                  this.liveOrders[orderIndex].items[itemIndex].qty = orderRemain + qtyOrder
                  this.liveOrders[orderIndex].items[itemIndex].remain = orderRemain
                  this.liveOrders[orderIndex].unsaved = true
                  console.log(`code1 `, this.liveOrders[orderIndex].items[itemIndex].code)
                  // this.somethingUnsave = true
                }
              } else
                if (orderRemain != this.liveOrders[orderIndex].items[itemIndex].remain && this.liveOrders[orderIndex].items[itemIndex].edit_count < 1) {
                  this.liveOrders[orderIndex].items[itemIndex].remain = orderRemain
                  this.liveOrders[orderIndex].unsaved = true
                  // console.log(`code2 `, this.liveOrders[orderIndex].items[itemIndex].code )
                  // this.somethingUnsave = true
                }
              if (this.liveOrders[orderIndex].items[itemIndex].qty !== qtyOrder && this.liveOrders[orderIndex].items[itemIndex].edit_count < 1) {
                this.liveOrders[orderIndex].items[itemIndex].qty = qtyOrder
                this.liveOrders[orderIndex].unsaved = true
                // console.log(`code3 `, this.liveOrders[orderIndex].items[itemIndex].code )
              }
            }

          } else {
            // customer.qty = 0
            if (this.liveOrders[orderIndex].items[itemIndex].qty !== 0 && this.liveOrders[orderIndex].items[itemIndex].edit_count < 1) {
              this.liveOrders[orderIndex].items[itemIndex].qty = 0
              this.liveOrders[orderIndex].unsaved = true
              // console.log(`code4 `, this.liveOrders[orderIndex].items[itemIndex].code)
            }
          }

        }
      }
      qtyRemain = qtyRemain - qtyOrder
      if (qtyRemain < 0) qtyRemain = 0
      // if (qtyRemain > 0) {
      //   qtyRemain = qtyRemain - customer.quanity
      //   customer.qty = customer.quanity
      //   if (qtyRemain < 0) {
      //     customer.qty = qtyRemain + customer.quanity
      //     qtyRemain = 0
      //   }
      // } else {
      //   customer.qty = 0
      // }
      // this.updateOrderItem(customer, code)
    });

    // console.log(`this.liveProducts[liveDataIndex]`, this.liveProducts[liveDataIndex])
    if (liveDataIndex > -1) {
      if (this.liveProducts[liveDataIndex].remain != qtyRemain) {
        this.liveProducts[liveDataIndex].remain = qtyRemain
        // this.liveProducts[liveDataIndex].unsaved = true
      }
    }
  }

  reCalcAllCode = () => {
    for (let index = 0; index < this.liveProducts.length; index++) {
      this.reCalcCodeQty(this.liveProducts[index].code, this.liveProducts[index])
    }
    // console.log(`this.liveProducts`, toJS(this.liveProducts))
    // console.log(`this.liveProducts`, this.liveProducts)
    // console.log(`this.liveOrders`, this.liveOrders)
  }

  updateOrderItem = (customer, code) => {
    let index = this.liveOrders.findIndex(item => item.sid === customer.sid)
    if (index < 0) return
    let itemIndex = this.liveOrders[index].items.findIndex(p => p.code === code)
    if (itemIndex < 0) return
    // console.log(`this.liveOrders[index].items[itemIndex]`, this.liveOrders[index].items[itemIndex])
    // console.log(`customer`, customer)
    if (this.liveOrders[index].items[itemIndex].quanity !== customer.qty || this.liveOrders[index].items[itemIndex].remain !== customer.remain) {
      if (this.liveOrders[index].items[itemIndex].id) {
        this.liveOrders[index].unsaved = true
        // this.somethingUnsave = true
      }
      this.liveOrders[index].items[itemIndex].quanity = customer.qty
      this.liveOrders[index].items[itemIndex].remain = customer.remain

    }
  }
  // get currentOrderItems() {
  //   let index = this.liveOrders.findIndex(item => item.sid === this.currentCustomerRecord.sid)
  //   return this.liveOrders[index].items
  //   // return this.liveOrders.find(item => item.sid === this.currentCustomerRecord.sid)?.items
  // }
  // setCurrentOrderById = (sid) => {
  //   this.currentOrder = this.liveOrders.find(item => item.sid === this.currentCustomerRecord.sid).items
  //   toJS()
  // }

  autoSelectValid = () => {
    // if (this.liveProducts.length > 0) return
    // this.liveProducts.forEach((s, index) => {

    //   if (!this.liveProducts[index].edit_count) this.liveProducts[index].edit_count = 0

    //   if (!s.match_code && this.refProduct[s.code] && s.code && !s.qty_live) {
    //     this.liveProducts[index].match_code = s.code
    //     this.liveProducts[index].qty_live = 1
    //     if (this.liveProducts[index].customers && this.liveProducts[index].customers.length > 1)
    //       this.liveProducts[index].qty_live = this.liveProducts[index].customers.length
    //     this.liveProducts[index].price_live = this.refProduct[s.code].price
    //     this.liveProducts[index].image = this.refProduct[s.code].image
    //     this.liveProducts[index].ref = toJS(this.refProduct[s.code])
    //     // const liveIndex = this.liveProducts.findIndex((item) => (item.code === s.code || (item.match_code === s.code)))
    //     // if (liveIndex < 0) {
    //     //   this.liveProducts.push(
    //     //     {
    //     //       code: s.match_code,
    //     //       match_code: s.code,
    //     //       price_live: this.refProduct[s.code].price,
    //     //       qty_live: 1
    //     //     }
    //     //   )
    //     // } else {
    //     //   if (!this.liveProducts[liveIndex]?.match_code) {
    //     //     this.liveProducts[liveIndex].match_code = s.code
    //     //     this.liveProducts[liveIndex].price_live = this.refProduct[s.code].price
    //     //     this.liveProducts[liveIndex].qty_live = 1
    //     //   }
    //     // }
    //     // newArr[index].price_live = datatableStore.refProduct[s.code].price
    //   } else {
    //     // this.liveProducts[index].qty_live = 0
    //   }
    //   // datatableStore.updateRefProduct(s.code, s.ref)
    // });
    this.reCalcAllCode()
    // console.log(`toJS()`, toJS(this.liveProducts))

    // this.suggestionCodes.forEach((s, index) => {
    //   if (!s.match_code && this.refProduct[s.code] && s.code) {
    //     this.suggestionCodes[index].match_code = s.code
    //     this.suggestionCodes[index].qty_live = 1
    //     this.suggestionCodes[index].price_live = this.refProduct[s.code].price
    //     this.suggestionCodes[index].image = this.refProduct[s.code].image
    //     const liveIndex = this.liveProducts.findIndex((item) => (item.code === s.code || (item.match_code === s.code)))
    //     if (liveIndex < 0) {
    //       this.liveProducts.push(
    //         {
    //           code: s.match_code,
    //           match_code: s.code,
    //           price_live: this.refProduct[s.code].price,
    //           qty_live: 1
    //         }
    //       )
    //     } else {
    //       if (!this.liveProducts[liveIndex]?.match_code) {
    //         this.liveProducts[liveIndex].match_code = s.code
    //         this.liveProducts[liveIndex].price_live = this.refProduct[s.code].price
    //         this.liveProducts[liveIndex].qty_live = 1
    //       }
    //     }
    //     // newArr[index].price_live = datatableStore.refProduct[s.code].price
    //   }
    //   // datatableStore.updateRefProduct(s.code, s.ref)
    // });
    // this.updateLiveOrdersFromLiveProduct()
    // this.liveOrders.forEach(lo => {
    //   this.updateLiveOrdersFromLiveProduct(lo.sid)
    // });
    this.updateAllLiveOrderFromLiveProduct()
  }

  updateAllLiveOrderFromLiveProduct() {
    // console.log(`this.liveOrders`, toJS(this.liveOrders))
    this.liveOrders.forEach(lo => {
      this.updateLiveOrdersFromLiveProduct(lo.sid)
    });
  }

  updateLiveOrdersFromLiveProduct(sid) {
    let oIndex = this.liveOrders.findIndex(lo => lo.sid === sid)
    if (oIndex < 0) return
    let op = toJS(this.liveOrders[oIndex])
    // console.log(`toJS(this.liveProducts`, toJS(this.liveProducts))
    // let op = toJS(this.liveOrders.find(item => item.sid === sid).items)

    op.items.forEach(p => {
      const liveIndex = this.liveProducts.findIndex((item) => (item.code === p.code || (item.match_code === p.code)))
      if (!p.ref) {
        if (liveIndex >= 0) {
          p.ref = this.liveProducts[liveIndex].ref
          if (p.edit_count < 1)
            p.price = this.liveProducts[liveIndex].price_live
          p.match_code = this.liveProducts[liveIndex].match_code
        }
        // if (!p.ref) {
        //   p.ref = this.refProduct[p.code]
        // }
        // if (p.ref) {
        //   p.match_code = p.ref.code
        // }
      }
      if (p.ref) {
        if (liveIndex >= 0) {
          p.name = this.liveProducts[liveIndex].name
          if (!p.price) {
            p.price = this.liveProducts[liveIndex].price_live
          }
        }
        // p.price = this.liveProducts[liveIndex].price_live
        // p.ref = this.liveProducts[liveIndex].ref
        // console.log(`this.liveProducts[liveIndex]`, this.liveProducts[liveIndex])
        // if (liveIndex >= 0) {
        //   if (p.edit_count < 1) {
        //     p.price = this.liveProducts[liveIndex].price_live
        //     p.ref = this.liveProducts[liveIndex].ref
        //   }
        //   // console.log(`p`, p)
        // }

      }
    });
    this.liveOrders[oIndex] = op
  }

  setCustomerOrders(sid, items, customer) {
    let index = this.liveOrders.findIndex(lo => lo.customer.lsid === sid)
    if (index < 0) {
      items.forEach(e => {
        e.edit_count = 0
      });

      let newOrder = {
        sid: nanoid(10),
        items
      }
      if (customer) newOrder.customer = customer
      this.liveOrders.push(newOrder)
    } else {
      this.liveOrders[index].items = items
      if (customer) this.liveOrders[index].customer = customer
      this.liveOrders[index].unsaved = true
      this.updateCurrentRecord()
      // this.somethingUnsave = true

      // console.log(`this.liveOrders[index].items`, toJS(this.liveOrders[index].items))
    }
  }

  updateCustomerDetail(sid, cdata) {
    let index = this.liveOrders.findIndex(lo => lo.customer.lsid === sid)
    if (index < 0) return
    if (!this.liveOrders[index].customer) {
      this.liveOrders[index].customer = cdata
      this.liveOrders[index].customer.ref = false
      this.liveOrders[index].customer.lsid = sid
      return
    }
    this.liveOrders[index].customer = Object.assign(this.liveOrders[index].customer, cdata)
    // this.liveOrders[index].unsaved = true
    this.updateCurrentRecord()
    // console.log(`toJS(this.liveOrders)`, toJS(this.liveOrders))
    // this.somethingUnsave = true
    // console.log(`this.liveOrders[index].customer`, this.liveOrders[index].customer)
  }

  // getOrderCustomerBySid = (sid) => {
  //   return this.liveOrders.find(item => item?.customer.lsid === sid)
  // }

  getItemPriceAndQuanityLive = (match_code) => {
    let item = this.liveProducts.find(p => p.match_code === match_code)
    if (item)
      return {
        price_live: item.price_live,
        qty_live: item.qty_live
      }
    else
      return false
  }

  updateCartItem = (row, isDelete) => {
    if (!this.currentLiveOrder) return
    let lIndex = this.liveOrders.findIndex(item => item.sid === this.currentLiveOrder.sid)
    if (lIndex > -1) {
      // this.currentLiveOrder = false
      if (this.liveOrders[lIndex].items) {
        let rIndex = this.liveOrders[lIndex].items.findIndex(item => item.code == row.code)
        if (rIndex >= 0) {
          let tmp = toJS(this.liveOrders[lIndex])
          row.edit_count++
          row.se = build_search_by_column(row, [{ key: 'code' }, { key: 'match_code' }, { key: 'name' }])
          tmp.items[rIndex] = row
          this.liveOrders[lIndex] = tmp
          // this.liveOrders[lIndex].unsaved = true
          // this.reCalcCodeQty(row.code)
          // this.somethingUnsave = true
          this.updateCurrentRecord()
          console.log(`row`, row)
        }
      }
      // this.currentLiveOrder = toJS(this.liveOrders[lIndex])
    }
  }

  updateCartData = (cdata) => {
    if (!this.currentLiveOrder) return
    let lIndex = this.liveOrders.findIndex(item => item.sid === this.currentLiveOrder.sid)
    if (lIndex > -1) {
      // this.currentLiveOrder = false
      cdata.edit_count++
      this.liveOrders[lIndex] = cdata
      // this.liveOrders[lIndex].unsaved = true
      // this.somethingUnsave = true
      // this.currentCustomerRecord.edit_count = cdata.edit_count
      this.updateCurrentRecord()
    }
  }

  addIoCart = (row) => {
    if (!this.currentLiveOrder) return
    let lIndex = this.liveOrders.findIndex(item => item.sid === this.currentLiveOrder.sid)
    if (lIndex > -1) {
      // this.currentLiveOrder = false
      let rIndex = this.liveOrders[lIndex].items?.findIndex(item => item.code == row.code)
      if (rIndex >= 0) {
        let tmp = toJS(this.liveOrders[lIndex])
        tmp.items[rIndex].qty++
        // tmp.edit_count++
        this.liveOrders[lIndex] = tmp
      } else {
        let tmp = toJS(this.liveOrders[lIndex])
        tmp.items.push(row)
        this.liveOrders[lIndex] = tmp
      }
      row.se = build_search_by_column(row, [{ key: 'code' }, { key: 'match_code' }, { key: 'name' }])
      this.updateCurrentRecord()
      // this.currentLiveOrder = toJS(this.liveOrders[lIndex])
    }
  }

  updateCurrentRecord = () => {
    this.updateCount++
  }

  // setCodeTmpSimilar = (v) => {
  //   this.code_tmp_similar = v
  // }

  // setSuggestionCode = (codes) => {
  //   this.suggestionCodes = codes
  // }

  // setRefProduct = (rp) => {
  //   this.refProduct = rp
  // }

  // updateRefProduct = (code, ref) => {
  //   this.refProduct[code] = ref
  // }

  // findRefProduct = (code, match_code) => {
  //   if (this.refProduct[code]) return this.refProduct[code]
  //   if (this.refProduct[match_code]) return this.refProduct[match_code]
  //   return false
  // }

  getLiveProductByCode = (code) => {
    let out = this.liveProducts.find(item => item.code === code)
    if (out) return {
      code: code,
      sid: out.sid,
      key: out.key,
      ref: out.ref
    }
    return false
  }

  setLiveProducts = (p) => {
    if (this.liveProducts.length < 1) this.liveProductUnsaved = true
    this.liveProducts = p
  }

  // setCustomerMessages = (messages) => {
  //   this.currentCustomerMessages = messages
  // }

  setCustomerRecord = (record) => {
    this.currentCustomerRecord = record
    // console.log(`record`, record)
    this.currentMsgCode = false
    // console.log(`record`, record)
    // this.currentLiveOrder = this.liveOrders.find(item => item.rid === this.currentDataTable.id + '_' + record.sid + '_o')
    // this.currentLiveOrder = this.liveOrders.find(item => item.sid === record.sid)
    // console.log(`this.currentLiveOrder`, toJS(this.currentLiveOrder))
  }

  // getAllUnsaved = (sid) => {
  //   if (this.liveOrders.find(o => o.rid === this.currentDataTable.id + '_' + sid + '_o')) return true
  //   return th
  //   return false
  // }

  getStatusFromCustomerOrder = (sid) => {
    let o = this.liveOrders.find(item => item.customer?.lsid === sid)
    if (o) {
      return o?.status?.ref?.key
    }
    return false
  }
}

export default new LiveStore()
