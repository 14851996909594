import { useQuery } from '@apollo/react-hooks'
import { useCallback } from 'react'

/**
 * @param {query} Graphql 
 * useQuery as an async hook.
 * Example: let data = await useAsyncQuery(query)
 */
export default (query) => {
  try {        
    const { refetch } = useQuery(query, {
      skip: true,
      notifyOnNetworkStatusChange: true,
    })

    // const _refetch = useQuery(query, {
    //   skip: true,
    //   notifyOnNetworkStatusChange: true,
    // }).refetch;

    // const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

    const imperativelyCallQuery = (variables) => {
      return refetch(variables)
    }

    return imperativelyCallQuery
  } catch (error) {
    return false
  }
}