import React, { useEffect, useState, useMemo } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Button,
    Input,
    Table,
    Space,
    Select,
    Avatar,
    Tooltip
} from 'antd';

import {
    PlusOutlined,
    UserOutlined,
    AntDesignOutlined
} from '@ant-design/icons';

// import { autorun, set, toJS } from 'mobx'
// import { debounce } from "lodash-es";
import { flatMap } from "lodash-es"
import { contrastColor } from 'contrast-color'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
import { toJS } from 'mobx';
const { Option } = Select;

const CustomSelect = styled(Select)`
min-width: 120px;
background-color:${props => props.color} !important;
border-color:${props => props.color};
.ant-select-selector {
  background-color:${props => props.color} !important;
  color:${props => contrastColor({ bgColor: props.color })};
}
`;


const AssignUser = ({
    commonStore, datatableStore,
    preloadKey,
    tableId,
    columns,
    limit,
    width = 150,
    initValue,
    initRow,
    viewType = "select",
    emptyLabel = '--trạng thái--',
    onOk,
    onSelect,
    rows
}) => {
    // const [matchItems, setMatchItems] = useState([])
    // const [inputValue, setInputValue] = useState("")

    const [options, setOptions] = useState(rows)
    const [inputValue, setInputValue] = useState(initValue ? initValue : '-')
    // const [currentText, setCurrentText] = useState("")
    function myFlat(v) {
        return Object.assign(v.row_data, { key: v.id })
    }

    const findRows = useAsyncQuery(DataTableServices.FIND_TABLE_ROW())
    const findTableRows = async () => {
        const { data, error } = await findRows({ where: { datatable: tableId } })
        const results = data ? data.datarowsConnection : ''
        const values = results?.values || ''
        if (values && values.length > 0) {
            return flatMap(values, myFlat)
        }
        return false
    }

    // useEffect(async () => {
    //   if (!rows && tableId) {
    //     rows = await findTableRows()
    //     setOptions(rows)
    //   }
    //   if (liveStore.currentLiveOrder.status) {
    //     setInputValue(liveStore.currentLiveOrder.status.ref?.key)
    //     setCurrentColor(liveStore.currentLiveOrder.status.ref.color)
    //     // doSearch(initValue)
    //   }
    // }, [liveStore.currentLiveOrder.status])

    useEffect(() => {
        async function findRow() {
            rows = await findTableRows()
            setOptions(rows)
        }
        if (!rows && tableId) {
            // findRow()
        }
    }, [])

    // function onChange(value) {
    //     if (value && value != inputValue) {
    //         // console.log(`options`, options)
    //         let selectedItem = options.find(item => item.key === value)
    //         if (selectedItem) {
    //             if (selectedItem.color)
    //                 setCurrentColor(selectedItem.color)
    //             setInputValue(value)
    //             onOk(selectedItem)
    //         }
    //     }
    // }

    return (
            <Space>
                <Avatar.Group maxCount={4} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                    <Avatar size={'small'} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    <Avatar size={'small'} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                    <Avatar size={'small'} style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} />
                    <Avatar size={'small'} style={{ backgroundColor: '#f56a00' }}>K</Avatar>
                </Avatar.Group>
                <Avatar size={'small'} icon={<PlusOutlined />} />
            </Space>
    )
    // if (viewType === 'select')
    //     return (
    //         // <SearchBoxContainer>
    //         <CustomSelect
    //             // style={{ width: width ? width : 200 }}
    //             // defaultValue={initValue?.key ? initValue.key : ""}
    //             // defaultValue={liveStore.currentLiveOrder?.status?.ref?.key}
    //             // defaultValue="60a0c51b9ed797535064a649"
    //             size={'small'}
    //             color={currentColor}
    //             onChange={onChange}
    //             defaultValue={'-'}
    //             value={inputValue}
    //         >
    //             <Option key={'-'} value={'-'} style={{ background: 'white', color: "black" }}>
    //                 {emptyLabel ? emptyLabel : "--chưa chọn--"}
    //             </Option>
    //             {options ? (
    //                 options.map(row => {
    //                     return (
    //                         <Option key={row.key} value={row.key} style={{ background: row.color, color: contrastColor({ bgColor: row.color }) }}>
    //                             {row.name}
    //                         </Option>
    //                     )
    //                 })
    //             ) : ""}
    //         </CustomSelect>
    //         // {/* </SearchBoxContainer> */}
    //     )

    // if (viewType === 'tag') {
    //     return (
    //         <>
    //         </>
    //     )
    // }

}

export default inject("usersStore", "commonStore", "liveStore", "datatableStore")(observer(AssignUser))
// export default SearchBoxItem