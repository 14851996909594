import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Button,
    Input,
    Modal,
} from 'antd';
import {
    CopyOutlined
} from '@ant-design/icons';
// import styled from 'styled-components'

const { TextArea } = Input;

// const SelectModal = styled(Modal)`

//   `

const OrderTextModal = ({
    datatableStore,
    summary,
    orders,
    customer,
    addressInfo,
    onClose,
}) => {

    const txtRef = useRef(null)
    const [initText, setInitText] = useState(false)
    const [copyText, setCopyText] = useState("")

    const closeModal = () => {
        onClose()
    }

    const
        orderTextOut = () => {
            if (orders.length < 1) return ""
            let txt = `Em chào chị ${customer?.name ? customer.name : ""} ạ \nEm chốt đơn cho chị các sản phẩm mình đặt trong Live bên em. \n ---------------------------------\n`
            let sumPrice = 0
            let sumQty = 0
            orders.forEach(o => {
                let price = parseInt(o.price)
                let qty = parseInt(o.qty)
                if (isNaN(qty)) qty = 0
                if (isNaN(price)) price = 0
                sumQty += qty
                sumPrice += qty * price
                txt += `${qty} x ${(price / 1000).toFixed(3)} _ [${o.code.toUpperCase()}] ${o.name}\n`
            });

            txt += `\nTổng tiền sản phẩm: ${summary.price.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}`
            if (summary.chuyenkhoan) {
                txt += `\nĐã thanh toán: ${summary.chuyenkhoan.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}`
            }
            if (summary.giam_gia) {
                txt += `\nGiảm giá: ${summary.giam_gia.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}`
            }
            txt += `\nPhí ship: ${summary.ship_fee.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}`
            txt += `\nTổng thanh toán: ${summary.tong_tra.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}`

            txt += `\n----------------------------------`
            txt += `\nChị xác nhận lại đơn hàng giúp em trong vòng 24h , do sản phẩm sale số lượng ít nên Nhà iu sẽ giữ hàng trong giỏ hàng của chị trong vòng 1 ngày c nha.`
            txt += `\nNếu đơn hàng đã OK chị xác nhận thông tin gửi hàng giúp em bao gồm sdt và địa chỉ :`

            txt += `\nĐT: ${customer.mobile ? customer.mobile : ""}`
            txt += `\nĐịa chỉ: ${customer.address ? customer.address : ""}`
            txt += `\n----------------------------------`
            txt += `\nĐây là tin nhắn hệ thống gửi để chị nắm được đơn hàng của mình . Chị check và phản hồi lại đơn hàng giúp em nha và vui lòng đợi giúp em 1 chút nhân viên tư vấn của Nhà iu sẽ lên đơn và phản hồi chị sớm nhất`
            txt += `\nNhà iu làm việc từ 9h am - 6h pm ạ`

            return txt
        }

    // const orderTextOut = () => {
    //     if (orders.length < 1) return ""
    //     let txt = `"Em chào chị ${customer?.name ? customer.name : ""} ạ \n Em chốt đơn cho chị các sản phẩm mình đặt trong Live bên em. \n ---------------------------------\n`
    //     let sumPrice = 0
    //     let sumQty = 0
    //     orders.forEach(o => {
    //         sumQty += parseInt(o.quanity)
    //         sumPrice += parseInt(o.quanity) * parseInt(o.price)
    //         txt += `${o.quanity} x ${(parseInt(o.price) / 1000).toFixed(3)} _ [${o.match_code.toUpperCase()}] ${o.ref?.name}\n`
    //     });
    //     txt += `\nTổng tiền: ${sumPrice.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}, SL đặt: ${sumQty} sản phẩm`
    //     txt += `\n----------------------------------`
    //     txt += `\nNếu chuẩn rồi em lên đơn cho chị luôn c nha`
    //     if (customer?.address) {
    //         txt += `\nThông tin gửi hàng :`
    //         txt += `\n${customer.address}`
    //     }
    //     return txt
    // }

    useEffect(() => {
        let out = orderTextOut()
        setInitText(out)
        setCopyText(out)
    }, [customer])

    const onChange = ({ target: { value } }) => {
        setCopyText(value)
    }

    return (
        <Modal
            title={`Tin  nhắn gửi khách : ${customer?.name}`}
            visible={true}
            maskClosable={false}
            onCancel={closeModal}
            centered
            footer={[
                <Button onClick={closeModal}>
                    Đóng
                </Button>,
                // <CopyToClipboard text={txtRef?.current?.state?.value}
                <CopyToClipboard text={copyText}
                // onCopy={() => {
                //     if (txtRef?.current) {

                //     }
                // }}
                >
                    {/* <button>Copy to clipboard with button</button> */}

                    <Button
                        icon={<CopyOutlined />} key="submit" type="primary"
                    // onClick={() => {
                    //     if (txtRef?.current) {
                    //         console.log(`txtRef.current`, txtRef.current?.state?.value)
                    //         // setCopyText(txtRef?.current?.state?.value)
                    //         // txtRef.current.state.value = 'hihi'
                    //     }
                    //     // onSave(selectedData)
                    //     // onClose()
                    // }}
                    >Copy</Button>
                </CopyToClipboard>,


            ]}
        >
            {initText ?
                <TextArea
                    ref={txtRef}
                    defaultValue={initText}
                    value={copyText}
                    rows={20}
                    onChange={onChange} /> : ""
            }

        </Modal>
    )
}
export default inject("datatableStore")(observer(OrderTextModal))