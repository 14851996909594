import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
// import { useMutation } from '@apollo/react-hooks'
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import axios from 'axios'
// import Cookies from 'js-cookie'
// import config from '../../../config'

import {
    notification,
    List,
    Drawer,
    Button,
    Badge,
    message,
    Space,
    Row,
    Col
} from 'antd';
// import moment from 'moment'
// import MarkdownIt from 'markdown-it';
import {
    NotificationOutlined,
} from '@ant-design/icons';

// import { imagePreset } from '../../../ultils/datatableUtils'

// import DataTableServices from '../../../services/datatableServices'
// import useAsyncQuery from '../../../ultils/useAsyncQuery'
import soundMessage from '../../../assets/sound/ping.mp3'
// import { price_k } from '../../../ultils/datatableUtils'

import { jobType } from '../Job/JobType'
// const mdParser = new MarkdownIt(/* Markdown-it options */);

const Notification = ({
    datatableStore,
    commonStore,
    usersStore
}) => {

    const [showResDetail, setShowResDetail] = useState(false)
    const [showDetail, setShowDetail] = useState(false)

    // const findNofications = useAsyncQuery(DataTableServices.FIND_NOFICATIONS())
    // const getResources = async (dataInput) => {
    //     const { data, error } = await findResQuery({
    //         input: {
    //             data: dataInput
    //         }
    //     })
    //     let result = data?.findResources?.data
    //     if (result.status === 'OK')
    //         return result.items
    //     return false
    // }

    useEffect(() => {
        async function init() {
            // if (row_ids) {
            //     let res = await getResources({ row_ids: row_ids })
            //     setResources(res)
            // } else {
            //     setResources(rows)
            // }
        }
        init()
    }, [])


    // useEffect(() => {
    //     if (usersStore.socket) {
    //         // usersStore.socket.on("addOrderJob", (data) => {
    //         //     if (data?.creator?.id === usersStore?.currentUser?.id) {
    //         //         return
    //         //     }
    //         //     // let title = ""
    //         //     let jt = jobType.find(j => j.type === data?.job?.type)
    //         //     let title = `${jt?.name} đơn [${data.row?.cname}] ${data.row?.mobile}`
    //         //     let body = `${data?.creator?.name} tham gia [${jt?.name}] đơn: ${data.row?.code}, Tổng tiền: ${price_k(data.row?.tong_tien, '?', 3)} `
    //         //     console.log('data', data)
    //         //     notification['success']({
    //         //         message: title,
    //         //         description: body,
    //         //     })
    //         //     // notification desktop
    //         //     if (window?.Notification && window.Notification.permission === 'granted') {
    //         //         const audio = new Audio(soundMessage)
    //         //         audio.play()
    //         //         const notification = new window.Notification(title, {
    //         //             body: body,
    //         //             icon: data?.creator?.avatar || "https://joeschmoe.io/api/v1/random",
    //         //         })
    //         //         notification.onclick = function (event) {
    //         //             event.preventDefault(); // prevent the browser from focusing the Notification's tab
    //         //             window.open('/tool/search_order/' + data?.row?.id, '_blank');
    //         //         }
    //         //     } else if (window?.Notification && window.Notification.permission !== 'granted') {
    //         //         window.Notification.requestPermission().then(permission => {
    //         //             if (permission === 'granted') {
    //         //                 const audio = new Audio(soundMessage)
    //         //                 audio.play()
    //         //                 const notification = new window.Notification(title, {
    //         //                     body: body,
    //         //                     icon: data?.creator?.avatar
    //         //                 })
    //         //                 notification.onclick = function (event) {
    //         //                     event.preventDefault(); // prevent the browser from focusing the Notification's tab
    //         //                     window.open('/tool/search_order/' + data?.row?.id, '_blank');
    //         //                 }
    //         //             }
    //         //         })
    //         //     }
    //         // })

    //         usersStore.socket.on("requestSkipJob", (data) => {
    //             if (data?.creator?.id === usersStore?.currentUser?.id) {
    //                 return
    //             }
    //             let title = `Yêu cầu tạm hoãn đơn [${data.row?.cname}] ${data.row?.mobile}`
    //             let body = `${data.creator?.name} đã gửi yêu cầu tạm hoãn`
    //             // let jt = jobType.find(j => j.type === data?.job?.type)
    //             // let title = `${jt?.name} đơn [${data.row?.cname}] ${data.row?.mobile}`
    //             // let body = `${data?.creator?.name} tham gia [${jt?.name}] đơn: ${data.row?.code}, Tổng tiền: ${price_k(data.row?.tong_tien, '?', 3)} `
    //             // console.log('data', data)
    //             notification['success']({
    //                 message: title,
    //                 description: body,
    //             })
    //             // notification desktop
    //             if (window?.Notification && window.Notification.permission === 'granted') {
    //                 const audio = new Audio(soundMessage)
    //                 audio.play()
    //                 const notification = new window.Notification(title, {
    //                     body: body,
    //                     icon: data?.creator?.avatar || "https://joeschmoe.io/api/v1/random",
    //                 })
    //                 // notification.onclick = function (event) {
    //                 //     event.preventDefault(); // prevent the browser from focusing the Notification's tab
    //                 //     window.open('/tool/search_order/' + data?.row?.id, '_blank');
    //                 // }
    //             } else if (window?.Notification && window.Notification.permission !== 'granted') {
    //                 window.Notification.requestPermission().then(permission => {
    //                     if (permission === 'granted') {
    //                         const audio = new Audio(soundMessage)
    //                         audio.play()
    //                         const notification = new window.Notification(title, {
    //                             body: body,
    //                             icon: data?.creator?.avatar || "https://joeschmoe.io/api/v1/random",
    //                         })
    //                     }
    //                 })
    //             }
    //         })
    //     }

    //     return () => {
    //         usersStore.socket.off("addOrderJob")
    //     }
    // }, [usersStore.socket])

    // let title = truncate(resource?.name || '', 35)
    return (
        <>
            {/* <Button>abc</Button> */}
            <Badge count={1} size="small">
                <NotificationOutlined
                    style={{ fontSize: "18px" }}
                    onClick={() => {
                        setShowDetail(true)
                    }}
                />
            </Badge>

            {/* <Drawer
                width="100%"
                // closable={true}
                style={{ maxWidth: 960 }}
                visible={showResDetail ? true : false}
                title='Chi tiết tài nguyên'
                placement="right"
                onClose={() => {
                    setShowResDetail(false)
                }}
            >
                <ResourceDetail resource={showResDetail} />
            </Drawer> */}

            {showDetail &&
                <Drawer
                    width="100%"
                    closable={true}
                    style={{ maxWidth: 480 }}
                    // visible={showResDetail ? true : false}
                    visible={true}
                    title={<>Clear all</>}
                    placement="right"
                    onClose={() => {
                        setShowDetail(false)
                    }}
                >
                    hihi
                    {/* <ResourceDetail resource={showResDetail}
                        onDelete={(id) => {
                            setResources(resources.filter(r => r.id !== id))
                            setShowResDetail(false)
                        }}
                    /> */}
                </Drawer>}
        </>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(Notification))