import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.img`
  width: ${({width}) => width ? width : '100%'};
  height: ${({width}) => width ? width : 'auto'};
  margin: ${({margin}) => margin ? margin : 0};
  display: block;
  /* object-fit: cover; */
`

const StyledIcon = ({ src, alt, width, height, margin }) => {
  return <IconWrapper
    margin={margin}
    src={src}
    alt={alt}
    width={width}
    height={height} />
}

export default StyledIcon
