import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useReactToPrint } from "react-to-print";
import { QRCodeCanvas } from "qrcode.react";

import {
    Button,
    // Modal,
} from 'antd';
import {
    // PlusOutlined,
    // CloseCircleOutlined,
    PrinterOutlined
} from '@ant-design/icons';

// import { autorun, toJS } from 'mobx'
// import styled, { createGlobalStyle } from 'styled-components'
import { price_k, getOrderSummary } from '../../../ultils/datatableUtils'
// import { sum } from 'lodash';
// import datatableStore from '../../../stores/datatableStore';

import OrderDetailPrintTemplate from './OrderDetailPrintTemplate'

const PrintOrderDetail = ({
    columns,
    title,
    orderData,
    noLabel = false,
    table,
    onClose,
}) => {

    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const rowsToPrint = () => {
        if (!orderData.items) return []
        let rows = orderData.items.filter(r => r.name && r.qty > 0).map(row => {
            let name = row?.name
            // let priceItem = (isNaN(row.qty) && isNaN(row.price)) ? price_k(row.qty * row.price, 0, 1) : 0
            let priceItems = price_k(row.qty * row.price, 0, 3)
            let code = row.code
            if (row.match_code) code = row.match_code
            let txt = ''
            if (code) txt = (<strong>[{code.toUpperCase()}]</strong>)
            let x = (
                <>
                    {txt}-{name}
                    {row.note ?
                        (<>
                            <br /><span style={{ fontSize: '8pt', color: "brown" }}>{row.note} </span>
                        </>
                        )
                        : ""}
                </>
            )
            return {
                name: x,
                qty: row.qty,
                // price: row.price ? row.price.toLocaleString('it-IT', { style: 'currency', currency: 'VND' }) : 0
                price: price_k(row.price, 0, 3),
                priceItems: priceItems
            }
        })
        return [{
            name: 'Sản phẩm',
            qty: 'SL',
            price: 'Giá',
            priceItems: 'Thành tiền'
        }, ...rows]
    }

    const headerPrint = () => {
        let province = orderData?.province?.ProvinceName || orderData?.ship?.sl_province
        let district = orderData?.district?.DistrictName || orderData?.ship?.sl_district
        let ward = orderData?.ward?.WardName || orderData?.ship?.sl_ward
        return (
            <>
                {
                    orderData?.luot_nhat &&
                    <> <strong>Lượt nhặt : </strong>{orderData?.luot_nhat}  <br /></>
                }
                <strong>Mã  đơn: </strong> {orderData.code ? orderData.code : "?"}
                <br />
                <strong>Khách hàng : </strong> {orderData.customer?.name ? orderData.customer.name : "?"}
                /  Mobile: {orderData.customer?.mobile ? orderData.customer.mobile : "?"}
                <br />
                <strong>Địa chỉ : </strong> {orderData.customer?.address ? orderData.customer?.address : "?"} <br />
                <strong>Khu vực : </strong> {province},{district},{ward} <br /> <br />
                {/* {orderData?.note ? (<>Ghi chú : <strong>{orderData?.note}</strong> <br /><br /></>) : <br />
                } */}
            </>
        )
    }

    const footerPrint = () => {
        // if (!orderData?.note) return ""
        return (
            <>
                <div style={{ width: "70%", float: "left" }}>
                    {orderData?.note && (<>  <br /> Ghi chú : <strong>{orderData?.note}</strong></>)}
                    <br />
                    NV nhặt : .............................. &nbsp;SL : ...............
                    <br />
                    NV đóng : ............................. &nbsp;SL : ...............
                    <br />
                    Xác nhận đủ sp : ☐
                </div>
                <div style={{ width: "30%", textAlign: 'right', float: 'right' }}>
                    <QRCodeCanvas
                        id="qrCode"
                        value={orderData.code}
                        size={50}
                        bgColor={"#ffffff"}
                        level={"H"}
                        style={{ margin: "20px 0", padding: 0 }}
                    />
                </div>
            </>
        )
    }


    const getSummary = () => {
        return getOrderSummary(orderData)
    }

    return (
        <>
            <div style={{ display: 'none' }}>
                <OrderDetailPrintTemplate
                    ref={printRef}
                    title={'In'}
                    columns={columns}
                    rows={rowsToPrint()}
                    headerPrint={headerPrint()}
                    footerPrint={footerPrint()}
                    summary={getSummary()}
                />
            </div>
            {noLabel ? <PrinterOutlined
                style={{ cursor: 'pointer' }}
                onClick={handlePrint}
            /> : <Button shape="round" size={'small'} onClick={handlePrint}
                icon={<PrinterOutlined />}
            >{title}</Button>}
        </>
    )
}
export default inject("datatableStore")(observer(PrintOrderDetail))

