import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'

import {
    Divider,
    List,
    Image,
    Avatar,
    message,
    Space,
    Row,
    Col
} from 'antd';
// import moment from 'moment'
import MarkdownIt from 'markdown-it';
import {
    AlignLeftOutlined,
    LinkOutlined,
} from '@ant-design/icons';

import { imagePreset } from '../../../ultils/datatableUtils'
import moment from 'moment'

const mdParser = new MarkdownIt(/* Markdown-it options */);

function truncate(input, maxLength = 20) {
    if (input.length > maxLength) {
        return input.substring(0, maxLength) + '...';
    }
    return input;
};


const ResourceListItem = ({
    datatableStore,
    commonStore,
    usersStore,
    onClick,
    resource }) => {
    let title = truncate(resource?.name || '', 20)
    if (resource.type === 'image') {
        let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
        let imageSrc = resource.rdata.image

        if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
        else {
            thumbnail = imagePreset(imageSrc, 'xsmall')
        }
        return (
            <List.Item>
                <List.Item.Meta
                    onClick={onClick}
                    avatar={<Image
                        width={64}
                        height={64}
                        src={thumbnail}
                        preview={false}

                        // preview={{
                        //     src: imageSrc,
                        // }}
                    />}
                    title={title}
                    description={
                        <>
                            <strong style={{ marginRight: 5 }}>Ngày tạo</strong>
                            {moment(resource?.rdata?.ngay_tao).format('DD/MM/YY HH:mm')}
                        </>
                    }
                />
            </List.Item>
        )
    } else if (resource.type === 'link') {
        title = resource.name && resource.name !== '' ? title : truncate(resource?.rdata?.link, 35)
        return (
            <List.Item>
                <List.Item.Meta
                    onClick={onClick}
                    avatar={<Avatar style={{ backgroundColor: '#4CB821' }} shape="square" size={64} icon={<LinkOutlined />} />}
                    title={title}
                    description={<a href={resource?.rdata?.link}>{resource?.rdata?.link}</a>}
                />
            </List.Item>
        )
    }
    else if (resource.type === 'text') {
        return (
            <List.Item>
                <List.Item.Meta
                    onClick={onClick}
                    avatar={<Avatar style={{ backgroundColor: '#2070B1' }} shape="square" size={64} icon={<AlignLeftOutlined />} />}
                    title={title}
                    description={
                        <div dangerouslySetInnerHTML={{ __html: mdParser.render(truncate(resource?.rdata?.text || '', 40)) }}></div>
                    }
                />
            </List.Item>

        )
    }
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(ResourceListItem))