import styled from 'styled-components'

export const FlexStyled = styled.div`
  display: flex;
  justify-content: ${ ({ justify }) => justify ? justify : null};
`
// style wrapper content layout
export const ContainerWrapper = styled.section`
  overflow-y: auto;
  height: 100%;
  position: relative;
  padding: 0 15px;
`
export const HeadWrapper = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.mediaQueries.sm}){
    align-items: ${ ({ align }) => align ? align : null};
    margin: ${({ mg }) => mg ? mg : null};
    padding: ${({ pd }) => pd ? pd : null};
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaQueries.sm}){
    display: flex;
    justify-content: ${ ({ justify }) => justify ? justify : null};
    flex-direction: ${ ({ direction }) => direction ? direction : null};
    align-items: ${ ({ align }) => align ? align : null};
    margin: ${({ mg }) => mg ? mg : null};
    padding: ${({ pd }) => pd ? pd : null};
  }

`
export const BodyWrapper = styled.div`
  background-color: #fff;
  padding: 12px 12px 0 12px;
`

export const BodyWrapperFix = styled(BodyWrapper)`
  padding: 30px;
  /* min-height: 80vh;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px; */
`
//============ MODAL CUSTOM ==============
export const ModalPadding = styled.div`
  padding: 24px;
`

export const ModalHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ModalBody = styled.div`
  margin-top: 20px;
  min-height: 400px;
  overflow: auto;
  overflow-x: hidden;
`

export const ModalFooter = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
