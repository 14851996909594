import { observable, action, decorate } from 'mobx'
import commonStore from './commonStore'
// import datatableStore from './datatableStore'
// import { message } from 'antd'
// import { apiUrl } from '../config'
// import socketClient from '../plugins/socket'
class UsersStore {
  @observable isLoading = false
  @observable currentUser = false
  @observable socket = false

  @action updateUserInfo = newInfo => {
  }

  @action userLogout = () => {
    this.currentUser = false
    // commonStore.clearToken()
    return Promise.resolve()
  }

  @action setLoadingProgress = state => {
    this.isLoading = state
  }

  @action setCurrentUser = (user, token) => {
    // let socket = socketClient(token)
    // this.socket = socket


    //notification desktop
    // if (window?.Notification && window.Notification.permission === 'granted') {
    //   const audio = new Audio(soundMessage)
    //   audio.play()
    //   if (window.location.pathname.search('chat') === -1 || (window.location.pathname.search('information') !== -1 && data.typeBooth === 'organizer') || (window.location.pathname.search('organizer') !== -1 && data.typeBooth === 'information')) {
    //     const audio = new Audio(soundMessage)
    //     audio.play()
    //     const notification = new window.Notification('Message from: ' + data?.user?.name, {
    //       body: data.message.message,
    //       icon: data?.user?.avatar || DefaultAvatar
    //     })
    //   }
    // } else if (window?.Notification && window.Notification.permission !== 'granted') {
    //   window.Notification.requestPermission().then(permission => {
    //     if (permission === 'granted') {
    //       if (window.location.pathname.search('chat') === -1 || (window.location.pathname.search('information') !== -1 && data.typeBooth === 'organizer') || (window.location.pathname.search('organizer') !== -1 && data.typeBooth === 'information')) {
    //         const audio = new Audio(soundMessage)
    //         audio.play()
    //         const notification = new window.Notification('Message from: ' + data?.user?.name, {
    //           body: data.message.message,
    //           icon: data?.user?.avatar || DefaultAvatar
    //         })
    //       }
    //     }
    //   })
    // }

    const theme = user?.user_profile?.data?.theme
    if (theme) {
      // console.log('theme', theme)
      commonStore.setThemeColor(theme.colors)
    }
    // await Cookies.set('access_token', token, { expires: 2 })
    this.isLoading = false
    this.currentUser = user
    // console.log('user', user)
    // history.push('/')
  }

  // @action async setCurrentUser(history) {
  //   this.setLoadingProgress(true)
  //   if (commonStore.checkToken()) {
  //     UserRequest.getCurrent()
  //       .then(response => {
  //         this.currentUser = response.data
  //         this.setLoadingProgress(false)
  //       })
  //       .catch(() => {
  //         commonStore.clearToken()
  //         this.setLoadingProgress(false)
  //         return (history) ? history.push('/auth/login') : null
  //       })
  //   } else {
  //     this.setLoadingProgress(false)
  //   }
  // }

  @action userLogout() {
    this.currentUser = {}
    commonStore.clearToken()
    return Promise.resolve()
  }



  @action getValidToken = (tokenid) => {
    this.setLoadingProgress(true)
  }

  // getValidToken = (tokenid) => {
  //   this.setLoadingProgress(true)
  //   return TokenRequest.getValidToken(tokenid)
  //     .then(response => {
  //       this.setLoadingProgress(false)
  //       return response
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       this.setLoadingProgress(false)
  //       return error
  //     })
  // }
}

export default new UsersStore()
