import React from 'react'
import styled from 'styled-components'
// import NotifiCation from './Notification'
import SearchBar from './SearchBar'
import Logout from './Logout'
import Profile from './Profile'
import LobbyName from './LobbyName'
import { Menu, Dropdown, Grid, Space } from 'antd'
import { UserOutlined, BarsOutlined } from '@ant-design/icons'

import Notification from '../../../pages/Notification/Notification'

const { useBreakpoint } = Grid;

// const ListWrapper = styled.ul`
//   @media only screen and (max-width: ${({ theme }) => theme.mediaQueries.sm}){
//     display: none;
//   }
//   @media only screen and (min-width: ${({ theme }) => theme.mediaQueries.sm}){
//     display: flex;
//     justify-content:flex-end;
//     align-items: center;
//     list-style: none;
//     padding:0;
//     margin: 0;
//     margin-right:15px;
//     height:100%;
//   }
//   height:100%
// `

const ItemWrapper = styled.li`
  padding: 0 10px;
  display:flex;
`

const DropdownWrapper = styled(Dropdown)`
  @media only screen and (max-width: ${({ theme }) => theme.mediaQueries.sm}){
    padding:0;
    margin: 0;
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaQueries.sm}){
    display: none;
  }
`


const MenuWrapper = styled(Menu)`
  @media only screen and (max-width: ${({ theme }) => theme.mediaQueries.sm}){
    padding:0;
    margin: 0;
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaQueries.sm}){
    display: none;
  }
`



const HeaderNav = () => {
  const screens = useBreakpoint();

  const menu = (
    <MenuWrapper>
      <Menu.Item key="0">
        <ItemWrapper><Profile /></ItemWrapper>
      </Menu.Item>
      {/* <Menu.Item key="1">
        <ItemWrapper><NotifiCation /></ItemWrapper>
      </Menu.Item> */}
      <Menu.Item key="1">
        <ItemWrapper><Logout /></ItemWrapper>
      </Menu.Item>
    </MenuWrapper>
  )
  return (
    <>
      <div style={{ marginRight: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <Space>
          < Notification />
          {!screens.xs &&
            <>
              <Profile />
              <Logout />
            </>
          }
          {screens.xs &&
            <DropdownWrapper overlay={menu} placement="bottomCenter" trigger={['click']} arrow>
              <BarsOutlined style={{ lineHeight: '40px', fontSize: "18px" }} />
            </DropdownWrapper>
          }
        </Space>
      </div>
      {/* {screens.xs &&
        < Notification />}


      <ListWrapper>
        <ItemWrapper><Profile /></ItemWrapper>
        <ItemWrapper><Logout /></ItemWrapper>
      </ListWrapper>
      <DropdownWrapper overlay={menu} placement="bottomCenter" trigger={['click']} arrow>
        <BarsOutlined style={{ fontSize: '20px', marginLeft: '70%' }} />
      </DropdownWrapper> */}
    </>
  )
}

export default HeaderNav
