import React, { useEffect, useRef, useState, useForm } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { toJS } from "mobx"
import { useMutation } from '@apollo/react-hooks'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Typography,
  Space,
  // Divider
  Card,
  Avatar, Tooltip
} from 'antd';
import {
  PhoneOutlined,
  EnvironmentOutlined,
  ContactsFilled,
  SaveOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  UserOutlined,
  AntDesignOutlined
} from '@ant-design/icons';

import DataSelectModal from '../DataTools/DataSelectModal'
import DataTableServices from '../../../../services/datatableServices'

import AssignUser from '../DataTools/AssignUser'
// import { set } from 'mobx';
// const { TabPane } = Tabs;
const { Text, Title } = Typography
const { TextArea } = Input;

const LiveCustomerForm = ({ liveStore, commonStore }) => {
  const [customerSelect, setCustomerSelect] = useState(false)
  const [form] = Form.useForm();
  const [isSaved, setIsSaved] = useState(true)

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [updateManyDataRows] = useMutation(
    DataTableServices.UPDATE_MANY_ROWS(),
    {
      errorPolicy: 'all'
    }
  )

  useEffect(() => {
    form.resetFields()
    setIsSaved(true)
  }, [liveStore.currentCustomerRecord])

  const onFinish = (values) => {
    liveStore.updateCustomerDetail(liveStore.currentCustomerRecord.sid, values)

    let order = toJS(liveStore.currentLiveOrder)
    let r = updateManyDataRows({
      variables: {
        input: {
          data: {
            type: "rowkey",
            rows: [order]
          }
        }
      }
    })

    setIsSaved(true)
  }

  const saveNewCustomer = (cSelect) => {
    if (!cSelect) return
    if (cSelect.ref) {
      liveStore.currentLiveOrder.customer = {
        lsid: liveStore.currentLiveOrder.customer.lsid,
        name: cSelect.ref.name,
        mobile: cSelect.ref.mobile,
        address: cSelect.ref.address,
        ref: cSelect.ref
      }
      form.setFieldsValue({
        name: cSelect.ref.name,
        mobile: cSelect.ref.mobile,
        address: cSelect.ref.address
      })
    } else {
      if (liveStore.currentLiveOrder.customer) {
        liveStore.currentLiveOrder.customer.ref = false
        form.setFieldsValue({
          name: liveStore.currentCustomerRecord?.name ? liveStore.currentCustomerRecord.name : "",
          mobile: liveStore.currentCustomerRecord?.mobile ? liveStore.currentCustomerRecord.mobile : "",
          address: "",
        })
      }
    }
    setIsSaved(false)
  }

  // useEffect(() => {
  //   console.log(`liveStore.currentLiveOrder`, liveStore.currentLiveOrder)
  // }, [liveStore.currentLiveOrder])


  // useEffect(() => {
  //   if (liveStore.refProduct) {
  //     liveStore.updateLiveOrdersFromLiveProduct(liveStore.currentCustomerRecord.sid)
  //     // setOrderProducts(toJS(liveStore.getOrderCustomerBySid(liveStore.currentCustomerRecord.sid).items))
  //   }
  // }, [liveStore.currentCustomerRecord, liveStore.liveProducts, liveStore.liveOrders])

  // useEffect(() => {
  //   if (liveStore.refProduct) {
  //     let op = toJS(liveStore.liveOrders.find(item => item.sid === liveStore.currentCustomerRecord.sid).items)
  //     op.forEach(p => {
  //       if (!p.ref) {
  //         const liveIndex = liveStore.liveProducts.findIndex((item) => (item.code === p.code || (item.match_code === p.code)))
  //         if (liveIndex >= 0) {
  //           p.ref = liveStore.liveProducts[liveIndex].ref
  //         }
  //         if (!p.ref) {
  //           p.ref = liveStore.refProduct[p.code]
  //         }
  //         if (p.ref) {
  //           p.match_code = p.ref.code
  //         }
  //       }
  //     });
  //     setOrderProducts(op)
  //   }
  // }, [liveStore.currentCustomerRecord, liveStore.liveProducts, liveStore.liveOrders])


  return (
    <div id="orderCustomerForm" style={{ width: "100%" }}>
      <Space direction="vertical">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: "flex" }}>
            <Space>
              <ContactsFilled
                style={{ fontSize: '32px', color: liveStore.currentLiveOrder?.customer?.ref ? "#03BF68" : "#dddddd" }}
              />
              <Button icon={liveStore.currentLiveOrder?.customer?.ref ? <CheckOutlined /> : <QuestionCircleOutlined />}
                onClick={() => {
                  // setCustomerSelect(liveStore.currentCustomerRecord?.name)
                  let customer = liveStore.currentLiveOrder?.customer
                  let name = (customer ? customer.name : liveStore.currentCustomerRecord?.name)
                  let mobile = (customer ? customer.mobile : liveStore.currentCustomerRecord?.mobile)
                  setCustomerSelect({
                    name: name,
                    mobile: mobile,
                    ref: customer?.ref
                  })
                }}
              >{liveStore.currentCustomerRecord?.name}</Button>
            </Space>
          </div>
          <div style={{ display: "flex", ustifyContent: 'flex-end' }}>
            <AssignUser />
            {/* <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              <Tooltip title="Ant User" placement="top">
                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
              </Tooltip>
              <Avatar style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} />
              <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
            </Avatar.Group>
            <Avatar icon={<PlusOutlined />} /> */}
          </div>
        </div>
        <Card size="small"
          title={<Title level={5}>Thông tin khách hàng</Title>}
          extra={
            <Button
              disabled={isSaved}
              icon={<SaveOutlined />}
              size="small"
              type="primary"
              onClick={() => {
                form.submit()
              }}>
              Cập nhật
            </Button>}
        >
          <Form
            layout="vertical"
            form={form}
            name="customer-info"
            onFinish={onFinish}
            initialValues={{
              name: liveStore.currentLiveOrder?.customer?.name,
              mobile: liveStore.currentLiveOrder?.customer?.mobile ? liveStore.currentLiveOrder.customer.mobile : "",
              address: liveStore.currentLiveOrder?.customer?.address,
              chuyenkhoan: liveStore.currentLiveOrder?.customer?.chuyenkhoan ? liveStore.currentLiveOrder?.customer?.chuyenkhoan : 0,
              giam_gia: liveStore.currentLiveOrder?.customer?.giam_gia ? liveStore.currentLiveOrder?.customer?.giam_gia : 0,
              phiship: liveStore.currentLiveOrder?.customer?.phiship ? liveStore.currentLiveOrder?.customer?.phiship : 0,
              note: liveStore.currentLiveOrder?.customer?.note
            }}
            style={{ width: "100%", background: "#f0f0f0" }}
            onValuesChange={(c, a) => {
              if (isSaved) setIsSaved(false)
            }}
          >
            <Form.Item
              name="name"
              label="Tên khách"
            >
              <Input prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              name="mobile"
              label="Điện thoại"
            >
              <Input prefix={<PhoneOutlined />} />
            </Form.Item>
            <Form.Item
              name="address"
              label="Địa chỉ"
            >
              <Input prefix={<EnvironmentOutlined />} />
            </Form.Item>
            <Form.Item
              name="note"
              label="Ghi chú"
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item
              name="chuyenkhoan"
              label="Tiền chuyển khoản"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="giam_gia"
              label="Giảm giá"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="phiship"
              label="Phí ship"
            >
              <InputNumber />
            </Form.Item>
          </Form>
        </Card>

        {/* <Space>
          <PhoneFilled
            style={{ fontSize: '32px', color: liveStore.currentLiveOrder?.customer?.mobile ? "#03BF68" : "#dddddd" }}
          />
          <span>{liveStore.currentLiveOrder?.customer?.mobile}</span>
        </Space> */}
      </Space>
      {customerSelect ?
        <DataSelectModal
          title={'Chỉnh sửa khách hàng'}
          searchColumns={["name", "mobile"]}
          displayColumns={["name", "mobile"]}
          searchText={(customerSelect.mobile) ? customerSelect.name + " " + customerSelect.mobile : false}
          initData={customerSelect}
          // tableId="5fe7fb4d2136a764ac573c1e"
          tableId={commonStore?.company?.companyData?.customer_table}
          onSave={(v) => {
            // console.log('cv', v)
            saveNewCustomer(v)
            // save(v)
          }}
          onClose={() => {
            setCustomerSelect(false)
          }}
          // component={ProductItem}
          onSearchSelect={(v, o) => {
            // console.log('o', o)
          }}
        />
        : ""
      }
    </div>
  )
}

export default inject("liveStore", "commonStore")(observer(LiveCustomerForm))