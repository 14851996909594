import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Avatar,
  Form,
  Button,
  Image,
  Tag,
  Input,
  Transfer,
  Modal,
  Card,
  // message,
  Space
} from 'antd';

import {
  ShoppingOutlined,

} from '@ant-design/icons'


// import DatarowSelectInput from '../DataBoard/DataTools/DatarowSelectInput'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'

// import { imagePreset } from '../../../ultils/datatableUtils'
import DataSelectModal from '../DataBoard/DataTools/DataSelectModal'
import DataTableServices from '../../../services/datatableServices'
import { flatMap, merge as LMerge } from 'lodash-es'
import useAsyncQuery from '../../../ultils/useAsyncQuery'
// import { autorun, toJS } from 'mobx'
// import { nanoid } from 'nanoid'
const { Meta } = Card;


function myFlat(v) {
  return Object.assign(v.row_data, { key: v.id })
}

const SelectManyItemModal = ({
  datatableStore,
  commonStore,
  usersStore,
  initData,
  title,
  rowKey = 'code',
  itemRender = false,
  tableId,
  columns,
  displayColumn,
  // width = 480,
  onSave,
  onClose
}) => {

  // const [selectedData, setSelectedData] = useState(initData)
  const [updating, setupdating] = useState(false)
  const [rows, setRows] = useState([])
  const [targetKeys, setTargetKeys] = useState([])
  const [targetData, setTargetData] = useState([])
  const [showCollectionModal, setShowCollectionModal] = useState(false)

  const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

  const findRowsByFilter = async (dataInput) => {
    const { data, error } = await findRowsQuery({
      input: {
        data: dataInput
      }
    })
    let results = data?.findDataRow?.data
    if (!results?.items) return false
    var items = flatMap(results.items, myFlat)
    return items
  }

  const closeModal = () => {
    onClose()
  }

  const handleChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys)
    setTargetData(rows.filter(item => targetKeys.includes(item[rowKey])))
  };

  return (
    <Modal
      title={title}
      visible={true}
      width={'auto'}
      maskClosable={false}
      onCancel={closeModal}
      centered
      // footer={false}
      footer={[
        <Button
          onClick={closeModal}
          disabled={updating}>
          Hủy thay đổi
        </Button>,
        <Button key="submit" type="primary"
          loading={updating}
          onClick={() => {
            onSave({
              items: targetData,
              keys: targetKeys
            })
          }}>
          OK
        </Button>,
      ]}
    >
      <Space>
        <SearchBoxItem

          // hasPreSearch={false}
          autoFocus={true}
          clearAfterSelect={true}
          className='searchMedium'
          autoClearSearchValue={true}
          width={200}
          tableId={tableId}
          filterOnDropdown={true}
          hasImage={true}
          columns={columns}
          displayColumn={displayColumn}
          // quickSelectOne={true}
          enterWithData={true}
          onSelect={async (v, o) => {
            let rData = o?.data?.row_data
            if (rData) {
              rData.id = o.key
              rData.se = o?.data?.se
            }
            if (rData?.id) {
              setRows([rData, ...targetData])
            }
            // window.location.reload(false);
          }}
          onEnter={(v) => {
            if (v?.rows) {
              setRows([...v.rows, ...targetData])
              // console.log('v.rows', v.rows)
            }
          }}
        />
        <Button
          style={{ width: 40, height: 40 }}
          size={'large'}
          icon={<ShoppingOutlined />}
          onClick={() => {
            setShowCollectionModal(true)
            // datatableStore.setCurrentDatarows(1)
            // setShowFilter(!showFilter)
          }}
        ></Button>
      </Space>
      <br />
      <Transfer
        style={{ marginTop: 10 }}
        titles={['Tìm sp', <span style={{ color: 'green', fontWeight: "bold" }}>Sản phẩm chọn</span>]}
        rowKey={record => record.code}
        listStyle={{
          width: 420,
          height: 400,
          align: "center"
        }}
        dataSource={rows}
        targetKeys={targetKeys}
        render={itemRender}
        onChange={handleChange}

      />
      {showCollectionModal &&
        <DataSelectModal
          hasAddButton={false}
          title={'Chọn bộ sản phẩm'}
          searchColumns={["name"]}
          initData={''}
          tableId={commonStore?.company?.companyData?.pcollection_table}
          closeOnSelect={true}
          onSave={async (v) => {
            if (v.ref) {
              if (v?.ref?.products) {
                let pRef = await findRowsByFilter(
                  {
                    tableId: commonStore?.company?.companyData?.product_table,
                    hasPreSearch: false,
                    limit: 100,
                    filter: { match: { "_id": v?.ref?.products.filter(p => p !== null).join('|') } }
                  }
                )
                if (pRef) {
                  setRows([...pRef, ...targetData])
                }
              }
            }
          }}
          onClose={() => {
            setShowCollectionModal(false)
          }}
          // component={ProductItem}
          onSearchSelect={(v, o) => {
            // console.log('o', o)
          }}
        />}
    </Modal>
  )
}
export default inject("datatableStore", "commonStore", "usersStore")(observer(SelectManyItemModal))