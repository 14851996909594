import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '../../layouts/DefaultLayout'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'
import DataTableServices from '../../../services/datatableServices'
import { useMutation } from '@apollo/react-hooks'
import useAsyncQuery from '../../../ultils/useAsyncQuery'
// import QuantityModal from './QuantityModal'
// import DatarowSelectInput from '../DataBoard/DataTools/DatarowSelectInput'
import { imagePreset, price_k } from '../../../ultils/datatableUtils'
import { customer_render, Tags_render } from '../DataBoard/DataTools/DataField'
import DataEditItem from '../DataBoard/DataTools/DataEditItem'
// import soundMessage from '../../../assets/sound/ping.mp3'
// import { Tags_render } from '../DataBoard/DataTools/DataField'
// import DataEditItem from '../DataBoard/DataTools/DataEditItem'
// import ResourceModal from '../Resource/ResourceModal'
// import fileDownload from 'js-file-download';
// import Axios from 'axios';
// import { toJS } from 'mobx'
// import moment from 'moment'
import QRReader from 'react-qr-scanner'
import styled from 'styled-components'
import {
    message,
    Space,
    Button,
    Tag,
    Tabs,
    Drawer,
    Image,
    List, Divider,
    Row, Col, Grid,
    notification

} from 'antd'

import {
    PlusOutlined,
    HeartOutlined,
    TagOutlined,
    // FolderOutlined,
    FilterOutlined,
    // HistoryOutlined,
    ShoppingOutlined,
    EditOutlined,
    BarcodeOutlined,
    // InstagramOutlined,
    // LinkOutlined,
    // AlignLeftOutlined,
} from '@ant-design/icons'
import { flatMap, merge as LMerge, intersection } from 'lodash-es'

// import moment from 'moment'
// import MarkdownIt from 'markdown-it';


// import SelectManyItemModal from '../Tools/SelectManyItemModal'

// import ResourceDetail from '../Resource/ResourceDetail'
import JobList from '../Job/JobList'
import { jobType } from '../Job/JobType'

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;


export const MyCol = styled(Col)`
    text-align: center;
    height:50px;
    line-height:50px;
    color:#fff;
`

// const jobType = [
//     { name: 'Nhặt hàng', type: 'nhathang', bgcolor: "#38C4B2" },
//     { name: 'Đóng hàng', type: 'donghang', bgcolor: "#0763A5" },
//     { name: 'Kiểm hàng', type: 'kiemhang', bgcolor: "#EB7C15" },
//     { name: 'Chốt đơn', type: 'chotdon', bgcolor: "#11C065" },
//     { name: 'Xử lý khiếu nại', type: 'xulykhieunai', bgcolor: "#D31341" },
//     { name: 'Chụp ảnh', type: 'chupanh', bgcolor: "#EBD215" },
// ]

// nhathang
// donghang
// chupanh
// chotdon
// kiemhang
// xulykhieunai
// const mdParser = new MarkdownIt(/* Markdown-it options */);
const OrderSearchPage = ({ usersStore, commonStore, datatableStore, history }) => {
    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )


    const [cameras, setCameras] = useState([])
    const [camId, setCamId] = useState(false)
    const [showQrcode, setShowQrcode] = useState(false)
    const [rows, setRows] = useState([])
    const [currentResources, setCurrentResources] = useState([])
    const [currentJobs, setCurrentJobs] = useState([])
    // const [currentFilter, setCurrentFilter] = useState({})
    const [showFilter, setShowFilter] = useState(false)
    const [currentTab, setCurrentTab] = useState('info')

    const [initData, setInitData] = useState(" ")
    const [showAddTask, setShowAddTask] = useState(false)

    const [currentEditRecord, setCurrentEditRecord] = useState(false)

    // const [currentEditRecord, setCurrentEditRecord] = useState(false)
    const [resTagData, setResTagData] = useState(false)
    // const [showResDetail, setShowResDetail] = useState(false)
    // const [currentResType, setCurrentResType] = useState('all')
    // const [currentResTag, setCurrentResTag] = useState(['all'])
    // const [showResourceModal, setShowResourceModal] = useState(false)
    // const [showSelectMany, setShowSelectMany] = useState(false)
    // const [recentProducts, setRecentProducts] = useState([])
    const screens = useBreakpoint();

    const [addJob] = useMutation(
        DataTableServices.ADD_JOB(),
        {
            errorPolicy: 'all'
        }
    )

    const [getProductByCodes] = useMutation(DataTableServices.GET_PRODUCTS_BY_CODES(), {
        errorPolicy: 'all',
    })

    // const [saveJob] = useMutation(
    //     DataTableServices.SAVE_JOB(),
    //     {
    //         errorPolicy: 'all'
    //     }
    // )

    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
    const findRowsByFilter = async (dataInput) => {
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        var items = flatMap(results.items, (v) => { return Object.assign(v.row_data, { key: v.id }) })
        return items
    }

    const findResQuery = useAsyncQuery(DataTableServices.FIND_RESOURCES())
    const getResources = async (dataInput) => {
        const { data, error } = await findResQuery({
            input: {
                data: dataInput
            }
        })
        let result = data?.findResources?.data
        if (result.status === 'OK')
            return result.items
        return false
    }


    const findJobQuery = useAsyncQuery(DataTableServices.FIND_JOBS())
    const getJobs = async (dataInput) => {
        const { data, error } = await findJobQuery({
            input: {
                data: dataInput
            }
        })
        let result = data?.findJobs?.data
        // console.log('result', result)
        if (result.status === 'OK') {
            if (result.userRef) {
                datatableStore.setUsersRef(result.userRef)
            }
            if (!datatableStore.usersRef[usersStore.currentUser]) {
                let u = {}
                let newU = {
                    id: usersStore.currentUser._id,
                    uname: usersStore.currentUser.username
                }
                if (usersStore.currentUser.user_profile) {
                    newU.name = usersStore.currentUser.user_profile.fullName
                    if (usersStore.currentUser.user_profile.avatar)
                        newU.avatar = usersStore.currentUser.user_profile.avatar.url
                }
                u[usersStore.currentUser.id] = newU
                // console.log('u', u)
                datatableStore.setUsersRef(u)
            }
            return result.items
        }
        return false
    }


    const { id } = useParams()
    const getRow = useAsyncQuery(DataTableServices.GET_ROW_BY_ID(id))

    let searchColumns = ['code', 'name', 'order_code']
    useEffect(() => {
        // commonStore.setCurrentPageTitle('Tài nguyên')
        async function init() {
            // let tagData = await findRowsByFilter(
            //     {
            //         tableId: commonStore?.company?.companyData?.tag_table,
            //         // tableId: '607d523459c61c2d08f434b2', // SUA LAI SAU
            //         hasPreSearch: false,
            //         limit: 500,
            //         filter: { match: { "category": ['Sản phẩm', 'Resource'] } }
            //     }
            // )
            // datatableStore.setPreloadTable('tags', tagData)

            if (rows.length === 0 && id) {
                let r = await getRow()
                let row = r?.data?.datarow?.row_data
                if (row) {
                    row.key = r?.data?.datarow?.id
                    row.se = r?.data?.datarow?.se
                    // datatableStore.setCurrentDatarows([row])
                    let codes = row?.items.filter(item => item.code).map(item => item.code.replaceAll(' ', ''))
                    const input = {
                        data: {
                            table_id: commonStore?.company?.companyData?.product_table,
                            codes: codes
                        }
                    }
                    let pRef = await getProductByCodes({
                        variables: { input }
                    })
                    pRef = pRef?.data?.getProductByCodes?.data
                    if (pRef.status === 'OK') {
                        row.items.forEach((item, index) => {
                            if (item.code) {
                                let code = item.code.replaceAll(' ', '')
                                // row.items[index].pref = pRef[code]
                                item.pref = pRef.products[code]
                            }
                        });
                    }
                    setRows([row])
                    commonStore.setCurrentPageTitle(row?.customer?.mobile)
                }
            } else {
                commonStore.setCurrentPageTitle('Đơn hàng')
            }
            if (!datatableStore?.preloadTable?.tags) {
                let tagData = await findRowsByFilter(
                    {
                        tableId: commonStore?.company?.companyData?.tag_table,
                        hasPreSearch: false,
                        limit: 500,
                        filter: { match: { "category": ['Đơn hàng', 'Sản phẩm', "Resource"] } }
                    }
                )
                datatableStore.setPreloadTable('tags', tagData)
                if (tagData)
                    setResTagData(tagData.filter(t => t.category === 'Resource'))
            }
            else
                setResTagData(datatableStore.preloadTable.tags.filter(t => t.category === 'Resource'))
        }
        init()
        if (cameras.length < 1) {
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const videoSelect = []
                    devices.forEach((device) => {
                        if (device.kind === 'videoinput') {
                            videoSelect.push(device)
                        }
                    })
                    return videoSelect
                })
                .then((devices) => {
                    setCameras(devices)
                    setCamId(devices[devices.length - 1].deviceId)
                    // this.setState({
                    //     cameraId: devices[0].deviceId,
                    //     devices,
                    //     loading: false,
                    // })
                })
                .catch((error) => {
                    console.log(error)
                })
        }


    }, [commonStore.company])


    // useEffect(() => {
    //     if (usersStore.socket) {
    //         usersStore.socket.on("addOrderJob", (data) => {
    //             console.log('data', data)
    //             notification['success']({
    //                 message: `TB`,
    //                 description: `Job ${data?.data?.name} đã được tạo bởi ${data?.data?.owner?.uname}`
    //             })
    //             // notification desktop
    //             if (window?.Notification && window.Notification.permission === 'granted') {
    //                 const audio = new Audio(soundMessage)
    //                 audio.play()
    //                 const notification = new window.Notification('Message from: ' + data?.creator?.uname, {
    //                     body: "hihi",
    //                     icon: data?.creator?.avatar || "https://joeschmoe.io/api/v1/random"
    //                 })
    //             } else if (window?.Notification && window.Notification.permission !== 'granted') {
    //                 window.Notification.requestPermission().then(permission => {
    //                     if (permission === 'granted') {
    //                         const audio = new Audio(soundMessage)
    //                         audio.play()
    //                         const notification = new window.Notification('Message from: ' + data?.creator?.uname, {
    //                             body: "hic",
    //                             icon: data?.creator?.avatar
    //                         })
    //                     }
    //                 })
    //             }
    //         })
    //     }

    //     return () => {

    //     }
    // }, [usersStore.socket])


    const OrderDetail = ({ order }) => {
        // console.log('product', product)
        // console.log('order.items', order.items)

        // useEffect(() => {
        //     if (order.items) {
        //         let codes = order?.items.map(item => item.code)

        //         const input = {
        //             data: {
        //                 table_id: commonStore?.company?.companyData?.product_table,
        //                 codes: codes
        //             }
        //         }
        //         let pRef = await getProductByCodes({
        //             variables: { input }
        //         })
        //         pRef = pRef?.data?.getProductByCodes?.data

        //         console.log('codes', codes)
        //     }
        // }, [])


        return (<>
            <h2 style={{ color: 'green' }}><strong style={{ marginRight: 5 }}>[{order.customer.name}]</strong>{order.customer.mobile}</h2>
            <Row>
                {/* <Col flex="auto" style={{ fontSize: '11pt' }}>
                    <strong style={{ marginRight: 5 }}>Tổng trả:</strong> {price_k(order.tong_tra, '?', 3)}
                    <br />
                    <strong style={{ marginRight: 5 }}>Địa chỉ:</strong>
                    <span>{order?.customer?.address}</span>
                    <br />
                    <strong style={{ marginRight: 5 }}> Ghi chú:</strong>{order?.note}
                </Col>
                <Divider /> */}
                {customer_render(order.customer, order, 0, (datatableStore.preloadTable))}
                <Divider />
                <h2 style={{ color: 'green' }}>Sản phẩm</h2>
                {datatableStore.preloadTable?.tags &&
                    <List
                        style={{ padding: 5, width: "100%" }}
                        itemLayout="horizontal"
                        dataSource={order.items}
                        renderItem={item => <ProductListItem product={item} />}
                    />
                }

                <Divider />
                <Space>
                    <Button
                        size={'large'}
                        icon={<EditOutlined />}
                        onClick={() => {
                            // console.log('product', product)
                            setCurrentEditRecord(order)
                            // setShowFilter(!showFilter)
                        }}
                    >
                        Chỉnh sửa
                    </Button>
                    <Button
                        size={'large'}
                        icon={<HeartOutlined />}
                        onClick={() => {
                            // setShowFilter(!showFilter)
                        }}
                    >
                    </Button>
                </Space>
            </Row>
        </>)
    }

    const ProductListItem = ({ product }) => {
        let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
        let imageSrc = product.image
        if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
        else {
            thumbnail = imagePreset(imageSrc, 'xsmall')
        }

        let pname = (product?.name || "")
        if (product.code)
            pname = "[" + product?.code + '] ' + pname.replace(product.code, "")
        // let cotheban = product.sl_tonkho - product.sl_giuhang
        // if (isNaN(cotheban) || cotheban < 0) cotheban = 0
        // let slColor = (product.sl_qty === cotheban) ? 'green' : 'blue'
        // let ctbColor = cotheban > 0 ? slColor : 'red'
        // if (product.sl_qty === 0) slColor = 'red'

        return (
            <List.Item>
                <List.Item.Meta
                    onClick={() => {
                        // setRows([product])
                        // history.replace('/tool/search_product/' + product.id)
                    }}
                    avatar={<Image
                        width={60}
                        height={60}
                        src={thumbnail}
                        preview={{
                            src: imageSrc,
                        }}
                    />}
                    // title={<a href={'/tool/search_product/' + product.key} target="_blank" title={pname}>{pname}</a>}
                    title={<a href={'/table/' + commonStore?.company?.companyData?.product_table + "?txt=" + product.code} target="_blank" title={pname}>{pname}</a>}
                    // title={pname}
                    description={
                        <>
                            {/* <strong style={{ marginRight: 5 }}>SL kho / giữ hàng:</strong> {product.sl_tonkho} / {product.sl_giuhang}
                            <br />
                            <strong style={{ marginRight: 5 }}>CTB / SLine:</strong>
                            <span style={{ fontWeight: 'bold', color: ctbColor }}> {cotheban}</span>/<span style={{ fontWeight: 'bold', color: slColor }}>{product.sl_qty}</span>
                            <br /> */}
                            <strong style={{ marginRight: 5 }}> Giá live:</strong>
                            <span style={{ color: 'green', marginRight: 15 }}> {price_k(product.price, '?', 3)}</span>
                            <strong style={{ marginRight: 5 }}>SL:</strong>
                            <span
                                style={{
                                    color: product.qty > 1 ? "orange" : "grey",
                                    fontSize: product.qty > 1 ? "large" : "inherit",

                                }
                                }>{product.qty}
                            </span>
                            <br />
                            vị trí :
                            {/* {product.pref?.tag_vitri} */}
                            <Tags_render tags={product.pref?.tag_vitri || []} preloadTable={datatableStore.preloadTable.tags} subCate="Tag vị trí" hasColor={true} />
                        </>
                    }
                />
            </List.Item>
        )
    }

    useEffect(() => {
        async function init() {
            if (id) {
                let res = await getJobs({ row_ids: [id] })
                // setCurrentJobs(res)
                datatableStore.setCurrentJobs(res)
            }
        }
        init()
    }, [id])


    const addType = async (type) => {
        if (type !== "donghang") {
            alert('chua co')
            return
        }
        let row_ids = []
        if (type === 'donghang') {
            row_ids = [id || rows[0].key]
        }
        let newData = {
            jdata: {},
            row_ids: row_ids,
            status: "dang_lam",
            type: type
        }
        let input = {
            data: newData
        }
        // console.log('input', input)
        let r = await addJob({
            variables: { input }
        })
        let result = r?.data?.addJob?.data
        if (result.status === 'OK') {
            let newJob = result.job
            let eData = {
                job: newJob
            }
            if (rows && rows.length === 1) {
                eData.row = {
                    cname: rows[0].customer.name,
                    code: rows[0].code,
                    mobile: rows[0].customer.mobile,
                    id: rows[0].key,
                    tong_tien: rows[0].tong_tien,
                }
            }
            // console.log('newJob', newJob)
            // if (usersStore.socket)
            // usersStore.socket.emit("addOrderJob", eData)
            datatableStore.setCurrentJobs([newJob, ...datatableStore.currentJobs || []])
        }
        // setCurrentJobs([...currentJobs, newData])
    }

    const SelectTaskType = ({ onClick }) => {
        return (
            <Row gutter={[24, 16]} align="middle" justify="center">
                {jobType.map(jt => {
                    // let textColor = {rt.type==='image'}
                    return (
                        <MyCol xs={8} sm={8} md={8} lg={6} xl={3} style={{ backgroundColor: jt.bgcolor }}
                            onClick={() => {
                                addType(jt.type)
                                setShowAddTask(false)
                            }}
                        >
                            {jt.name}
                        </MyCol>
                        // <Button
                        //     style={{ background: bgColor, color: textColor, marginRight: 6, marginBottom: 10 }}
                        //     size={'middle'}
                        //     icon={rt.icon}
                        //     onClick={() => {
                        //         if (currentResType === rt.type) {
                        //             setCurrentResType('all')
                        //         } else {
                        //             setCurrentResType(rt.type)
                        //         }
                        //     }}
                        // >
                        //     {screens.xs === false && rt.name}
                        // </Button>
                    )
                })}
            </Row>
        )
    }

    return (
        <DefaultLayout>
            <Space>
                {initData &&

                    <SearchBoxItem
                        // hasPreSearch={false}
                        autoFocus={true}
                        clearAfterSelect={true}
                        className='searchMedium'
                        autoClearSearchValue={true}
                        width={200}
                        tableId={commonStore?.company?.companyData?.order_table}
                        filterOnDropdown={true}
                        hasImage={true}
                        columns={searchColumns}
                        displayColumn={['code', 'customer.name', 'order_code']}
                        quickSelectOne={true}
                        enterWithData={true}
                        initValue={initData}
                        onSelect={async (v, o) => {
                            let rData = o?.data?.row_data
                            if (rData) {
                                rData.id = o.key
                                rData.se = o?.data?.se
                            }
                            if (rData?.id) {
                                // datatableStore.setCurrentDatarows([rData])
                                let codes = rData?.items.map(item => {
                                    if (item.code)
                                        return item.code.replaceAll(' ', '')
                                    return item.code
                                }
                                )
                                const input = {
                                    data: {
                                        table_id: commonStore?.company?.companyData?.product_table,
                                        codes: codes
                                    }
                                }
                                let pRef = await getProductByCodes({
                                    variables: { input }
                                })
                                pRef = pRef?.data?.getProductByCodes?.data
                                if (pRef.status === 'OK') {
                                    rData.items.forEach((item, index) => {
                                        if (item.code) {
                                            let code = item.code.replaceAll(' ', '')
                                            // row.items[index].pref = pRef[code]
                                            item.pref = pRef.products[code]
                                        }
                                    });
                                }
                                setRows([rData])
                                history.replace('/tool/search_order/' + rData.id)
                                commonStore.setCurrentPageTitle(rData?.customer?.mobile)
                                // setCurrentTab('info')
                            }

                        }}
                        onEnter={(v) => {
                            if (v?.rows) {
                                // setRows(v.rows)
                                // console.log('v.rows', v.rows)
                            }
                        }}
                    />
                }

                {/* <Button
                    style={{ width: 40, height: 40 }}
                    size={'large'}
                    icon={<FilterOutlined />}
                    onClick={() => {
                        setShowFilter(true)
                    }}
                ></Button> */}
                {/* <Button
                    style={{ width: 40, height: 40 }}
                    size={'large'}
                    icon={<ShoppingOutlined />}
                    onClick={() => {
                    }}
                ></Button> */}

                <Button
                    style={{ width: 40, height: 40 }}
                    size={'large'}
                    icon={<BarcodeOutlined />}
                    onClick={() => {
                        setShowQrcode(!showQrcode)
                        // console.log('qrRef', qrRef)
                        // if (qrRef.current) {
                        //     qrRef.current.reader.openImageDialog()
                        // }
                    }}></Button>
            </Space>
            <br />
            {showQrcode && camId && cameras.length > 0 &&
                <div>
                    <QRReader
                        delay={100}

                        style={{ width: "100%", margin: "10px 0 10px 0" }}
                        // onError={this.handleError}
                        facingMode="rear"
                        // constraints={{ audio: false, video: true }}
                        legacyMode={true}
                        constraints={camId && ({ audio: false, video: { deviceId: camId } })}
                        onScan={(data) => {
                            if (data && data.text) {

                                // console.log('data', data)
                                // alert(data)
                                setShowQrcode(false)
                                setInitData(data.text)
                            }
                            // console.log('data', data)
                        }}
                    />
                    <select
                        onChange={e => {
                            const value = e.target.value
                            setCamId(value)
                        }}
                    >
                        {cameras.map((deviceInfo, index) => (
                            <React.Fragment key={deviceInfo.deviceId}><option value={deviceInfo.deviceId}>{deviceInfo.label || `camera ${index}`}</option></React.Fragment>
                        ))}
                    </select>
                </div>

            }

            {currentEditRecord && rows && rows.length === 1 && (
                <DataEditItem
                    title={'Chỉnh sửa chi tiết'}
                    initData={currentEditRecord}
                    table={commonStore?.company?.companyData?.order_table}
                    rows={rows}
                    onSave={v => {
                        if (v?.data && v.data.length === 1)
                            setRows(v.data)
                        setCurrentEditRecord(false)
                    }}
                    onClose={() => {
                        setCurrentEditRecord(false)
                    }}
                />
            )}

            {/* {rows && rows.length > 1 &&
                <List
                    style={{ padding: 10 }}
                    itemLayout="horizontal"
                    dataSource={rows}
                    renderItem={item => <ProductListItem product={item} />}
                />
            } */}
            {rows && rows.length === 1 &&
                <Tabs activeKey={currentTab} onChange={(t) => { setCurrentTab(t) }}>
                    <TabPane tab="Thông tin" key="info">
                        <OrderDetail order={rows[0]} />
                    </TabPane>
                    {/* <TabPane tab="Công việc" key="work">
                        <Button
                            style={{ marginBottom: 10, marginRight: 6 }}
                            size={'middle'}
                            icon={<PlusOutlined />}
                            type={'dashed'}
                            onClick={() => {
                                setShowAddTask(!showAddTask)
                                // setShowResourceModal(true)
                            }}
                        >
                            thêm task
                        </Button>
                        {showAddTask &&
                            <>
                                <Divider />
                                <SelectTaskType />
                                <Divider />
                            </>
                        }
                        {datatableStore.currentJobs && datatableStore.currentJobs.length > 0 &&
                            <>
                                <Divider />
                                <JobList
                                    rdata={rows[0]} />
                            </>

                        }

                    </TabPane> */}
                    <TabPane tab="Lịch sử" key="histories">
                        chưa có ^^
                    </TabPane>
                    <TabPane tab="Ghi chú" key="notes">
                        chưa có ^^
                    </TabPane>
                    {/* <TabPane tab="Lịch sử" key="histories">
                        nothing
                    </TabPane> */}
                </Tabs>
            }
            <Drawer
                visible={showFilter}
                title='Bộ lọc'
                placement="right"
                onClose={() => {
                    setShowFilter(false)
                }}
            >
                <h3>Tag phân loại</h3>
            </Drawer>


            {/* {showResDetail &&
                <Drawer
                    visible={showResDetail}
                    title='Chi tiết tài nguyên'
                    placement="right"
                    onClose={() => {
                        setShowResDetail(false)
                    }}
                >
                    <ResourceDetail resource={showResDetail} />
                </Drawer>} */}

            {/* {showSelectMany &&
                <SelectManyItemModal
                    rowKey={'code'}
                    tableId={commonStore?.company?.companyData?.product_table}
                    title='Chọn nhiều sản phẩm'
                    columns={['code', 'name']}
                    displayColumn={['image', 'code', 'ngay_kiem', 'sl_tonkho', 'name']}
                    itemRender={
                        (item) => {
                            let thumbnail = imagePreset(item.image, 'xsmall')
                            const customLabel = (
                                <span className="list-compact">
                                    <div>
                                        <Image
                                            width={40}
                                            height={40}
                                            src={thumbnail}
                                            preview={{
                                                src: item.image,
                                            }}
                                        />
                                    </div>

                                    <span className="content">
                                        {item.code} {item.name}
                                    </span>
                                </span>
                            );
                            return {
                                label: customLabel,
                                value: item.code, 
                            };
                        }
                    }
                    onClose={() => {
                        setShowSelectMany(false)
                    }} />
            } */}
        </DefaultLayout>
    )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(OrderSearchPage))