import React from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar } from 'antd'
import styled from 'styled-components'
import { UserOutlined } from '@ant-design/icons'

const Username = styled.div`
  margin: 0 10px;
  font-size: 12px;
  font-weight: 300;
  @media only screen and (max-width: ${({ theme }) => theme.mediaQueries.lg}){
    display:none
  }
`

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`

const Profile = (props) => {
  const { usersStore } = props
  let user = usersStore.currentUser
  return (
    <ProfileContainer>
      <Avatar size={22} src={user?.user_profile?.avatar?.url || user?.user_profile?.avatar || ''} icon={<UserOutlined />} />
      <Username>{user?.email || ''}</Username>
    </ProfileContainer>
  )
}

export default inject('usersStore')(observer(Profile))