import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import { useParams, useHistory, useLocation } from 'react-router-dom'
// import { useMutation } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Input,
    InputNumber,
    Switch,
    Modal,
    message,
    Upload,
    Row,
    Col,
    Select,
    Badge
} from 'antd';
import {
    PlusOutlined,
    LoadingOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons';
// import { autorun, toJS } from 'mobx'
// import { debounce } from "lodash-es";
// import DataTableServices from '../../../../services/datatableServices'
// import UploadServices from '../../../../services/uploadServices'
// import useAsyncQuery from "../../../../ultils/useAsyncQuery";
// import styled from 'styled-components'
// import { nanoid } from 'nanoid'
// import { merge as LMerge, flatMap } from 'lodash-es'
// import { removeVietnameseCharMark, imagePreset, getRevisionData } from '../../../../ultils/datatableUtils'
// import 'rc-color-picker/assets/index.css';

import ColorPicker from 'rc-color-picker';

// import DatarowSelectInput from '../DataTools/DatarowSelectInput'

const { Option } = Select;

// function getBase64(img, callback) {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
// }


const GiuhangModal = ({
    datatableStore,
    commonStore,
    data,
    title,
    onClose
}) => {

    // const [selectedData, setSelectedData] = useState(initData)
    // const [defaultData, setDefaultData] = useState(toJS(initData))

    // const [updateDatarow] = useMutation(DataTableServices.UPDATE_DATAROW(), {
    //     errorPolicy: 'all',
    //     update(cache, { data }) {
    //     }
    // })

    // useEffect(async () => {
    //     // console.log(`table`, table)
    //     // console.log(`initData`, initData)
    //     if (!table.id) {
    //         const { data } = await getTableById()
    //         // console.log('datatable', data.datatable)
    //         setDatatable(data.datatable)
    //     } else {
    //         setDatatable(table)
    //     }
    // }, [])

    let history = useHistory();
    return (
        <Modal
            // style={{ width: "95%" }}
            title={title ? title : `Các đơn hàng có sp ${data?.pCode}`}
            visible={true}
            width={"95%"}
            maskClosable={false}
            onCancel={onClose}
            centered
            // footer={false}
            footer={[
                <Button type="default" onClick={onClose} danger>
                    Đóng
                </Button>,
                <Button type="default" onClick={() => {
                    if (data?.giuhang) {
                        console.log(`data.giuhang`, data.giuhang)
                        let rows = []
                        data.giuhang.map(gh => {
                            rows.push(
                                {
                                    id: gh.id,
                                    code: gh.code,
                                    name: gh.cname,
                                    mobile: gh.mb
                                }
                            )
                        });
                        datatableStore.add2cart(rows)
                        datatableStore.setShowOrderCart(true)
                    }
                    onClose()
                }} icon={<ShoppingCartOutlined />}>
                    Thêm vào list nhớ
                </Button>,
                <Button type="primary"
                    onClick={() => {
                        if (data?.giuhang) {
                            let searchIds = data.giuhang.map(o => o.id)
                            searchIds = searchIds.join('|')
                            let url = `/table/${commonStore?.company?.companyData?.order_table}?_id=${searchIds}`
                            const win = window.open(url, '_blank');
                            if (win != null) {
                                win.focus();
                            }
                            // history.push({
                            //     pathname: '/table/' + commonStore?.company?.companyData?.order_table,
                            //     search: '?_id=' + searchIds,
                            // })
                        }
                    }}>
                    Xem nhanh
                </Button>,

            ]}
        >
            {data?.giuhang &&
                data.giuhang.map(gh => {
                    return (
                        <>
                            <a href={'/row/' + gh.id} target="_blank" title={gh.cname}>{gh.code} </a> {gh.cname} - ({gh.mb}) , SL: {gh.qty}
                            <br />
                        </>
                    )
                })
            }
        </Modal>
    )
}
export default inject("datatableStore", "commonStore")(observer(GiuhangModal))