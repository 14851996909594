import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'

import {
    Divider,
    List,
    Image,
    Avatar,
    Tag,
    message,
    Space,
    Row,
    Col
} from 'antd';
// import moment from 'moment'
import {
    AlignLeftOutlined,
    LinkOutlined,
} from '@ant-design/icons';

import { imagePreset, date_str } from '../../../ultils/datatableUtils'
import moment from 'moment'
import { jobType, jobStatus } from './JobType'
import config from '../../../config'

const JobListItem = ({
    datatableStore,
    commonStore,
    usersStore,
    onClick,
    job }) => {
    // let jt = jobType.find(j => j.type === job.type)
    let jstatus = jobStatus.find(j => j.type === job.status)
    let uref = datatableStore.usersRef[job?.owner?.id]
    // console.log('ableStore.usersRef', ableStore.usersRef)
    let ava = uref?.avatar

    return (
        <List.Item>
            <List.Item.Meta
                onClick={onClick}
                // avatar={<Avatar style={{ backgroundColor: jt.bgcolor }} shape="square" size={64}>{jt.name}</Avatar>}
                // avatar={<Avatar style={{ backgroundColor: jt.bgcolor } } shape="square" size={64}>{jt.name}</Avatar>}
                avatar={
                    <Avatar
                        width={32}
                        height={32}
                        // src={config.ASSET_URL + "users/" + job.owner.id + ".jpg"}
                        src={uref?.avatar}
                    />}
                // title={jt.name}
                title={
                    <Space>
                        {/* <Avatar size={32} src={ava} /> */}
                        {uref?.name}
                    </Space>
                }
                description={
                    <>
                        {/* <strong>Trạng thái: </strong> <Tag title={jstatus.name} color={jstatus.bgcolor || "#dddddd"} key={jstatus.type}>{jstatus.name}</Tag>
                        <br /> */}
                        <strong>Bắt đầu: </strong> {date_str(job?.createdAt)}
                        {/* <br />
                        <strong>Kết thúc: </strong> {date_str(job?.jdata?.done_time)} */}
                    </>

                }
            />
        </List.Item>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(JobListItem))