import React, { useEffect, useState, useMemo, useCallback } from 'react'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Input,
  AutoComplete,
  Button
} from 'antd';

import {
  PlusOutlined
} from '@ant-design/icons'

// import { autorun, toJS } from 'mobx'
import { debounce, isObject } from "lodash-es";

import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
import DataSelectModal from '../DataTools/DataSelectModal'

// import { findRowsByText } from "../../../../ultils/datatableUtils";
const SearchBoxContainer = styled.div`
 
  `

const QuickAddParent = ({
  tableId,
  columns,
  limit,
  label = 'danh mục',
  onSave,
  autoLabel,
  autoInitType='date',
  width,
  placeholder,
  initValue,
  keyField = "name"
}) => {
  const [showAddModal, setShowAddModal] = useState(false)
  // const [matchItems, setMatchItems] = useState([])
  // const [inputValue, setInputValue] = useState("")
  // const [isSearching, setIsSearching] = useState(false)
  // const [doEnterSearch, setDoEnterSearch] = useState(false)
  // const [updateByFilter, setUpdateByFilter] = useState(false)
  // const [currentText, setCurrentText] = useState("")

  // function useDebounce(value, delay) {
  //   // State and setters for debounced value
  //   const [debouncedValue, setDebouncedValue] = useState(value);
  //   useEffect(
  //     () => {
  //       // Update debounced value after delay
  //       const handler = setTimeout(() => {
  //         setDebouncedValue(value);
  //       }, delay);
  //       // Cancel the timeout if value changes (also on delay change or unmount)
  //       // This is how we prevent debounced value from updating if value is changed ...
  //       // .. within the delay period. Timeout gets cleared and restarted.
  //       return () => {
  //         clearTimeout(handler);
  //       };
  //     },
  //     [value, delay] // Only re-call effect if value or delay changes
  //   );
  //   return debouncedValue;
  // }

  // useEffect(() => {
  //   if (!isSearching && onEnter && doEnterSearch) {
  //     onEnter(inputValue)
  //   }
  // }, [doEnterSearch, isSearching, inputValue])

  // const searchTxt = useCallback(
  //   debounce(async (name) => {
  //     await handleSearch(name);
  //   }, 600),
  //   []
  // );

  // useEffect(async () => {
  //   if (inputValue && !doEnterSearch) {
  //     searchTxt(inputValue)
  //   }
  // }, [inputValue, doEnterSearch])

  // useEffect(() => {
  //   if (keyField === 'id') {

  //   } else {
  //     if (initValue) {
  //       // doSearch(initValue)
  //     }
  //   }
  // }, [])

  // function valueByPath(row, c) {
  //   let path = c.split('.')
  //   // console.log(`path`, path)
  //   if (c.length == 1) return row[c]
  //   let out = row
  //   path.forEach(p => {
  //     out = out[p]
  //   });
  //   if (isObject(out)) return ""
  //   if (!out) return ""
  //   return out
  // }

  // function getRowText(row) {
  //   var s = ''
  //   columns.forEach((c, index) => {
  //     // s += row.row_data[c]
  //     let vp = valueByPath(row.row_data, c)
  //     if (index == 0) s = vp
  //     else
  //       if (vp != "") s += ' ' + vp
  //   });
  //   return s
  // }


  // const textColor = [
  //   '#03BF68', "333333"
  // ]

  // const renderItem = (row) => {
  //   return {
  //     key: row.id,
  //     value: clearAfterSelect ? "" : getRowText(row),
  //     data: row,
  //     label: (
  //       <div
  //         className='searchBoxItem'
  //       >
  //         {columns.map((column, index) => {
  //           if (index === 0)
  //             return (<strong className="boxItem" style={{ color: textColor[index % 2] }}>{valueByPath(row.row_data, column)}</strong>)
  //           else
  //             return (<span className="boxItem" style={{ color: textColor[index % 2] }}>{valueByPath(row.row_data, column)}</span>)
  //         })}
  //       </div>
  //     ),
  //   };
  // };

  // const rowsByText = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
  // const findRowsByText = async (txt, id) => {
  //   let params = {
  //     // txt: txt,
  //     columns: columns,
  //     tableId: tableId,
  //     limit: limit ? limit : 50,
  //     hasPreSearch: hasPreSearch,
  //     search_type: search_type
  //   }
  //   if (id) {
  //     params.id = id
  //   } else {
  //     params.txt = txt
  //   }
  //   if (filter) {
  //     params.filter = filter
  //     if (params.txt) params.filter.txt = params.txt
  //   }

  //   const { data, error } = await rowsByText({
  //     input: {
  //       data: params
  //     }
  //   })
  //   var results = data?.findDataRow?.data?.items
  //   if (!results) return []
  //   return results
  // }

  // const doSearch = async (s) => {
  //   s = s.toLowerCase()
  //   let out = []
  //   var filterData = await findRowsByText(s)
  //   // console.log('filterData', filterData)
  //   if (filterData.length > 0)
  //     setMatchItems(filterData.map((r) => renderItem(r)))
  //   else {
  //     setMatchItems([])
  //   }
  //   // datatableStore.setCurrentDatarows(unionBy(filterDataName, filterDataCodeCustomer, 'sid'))
  //   return out
  // }

  // const handleSearch = async (value) => {
  //   // setInputValue(value)
  //   // if (!inputValue) return
  //   setIsSearching(true)
  //   await doSearch(value)
  //   setIsSearching(false)
  //   // datatableStore.setFilter({txt:value})
  //   // onOk(value)
  // }

  // const onKeyDown = async e => {
  //   // if (onKeypress) onKeypress(e, inputValue)
  //   if (e.key == "Enter") {
  //     if (filterOnDropdown) {
  //       e.preventDefault()
  //       setDoEnterSearch(true)
  //       // if (!isSearching)
  //       // let v = inputValue
  //       // setInputValue(false)
  //       // if (onEnter) onEnter(e, v)
  //       // datatableStore.setFilter({ txt: inputValue })

  //     }
  //   }
  //   // else if (e.code == "Escape") {
  //   //   if (filterOnDropdown) {
  //   //     setInputValue("")
  //   //     datatableStore.setFilter({ txt: "" })
  //   //     e.preventDefault()
  //   //   }
  //   // }
  // }

  return (
    <>
      <Button
        size="small"
        icon={<PlusOutlined />}
        onClick={() => {
          setShowAddModal(true)
        }}
      > {label}</Button>

      {showAddModal ? (
        <DataSelectModal
          title={`Thêm ${label}`}
          dataTextName={label}
          autoLabel={autoLabel}
          searchColumns={["name"]}
          displayColumns={["name"]}
          searchText={false}
          autoInitType
          // initData={customerSelect}
          // tableId="5fe7fb4d2136a764ac573c1e"
          tableId={tableId}
          onSave={onSave}
          onClose={() => {
            setShowAddModal(false)
          }}
          // component={ProductItem}
          onSearchSelect={(v, o) => {
            // console.log('o', o)
          }}
        />


      ) : (
        ''
      )}

      {/* <AutoComplete
        style={{ width: width ? width : 150 }}
        defaultValue={initValue ? initValue : ""}
        // notFoundContent="không tìm thấy"
        // dropdownMatchSelectWidth={252}
        // style={{ width: "auto" }}
        dropdownMatchSelectWidth={350}
        // onBlur={() => {
        //   datatableStore.setFilter({ txt: inputValue })
        //   // onOk(inputValue)
        // }}
        size={'small'}
        allowClear={true}
        autoClearSearchValue={false}
        // value={inputValue}
        onSelect={
          (v, option) => {
            // console.log('options', option)
            // if (clearAfterSelect) setInputValue("")
            if (onSelect)
              onSelect(v, option)
          }
        }
        // onChange={(v) => {
        //   setInputValue(v)
        // }}
        onKeyDown={onKeyDown}
        // onDropdownVisibleChange={
        //   (open) => {
        //     if (!filterOnDropdown) return
        //     if (!open) {
        //       {
        //         setUpdateByFilter(true)
        //         // console.log(`inputValue`, inputValue)
        //         // datatableStore.setFilter({ txt: inputValue })
        //         // console.log(`datatableStore.filterData`, datatableStore.filterData)
        //       }
        //     }
        //   }
        // }

        onChange={(v) => {
          setInputValue(v)
        }}
        // onSearch={
        //   debounce(handleSearch, 600)
        // }


        // onSearch={(s) => {
        //   setInputValue(s)
        //   searchTxt(s)
        //   // debounce(
        //   //   function () {
        //   //     handleSearch(s)
        //   //   }
        //   //   , 600)
        // }}
        options={matchItems}
        placeholder={placeholder ? placeholder : "nhập từ khóa tìm kiếm"}
      >
        {hasSearchIcon ? <Input.Search
          allowClear
          size="small"
        /> : ""}

      </AutoComplete> */}

    </>
  )
}

export default QuickAddParent
// export default SearchBoxItem