import React, { useEffect, useRef, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import DataTableServices from '../../../../services/datatableServices'

import {
  Button,
  Tabs,
  Input,
  Table,
  Empty,
  Divider,
  Image,
  Typography,
  Popover,
  message,
  Space,
  // Popconfirm
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  FileTextOutlined,
  CloseOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
// import moment from 'moment'
// import { unionBy, flatMap, merge as LMerge } from "lodash-es"
// import { autorun, toJS, get } from 'mobx'
import { toJS } from 'mobx';
import { contrastColor } from 'contrast-color'
import styled from 'styled-components'
// import useAsyncQuery from "../../../../ultils/useAsyncQuery";
// import SearchBoxItem from "../DataTools/SearchBoxItem";
import DataSelectModal from '../DataTools/DataSelectModal'
import DataOptionSelect from '../DataTools/DataOptionSelect'
import OrderTextModal from './OrderTextModal'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PrintOrderDetail from '../../../printer/Product/PrintOrderDetail'

import { EditableCell, EditableRow } from "../EditableCell"
import { removeVietnameseCharMark, imagePreset, build_search_by_column } from '../../../../ultils/datatableUtils'

const { Text } = Typography
const { TextArea } = Input;

const CustomTable = styled(Table)`
.ant-table-thead > tr > th {
  background-color:${props => props.color} !important;
  color:${props => contrastColor({ bgColor: props.color })};
}
`;

const LiveOrderContainer = styled.div`
  width:100%;
  `

const LiveOrderPanel = ({ liveStore, datatableStore, commonStore, table }) => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [showOrderText, setShowOrderText] = useState(false)

  // const [createDatarow] = useMutation(DataTableServices.CREATE_DATAROW(), {
  //   errorPolicy: 'all',
  //   update(cache, { data }) {
  //   }
  // })

  const [updateManyDataRows] = useMutation(
    DataTableServices.UPDATE_MANY_ROWS(),
    {
      errorPolicy: 'all'
    }
  )
  // const getTableById = useAsyncQuery(DataTableServices.GET_TABLE(table.id ? table.id : table))

  const QuickNote = ({ rIndex, row, onClose }) => {
    // console.log(`row`, row)
    const noteText = useRef(null)
    return (
      <div style={{
        width: 250
      }}>
        <TextArea defaultValue={row?.note} ref={noteText} showCount maxLength={100} />

        <Button size={'small'} type="primary" style={{ marginRight: 8 }}
          onClick={() => {
            // console.log(`noteText.current`, noteText.current)
            if (noteText.current?.resizableTextArea?.props?.value !== undefined) {
              row.note = noteText.current?.resizableTextArea?.props?.value
              // console.log(`row`, row)
              liveStore.updateCartItem(row)
              if (row) {
                let order = toJS(liveStore.currentLiveOrder)
                // order.se = build_search_by_column(row, [{ key: 'code' }, { key: 'match_code' }, { key: 'name' }])
                let r = updateManyDataRows({
                  variables: {
                    input: {
                      data: {
                        type: "rowkey",
                        rows: [order]
                      }
                    }
                  }
                })
              }
            }
            onClose()
          }}
        >Lưu ghi chú</Button>
        <Button type="primary" danger icon={<DeleteOutlined />} size={'small'}
          onClick={() => {
            // row.note = undefined
            delete row.note
            liveStore.updateCartItem(row)
            if (row) {
              let order = toJS(liveStore.currentLiveOrder)
              let r = updateManyDataRows({
                variables: {
                  input: {
                    data: {
                      type: "rowkey",
                      rows: [order]
                    }
                  }
                }
              })
            }
            onClose()
          }}
        />
      </div>
    )
  }

  const ActionRender = (value, row, index) => {
    // console.log(`index`, index)
    const [showNoteBox, setShowNoteBox] = useState(false)
    const hideNoteBox = () => {
      setShowNoteBox(false)
    };
    return (
      <div>
        <Space>
          <Popover
            content={<QuickNote onClose={hideNoteBox} rIndex={index} row={row} />}
            placement="left"
            title={false}
            trigger="click"
            visible={showNoteBox}
            onVisibleChange={(v) => { setShowNoteBox(v) }}
          >
            <FileTextOutlined style={{ cursor: 'pointer' }} />
          </Popover>
          <CloseOutlined style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              row.qty = 0
              liveStore.updateCartItem(row)
              if (row) {
                let order = toJS(liveStore.currentLiveOrder)

                let r = updateManyDataRows({
                  variables: {
                    input: {
                      data: {
                        type: "rowkey",
                        rows: [order]
                      }
                    }
                  }
                })
              }
            }}
          />
        </Space>
      </div>
    )
  }

  const NameRender = (value, row, index) => {
    let name = row?.name
    let str = false
    if (row.code)
      str = (<><strong>{'[' + row.code.toUpperCase() + ']'}</strong> - {name}</>)
    if (row.match_code && row.match_code != row.code) {
      str = (<><strong>{'[' + row.match_code.toUpperCase() + ']'}</strong>{(row.code ? " -" + row.code : "")} - {name}</>)
    }
    return (
      <>
        {str}
        {/* <strong>{'[' + row.match_code.toUpperCase() + ']'}</strong> {(row.code !== row.match_code ? " -" + row.code : "")} - {name} */}
        {row.note ?
          (<>
            <br /><span style={{ fontSize: 11, color: "brown" }}>{row.note} </span>
          </>
          )
          : ""}
      </>
    )
  }

  const cellSave = (row) => {
    // console.log(`row`, row)
    liveStore.updateCartItem(row)
    if (row) {
      let order = toJS(liveStore.currentLiveOrder)

      let r = updateManyDataRows({
        variables: {
          input: {
            data: {
              type: "rowkey",
              rows: [order]
            }
          }
        }
      })
    }
  };

  const columns = [
    {
      title: 'Ảnh',
      dataIndex: 'image',
      key: 'image',
      render: (value, row, index) => {
        if (!value) {
          let lp = liveStore.liveProducts.find(item => item.match_code === row.code)
          if (!lp) lp = liveStore.liveProducts.find(item => item.code === row.code)
          if (lp) {
            value = lp.image
          }
        }
        let thumbnail = imagePreset(value, 'xsmall')
        return <Image
          width={40}
          height={40}
          src={thumbnail}
          preview={{
            src: value,
          }}
        />
      },
      width: 44
    },
    {
      title: 'SẢN PHẨM',
      dataIndex: 'code',
      key: 'code',
      width: 'auto',
      render: NameRender
    },
    {
      title: 'SL',
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',
      width: 50,
      editable: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'qty',
        inputType: 'number',
        title: 'SL',
        handleSave: cellSave,
      })
    },
    {
      title: 'GIÁ',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      onCell: (record) => ({
        record,
        editable: true,
        inputType: 'number',
        dataIndex: 'price',
        title: 'GIÁ',
        handleSave: cellSave,
      }),
      align: 'right',
      render: (value) => {
        return value ? value.toLocaleString('it-IT', { style: 'currency', currency: 'VND' }) : 0
      }
    },
    {
      title: '',
      dataIndex: 'code',
      width: 50,
      render: ActionRender
    },
  ];

  // useEffect(() => {
  //   console.log(`liveStore.currentLiveOrder`, liveStore.currentLiveOrder)
  // }, [liveStore.currentLiveOrder])


  // console.log('detailData', detailData)
  return (
    <LiveOrderContainer>
      <Space style={{ marginBottom: 10 }}>
        {liveStore.currentLiveOrder?.code && datatableStore.preloadTable?.status ?
          <>
            <DataOptionSelect
              subType={'color'}
              // tableId="607d523459c61c2d08f434b2"
              // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
              // initValue={"607e396659c61c2d08f434c2"}

              onOk={(item) => {
                let r = liveStore.currentLiveOrder
                r.status.ref = item
                liveStore.updateCartData(r)

                if (r) {
                  let order = toJS(liveStore.currentLiveOrder)
                  let r = updateManyDataRows({
                    variables: {
                      input: {
                        data: {
                          type: "rowkey",
                          rows: [order]
                        }
                      }
                    }
                  })
                }


                // console.log(`r`, r)
              }}
              rows={datatableStore.preloadTable.status}
            // rows={[
            //   { name: "abc", color: "blue" },
            //   { name: "zc", color: "orange" }
            // ]}
            />

            {/* <DataOptionSelect
              // tableId="607d523459c61c2d08f434b2"
              // initValue={toJS(liveStore.currentLiveOrder.status.ref)}
              // initValue={"607e396659c61c2d08f434c2"}

              onOk={(users) => {
                let r = liveStore.currentLiveOrder
                r.assigner = users
                liveStore.updateCartData(r)
                if (r) {
                  let order = toJS(liveStore.currentLiveOrder)
                  updateManyDataRows({
                    variables: {
                      input: {
                        data: {
                          type: "rowkey",
                          rows: [order]
                        }
                      }
                    }
                  })
                }


                // console.log(`r`, r)
              }}
              rows={datatableStore.preloadTable.status}
            /> */}


            <Button shape="round" icon={<PlusOutlined />} size={'small'} onClick={() => {
              setShowAddModal(true)
            }}>
              sản phẩm
            </Button>
            <Button shape="round" icon={<FileTextOutlined />} size={'small'} onClick={() => {
              setShowOrderText(true)
            }}>
              Nhắn khách
            </Button>
            {liveStore.currentLiveOrder.code ?
              <PrintOrderDetail title='In'
                orderData={toJS(liveStore.currentLiveOrder)}
                columns={['name', 'qty', 'price', 'priceItems']}
              />
              : ""}

          </>
          : ""
          // <Button type="primary" shape="round" disabled={liveStore.currentLiveOrder?.items?.length < 1} icon={<ShoppingCartOutlined />} size={'small'}
          //   onClick={async () => {
          //     if (liveStore.currentLiveOrder.items.length > 0) {
          //       liveStore.currentLiveOrder.items.forEach(item => {
          //         if (item.ref) {
          //           item.ref = {
          //             name: item.ref.name,
          //             code: item.ref.code,
          //             id: item.ref.id,
          //             sid: item.ref.sid,
          //             image: item.ref.image,
          //             price: item.ref.price
          //           }
          //         }
          //       });
          //     }
          //     let co = toJS(liveStore.currentLiveOrder)
          //     co.ngay_tao = (new Date()).toISOString()
          //     co.live_id = table
          //     co.code = (new Date()).valueOf()
          //     // console.log(`liveStore.preloadTable`, datatableStore.preloadTable)
          //     if (datatableStore.preloadTable?.status)
          //       co.status = {
          //         ref: datatableStore.preloadTable.status[0]
          //       }
          //     let se = co.code + " " + removeVietnameseCharMark(co.customer?.name)
          //     if (co.customer?.mobile) se += " " + co.customer?.mobile
          //     const input = {
          //       data: {
          //         datatable: commonStore?.company?.companyData?.order_table,
          //         row_data: co,
          //         rid: commonStore?.company?.companyData?.order_table + '_' + co.sid,
          //         se: se
          //       }
          //     }
          //     try {
          //       var newRow = await createDatarow({
          //         variables: { input }
          //       })
          //       // console.log(`newRow`, newRow)
          //       if (newRow) {
          //         message.success(`Thêm mới đơn hàng thành công!`)
          //         liveStore.updateCurrentRecord()
          //         // console.log(`newRow`, newRow)
          //       } else {
          //         message.error(`Thêm mới đơn hàng thất bại!`);
          //       }
          //     } catch (error) {
          //     }
          //   }}>
          //   Tạo đơn
          //   </Button>
        }

        {/* <Divider type="vertical" /> */}


        {/* <Button size={'small'}
          onClick={() => {
            liveStore.addToCart()
          }}
          icon={<ThunderboltFilled />}
        >Thêm tự động</Button> */}
      </Space>
      {
        liveStore.currentOrderItems ?
          <PerfectScrollbar
            style={{
              maxHeight: 'calc(100vh - 230px)',
              height: '100vh',
              marginBottom: 10,
            }}>
            {liveStore.currentLiveOrder?.code ?
              <CustomTable
                color={liveStore.currentLiveOrder?.status?.ref?.color ? liveStore.currentLiveOrder.status.ref.color : "#bbb"}
                id="liveOrderTable"
                components={{
                  body: {
                    row: EditableRow,
                    cell: EditableCell,
                  },
                }}
                // style={{
                //   overflowY: 'auto',
                //   maxHeight: 'calc(100vh - 300px)',
                //   height: '100vh',
                //   marginBottom: 10,
                //   border: '1px solid #fcfcfc'
                // }}
                // dataSource={liveStore.currentOrderItems.filter(item => item.code && item.qty > 0)}
                dataSource={liveStore.currentOrderItems.filter(item => item.code && item.qty > 0)}
                // dataSource={liveStore.currentOrderItems}
                columns={columns}
                // scroll={{ y: 'calc(100vh - 235px)', scrollToFirstRowOnChange: true }}
                bordered
                size={'small'}
                pagination={false}
                summary={pageData => {
                  let totalQuanity = 0;
                  let totalPrice = 0;

                  pageData.forEach(({ price, qty }) => {
                    qty = parseInt(qty)
                    if (qty > 0 && price) {
                      totalPrice += price * qty
                      totalQuanity += qty
                    }
                  });
                  let finalPrice = totalPrice
                  let extraText = false
                  if (!liveStore.currentLiveOrder?.customer?.phiship) liveStore.currentLiveOrder.customer.phiship = 0

                  if (liveStore.currentLiveOrder?.customer?.giamgia || liveStore.currentLiveOrder?.customer?.chuyenkhoan || liveStore.currentLiveOrder?.customer?.phiship)
                    extraText = 'Tổng thu : ('

                  if (liveStore.currentLiveOrder?.customer?.giamgia) {
                    finalPrice = finalPrice - parseInt(liveStore.currentLiveOrder.customer.giamgia)
                    extraText += " - Giảm giá " + liveStore.currentLiveOrder.customer.giamgia.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
                  }
                  if (liveStore.currentLiveOrder?.customer?.chuyenkhoan) {
                    finalPrice -= parseInt(liveStore.currentLiveOrder.customer.chuyenkhoan)
                    extraText += ' - Đã ck : ' + liveStore.currentLiveOrder.customer.chuyenkhoan.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
                  }
                  if (liveStore.currentLiveOrder?.customer?.phiship) {
                    finalPrice += parseInt(liveStore.currentLiveOrder.customer.phiship)
                    extraText += ' + Phí ship : ' + liveStore.currentLiveOrder.customer.phiship.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
                  }
                  if (extraText) extraText += ')'

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>Tổng số
                         </Table.Summary.Cell>
                        <Table.Summary.Cell align='center'>
                          <Text strong type="success">{totalQuanity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right' colSpan={2}>
                          <Text strong type="success" >{totalPrice.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      {extraText ? <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>{extraText}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center'>
                          <Text strong type="success">{totalQuanity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right' colSpan={2}>
                          <Text strong type="success" >{finalPrice.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                        : ""}
                    </>
                  );
                }}
              />
              : ""
            }

          </PerfectScrollbar>

          : ""}

      {showAddModal ?
        <DataSelectModal
          title={'Chọn sản phẩm thêm'}
          searchColumns={["code", "name", "match_code"]}
          // displayColumns={["code", "name", "price"]}
          initData={''}
          tableId={commonStore?.company?.companyData?.live_product_table}
          closeOnSelect={true}
          onSave={(v) => {
            if (v.ref) {
              let newItem = {
                code: v.ref.code,
                edit_count: 0,
                price: v.ref.price_live,
                quanity: 1,
                qty: 1,
                match_code: v.ref.code,
                ref: v.ref.sid,
                name: v.ref.name
              }
              liveStore.addIoCart(newItem)
              if (newItem) {
                // row.se = build_search_by_column(row, [{ key: 'code' }, { key: 'match_code' }, { key: 'name' }])
                let r = updateManyDataRows({
                  variables: {
                    input: {
                      data: {
                        type: "rowkey",
                        rows: [newItem]
                      }
                    }
                  }
                })
              }
            }

            // saveNewCode(v)
          }}
          onClose={() => {
            setShowAddModal(false)
          }}
          // component={ProductItem}
          onSearchSelect={(v, o) => {
            // console.log('o', o)
          }}
        />
        : ""
      }
      {showOrderText ?
        <OrderTextModal
          onClose={() => {
            setShowOrderText(false)
          }}
          orders={liveStore.currentOrderItems.filter(item => item.code && item.qty > 0)}
          addressInfo={liveStore.currentLiveOrder.customer?.address}
          customer={liveStore.currentLiveOrder.customer}
        />
        : ""
      }

    </LiveOrderContainer>
  )
}

export default inject("liveStore", "datatableStore", "commonStore")(observer(LiveOrderPanel))