import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Modal,
    Switch,
    message,
} from 'antd';

// import {
//     PlusOutlined,
//     LoadingOutlined
// } from '@ant-design/icons';

// import DataTableServices from '../../../../../services/datatableServices'
// import UploadServices from '../../../../services/uploadServices'
// import useAsyncQuery from "../../../../../ultils/useAsyncQuery";
// import { removeVietnameseCharMark, imagePreset, getRevisionData } from '../../../../ultils/datatableUtils'

import DatarowSelectInput from './DatarowSelectInput'

const TagSelectModal = ({
    datatableStore,
    commonStore,
    // tableId,
    title,
    massFilter,
    width = 400,
    placeholder,
    onSave,
    onClose,
    onDelete
}) => {

    // const [selectedData, setSelectedData] = useState(initData)
    const [updating, setUpdating] = useState(false)
    const [currentValue, setCurrentValue] = useState(false)
    const [override, setOverride] = useState(false)
    // const [updateManyDataRows] = useMutation(
    //     DataTableServices.UPDATE_MANY_ROWS(),
    //     {
    //         errorPolicy: 'all'
    //     }
    // )


    return (
        <Modal
            title={title ? title : `Chọn tag`}
            loading={updating}
            visible={true}
            width={width}
            maskClosable={false}
            onCancel={onClose}
            centered
            // footer={false}
            footer={[
                <Button onClick={onClose} disabled={updating}>
                    Hủy chọn
                </Button>,
                <Button onClick={async () => {
                    setUpdating(true)
                    if (onDelete)
                        await onDelete({
                            tags: currentValue
                        })
                    setUpdating(false)
                }} disabled={updating} type="primary" style={{ backgroundColor: "#ff0000" }}>
                    Xóa thẻ
                </Button>,
                <Button key="submit" type="primary"
                    loading={updating}
                    onClick={async () => {
                        setUpdating(true)
                        if (onSave)
                            await onSave({
                                tags: currentValue,
                                override: override
                            })
                        setUpdating(false)
                    }}>
                    Cập nhật hàng loạt
                </Button>,
            ]}
        >
            <DatarowSelectInput
                tableId={commonStore?.company?.companyData?.tag_table}
                // rows={massFilter?.preload ? datatableStore.preloadTable[massFilter.preload] : false}
                placeholder={placeholder || "chọn thẻ"}
                width={'100%'}
                viewType={'tag'}
                initFilter={massFilter?.filter}
                initValue={false}
                onOk={(item) => {
                    setCurrentValue(item)
                }}
            />
            <br />
            <br />
            <Switch onChange={(v) => {
                setOverride(v)
            }}
                checkedChildren="Reset dữ liệu"
                unCheckedChildren="Không reset" defaultChecked={override} />
        </Modal>
    )
}
export default inject("datatableStore", "commonStore")(observer(TagSelectModal))