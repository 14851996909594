import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '../../layouts/DefaultLayout'
import SearchBoxItem from '../DataBoard/DataTools/SearchBoxItem'
import DataTableServices from '../../../services/datatableServices'
import { useMutation } from '@apollo/react-hooks'

import {
    message,
    Space,
    Button
} from 'antd'

import {
    PlusOutlined,
    MinusOutlined,
    ReloadOutlined
} from '@ant-design/icons'

const QuickSetQtyProduct = ({ usersStore, commonStore, history }) => {
    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )
    let searchColumns = ['code', 'name']
    return (
        <DefaultLayout>
            <h2 style={{ margin: '10px 0' }}>Cập nhật số lượng tồn kho</h2>
            <SearchBoxItem
                hasPreSearch={false}
                autoFocus={true}
                clearAfterSelect={true}
                className='searchSyncPrice'
                autoClearSearchValue={true}
                width={'100%'}
                tableId={commonStore?.company?.companyData?.product_table}
                filterOnDropdown={true}
                columns={searchColumns}
                displayColumn={['code', 'sl_tonkho', 'sl_kiem', 'name']}
                quickSelectOne={true}
                onSelect={async (v, o) => {
                    let rData = o?.data?.row_data
                    if (rData) rData.id = o.key
                    if (rData?.id) {
                        let qty = prompt('Số lượng tồn kho:')
                        let sl_kiem = rData.sl_kiem
                        let sl_tonkho = rData.sl_tonkho
                        if (isNaN(qty)) {
                            alert('Dữ liệu đầu vào sai!')
                        } else {
                            qty = parseInt(qty)
                            if (sl_kiem) {
                                sl_kiem += qty
                                sl_tonkho = sl_kiem
                            } else {
                                sl_kiem = qty
                                sl_tonkho = qty
                            }
                            if (sl_tonkho >= 0 && qty > 0) {
                                rData.sl_tonkho = sl_tonkho
                                let r = await updateManyDataRows({
                                    variables: {
                                        input: {
                                            data: {
                                                type: "oldnew",
                                                rows: [{
                                                    id: rData.id,
                                                    n: {
                                                        'sl_kiem': sl_kiem,
                                                        'sl_tonkho': sl_tonkho
                                                    },
                                                    o: false
                                                }]
                                            }
                                        }
                                    }
                                })
                            }
                        }

                    }
                    window.location.reload(false);
                }}
                onEnter={(v) => {
                    //   console.log('v', v)
                    //   alert(v)
                }}
            />
        </DefaultLayout>
    )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(QuickSetQtyProduct))