import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'
import {
  Card,
  Row, Col
} from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

const { Meta } = Card;

const ToolsPage = ({ usersStore, commonStore, datatableStore, history }) => {
  // const { id } = useParams()
  useEffect(() => {
    commonStore.setCurrentPageTitle('Tiện ích')
  }, [])

  return (
    <DefaultLayout>
      <Row>
        <Col xs={24} sm={24} md={12} lg={6}>
          <a href="/tool/search_product">
            <Card
              hoverable
              style={{ width: 200, margin: 10 }}
              cover={<img alt="cập nhật giá live" src="/product.jpg" />}
            >
              <Meta title="Tìm sản phẩm" />
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <a href="/tool/search_order">
            <Card
              hoverable
              style={{ width: 200, margin: 10 }}
              cover={<img alt="Xem đơn hàng" src="/order.jpg" />}
            >
              <Meta title="Xem đơn hàng" />
            </Card>
          </a>
        </Col>
        {['chu-cong-ty', 'nv-kho', 'nhan-vien', 'leader1', 'leader2', 'quan-ly'].includes(usersStore.currentUser.company_role) &&
          <>
            <Col xs={24} sm={24} md={12} lg={6}>
              <a href="/tool/quick_adj_qty">
                <Card
                  hoverable
                  style={{ width: 200, margin: 10 }}
                  cover={<img alt="cập nhật sl san pham" src="/kiemhang2.jpg" />}
                >
                  <Meta title="Cập nhật số lượng sp" />
                </Card>
              </a>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6}>
              <a href="/tool/quick_add_products">
                <Card
                  hoverable
                  style={{ width: 200, margin: 10 }}
                  cover={<img alt="Nhặt list sản phẩm" src="/rack.jpg" />}
                >
                  <Meta title="Gom list sản phẩm" />
                </Card>
              </a>
            </Col> */}
          </>}
        {['chu-cong-ty', 'nv-kho', 'leader1', 'leader2', 'quan-ly', 'nv-online','nhan-vien'].includes(usersStore.currentUser.company_role) &&
          <>
            <Col xs={24} sm={24} md={12} lg={6}>
              <a href="/tool/quick_set_kwo">
                <Card
                  hoverable
                  style={{ width: 200, margin: 10 }}
                  cover={<img alt="cập nhật từ khóa đặt hàng" src="/matching.jpg" />}
                >
                  <Meta title="Cập nhật từ khóa đặt hàng" />
                </Card>
              </a>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <a href="/tool/quick_sync_price_live">
                <Card
                  hoverable
                  style={{ width: 200, margin: 10 }}
                  cover={<img alt="cập nhật giá live" src="/pricelive.jpg" />}
                >
                  <Meta title="Cập nhật nhanh giá live" />
                </Card>
              </a>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6}>
              <a href="/tool/live_quick_products">
                <Card
                  hoverable
                  style={{ width: 200, margin: 10 }}
                  cover={<img alt="sp live" src="/screen1.png" />}
                >
                  <Meta title="Sản phẩm live" />
                </Card>
              </a>
            </Col> */}
          </>
        }
        {/* {['chu-cong-ty', 'nv-kho', 'leader1', 'leader2', 'quan-ly', 'nv-online'].includes(usersStore.currentUser.company_role) &&
          <>

          </>
        } */}
      </Row>
    </DefaultLayout>


  )
}
export default inject("usersStore", "commonStore", "datatableStore")(observer(ToolsPage))