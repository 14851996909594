import CoreServices from './coreServices'
import { gql } from 'apollo-boost'

class DataTableServices extends CoreServices {
  constructor() {
    super()
  }

  GET_COMPANY(company_slug) {
    // return gql`
    //    query companies($company_slug:String!) { 
    //     companies (where:{company:{slug:$company_slug}} )
    //     {
    //       id,
    //       slug,
    //       name,
    //       companyData
    //     }
    //   }`

    return gql`
    query companies(where:{slug:"${company_slug}"}){
        id,
        slug,
        name,
        logo {
          url
        }
    }`
  }

  GET_COMPANY_BOARD() {
    return gql`
      query databoards($company_slug:String!) { 
        databoards (where:{company:{slug:$company_slug}} )
        {
          id,
          name,
          slug,
          board_data,
          datatables {
            id,
            name,
            type,
            table_data
          }
        }
      }
    `
  }

  GET_TABLE(id = '') {
    return this.GET(`
      id,
      name,
      databoard {
        id,
        name
      },
      data_sources {
        name,
        importFiles {
          name,
          hash,
          url,
          ext,
          createdAt
        }
      },
      type,
      table_scheme,
      table_columns,      
      table_data,
      data_table_template {
        table_columns,
        table_data_default,
        table_schema
      }
      `, 'datatable', id)
  }

  FIND_TABLE_ROW() {

    // return gql`
    //   query datarowsConnection($sort:String, $limit:Int, $start: Int, $where:JSON) {
    //     datarowsConnection(sort:$sort, limit:$limit, start:$start, where:$where) {
    //       values {
    //         id,
    //         row_data,
    //         rid
    //       }
    //       aggregate {
    //         count
    //         totalCount
    //       }
    //     }
    //   }
    // `

    return this.FIND(
      `
      id,
      row_data,
      rid,
      se
      `
      , 'datarows')
  }

  FIND_TABLE() {

    // return gql`
    //   query datarowsConnection($sort:String, $limit:Int, $start: Int, $where:JSON) {
    //     datarowsConnection(sort:$sort, limit:$limit, start:$start, where:$where) {
    //       values {
    //         id,
    //         row_data,
    //         rid
    //       }
    //       aggregate {
    //         count
    //         totalCount
    //       }
    //     }
    //   }
    // `

    return this.FIND(
      `
      id,
      name
      `
      , 'datatables')
  }

  GET_PRODUCTS_BY_ORDERS() {
    return gql`
      mutation getProductsByOrders($input: jsonInput!){
        getProductsByOrders(input:$input) {
          data
        }
      }
    `
  }

  UPDATE_MANY_ROWS() {
    return gql`
      mutation updateManyDataRows($input: jsonInput!){
        updateManyDataRows(input:$input) {
          data
        }
      }
    `
  }

  UPDATE_LIVE_PRODUCTS() {
    return gql`
      mutation updateLiveProducts($input: jsonInput!){
        updateLiveProducts(input:$input) {
          data
        }
      }
    `
  }

  UPDATE_LIVE_PRODUCTS_DELETED() {
    return gql`
      mutation updateLiveProductsDeleted($input: jsonInput!){
        updateLiveProductsDeleted(input:$input) {
          data
        }
      }
    `
  }



  FILL_TO_TABLE() {
    return gql`
      mutation fillDataToTable($input: jsonInput!){
        fillDataToTable(input:$input) {
          data
        }
      }
    `
  }


  DELETE_MANY_ROWS() {
    return gql`
      mutation deleteManyDataRows($input: jsonInput!){
        deleteManyDataRows(input:$input) {
          data
        }
      }
    `
  }

  GET_PRODUCTS_BY_CODES() {
    return gql`
      mutation getProductByCodes($input: jsonInput!){
        getProductByCodes(input:$input) {
          data
        }
      }
    `
  }

  FIND_ROW_BY_FILTER() {
    return gql`
      query findDataRow($input: jsonInput!){
        findDataRow(input:$input) {
          data
        }
      }
    `
  }

  GET_CUSTOMER_HISTORIES() {
    return gql`
      query getCustomerHistories($input: jsonInput!){
        getCustomerHistories(input:$input) {
          data
        }
      }
    `
  }
  GET_ROW_HISTORIES() {
    return gql`
      query getRowHistories($input: jsonInput!){
        getRowHistories(input:$input) {
          data
        }
      }
    `
  }

  FIND_RESOURCES() {
    return gql`
      query findResources($input: jsonInput!){
        findResources(input:$input) {
          data
        }
      }
    `
  }


  CHECK_CODE() {
    return gql`
      query check_code($input: jsonInput!){
        check_code(input:$input) {
          data
        }
      }
    `
  }


  FIND_JOBS() {
    return gql`
      query findJobs($input: jsonInput!){
        findJobs(input:$input) {
          data
        }
      }
    `
  }


  GET_ROW_BY_ID(id = '') {
    return this.GET(`
      id,
      row_data,
      datatable {
        id,
        name,
        type
      },
      se
      `, 'datarow', id)
  }

  UPDATE_STOCK_PRODUCT() {
    return gql`
    mutation updateStockProduct($input: jsonInput!){
      updateStockProduct(input:$input) {
        data
      }
    }
  `
  }

  DELETE_DATAROW(response) {
    if (!response) response = `
      id
    `
    return this.MUTATION(response, 'deleteDatarow', 'datarow')
  }

  DELETE_RESOURCE() {
    return gql`
    mutation removeResource($input: jsonInput!){
      removeResource(input:$input) {
        data
      }
    }
  `
  }


  // DELETE_RESOURCE(response) {
  //   if (!response) response = `
  //     id
  //   `
  //   return this.MUTATION(response, 'deleteResource', 'resource')
  // }


  ADD_RESOURCE() {
    return gql`
    mutation addResource($input: jsonInput!){
      addResource(input:$input) {
        data
      }
    }
  `
  }


  ADD_JOB() {
    return gql`
    mutation addJob($input: jsonInput!){
      addJob(input:$input) {
        data
      }
    }
  `
  }


  SAVE_JOB() {
    return gql`
    mutation saveJob($input: jsonInput!){
      saveJob(input:$input) {
        data
      }
    }
  `
  }

  DELETE_JOBS() {
    return gql`
    mutation deleteManyJob($input: jsonInput!){
      deleteManyJob(input:$input) {
        data
      }
    }
  `
  }

  ADD_NOTE() {
    return gql`
    mutation addNote($input: jsonInput!){
      addNote(input:$input) {
        data
      }
    }
  `
  }

  CREATE_DATAROW() {
    return this.MUTATION(
      `
      id,
      row_data,
      se,
      rid
      `
      , 'createDatarow', 'datarow')
  }

  UPDATE_DATAROW() {
    return this.MUTATION(
      `
      id,
      row_data,
      se,
      rid
      `
      , 'updateDatarow', 'datarow')
  }

  DO_SHIP() {
    return gql`
    mutation doShip($input: jsonInput!){
      doShip(input:$input) {
        data
      }
    }
  `
  }


  CANCEL_SHIP() {
    return gql`
    mutation cancelShip($input: jsonInput!){
      cancelShip(input:$input) {
        data
      }
    }
  `
  }

  EXPORT_PRODUCTS_EXCEL() {
    return gql`
    mutation exportProductExcel($input: jsonInput!){
      exportProductExcel(input:$input) {
        data
      }
    }
  `
  }

  EXPORT_ROWS_EXCEL() {
    return gql`
    mutation exportRowsExcel($input: jsonInput!){
      exportRowsExcel(input:$input) {
        data
      }
    }
  `
  }

  EXPORT_SLINE_LIVE() {
    return gql`
    mutation exportSLineLive($input: jsonInput!){
      exportSLineLive(input:$input) {
        data
      }
    }
  `
  }

  EXPORT_ORDERS_EXCEL() {
    return gql`
    mutation exportOrderNTExcel($input: jsonInput!){
      exportOrderNTExcel(input:$input) {
        data
      }
    }
  `
  }

  EXPORT_ORDERS_EXCEL_DETAIL() {
    return gql`
    mutation exportOrderExcelDetail($input: jsonInput!){
      exportOrderExcelDetail(input:$input) {
        data
      }
    }
  `
  }



  GET_PRINT_TOKEN() {
    return gql`
    mutation getPrintToken($input: jsonInput!){
      getPrintToken(input:$input) {
        data
      }
    }
  `
  }


  GET_COMPANY_USERS() {
    return gql`
      query companyRoleUsers($company_slug:String!) { 
        companyRoleUsers (where:{company:{slug:$company_slug}, isActive:true} )
        {
          user {
            username,
            email,
            user_profile {
              fullName,
              avatar {
                url
              }
            }            
          }
        }
      }
    `
  }

  MERGE_ORDER() {
    return gql`
    mutation mergeOrder($input: jsonInput!){
      mergeOrder(input:$input) {
        data
      }
    }
  `
  }

  // COPY_ORDER() {
  //   return gql`
  //   mutation copyOrder($input: jsonInput!){
  //     copyOrder(input:$input) {
  //       data
  //     }
  //   }
  // `
  // }

  CLEAN_COPY_ORDER() {
    return gql`
    mutation cleanCopyOrder($input: jsonInput!){
      cleanCopyOrder(input:$input) {
        data
      }
    }
  `
  }



  SPLIT_ORDER() {
    return gql`
    mutation splitOrder($input: jsonInput!){
      splitOrder(input:$input) {
        data
      }
    }
  `
  }

  IMPORT_ROWS() {
    return gql`
    mutation importRows($input: importInput!){
      importRows(input:$input) {
        data
      }
    }
  `
  }

  IMPORT_ORDERS() {
    return gql`
    mutation importOrders($input: importInput!){
      importOrders(input:$input) {
        data
      }
    }
  `
  }

  CREATE_REPORT() {
    return gql`
    mutation createReport($input: jsonInput!){
      createReport(input:$input) {
        data
      }
    }
  `
  }

  GET_RECORD_BY_ID() {
    return gql`
      query getReportDetail($id: String!){
        getReportDetail(id:$id) {
          data
        }
      }
    `
  }

  REFRESH_ORDER_DATA() {
    return gql`
    mutation refreshDataOrder($input: jsonInput!){
      refreshDataOrder(input:$input) {
        data
      }
    }
  `
  }

  RECALC_PRODUCT_QTY() {
    return gql`
    mutation recalcProductQty($input: jsonInput!){
      recalcProductQty(input:$input) {
        data
      }
    }
  `
  }

  EXPORT_REPORT_EXCEL() {
    return gql`
    mutation exportReportExcel($input: jsonInput!){
      exportReportExcel(input:$input) {
        data
      }
    }
  `
  }

  NI_DOWNLOAD() {
    return gql`
    mutation ni_download($input: jsonInput!){
      ni_download(input:$input) {
        data
      }
    }
  `
  }


  GET_SL_ORDERS() {
    return gql`
      query getShoplineOrders($input: jsonInput!){
        getShoplineOrders(input:$input) {
          data
        }
      }
    `
  }

  GET_SL_CUSTOMERS() {
    return gql`
      query getShoplineCustomers($input: jsonInput!){
        getShoplineCustomers(input:$input) {
          data
        }
      }
    `
  }

  GET_SL_PRODUCTS() {
    return gql`
      query getShoplineProducts($input: jsonInput!){
        getShoplineProducts(input:$input) {
          data
        }
      }
    `
  }

  SYNC_SL_ORDERS() {
    return gql`
    mutation syncOrdersFromSL($input: jsonInput!){
      syncOrdersFromSL(input:$input) {
        data
      }
    }
    `
  }

  SYNC_SL_CUSTOMERS() {
    return gql`
    mutation syncCustomerFromSL($input: jsonInput!){
      syncCustomerFromSL(input:$input) {
        data
      }
    }
    `
  }

  SYNC_SL_CATE() {
    return gql`
    mutation syncSLCate($input: jsonInput!){
      syncSLCate(input:$input) {
        data
      }
    }
    `
  }

  // CREATE_SL_CATE() {
  //   return gql`
  //   mutation createSLCategory($input: jsonInput!){
  //     createSLCategory(input:$input) {
  //       data
  //     }
  //   }
  //   `
  // }

  CREATE_SL_PRODUCT() {
    return gql`
    mutation createSLProduct($input: jsonInput!){
      createSLProduct(input:$input) {
        data
      }
    }
    `
  }

  REMOVE_SL_PRODUCTS() {
    return gql`
    mutation removeSLProducts($input: jsonInput!){
      removeSLProducts(input:$input) {
        data
      }
    }
  `
  }

  BULK_ASSIGN_SL_PCATE() {
    return gql`
    mutation bulkAssignSLProductCate($input: jsonInput!){
      bulkAssignSLProductCate(input:$input) {
        data
      }
    }
    `
  }

  UPDATE_RESOURCES() {
    return gql`
      mutation udpateResource($input: jsonInput!){
        udpateResource(input:$input) {
          data
        }
      }
    `
  }

  REMOVE_TAG_ROWS() {
    return gql`
    mutation removeTagRows($input: jsonInput!){
      removeTagRows(input:$input) {
        data
      }
    }
  `
  }

  MASS_UPDATE_TAG() {
    return gql`
    mutation massUpdateTag($input: jsonInput!){
      massUpdateTag(input:$input) {
        data
      }
    }
  `
  }
}

export default new DataTableServices()

