import React from 'react'
// import { Redirect } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { price_k } from '../../../ultils/datatableUtils'

const PrintableBodyWrapper = styled.div`
 {
    @page {
        size: auto;
        margin: auto;
      }
    @media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    }
    
    @media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always;
    }
    }

    font-size:10pt;
    table {
        width:100vw;
        font-size:9pt;
        tr {
        width:100%;
        border:1px solid #333;
        margin:0;
        padding:5px;
        text-align:left;
            td {
                padding:3px;
            }
            th {
                padding: 10px 5px;
            }
            td:not(:last-child), th:not(:last-child) {
                border-right:1px solid #333;
            }
        }
    }
    .summary {
        font-size:11pt;
        padding: 6px 5px;
    }
 
  }
`;

class OrderDetailPrintTemplate extends React.PureComponent {

    render() {
        // console.log(`this.props.summary`, this.props.summary)
        return (
            <PrintableBodyWrapper>

                {this.props.headerPrint ? this.props.headerPrint : ""}
                <table>
                    <tbody>
                        {
                            this.props.rows.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            this.props.columns.map(col => {
                                                let align = 'left'
                                                if (col === 'qty') align = 'center'
                                                if (col == 'price' || col == 'priceItems') align = 'right'
                                                if (index === 0)
                                                    return (<th key={`${index}-${col}`} align={align}>{row[col]}</th>)
                                                return (<td key={`${index}-${col}`} align={align}>{row[col]}</td>)
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td className="summary" colSpan={this.props.columns.length} align={'right'}>
                                <strong>SL: </strong>{this.props.summary.qty}<strong style={{ margin: "0 5px 0 15px" }}>Tổng :</strong>{price_k(this.props.summary.price, 0, 3)}
                            </td>
                        </tr>
                        <>
                            {this.props.summary.ship_fee ?
                                <tr style={{ borderTop: 'none', borderBottom: 'none' }}>
                                    <td className="summary" colSpan={this.props.columns.length - 1} align={'right'} style={{ border: 'none' }}>
                                        <strong>Tiền ship</strong>
                                    </td>
                                    <td className="summary" align={'right'} style={{ border: 'none' }}>
                                        {price_k(this.props.summary.ship_fee, 0, 3)}
                                    </td>
                                </tr>
                                : ""}
                            {this.props.summary.chuyenkhoan ?
                                <tr style={{ borderTop: 'none', borderBottom: 'none' }}>
                                    <td className="summary" colSpan={this.props.columns.length - 1} align={'right'} style={{ border: 'none' }}>
                                        <strong>Tiền chuyển khoản</strong>
                                    </td>
                                    <td className="summary" align={'right'} style={{ border: 'none' }}>
                                        {price_k(this.props.summary.chuyenkhoan, 0, 3)}
                                    </td>
                                </tr>
                                : ""}
                            {this.props.summary.giam_gia ?
                                <tr style={{ borderTop: 'none', borderBottom: 'none' }}>
                                    <td className="summary" colSpan={this.props.columns.length - 1} align={'right'} style={{ border: 'none' }}>
                                        <strong>Giảm giá</strong>
                                    </td>
                                    <td className="summary" align={'right'} style={{ border: 'none' }}>
                                        {price_k(this.props.summary.giam_gia, 0, 3)}
                                    </td>
                                </tr>
                                : ""}
                        </>

                        <tr style={{ borderTop: 'none' }}>
                            <td className="summary" colSpan={this.props.columns.length - 1} align={'right'} style={{ border: 'none' }}>
                                <strong>Tổng tiền</strong>
                            </td>
                            <td className="summary" align={'right'} style={{ border: 'none' }}>
                                {price_k(this.props.summary.tong_tra, 0, 3)}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.props.footerPrint ? this.props.footerPrint : ""}
                <span className='page-break' />
            </PrintableBodyWrapper>
        );
    }
}

export default OrderDetailPrintTemplate

