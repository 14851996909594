import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Table, Avatar, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import DataCardList from './DataCard/DataCardList'

const DataBoard = ({ usersStore, commonStore, datatableStore, history }) => {
  const { id } = useParams()

  useEffect(() => {
    if (datatableStore.dataBoards) {
      
      let boardIndex = datatableStore.dataBoards.findIndex(item => item.id === id)
      if (boardIndex >= 0) {
        commonStore.setCurrentPage(datatableStore.dataBoards[boardIndex].slug)
        datatableStore.setCurrentDataBoard(datatableStore.dataBoards[boardIndex])
        // console.log('datatableStore.currentDataBoard', datatableStore.currentDataBoard)
      }
      commonStore.setCurrentPageTitle(datatableStore.dataBoards[boardIndex].name)
    }
    // commonStore.setCurrentPageTitle(tb.datatable.name)
  }, [datatableStore.dataBoards])
  // const { usersStore, commonStore } = props

  // useEffect(() => {
  //   console.log('commonStore.currentPage', commonStore.currentPage)
  //   return () => {

  //   }
  // }, [commonStore.currentPage])

  const onClick = () => {
    // uiStore.setIsLoading(true)
    // commonStore.setCurrentPage('aaaaaaaaaaaaa')

    commonStore.setThemeColor({
      sidebarBgColor: 'red',
      primaryColor: 'red'
    })
  }

  const onClick2 = () => {
    // uiStore.setIsLoading(false)
    // commonStore.setCurrentPage('bbbbbbbbbbb')
    commonStore.setThemeColor({
      sidebarBgColor: 'green',
      primaryColor: 'green'
    })
  }

  return (
    <DefaultLayout>
      {datatableStore.currentDataBoard ?
        <DataCardList
          datasource={datatableStore.currentDataBoard.datatables}
          configs={{
            titleColumn: 'name',
            baseUrl: datatableStore.currentDataBoard?.board_data?.type === 'live' ? '/live/' : '/table/'
          }}
        />
        : ""}
    </DefaultLayout>
  )
  // if (user.role.type === 'organizer') {
  //   return <DashboardManager />
  // }
  // if (user.role.type === 'booth_owner') {
  //   return <DashboardBoothOwner />
  // }

}
export default inject("usersStore", "commonStore", "datatableStore")(observer(DataBoard))