import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const CrumbStyed = styled.div`

  @media only screen and (max-width: ${({ theme }) => theme.mediaQueries.sm }){
    display:none;
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaQueries.sm }){
    margin: ${({ val }) => val ? val: null };
  }
`

const StyledBreadCrumb = ({ commonStore, margin, breadcrumb }) => {
  // let { url } = useRouteMatch()
  // console.log(breadcrumb)
  let capitalizeCrumb
  if (breadcrumb) {
    let hashUrl = breadcrumb.split('/').splice(1)
    capitalizeCrumb = hashUrl.map( crumb => {
      return crumb.charAt(0).toUpperCase() + crumb.slice(1)
    })
  }
  // console.log(capitalize)
  return (
    <CrumbStyed val={margin}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard" ><HomeOutlined /></Link>
        </Breadcrumb.Item>
        {capitalizeCrumb && capitalizeCrumb.map( crumb => <Breadcrumb.Item key={crumb}>{crumb}</Breadcrumb.Item>)}
      </Breadcrumb>
    </CrumbStyed>
  )
}

export default inject('commonStore')(observer(StyledBreadCrumb))