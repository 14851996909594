import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
// import { useMutation } from '@apollo/react-hooks'
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import axios from 'axios'
// import Cookies from 'js-cookie'
// import config from '../../../config'

import {
    List,
    Button,
    Image,
    message,
    Space,
} from 'antd';
// import moment from 'moment'
// import MarkdownIt from 'markdown-it';
import {
    CopyOutlined,
} from '@ant-design/icons';

import { price_k, imagePreset } from '../../../ultils/datatableUtils'
// import ResourceDetail from './ResourceDetail'
// import DataTableServices from '../../../services/datatableServices'
// import useAsyncQuery from '../../../ultils/useAsyncQuery'
// const mdParser = new MarkdownIt(/* Markdown-it options */);

const CheckQtyProductList = ({
    datatableStore,
    commonStore,
    usersStore,
    products,
    hangthieu = {},
    onUpdateThieu=false
}) => {

    const ProductListItem = ({ product }) => {

        const [thieu, setThieu] = useState(hangthieu[product.code] || 0)
        // const [thieu, setThieu] = useState(product?.thieu || 0)
        let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
        let imageSrc = product.image
        if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
        else {
            thumbnail = imagePreset(imageSrc, 'xsmall')
        }

        let pname = (product?.name || "")
        if (product.code)
            pname = "[" + product?.code + '] ' + pname.replace(product.code, "")
        // let cotheban = product.sl_tonkho - product.sl_giuhang
        // if (isNaN(cotheban) || cotheban < 0) cotheban = 0
        // let slColor = (product.sl_qty === cotheban) ? 'green' : 'blue'
        // let ctbColor = cotheban > 0 ? slColor : 'red'
        // if (product.sl_qty === 0) slColor = 'red'

        // let label = thieu > 0 ? "Thiếu hàng (" + thieu + ")" : "Đủ hàng"


        return (
            <List.Item>
                <List.Item.Meta
                    onClick={() => {
                        // setRows([product])
                        // history.replace('/tool/search_product/' + product.id)
                    }}
                    avatar={<Image
                        width={60}
                        height={60}
                        src={thumbnail}
                        preview={{
                            src: imageSrc,
                        }}
                    />}
                    // title={<a href={'/tool/search_product/' + product.key} target="_blank" title={pname}>{pname}</a>}
                    title={<a href={'/table/' + commonStore?.company?.companyData?.product_table + "?txt=" + product.code} target="_blank" title={pname}>{pname}</a>}
                    // title={pname}
                    description={
                        <>
                            {/* <strong style={{ marginRight: 5 }}>SL kho / giữ hàng:</strong> {product.sl_tonkho} / {product.sl_giuhang}
                            <br />
                            <strong style={{ marginRight: 5 }}>CTB / SLine:</strong>
                            <span style={{ fontWeight: 'bold', color: ctbColor }}> {cotheban}</span>/<span style={{ fontWeight: 'bold', color: slColor }}>{product.sl_qty}</span>
                            <br /> */}
                            <strong style={{ marginRight: 5 }}> Giá live:</strong>
                            <span style={{ color: 'green', marginRight: 15 }}> {price_k(product.price, '?', 3)}</span>
                            <strong style={{ marginRight: 5 }}>SL:</strong> {product.qty}
                            <br />
                            <Button
                                onClick={() => {
                                    let qtyThieu = prompt('Thiếu ? sản phẩm:', thieu)
                                    qtyThieu = parseInt(qtyThieu)
                                    hangthieu[product.code] = qtyThieu
                                    setThieu(qtyThieu)
                                }}
                                size={"small"} style={{ backgroundColor: thieu > 0 ? "red" : "green", color: "white" }} >
                                {thieu > 0 ? "Thiếu hàng (" + thieu + ")" : "Đủ hàng"}
                            </Button>
                        </>
                    }
                />
            </List.Item>
        )
    }
    // useEffect(() => {
    //     async function init() {
    //         if (row_ids) {
    //             let res = await getjobs({ row_ids: row_ids })
    //             setjobs(res)
    //         } else {
    //             setjobs(rows)
    //         }
    //     }
    //     init()

    // }, [row_ids, rows])

    // let title = truncate(resource?.name || '', 35)
    return (
        <>
            <List
                header={
                    <h3>
                        <strong>Tình trạng sản phẩm</strong>
                    </h3>
                }
                style={{ padding: 5, width: "100%" }}
                itemLayout="horizontal"
                dataSource={products}
                renderItem={item => <ProductListItem product={item} />}
            />
        </>
    )
}

export default inject("datatableStore", "commonStore", "usersStore")(observer(CheckQtyProductList))