import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Image,
    InputNumber,
    Modal,
    message
} from 'antd';

import DataTableServices from '../../../services/datatableServices'
import { imagePreset } from '../../../ultils/datatableUtils'

const QuickSetWeightBlock = ({
    datatableStore,
    commonStore,
    usersStore,
    initData,
    onSave,
    onClose,
    rows
}) => {

    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )
    const [updating, setupdating] = useState(false)
    const [qRows, setQRows] = useState(rows)
    const [form] = Form.useForm();
    const closeModal = () => {
        onClose()
    }

    const onFinish = async (values) => {
        setupdating(false)
        let uRows = qRows.filter(qq => values[qq.key] !== undefined).map(u => {
            let new_weight = parseInt(values[u.key])
            return {
                id: u.key,
                n: {
                    weight: new_weight
                },
                o: false
            }
        })
        if (uRows.length >= 0) {
            let r = await updateManyDataRows({
                variables: {
                    input: {
                        data: {
                            type: "oldnew",
                            rows: uRows
                        }
                    }
                }
            })
            if (r?.data?.updateManyDataRows?.data?.status === 'OK') {
                qRows.forEach(qq => {
                    let new_weight = parseInt(values[qq.key])
                    qq.weight = new_weight
                });
                datatableStore.massUpdateRows("weight", false, datatableStore.selectedRowKeys, qRows)
                message.success('Cập nhật cân nặng thành công', 2);
            }
        }

        onSave(values)

    };

    const layout = {
        labelCol: {
            span: 18,
        },
        wrapperCol: {
            span: 6,
        },
    };

    const MyForm = () => {
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        const PItem = ({ r }) => {
            let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
            let imageSrc = r.image
            if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
            else {
                thumbnail = imagePreset(imageSrc, 'xsmall')
            }
            return (
                <>
                    <span>
                        {r.name} - (<b style={{ color: "blue" }}>{r.weight}g</b>)
                    </span>
                    <b style={{ background: 'green', color: 'white', padding: "3px 4px", margin: "0 10px", borderRadius: 4 }}>
                        {r.code}
                    </b>
                    <Image
                        width={36}
                        height={36}
                        src={thumbnail}
                        // style={{ marginRight: 5 }}
                        preview={{
                            src: imageSrc,
                        }} />
                </>
            )
        }

        const qtyRef = useRef(null)
        useEffect(() => {
            if (qtyRef.current) {
                qtyRef.current.focus();
                // sleep(100)
                // form.setFieldsValue({ qty: "" })
            }
        }, [qtyRef])
        return (
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // initialValues={{
                //   qty: ""
                // }}
                onFinish={onFinish}>

                {qRows.map(r => {
                    return (
                        <Form.Item
                            style={{ textAlign: 'left' }}
                            name={r.key}
                            label={<PItem r={r} />}
                        >
                            <InputNumber />
                        </Form.Item>
                    )
                })}
            </Form>
        )
    }

    return (
        <Modal
            title={"Cập nhật cân nặng nhiều sp"}
            visible={true}
            width={"100%"}
            // height={"100%"}
            maskClosable={false}
            onCancel={closeModal}
            centered
            // footer={false}
            footer={[
                <Button
                    onClick={closeModal}
                    disabled={updating}>
                    Hủy thay đổi
                </Button>,
                <Button key="submit" type="primary"
                    loading={updating}
                    onClick={() => {
                        setupdating(true)
                        form.submit()
                    }}>
                    Cập nhật
                </Button>,
            ]}
        >
            <MyForm />
        </Modal>
    )
}
export default inject("datatableStore", "commonStore", "usersStore")(observer(QuickSetWeightBlock))