import CoreServices from './coreServices'
import { gql } from 'apollo-boost'

class UploadServices extends CoreServices {
  constructor() {
    super()
    this.response = `
      id
      createdAt
      updatedAt
      name
      alternativeText
      caption
      size
      hash
      ext
      mime
      provider
      previewUrl
      caption
      url
    `
  }

  FIND_FILES(response = '') {
    if (!response) response = this.response
    return this.FIND(response, 'files')
  }

  UPLOAD(response = '') {
    if (!response) response = this.response
    return gql`
      mutation upload($file: Upload!){
        upload(file:$file){
          ${response}
        }
      }
    `
  }

  MULTIPLE_UPLOAD(response = '') {
    if (!response) response = this.response
    return gql`
      mutation MultipleUpload($files: [Upload]!){
        multipleUpload(files:$files){
          ${response}
        }
      }
    `
  }

}

export default new UploadServices()

