import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import config from '../../../../../config'
import {
    Form,
    Button,
    Select,
    Input,
    InputNumber,
    Typography,
    Modal,
    Card,
    Space,
    Row,
    Col
} from 'antd';
import {
    PhoneOutlined,
    EnvironmentOutlined,
    ContactsFilled,
    QuestionCircleOutlined,
    CheckOutlined,
    UserOutlined,
    CoffeeOutlined
} from '@ant-design/icons';
// import { autorun, toJS } from 'mobx'
import DataTableServices from '../../../../../services/datatableServices'
import useAsyncQuery from "../../../../../ultils/useAsyncQuery";
import { useMutation } from '@apollo/react-hooks'
// import styled from 'styled-components'
// import { nanoid } from 'nanoid'
// import { merge as LMerge } from 'lodash-es'
// import OrderCustomerForm from './OrderCustomerForm'
import DataSelectModal from '../../DataTools/DataSelectModal'
import { removeVietnameseCharMark } from '../../../../../ultils/datatableUtils'
import { flatMap } from "lodash-es"

import 'rc-color-picker/assets/index.css';

const { Title } = Typography
const { TextArea } = Input;
const { Option } = Select;


// const { TabPane } = Tabs;
// const { Meta } = Card;

// const SelectModal = styled(Modal)`

//   `
function myFlat(v) {
    return Object.assign(v.row_data, { key: v.id })
}

const OrderEditCustomer = ({
    commonStore,
    datatableStore,
    orderData,
    table,
    width = '100%',
    onSave,
    onClose,
    noDefaultCamera
}) => {

    const [customerSelect, setCustomerSelect] = useState(false)
    const [form] = Form.useForm();
    const [isSaved, setIsSaved] = useState(true)

    const [provinces, setProvinces] = useState([])
    // const [districts, setDistricts] = useState([{DistrictID:-1,ProvinceName:'--chưa chọn--'}])
    const [districts, setDistricts] = useState([])
    const [wards, setWards] = useState([])

    const [carriers, setCarriers] = useState([])
    const [branches, setBranches] = useState([])

    const [currentCity, setCurrentCity] = useState('--chưa chọn--')
    const [currentDist, setCurrentDist] = useState('--chưa chọn--')
    const [currentWard, setCurrentWard] = useState('--chưa chọn--')
    const [outOrder, setOutOrder] = useState(orderData)

    // const [datatable, setDatatable] = useState(false)
    const [adding, setAdding] = useState(false)


    const [getProductByCodes] = useMutation(DataTableServices.GET_PRODUCTS_BY_CODES(), {
        errorPolicy: 'all',
    })

    function inSet(arr, value, value2, keyName) {
        let found = false
        let v = value || value2
        if (v) {
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                let nexs = []
                if (item.NameExtension) {
                    item.NameExtension.forEach(nex => {
                        if (nex) {
                            let n = removeVietnameseCharMark(nex)
                            if (n) {
                                n = n.replace('- ', '')
                                nexs.push(n.toLowerCase())
                            }
                        }
                    });
                }
                v = v.toLowerCase()
                if (v) {
                    v = removeVietnameseCharMark(v)
                    v = v.replace('- ', '')
                }
                if (nexs && nexs.includes(v)) {
                    found = item[keyName]
                    break
                }
            }
        }
        return found
    }

    function getCarrierByWeight(weight, branch) {
        let wei = weight
        wei = wei * 1000
        // if (wei < 40) wei = wei * 1000 // nếu bé hơn 30g tự nhận là kg
        let found = false
        for (let index = 0; index < carriers.length; index++) {
            const ca = carriers[index];
            if (wei >= ca.weight_from && wei <= ca.weight_to && branch === ca.branch) {
                found = carriers[index]
                break;
            }
        }
        if (!found) found = carriers.find(item => item.main_code === 'ghn-main')
        return found
    }

    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
    const findOptionRow = async (tableId, filter) => {
        let dataInput = {
            tableId: tableId,
            hasPreSearch: true,
        }
        if (filter)
            dataInput.filter = filter
        // if (datatableStore.filterData.sort) dataInput.sort = datatableStore.filterData.sort
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        // console.log(`result`, results)
        if (!results?.items) return false
        return flatMap(results.items, myFlat)
    }

    // const getTableById = useAsyncQuery(DataTableServices.GET_TABLE(table.id ? table.id : table))
    // const [upload] = useMutation(
    //     UploadServices.UPLOAD()
    // )

    // const [updateManyDataRows] = useMutation(
    //     DataTableServices.UPDATE_MANY_ROWS(),
    //     {
    //         errorPolicy: 'all'
    //     }
    // )

    // const [form] = Form.useForm();
    const closeModal = () => {
        onClose()
    }
    useEffect(async () => {
        // if (!table.id) {
        //     const { data } = await getTableById()
        //     // console.log('datatable', data.datatable)
        //     setDatatable(data.datatable)
        // } else {
        //     setDatatable(table)
        // }
        form.resetFields()
        setIsSaved(true)

        async function fetchData() {
            let p = await fetch(config.API_ENDPOINT + '/all_province')
                .then(response => {
                    return response.json()
                })
            if (p.data) {
                p.data.unshift({
                    ProvinceID: -1,
                    NameExtension: [],
                    Code: -1,
                    ProvinceName: '--chưa chọn--'
                })
                setProvinces(p.data)
                let found = inSet(p.data, orderData?.province?.ProvinceName, orderData?.ship?.sl_province, 'ProvinceName')
                // console.log(`found`, found)
                if (found)
                    setCurrentCity(found)
                else
                    setCurrentCity('--chưa chọn--')
            }

            let cr = await findOptionRow(commonStore?.company?.companyData?.carrier_table)
            // console.log(`cr`, cr)
            if (cr)
                setCarriers(cr)

            let br = await findOptionRow(commonStore?.company?.companyData?.branch_table)
            if (br)
                setBranches(br)
        }
        fetchData()


    }, [])

    useEffect(() => {
        async function fetchData() {
            let v = provinces.find(item => item.ProvinceName == currentCity)
            if (!v) {
                setDistricts([])
                setCurrentDist('--chưa chọn--')
            } else {
                let d = await fetch(config.API_ENDPOINT + '/district_by_province/' + v.ProvinceID)
                    .then(response => response.json())
                if (d.data) {
                    d.data.unshift({
                        DistrictID: -1,
                        NameExtension: [],
                        Code: -1,
                        DistrictName: '--chưa chọn--'
                    })
                    setDistricts(d.data)
                    let found = inSet(d.data, orderData?.district?.DistrictName, orderData?.ship?.sl_district, 'DistrictName')
                    if (!found) setCurrentDist('--chưa chọn--')
                    else setCurrentDist(found)
                    // if (d.data.include(orderData?.customer?.district?.DistrictName)) {
                    //     setCurrentDist(orderData?.customer?.district?.DistrictName)
                    // }
                    // if (orderData?.customer?.district) {
                    //     console.log(`orderData?.customer?.district`, orderData?.customer?.district)
                    //     setCurrentDist(orderData?.customer?.district.DistrictName)
                    // }
                } else {
                    setDistricts([])
                    setCurrentDist('--chưa chọn--')
                }
            }
            // You can await here
            // await handleProvinceChange(orderData?.customer?.province.ProvinceName)
            // ...
        }
        if (provinces.length > 0) {
            fetchData();
        }
        form.setFieldsValue({
            province: currentCity,
        });
    }, [currentCity])

    useEffect(() => {
        async function fetchData() {
            let v = districts.find(item => item.DistrictName == currentDist)
            if (!v || v.DistrictID === -1) {
                setWards([])
                setCurrentWard('--chưa chọn--')
            } else {
                let w = await fetch(config.API_ENDPOINT + '/ward_by_district/' + v.DistrictID)
                    .then(response => response.json())
                if (w.data) {
                    w.data.unshift({
                        WardCode: -1,
                        NameExtension: [],
                        WardName: '--chưa chọn--'
                    })
                    setWards(w.data)
                    let found = inSet(w.data, orderData?.ward?.WardName, orderData?.ship?.sl_ward, 'WardName')
                    if (!found) setCurrentWard('--chưa chọn--')
                    else setCurrentWard(found)

                } else {
                    setWards([])
                    setCurrentWard('--chưa chọn--')
                }
            }
            // You can await here
            // await handleProvinceChange(orderData?.customer?.province.ProvinceName)
            // ...
        }
        if (districts.length > 0) {
            fetchData();
        }
        form.setFieldsValue({
            district: currentDist,
        });

    }, [currentDist])

    useEffect(() => {
        form.setFieldsValue({
            ward: currentWard,
        });
    }, [currentWard])

    const handleProvinceChange = async (value) => {
        setCurrentCity(value)
    };

    const handleDistrictChange = async (value) => {
        setCurrentDist(value)
    };

    const handleWardChange = async (value) => {
        setCurrentWard(value)
        let v = wards.find(item => item.WardName == value)
        // console.log(`v`, v)
        // setSecondCity(value);
    };

    const onFinish = (values) => {
        // console.log('values', values)
        // alert('acccccccccccc')
        let out = values
        if (values.cod && values.cod < 1000) out.cod = values.cod * 1000
        out.province = provinces.find(item => item.ProvinceName == values.province)
        out.district = districts.find(item => item.DistrictName == values.district)
        out.ward = wards.find(item => item.WardName == values.ward)
        if (out.province) delete out.province['NameExtension']
        if (out.district) delete out.district['NameExtension']
        if (out.ward) delete out.ward['NameExtension']

        if (!out.branch) {
            out.branch = "Yên Lạc"
        }

        // if (out.carrier && out.weight) {
        //     let refCarrier = carriers.find(c => c.name === values.carrier)
        //     if (refCarrier)
        //         out.carrier = {
        //             name: refCarrier.name,
        //             code: refCarrier.code,
        //             ref: refCarrier.key
        //         }
        // } else {

        // }
        if (!out.weight || !out.carrier) {
            if (orderData.autoWeight > 0) {
                let w = Math.round(orderData.autoWeight * 1.5) / 1000 // 15%
                form.setFieldsValue({ 'weight': w })
                let cw = changeWeight(w, out.branch)
                out.weight = cw.weight
                out.carrier = cw.carrier
            }
        }

        if (out.carrier) {
            let refCarrier = carriers.find(c => c.name === values.carrier)
            if (refCarrier)
                out.carrier = {
                    name: refCarrier.name,
                    code: refCarrier.code,
                    ref: refCarrier.key
                }
        }

        // if (out.province && out.district && out.ward && out.weight > 0 && out.carrier) {
        //     if (orderData.status.name === 'Nháp') {
        //         if (datatableStore.preloadTable?.status) {
        //             let chot_status = datatableStore.preloadTable.status.find(s => s.name === 'Đã chốt')
        //             if (chot_status) {
        //                 out.status = {
        //                     name: chot_status.name,
        //                     color: chot_status.color,
        //                     ref: chot_status.key
        //                 }
        //             }
        //         }
        //     }
        // }
        onSave(out)
        setIsSaved(true)
    }

    const saveNewCustomer = (cSelect) => {
        if (!cSelect) return
        let o = outOrder
        if (cSelect.ref) {
            o.customer = {
                lsid: o.customer.lsid,
                name: cSelect.ref.name,
                mobile: cSelect.ref.mobile,
                address: cSelect.ref.address,
                code: cSelect.ref.code,
                ref: cSelect.id
            }
            form.setFieldsValue({
                name: cSelect.ref.name,
                mobile: cSelect.ref.mobile,
                address: cSelect.ref.address
            })
        } else {
            if (o.customer) {
                o.customer.ref = false
                form.setFieldsValue({
                    name: orderData?.customer?.name ? orderData?.customer?.name : "",
                    mobile: orderData?.customer?.mobile ? orderData?.customer?.mobile : "",
                    address: "",
                })
            }
        }
        setOutOrder(o)
        // onSave(o)
        setIsSaved(false)
    }

    function lookOption(input, option) {
        let nosign = removeVietnameseCharMark(option?.children)
        nosign = nosign.toLowerCase()
        return nosign.indexOf(input.toLowerCase()) >= 0
    }

    function changeWeight(value, branch) {
        if (!branch) {
            branch = form.getFieldValue('branch')
            if (!branch) branch = "Yên Lạc"
        }
        if (value > 100) value = value / 1000
        form.setFieldsValue({ 'weight': value })
        let out = { weight: value }
        // chi doi van chuyen khi chua gui hang
        if (!orderData.order_code) {
            let f = getCarrierByWeight(value, branch)
            if (f) {
                form.setFieldsValue({ 'carrier': f.name })
                out.carrier = f.name
            }
        }
        return out
    }

    return (
        <Modal
            title={false}
            visible={true}
            width={width}
            maskClosable={false}
            onCancel={closeModal}
            centered
            // footer={false}
            footer={[
                <Button onClick={closeModal} disabled={adding}>
                    Hủy thay đổi
                </Button>,
                <Button key="submit" type="primary"
                    loading={adding}
                    onClick={() => {
                        setAdding(true)
                        form.submit()
                    }}>
                    Cập nhật
                </Button>,
            ]}
        >

            <div id="orderCustomerForm" style={{ width: "100%" }}>
                <Form
                    layout="vertical"
                    form={form}
                    name="customer-info"
                    onFinish={onFinish}
                    initialValues={{
                        weight: orderData?.ship?.weight,
                        cod: (orderData?.ship?.cod || orderData?.ship?.cod === 0) ? orderData?.ship?.cod : orderData.tong_tra,
                        name: orderData?.customer?.name,
                        mobile: orderData?.customer?.mobile ? orderData.customer.mobile : "",
                        address: orderData?.customer?.address,
                        carrier: orderData?.ship?.carrier?.name,
                        branch: orderData?.branch,
                    }}
                    style={{ width: "100%", background: "#fff" }}
                    onValuesChange={(c, a) => {
                        // console.log(`c`, c)
                        // console.log(`a`, a)
                        if (isSaved) setIsSaved(false)
                    }}
                >
                    <Row gutter={[24, 16]}>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Card size="small"
                                title={<Title level={5}>Thông tin khách hàng</Title>}
                            >
                                <div style={{ display: "flex" }}>
                                    <Space>
                                        <ContactsFilled
                                            style={{ fontSize: '32px', color: outOrder?.customer?.ref ? "#03BF68" : "#dddddd" }}
                                        />
                                        <Button size={'small'} icon={outOrder?.customer?.ref ? <CheckOutlined /> : <QuestionCircleOutlined />}
                                            onClick={() => {
                                                // setCustomerSelect(currentCustomerRecord?.name)
                                                let customer = outOrder?.customer
                                                let name = (customer ? customer.name : "")
                                                let mobile = (customer ? customer.mobile : "")
                                                setCustomerSelect({
                                                    name: name,
                                                    mobile: mobile,
                                                    ref: customer?.ref
                                                })
                                            }}
                                        >{orderData?.customer?.name}</Button>
                                    </Space>
                                </div>
                                <Form.Item
                                    name="name"
                                    label="Tên khách"
                                >
                                    <Input prefix={<UserOutlined />} />
                                </Form.Item>
                                <Form.Item
                                    name="mobile"
                                    label="Điện thoại"
                                >
                                    <Input prefix={<PhoneOutlined />} />
                                </Form.Item>
                                <Form.Item
                                    name="address"
                                    label="Địa chỉ"
                                >
                                    <Input prefix={<EnvironmentOutlined />} />
                                </Form.Item>


                            </Card>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Card size="small"
                                title={<Title level={5}>Vận chuyển</Title>}
                            >
                                <Row>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Space>
                                            <Form.Item
                                                name="weight"
                                                label="Cân nặng"
                                            >
                                                <InputNumber onChange={changeWeight} />
                                                {/* <Space>
                                                <InputNumber
                                                    onChange={changeWeight}
                                                    // defaultValue={orderData?.ship?.weight}
                                                /> KG
                                                <Button type={'ghost'}
                                                    onClick={async () => {
                                                        form.setFieldsValue({ 'weight': 10000 })
                                                        // let weightSum = await getTotalWeight(orderData, commonStore?.company?.companyData?.product_table, getProductByCodes)
                                                        // if (weightSum.weight > 0)
                                                        //     form.setFieldsValue({ 'weight': weightSum.weight })
                                                        // console.log(`weightSum`, weightSum)
                                                    }}
                                                >Lấy cân nặng

                                                </Button>
                                            </Space> */}
                                            </Form.Item>
                                            KG
                                            <Button type={'ghost'} icon={<CoffeeOutlined />}
                                                onClick={async () => {
                                                    // form.setFieldsValue({ 'weight': 10000 })
                                                    // let weightSum = await getTotalWeight(orderData, commonStore?.company?.companyData?.product_table, getProductByCodes)
                                                    if (orderData.autoWeight > 0) {
                                                        let w = Math.round(orderData.autoWeight * 1.5) / 1000 // 10%
                                                        form.setFieldsValue({ 'weight': w })
                                                        changeWeight(w)
                                                    }
                                                    // console.log(`weightSum`, weightSum)
                                                }}
                                            > cân nặng + 15%

                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item
                                            name="cod"
                                            label="Tiền thu hộ (COD)"
                                        >
                                            <InputNumber
                                            // onChange={changeWeight}
                                            // defaultValue={(orderData?.ship?.cod || orderData?.ship?.cod === 0) ? orderData?.ship?.cod : orderData.tong_tra}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Form.Item
                                    name="carrier"
                                    label="Đối tác vận chuyển"
                                >
                                    <Select
                                    // value={getCarrierByWeight(form.getFieldValue('weight'))?.name}
                                    >
                                        {carriers.map(c => (
                                            <Option key={c.key} value={c.name}>{c.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>


                                <Form.Item
                                    name="province"
                                    label="Thành phố / Tỉnh"
                                // initialValue={orderData?.customer?.province ? orderData?.customer?.province.ProvinceName : currentCity}
                                >
                                    <Select
                                        // defaultValue={currentCity}
                                        onChange={handleProvinceChange}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            // console.log(`option`, option)
                                            let nosign = removeVietnameseCharMark(option?.children)
                                            nosign = nosign.toLowerCase()
                                            return nosign.indexOf(input.toLowerCase()) >= 0
                                        }
                                        }
                                    >
                                        {provinces.map(p => (
                                            <Option key={p.ProvinceID} value={p.ProvinceName}>{p.ProvinceName}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="district"
                                    label="Quận / Huyện"
                                // initialValue={orderData?.customer?.district ? orderData?.customer?.district.DistrictName : currentDist}
                                >
                                    <Select
                                        disabled={currentCity === '--chưa chọn--'}
                                        // value={currentDist}
                                        onChange={handleDistrictChange}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={lookOption}
                                    // filterOption={(input, option) => {
                                    //     // console.log(`option`, option)
                                    //     let nosign = removeVietnameseCharMark(option?.children)
                                    //     nosign = nosign.toLowerCase()
                                    //     return nosign.indexOf(input.toLowerCase()) >= 0
                                    // }
                                    // }
                                    >
                                        {districts.map(d => (
                                            <Option key={d.DistrictID} value={d.DistrictName}>{d.DistrictName}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="ward"
                                    label="Phường / Xã"
                                >
                                    <Select
                                        disabled={currentDist === '--chưa chọn--'}
                                        defaultValue={currentWard}
                                        onChange={handleWardChange}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={lookOption}
                                    // filterOption={(input, option) => {
                                    //     // console.log(`option`, option)
                                    //     let nosign = removeVietnameseCharMark(option?.children)
                                    //     nosign = nosign.toLowerCase()
                                    //     return nosign.indexOf(input.toLowerCase()) >= 0
                                    // }
                                    // }
                                    >
                                        {wards.map(w => (
                                            <Option key={w.WardCode} value={w.WardName}>{w.WardName}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="branch"
                                    label="Nơi lấy hàng (chi nhánh)"
                                // initialValue={orderData?.customer?.district ? orderData?.customer?.district.DistrictName : currentDist}
                                >
                                    <Select
                                        onChange={(v) => {
                                            if (orderData.autoWeight > 0) {
                                                let w = Math.round(orderData.autoWeight * 1.5) / 1000 // 10%
                                                let cw = changeWeight(w, v)
                                                form.setFieldsValue({ 'weight': cw.weight })
                                                form.setFieldsValue({ 'carrier': cw.carrier })
                                            }
                                        }}
                                    // value={getCarrierByWeight(form.getFieldValue('weight'))?.name}
                                    >
                                        {branches.map(b => (
                                            <Option key={b.slug} value={b.name}>{b.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>



                </Form>

                {/* <Space>
          <PhoneFilled
            style={{ fontSize: '32px', color: orderData?.customer?.mobile ? "#03BF68" : "#dddddd" }}
          />
          <span>{orderData?.customer?.mobile}</span>
        </Space> */}
                {/* </Space> */}
                {customerSelect ?
                    <DataSelectModal
                        title={'Chỉnh sửa khách hàng'}
                        searchColumns={["name", "mobile"]}
                        displayColumns={["name", "mobile"]}
                        searchText={(customerSelect.mobile) ? customerSelect.mobile : customerSelect.name ? customerSelect.name : false}
                        initData={customerSelect}
                        // tableId="5fe7fb4d2136a764ac573c1e"
                        tableId={commonStore?.company?.companyData?.customer_table}
                        onSave={(v) => {
                            // console.log('cv', v)
                            saveNewCustomer(v)
                            // save(v)
                        }}
                        onClose={() => {
                            setCustomerSelect(false)
                        }}
                        // component={ProductItem}
                        onSearchSelect={(v, o) => {
                            // console.log('o', o)
                        }}
                    />
                    : ""
                }
            </div>
            {/* {datatable ? <OrderCustomerForm orderData={initData} onSave={onSave} /> : ""} */}
        </Modal>
    )
}
export default inject("datatableStore", "commonStore")(observer(OrderEditCustomer))