export const jobType = [
    { name: 'Nhặt hàng', type: 'nhathang', bgcolor: "#38C4B2" },
    { name: 'Đóng hàng', type: 'donghang', bgcolor: "#0763A5" },
    { name: 'Kiểm hàng', type: 'kiemhang', bgcolor: "#EB7C15" },
    { name: 'Chốt đơn', type: 'chotdon', bgcolor: "#11C065" },
    { name: 'Xử lý khiếu nại', type: 'xulykhieunai', bgcolor: "#D31341" },
    { name: 'Chụp ảnh', type: 'chupanh', bgcolor: "#EBD215" },
]

export const jobStatus = [
    { name: 'Đang làm', type: 'dang_lam', bgcolor: "green" },
    { name: 'Đã xong', type: 'da_xong', bgcolor: "Green" },
    { name: 'Kế hoạch', type: 'ke_hoach', bgcolor: "purple" },
    { name: 'hủy', type: 'huy', bgcolor: "red" },
]