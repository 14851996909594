import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Input,
    InputNumber,
    Modal,
} from 'antd';

import { nanoid } from 'nanoid'

const OrderCustomAddItem = ({
    title,
    width = 550,
    onSave,
    onClose,
}) => {

    // const [selectedData, setSelectedData] = useState(initData)
    // const [imageUrl, setImageUrl] = useState(false)
    const [adding, setAdding] = useState(false)
    const [form] = Form.useForm();
    // useEffect(async () => {
    //     if (!table.id) {
    //         const { data } = await getTableById()
    //         // console.log(`data`, data)
    //         setDatatable(data.datatable)
    //     } else {
    //         setDatatable(table)
    //     }
    // }, [])

    const onFinish = async (values) => {
        let sid = nanoid(10)
        let item = values
        item.sid = sid
        for (const [key, value] of Object.entries(values)) {
            if (key === 'price') {
                item[key] = parseInt(value)
                if (item[key] < 1000) item[key] = item[key] * 1000
            }
        }
        onSave(item)
        setAdding(false)
    };

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 19,
        },
    };

    const AddForm = () => {
        return (
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                initialValues={{
                    qty: 1,
                    weight: 0
                }}
                onFinish={onFinish}

            >


                <Form.Item
                    name='code'
                    label="Mã sp"
                >
                    <Input autocomplete="off" />
                </Form.Item>
                <Form.Item
                    name='name'
                    label="Tên"
                >
                    <Input autocomplete="off" />
                </Form.Item>
                <Form.Item
                    name='qty'
                    label={'Số lượng'}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name='price'
                    label={'Giá'}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name='weight'
                    label={'Cân nặng (g)'}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name='note'
                    label="Ghi chú"
                >
                    <Input autocomplete="off" />
                </Form.Item>
            </Form>
        )
    }

    return (
        <Modal
            // title={title ? title : `Chỉnh sửa ${dataTextName}`}
            title={title ? title : false}
            visible={true}
            width={width ? width : 450}
            maskClosable={false}
            onCancel={onClose}
            centered
            // footer={false}
            footer={[
                <Button onClick={onClose} disabled={adding}>
                    Đóng
                </Button>,
                <Button key="submit" type="primary"
                    loading={adding}
                    onClick={() => {
                        setAdding(true)
                        form.submit()
                    }}>
                    Thêm mới
                </Button>,
            ]}
        >
            <AddForm />
        </Modal>
    )
}
export default inject("datatableStore")(observer(OrderCustomAddItem))