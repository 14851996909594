import React, { useEffect, useState, useRef } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
  Avatar,
  Form,
  Button,
  Image,
  Tag,
  Input,
  InputNumber,
  Modal,
  Card,
  // message,
  Space
} from 'antd';

import DatarowSelectInput from '../DataBoard/DataTools/DatarowSelectInput'

import { imagePreset } from '../../../ultils/datatableUtils'
// import { autorun, toJS } from 'mobx'
// import { nanoid } from 'nanoid'
const { Meta } = Card;



const QuantityModal = ({
  datatableStore,
  commonStore,
  usersStore,
  initData,
  typeUpdate = "add",
  width = 480,
  onSave,
  onClose,
  branch = false,
  defaultBranch = false,
  isResetTag,
  resetNgaykiem,
  tagVitri = []
}) => {

  // const [selectedData, setSelectedData] = useState(initData)
  const [updating, setupdating] = useState(false)
  // const [value, setValue] = useState(initData)
  const [currentValue, setCurrentValue] = useState(false)
  const [showSelectData, setShowSelectData] = useState(false)

  const [currentTags, setCurrentTags] = useState(initData?.new_tag_vitri || [])


  const styleType = {
    'add': {
      title: 'Bổ sung sản phẩm vào kho',
      color: 'green',
      btn: 'Thêm vào'
    },
    'sub': {
      title: 'Bỏ bớt sản phẩm ra',
      color: 'red',
      btn: 'Bỏ ra'
    },
    'update': {
      title: 'Đặt lại số lượng sản phẩm',
      color: 'blue',
      btn: 'Đặt lại'
    }
  }

  // const getRef = useAsyncQuery(DataTableServices.GET_ROW_BY_ID(initData?.id))

  // useEffect(async () => {
  //   if (initData) {
  //     if (initData.code) setAddOnly(false)
  //     if (!initData.qty) initData.qty = 1
  //     if (initData.id && !initData.ref) {
  //       let product = await getRef()
  //       if (product) product = product.data?.datarow
  //       // console.log(`product`, product)
  //       if (product) {
  //         initData.ref = product.row_data
  //         // console.log(`initData`, initData)
  //         // setCurrentValue(toJS(initData))
  //       }
  //     }
  //     setCurrentValue(toJS(initData))
  //     if (!initData.code) setShowSelectData(true)
  //   }
  //   return () => {
  //     currentValue(false)
  //   }
  // }, [])


  const [form] = Form.useForm();
  const closeModal = () => {
    onClose()
  }

  const onFinish = async (values) => {
    // console.log('values.tag_vitri', values.tag_vitri)
    // let row_data = values
    // row_data.sid = sid
    // setupdating(true)
    // let v = { ...currentValue, qty: values.qty, addOnly: addOnly }
    // console.log(`vzz`, v)
    // setupdating(false)
    values.qty = parseInt(values.qty)
    initData.sl_tonkho = parseInt(initData.sl_tonkho)
    if (isNaN(values.qty)) {
      alert('So khong hop le')
      return
    }
    let newQty = initData.sl_tonkho || 0
    let branches = initData.branches || {}
    if (!initData.branches && defaultBranch) {
      branches[branch.map_tag] = 0
      branches[defaultBranch.map_tag] = newQty
    }

    switch (typeUpdate) {
      case 'add':
        newQty += values.qty
        branches[branch.map_tag] += values.qty
        break;
      case 'sub':
        newQty -= values.qty
        if (branches[branch.map_tag] > values.qty) {
          branches[branch.map_tag] -= values.qty
        }
        if (newQty < 0) {
          alert('SL bỏ ra lớn hơn tồn kho hiện tại!')
          return
        }
        break;
      case 'update':
        newQty = values.qty
        branches[branch.map_tag] = values.qty
      default:
        break;
    }

    let quanityLogs = {
      n: { sl_tonkho: newQty, branches: branches },
      o: { sl_tonkho: initData.sl_tonkho },
      extra: {
        code: initData.code,
        name: initData.name,
        type: typeUpdate,
        qty: values.qty,
        branch: branch
      },
      row_id: initData?.id
    }
    onSave(
      {
        // data: initData,
        // newQty: newQty,
        // qty: values.qty,
        // type: typeUpdate,
        image: initData.image,
        quanityLogs: quanityLogs,
        color: styleType[typeUpdate].color,
        tagUpdate: currentTags || []
      }
    )
  };

  // const onRemove = () => {
  //   let v = { ...currentValue, remove: true }
  //   setupdating(false)
  //   onSave(
  //     v
  //   )
  // }

  // const onReset = () => {
  //   form.resetFields();
  // };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };


  const ProductItem = ({ onClick }) => {
    let name = initData.name
    let title = initData.code
    if (title) title = '[' + title + ']-' + name
    else title = name

    let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
    let imageSrc = initData.image
    if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
    else {
      thumbnail = imagePreset(imageSrc, 'xsmall')
    }
    return (
      <Card
        onClick={onClick}
        bordered
        style={{ marginBottom: 10, border: '1px solid grey' }}
      >
        <Meta
          avatar={
            <Image
              width={60}
              height={60}
              src={thumbnail}
              preview={{
                src: imageSrc,
              }}
            />
          }
          title={title}
          description={<>
            {name}<br />
            <strong>SL:</strong>{initData.sl_tonkho}
          </>}
        />
      </Card>
    )
  }


  const QuantityForm = () => {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const qtyRef = useRef(null)
    useEffect(() => {
      if (qtyRef.current) {
        qtyRef.current.focus();
        // sleep(100)
        // form.setFieldsValue({ qty: "" })
      }
    }, [qtyRef])
    // let columns = datatable.table_columns ? datatable.table_columns : datatable.data_table_template.table_columns
    return (
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        // initialValues={{
        //   qty: ""
        // }}
        onFinish={onFinish}>
        <Form.Item
          name={'qty'}
          label={(<>Số lượng <span style={{ fontSize: 12, paddingLeft: 10, color: branch.color }}>(chi nhánh:<strong>{branch?.name}</strong>)</span> </>)}
        >
          <InputNumber id="qtyinput" ref={qtyRef} />
        </Form.Item>
        <ProductItem />
        <Form.Item
          name={'tag_vitri'}
          label={''}
        >
          <DatarowSelectInput
            tableId={commonStore?.company?.companyData?.tag_table}
            placeholder={'thẻ vị trí'}
            width={'100%'}
            viewType={'tag'}
            initFilter={{
              "match": {
                "category": "Sản phẩm",
                "sub_cate": "Tag vị trí"
              }
            }}
            size={'medium'}
            initValue={initData?.new_tag_vitri || []}
            // initValue={updateFilter.match && updateFilter.match[af.filterPath] ? updateFilter.match[af.filterPath] : false}
            onOk={(item) => {
              setCurrentTags(item)
            }}
          />
        </Form.Item>
      </Form>
    )
  }




  return (
    <Modal
      title={styleType[typeUpdate].title}
      visible={true}
      width={width}
      maskClosable={false}
      onCancel={closeModal}
      centered
      // footer={false}
      footer={[
        <Button
          onClick={closeModal}
          disabled={updating}>
          Hủy thay đổi
        </Button>,
        <Button key="submit" type="primary"
          style={{ backgroundColor: styleType[typeUpdate].color }}
          loading={updating}
          onClick={() => {
            setupdating(true)
            form.submit()
          }}>
          {styleType[typeUpdate].btn}
        </Button>,
      ]}
    >
      <QuantityForm />

    </Modal>
  )
}
export default inject("datatableStore", "commonStore", "usersStore")(observer(QuantityModal))