import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Switch,
    Modal,
} from 'antd';

const BooleanModal = ({
    datatableStore,
    commonStore,
    usersStore,
    width = 480,
    onSave,
    onClose,
    defaultValue = false,
    label = false,
    field = false
}) => {
    const [updating, setupdating] = useState(false)

    const [form] = Form.useForm();
    const closeModal = () => {
        onClose()
    }

    const onFinish = async (values) => {
        onSave(values)
        setupdating(false)
        onClose()
    };


    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const BooleanForm = () => {
        let initValues = {}
        initValues[field] = defaultValue
        return (
            <Form
                {...layout}
                form={form}
                // name="control-hooks"
                initialValues={initValues}
                onFinish={onFinish}>
                <Form.Item
                    label={label}
                    name={field}
                >
                    <Switch defaultChecked={defaultValue} />
                </Form.Item>
            </Form>
        )
    }
    return (
        <Modal
            title={'Chọn giá trị'}
            visible={true}
            width={width}
            maskClosable={false}
            onCancel={closeModal}
            centered
            // footer={false}
            footer={[
                <Button
                    onClick={closeModal}
                    disabled={updating}>
                    Hủy thay đổi
                </Button>,
                <Button key="submit" type="primary"
                    loading={updating}
                    onClick={() => {
                        setupdating(true)
                        form.submit()
                    }}>
                    Xác nhận
                </Button>,
            ]}
        >
            <BooleanForm />
        </Modal>
    )
}
export default inject("datatableStore", "commonStore", "usersStore")(observer(BooleanModal))