import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  /* header layout */
  /* font-family: 'Inter', sans-serif;
  font-family: 'Roboto', sans-serif; */
  body {
    font-family: 'Roboto', sans-serif;
  }
  .ant-popover-inner {
    .ant-popover-inner-custom{
      border : 1px solid rgba(63, 63, 147, 1) !important;
      border-radius : 10px 10px 10px 10px !important;
      background-color: #fff;
      max-width: 300px;
      max-height: 443px;
    }
  }
  .ant-btn-primary {
    background: ${({ theme }) => theme.colors.primaryColor};
  }
  .ant-btn-primary {
    background: ${({ theme }) => theme.colors.primaryColor};
    border-color: ${({ theme }) => theme.colors.primaryColor};
  }
  .ant-menu-item-selected a, 
  .ant-menu-item-selected a:hover,
  .ant-menu-submenu-selected,
  .ant-menu-item-selected,
  .ant-menu-submenu-active, .ant-menu-submenu-title:hover
  {
    color: ${({ theme }) => theme.colors.menuTextColor};
  }

  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid ${({ theme }) => theme.colors.menuTextColor};
  }

  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, ${({ theme }) => theme.colors.menuTextColor}, ${({ theme }) => theme.colors.primaryColor});
  }

  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 5px 8px;
  }

  .ant-table .ant-table-selection-col {
    width: 40px;
  }
  .customer-msg span {
    font-weight: bold;
    padding: 1px 9px;
    border-radius: 10px;
    color:#fff;    
  }
  #liveSidebar {
    border-right: 1px solid #e4e4e4;
    .a1nt-table-selection-col, .an1t-table-selection-column {
      display: none;
    }
    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      padding: 10px;
    }  
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: #e3efe6;
    }
  }

  mark {
    border: 1px solid #333;
    line-height: 30px;
  }
  mark.quanity {
    background-color:#E2F3FA;
  }
  #liveMessages{
    a.tel {
      font-weight: bold;
      color:green;
      text-decoration: underline;
    }
  }

  #adjustTable {
    .ant-btn > span + .anticon {
      margin-left:0;
    }
  }

  .tagCode {
    span {
      font-weight: bold;
      border: 1px solid #ccc;
      padding:3px;
      text-transform: uppercase;
      
    }
    .hasProduct {
      background:#E5E1B4;
    }
    .noProduct {
      background:#DCC7C3;
      color:black; 
    }   
    .sp-chinh {
      background:#AFC6A0;
      color:black;
    }
    .sp-le {
      background:#BFCED7;
      color:black;
    }
    .cannotOrder {
      background:#FFBB99;
      color:black;  
    }
    .noValid {
      background:#EEEEEE;
      color:black;
    }
    .highLight {
      background:#FFE793;
      color:black;
    }
  }

  .boxItem {
    padding-right:6px;
  }

  .searchBoxItem {
    padding:5px;
    display: flex,
    justify-content: start,
  }
  #overviewDrawer {
    .ant-drawer-body {
      padding:0 0 20px 0;
    }
    .ant-table.ant-table-small {
      font-size:12px;
    }
  }
  .correctProductModal {
    .ant-modal-body {
      overflow-y:auto;
      padding:0;
      height:100%;
    }
  }
  
  .ant-table-cell {
    min-height:28px;
  }

  .editable-row {
    .ant-form-item-control-input {
      min-height:22px;
      input {
        padding:0;
      }
    }
  }

  #correctTable {
    padding:0;
    margin:0;
  }
  #liveOrderTable {
    border:1px solid #888;
    .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
      border-bottom: 1px solid #888;
      border-right: 1px solid #888;
    }
    .ant-table-thead > tr > th {
      font-weight: bold;
    }

    .ant-table-tbody > tr > td:last-child {
      border-right:none;
    }
    .ant-typography.ant-typography-success {
      color:#038D4C;
    }
  }

  .mainTable {
    .ant-input-number {
      width:100%;
    }
    .ant-table-thead > tr > th {
      background: #fff;
      border-bottom:1px solid #bbb;
      font-weight:bold;
    }
  }


  // .mainTable .ant-avatar:hover {
  //   transform: scale(5);
  //   z-index:1000;
  // }

  .ant-modal-mask, .ant-image-preview-mask {
    background-color: rgba(241, 241, 241, 0.9);
  }
  .liveTable {
    .ant-table-selection-col, .ant-table-selection-column {
      display: none;
    }
  }

  .rc-color-picker-trigger {
    width:30px;
    height:30px;
  }

  #orderCustomerForm, .orderEditForm {
      .ant-space-vertical {
        width:100%;
      }
      .ant-card-small {
        background:#f0f0f0;
      }
  }

  #rowOrder {
    .ant-card-head-title {
      color:green;
    }
    .ant-card-small > .ant-card-head {
      font-size:13pt;
      border-bottom: 3px solid #ccc;
    }
    .ant-card-bordered {
      background: #fff;
    }
    .ant-card-body p {
      b {
        font-size:10pt;
        color:#000;
      }
      font-size:9pt;
      color:#666;
    }
  }

  .customer-render {
    .ant-tag {
      line-height:16px;
      font-size:8pt;
    }
    .ant-tag > .anticon + span, .ant-tag > span + .anticon {
      margin-left:4px;
    }
  }

  .resultItems {
    padding:10px 0;
    margin:10px 0;
    max-height:400px;
    overflow: auto;
    .o-compact-render {
      padding:8px;
      margin-bottom:8px;
      border:1px solid #eee;
      :hover {
        cursor:pointer;
        background:#eeee;
      }
    }
    .disabled {
      background:#ccc;
      pointer-events:none;
      * {
        color:#999;
      }
    }
  }

  .list-compact {
    display: flex;
    align-items: flex-start;
    .content {
      flex: 1;
      padding: 10px;
    }

  }

  .ant-pagination-options {
    display: inline;
  }

  .searchSyncPrice {
    .ant-select-selector {
      font-size:24px;
      height: 50px !important;
      line-height: 50px !important;
      width: 100%;
    }
    .ant-select-selection-placeholder {
      line-height:50px !important;
    }
  }

  .searchMedium {
    .ant-select-selector {
      font-size:20px;
      height: 40px !important;
      line-height: 40px !important;
      width: 100%;
    }
    .ant-select-selection-placeholder {
      line-height:40px !important;
    }
  }


  .list-resources {
    .ant-list-item-meta {
      border: 1px solid #eee;
      border-radius: 12px;
      padding: 8px;
    }
  }

  .job-card {
    .ant-list-bordered {
      border: 1px solid #b1b1b1;
      border-radius: 16px;
      background: #ffffff;
    }
  }

  .ant-tag {
    margin-right: 5px;
    padding: 0 4px;
    font-size: 10px;
    line-height: 14px;
}

  .small-tag {
    .ant-tag {
      padding: 0 5px;
      font-size: 8px;
      line-height: 13px;
    }
  }

  .big-tag {
    height: 30px;
    line-height:30px;
    margin-bottom: 5px;
    cursor:pointer;
  }

`

