import { imagePreset, price_k } from '../../../../../ultils/datatableUtils'
import React, { useEffect, useState } from 'react'

import {
    Image,
    List,
    Button
} from 'antd';

import {
    DeleteOutlined,
    ControlOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react'
import { Tags_render } from '../../DataTools/DataField'


const QuickListItem = ({ datatableStore, commonStore, product, onDelete, view }) => {

    let history = useHistory();
    let label = ""
    let thumbnail = 'https://louisville.edu/history/images/noimage.jpg/image'
    let imageSrc = product.image
    if (!imageSrc) imageSrc = 'https://louisville.edu/history/images/noimage.jpg/image'
    else {
        thumbnail = imagePreset(imageSrc, 'xsmall')
    }
    let cotheban = product.sl_tonkho - product.sl_giuhang
    if (isNaN(cotheban) || cotheban < 0) cotheban = 0
    let slColor = (product.sl_qty === cotheban) ? 'green' : 'blue'
    let ctbColor = cotheban > 0 ? slColor : 'red'
    if (product.sl_qty === 0) slColor = 'red'
    let imgSize = view === 'compact' ? 48 : 60

    useEffect(() => {
    }, [datatableStore.product_cart])

    return (
        <List.Item
            actions={[

                <Button size={'small'} icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete(product)
                    }}>
                    xóa</Button>

            ]}
        >
            <List.Item.Meta
                onClick={() => {
                }}
                avatar={<Image
                    width={imgSize}
                    height={imgSize}
                    src={thumbnail}
                    preview={{
                        src: imageSrc,
                    }}
                />}
                title={
                    <span style={{ color: 'black' }}
                        onClick={() => {
                            datatableStore.setCurrentRow(product)
                            history.replace('/tool/search_product/' + product.id || product.key)
                            commonStore.setCurrentPageTitle('Mã: ' + product.code)
                        }}
                    >
                        {product?.code + '-' + product?.name}
                    </span>
                }
                description={
                    <>
                        {product?.tag_vitri && datatableStore.preloadTable.tags &&
                            <>
                                <p><Tags_render tags={product?.tag_vitri} preloadTable={datatableStore.preloadTable.tags} subCate="Tag vị trí" hasColor={true} /></p>
                            </>
                        }
                    </>
                }
            />
        </List.Item>
    )
}

export default inject("datatableStore", "commonStore")(observer(QuickListItem))
