import React, { useEffect, useState, useRef } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    message,
    Form,
    Upload,
} from 'antd'
import DataTableServices from '../../../../services/datatableServices'
// import styled from 'styled-components'
// import moment from 'moment'

const DataFileUpload = ({ datatableStore, label, inputFile, onOk, tableId }) => {
    // const inputFile = useRef(null)
    const [formImport] = Form.useForm();
    const [importRows] = useMutation(
        DataTableServices.IMPORT_ORDERS(),
        {
            errorPolicy: 'all'
        }
    )

    async function beforeUpload(file) {
        // console.log(`file`, file)
        // console.log('file', file)
        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        // if (!isJpgOrPng) {
        //   message.error('Bạn chỉ có thể tải lên JPG/PNG file!');
        // }
        const sizeOK = file.size / 1024 / 1024 < 20;
        if (!sizeOK) {
            message.error('Kích thước không được quá 20 MB!');
            return false
        }
        let f = await file
        let r = await importRows({
            variables: {
                input: {
                    data: {
                        table: tableId
                    },
                    file: f
                }
            }
        })
        onOk(r?.data?.importOrders?.data)
        // console.log(`r`, r)

        // let ok = isJpgOrPng && sizeOK;
        // if (ok) {
        //   getBase64(file, imageUrl => {
        //     setImageUrl(imageUrl)
        //     setUploading(false)
        //   });
        // }
        formImport.submit()
        return true
    }

    return (
        <>
            <Form
                // onFinish={onFinishImport}
                form={formImport}
                style={{ display: 'none' }}>
                <Form.Item
                    name={'ifilez'}
                    label={'abce'}
                >
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        multiple={true}
                        // accept="image/*"
                        // capture="environment"
                        // capture="user"
                        // onChange={handleChange}
                        maxCount={3}
                    >
                        <div ref={inputFile}>{label}</div>
                    </Upload>
                </Form.Item>
            </Form>
        </>
    )
}

export default inject(
    'datatableStore'
)(observer(DataFileUpload))
