import { useState, useEffect } from 'react'

import {
  PhoneOutlined,
  EnvironmentOutlined,
  SmileFilled,
  FileTextOutlined,
  DollarOutlined,
  CarOutlined,
  ShoppingFilled
} from '@ant-design/icons';

import { Tag, Space } from 'antd'
import { contrastColor } from 'contrast-color'
import { price_k, getOrderSummary } from '../../../../ultils/datatableUtils'
import { toJS } from 'mobx'
// import { Space } from 'antd'

export const price_render = (value, row, index) => {
  return price_k(value, '?', 3)
  // if (value)
  //   return price_k(value, '?', 3)
  // else
  //   return value
}

export const color_render = (value, row, index) => {
  if (!value) value = 'white'
  return <span style={{ display: 'block', width: 50, height: 15, border: "1px solid #333", backgroundColor: value, color: contrastColor({ bgColor: value }) }}></span>
}

// export const list_object_render = ( value,row,index) => {

// }

export const order_compact_render = (value, row, index, preloadTable) => {
  // console.log(`row`, row)
  let color = row.status?.color ? row.status.color : "lightgray"
  // let address = value?.address
  // if (!address)
  //   address = value?.ref?.address

  return (
    <div className='o-compact-render'>
      {/* <Space> */}
      <SmileFilled style={{ marginRight: 8, color: color }} />
      <span style={{ marginRight: 20, fontWeight: "bold", color: value?.ref ? "green" : "#b59324" }}>
        {value.name}
      </span>
      {value.mobile ?
        <span>/
          <PhoneOutlined style={{ marginRight: 4 }} />
          {value.mobile}
        </span> : ""}
      {/* </Space> */}

      {row.tong_tra ?
        <span>
          <br />
          <DollarOutlined style={{ marginRight: 8, color: color }} />
          <Space>
            <>Tổng tiền: <b>{price_k(row.tong_tra, '?', 3)}</b></>
            {row?.ship?.cod ?
              <span
                style={{
                  color: (row?.pay_status?.name === 'Đã thanh toán' && row.ship.cod > 0) ? "red" : "green"
                }}>
                /  COD: <strong>{price_k(row?.ship?.cod, '?', 3)}</strong>
              </span> : ""}
          </Space>

        </span>
        : ""
      }
      {row.note ?
        <span style={{ color: "red", fontWeight: "bolder", fontSize: '12pt' }}>
          <br />
          <FileTextOutlined style={{ marginRight: 8, color: color }} />{row.note}
        </span>
        : ""
      }
      <span>
        <br />
        {row.status ?
          <Tags_render
            tags={[row.status?.name]}
            preloadTable={preloadTable.status}
            extra={{
              type: 'status'
            }}
          />
          : ""
        }
        {/* {row.ship?.ship_status ?
          <Tags_render
            tags={[row.ship?.ship_status?.name]}
            preloadTable={preloadTable.shipStatus}
            extra={{
              type: 'ship',
              order_code: row.order_code
            }}
          />
          : ""
        } */}
        {/* {row.pay_status ?
          <Tags_render
            tags={[row.pay_status?.name]}
            preloadTable={preloadTable.paymentStatus}
            extra={{
              type: 'payment'
            }}
          />
          : ""
        } */}
        {row.tags ?
          <Tags_render tags={row.tags} preloadTable={preloadTable.tags} />
          : ""
        }
      </span>
    </div>
  )
}

export const customer_render = (value, row, index, preloadTable) => {

  if (!row.tong_tien) {
    let sum = getOrderSummary(row)
    row.tong_tien = sum.price
    row.tong_tra = sum.tong_tra
  }
  let color = row.status?.color ? row.status.color : "lightgray"
  let pColor = (row.tong_tra % 1000 > 0) ? 'red' : "black"
  let address = value?.address
  if (!address)
    address = value?.ref?.address

  // let statusArr = ['Đã hoàn thành', "Nhặt hàng", "Đóng hàng", "Hủy đơn", "Chờ vận chuyển", "Đã in", "Đơn cũ", "Đã bị gộp"]
  let statusArr = ['Đã hoàn thành', "Nhặt hàng", "Đóng hàng", "Hủy đơn", "Chờ vận chuyển", "Đã bị gộp"]
  let undoneOrder = false
  if (row?.relateOrders) {
    undoneOrder = row?.relateOrders.filter(o => !statusArr.includes(o.status.name))
  }

  return (
    <div className='customer-render'>
      <Space>
        <ShoppingFilled style={{ marginRight: 8, color: color }} />
        <span style={{ marginRight: 20, fontWeight: "bold", color: color }}>
          <a style={{ color: color }} href={'/row/' + row.key || row.id} target="_blank" title={row.code}>Mã đơn :  {row.code}</a>
        </span>
      </Space>

      <br />
      <Space>
        <SmileFilled style={{ marginRight: 8, color: color }} />
        <span style={{ marginRight: 20, fontWeight: "bold", color: value?.ref ? "green" : "#b59324" }}>
          {value?.name}
        </span>
        {value?.mobile &&
          <span>
            <PhoneOutlined style={{ marginRight: 4 }} />
            {value.mobile}
          </span>}
        {undoneOrder &&
          <span style={{
            color: (undoneOrder.length > 1) ? "red" : "#D4AC0D"
          }}>
            /<b>{undoneOrder.length} đơn</b> chưa hoàn thành
          </span>
        }
      </Space>
      {row.tong_tien ?
        <span>
          <br />
          <DollarOutlined style={{ marginRight: 8, color: color }} />
          <Space>
            <span>Tổng đơn hàng: <b>{price_k(row.tong_tien, '?', 3)}</b></span>
            <span style={{ color: pColor }}>Tổng trả: <b>{price_k(row.tong_tra, '?', 3)}</b></span>
            {row?.ship?.cod ?
              <span
                style={{
                  color: (row?.pay_status?.name === 'Đã thanh toán' && row.ship.cod > 0) ? "red" : "green"
                }}>
                /  COD: <strong>{price_k(row?.ship?.cod, '?', 3)}</strong>
              </span> : ""}
          </Space>

        </span>
        : ""
      }
      <br />
      <EnvironmentOutlined style={{ marginRight: 8, color: color }} />{address}
      {row.note || row.note_vitri ?
        <div>
          <FileTextOutlined style={{ marginRight: 8, color: color }} />
          <span style={{ color: "red", fontWeight: "bolder", fontSize: '12pt', marginRight: 10 }}>
            {row.note}
          </span>
          {row.note_vitri &&
            <span style={{ color: "blue", fontWeight: "bolder", fontSize: '12pt' }}>
              {row.note_vitri}
            </span>
          }
        </div>
        : ""

      }
      {row.ship?.ship_method ?
        <span style={{ color: "green" }}>
          <br />
          <CarOutlined style={{ marginRight: 8, color: color }} />
          <Space>
            <span>{row.ship.ship_method}</span>
            {(row.ship?.carrier) ?
              <span>/ {row.ship?.carrier?.name}</span>
              : ""
            }
            {(row.order_ship_fee) ?
              <span>/ Phí thực: <strong>{price_k(row.order_ship_fee, '?', 3)}</strong></span>
              : ""
            }
            {row.ship?.weight ?
              <>
                <span>/ Nặng: <strong style={{
                  color: (row.ship.weight > 80) ? "red" : "green"
                }}>{row.ship.weight}&nbsp;KG</strong></span>
              </>
              : ""
            }
          </Space>
        </span>
        : ""
      }
      <span>
        <br />
        {row.ship?.ship_status ?
          <Tags_render
            tags={[row.ship?.ship_status?.name]}
            preloadTable={preloadTable.shipStatus}
            extra={{
              type: 'ship',
              order_code: row.order_code
            }}
          />
          : ""
        }
        {/* {row.pay_status ?
          <Tags_render
            tags={[row.pay_status?.name]}
            preloadTable={preloadTable.paymentStatus}
            extra={{
              type: 'payment'
            }}
          />
          : ""
        } */}
        {row.tags ?
          <Tags_render tags={row.tags} preloadTable={preloadTable.tags} />
          : ""
        }
      </span>
    </div>
  )
}

export const Tags_render = ({ tags, preloadTable, extra, subCate = false, hasColor = true }) => {
  const [newTags, setNewTags] = useState(false)
  useEffect(() => {
    if (preloadTable && preloadTable.length > 0) {
      tags = toJS(tags)
      let temp = []
      for (let index = 0; index < tags.length; index++) {
        // debugger
        let refTag = false
        if (subCate) {
          refTag = preloadTable.find(item => item.name === tags[index] && item.sub_cate === subCate)
        } else {
          refTag = preloadTable.find(item => item.name === tags[index])
        }
        // const element = tags[index];
        if (refTag) {
          let t = {
            name: refTag.name,
            // color: refTag.color,
            ref: refTag.id,
          }
          if (hasColor) t.color = refTag.color
          else t.color = "#bbb"
          temp.push(t)
        } else {
          temp.push({
            name: tags[index],
          })
        }
      }
      setNewTags(temp)
    }
  }, [tags, preloadTable])

  if (extra?.type == 'ship' && newTags.length === 1) {
    let tag = newTags[0]
    let label = tag.name
    if (extra.order_code) {
      label = <a href={`https://donhang.ghn.vn/?order_code=${extra.order_code}`} target="_blank">{tag.name} : <strong>{extra.order_code}</strong> </a>
      tag.color = "#E9A154"
    }
    return (
      <Tag icon={<CarOutlined />} color={tag.color || "#000"} key={tag.name || tag}>{label}</Tag>
    )
  }

  // if (extra?.type == 'payment' && newTags.length === 1) {
  //   let tag = newTags[0]
  //   let label = tag.name
  //   return (
  //     <Tag title={tag.name} icon={<DollarOutlined />} color={tag.color || "#dddddd"} key={tag.name || tag}>{label}</Tag>
  //   )
  // }
  return (
    <>
      {newTags && newTags.map(tag =>
        <Tag
          style={{ color: contrastColor({ bgColor: tag.color }), backgroundColor: tag.color }}
          key={tag.name || tag}>{tag.name || tag}</Tag>
      )
      }
    </>
  )
}

// export const InputDisplay = ({ attr, field, value, rowData = false, companyData = false }) => {
//   if (field === '_') {
//     return (
//       <Divider />
//     )
//   }
//   if (!attr) return ""
//   let dataType = attr.type ? attr.type : 'text'
//   let subType = attr.sub_type
//   switch (dataType) {
//     case 'text':
//       // if (subType === 'ma_don_dat') {
//       //   return (
//       //     <p className={'field_label'}>
//       //       <b>Mã đơn đặt: </b>
//       //       {value}
//       //       <span style={{ marginLeft: 10 }}>
//       //         / <a href={`https://orderalibaba.vn/chi-tiet-don-hang/${value}`} target="_blank">link đặt hàng</a>
//       //       </span>
//       //     </p>
//       //   )
//       // }
//       let label = value || '-'
//       return (
//         <p className={'field_label'}>
//           <b>{attr?.title} :</b>
//           {(label === '-') ?
//             <span>{label}</span> :
//             <span className={attr?.class_name}>{label}</span>
//           }

//         </p>
//       )
//     case 'number':
//       let v = value
//       if (!isNaN(v)) {
//         if (subType === 'price_vn') {
//           v = parseInt(value)
//           v = price_k(v, '?', 3)
//         }
//       } else
//         v = '?'

//       return (
//         <p className={'field_label'}>
//           <b>{attr?.title} :</b>{v}
//         </p>
//       )

//     case 'date':
//       let format = attr?.format || 'DD/MM/YY HH:mm'
//       return (
//         <p className={'field_label'}>
//           <b>{attr?.title} :</b>{moment(value).format(format)}
//         </p>
//       )

//       break

//     // case 'select':
//     //   return (
//     //     <p className={'field_label'}>
//     //       <b>{attr?.title} :</b> <SelectRender value={value} row={rowData} attr={attr} />
//     //     </p>
//     //   )

//     // case 'tags':
//     //   return (
//     //     <p className={'field_label'}>
//     //       <b>{attr?.title} :</b> <SelectRender value={value} row={rowData} attr={attr} />
//     //     </p>
//     //   )
//     case 'image':
//       let thumbnail = imagePreset(value, 'xsmall')
//       return (
//         <p>
//           <Image
//             width={128}
//             height={128}
//             src={thumbnail}
//             preview={{
//               src: value,
//             }}
//           />
//         </p>
//       )

//     // case 'dhn_pbase':
//     //   let log_items = (datatableStore.currentRefData || []).filter(
//     //     r => r.row_data.ma_hub === rowData.code
//     //   )
//     //   return (
//     //     <p style={{ paddingTop: 10 }}>
//     //       <LogDhnTimeline logs={log_items} dhn_table={companyData?.donnhaphang_table} />
//     //     </p>
//     //   )

//     default:
//       return false
//   }
// }

// PhoneOutlined,
// EnvironmentOutlined,
// ContactsFilled,