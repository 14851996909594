import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import {
    Form,
    Button,
    Image,
    Switch,
    Modal,
    Card,
    DatePicker
} from 'antd';
import moment from 'moment'

// import { imagePreset } from '../../../ultils/datatableUtils'
// import { autorun, toJS } from 'mobx'
// import { nanoid } from 'nanoid'
const { Meta } = Card;



const KiemhangModal = ({
    datatableStore,
    commonStore,
    usersStore,
    width = 480,
    onSave,
    onClose
}) => {
    const [updating, setupdating] = useState(false)

    const [form] = Form.useForm();
    const closeModal = () => {
        onClose()
    }

    const onFinish = async (values) => {
        onSave(values)
        setupdating(false)
    };


    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const KiemForm = () => {
        const [showDate, setShowDate] = useState(false)
        return (
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                initialValues={{
                    ngay_kiem: moment()
                }}
                onFinish={onFinish}>
                <Form.Item
                    label="Đã kiểm"
                    name={'da_kiem'}
                // valuePropName="checked"
                // name={}
                >
                    <Switch defaultChecked={showDate} onChange={(v) => {
                        setShowDate(v)
                    }} />
                </Form.Item>
                {showDate &&
                    <Form.Item
                        name={'ngay_kiem'}
                        label="Ngày kiểm"
                    >
                        <DatePicker />
                    </Form.Item>
                }

            </Form>
        )
    }
    return (
        <Modal
            title={'Cài đặt ngày kiểm hàng'}
            visible={true}
            width={width}
            maskClosable={false}
            onCancel={closeModal}
            centered
            // footer={false}
            footer={[
                <Button
                    onClick={closeModal}
                    disabled={updating}>
                    Hủy thay đổi
                </Button>,
                <Button key="submit" type="primary"
                    loading={updating}
                    onClick={() => {
                        setupdating(true)
                        form.submit()
                    }}>
                    Xác nhận
                </Button>,
            ]}
        >
            <KiemForm />

        </Modal>
    )
}
export default inject("datatableStore", "commonStore", "usersStore")(observer(KiemhangModal))