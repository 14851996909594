import React from 'react'
import styled from 'styled-components'

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ mg }) => mg ? mg : ''};
  padding: ${({ pd }) => pd ? pd : ''};

`
const Heading = styled.div`
  font-size: ${({ fontSize }) => fontSize ? fontSize : '24px'};
`
const BadgeStyled = styled.div`
  font-size: 10px;
  padding: 1px 8px;
  font-weight: normal;
  border: 1px solid ${({ theme }) => theme.colors.successColor};
  color: ${({ theme }) => theme.colors.successColor};
  border-radius: 2px;
  margin-left: 10px;
`

const StyledHeading = ({ name, type, margin, padding, fontSize }) => {
  return (
    <HeadingWrapper
      mg={margin}
      pd={padding}
    >
      <Heading
        fontSize={fontSize}
      >{name ? name : 'Name assets'}</Heading>
      {type ?
        <BadgeStyled>{type}</BadgeStyled>
        : ''}
    </HeadingWrapper>
  )
}

export default StyledHeading
