import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import theme from './components/styles/theme'
import GlobalStyle from './components/styles/GlobalStyle' //as css reset
import { ThemeProvider } from 'styled-components'

ReactDOM.render(<App/>, document.getElementById('root'))

// ReactDOM.render(
//   <React.StrictMode>
//     {/* <ThemeProvider theme={theme}> */}
//     {/* <GlobalStyle /> */}
//     <App />
//     {/* </ThemeProvider> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
