import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Image,
    Form,
    Button,
    Input,
    InputNumber,
    Switch,
    Modal,
    message,
    Upload,
    Row,
    Col,
    Select,
    Badge,
    Tag
} from 'antd';
import {
    PlusOutlined,
    LoadingOutlined,
    SyncOutlined
} from '@ant-design/icons';
// import { autorun, toJS } from 'mobx'
// import { debounce } from "lodash-es";
import DataTableServices from '../../../../services/datatableServices'
import UploadServices from '../../../../services/uploadServices'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
// import styled from 'styled-components'
// import { nanoid } from 'nanoid'
import { merge as LMerge, flatMap } from 'lodash-es'
import { removeVietnameseCharMark, imagePreset, getRevisionData, price_k } from '../../../../ultils/datatableUtils'
import 'rc-color-picker/assets/index.css';

import ColorPicker from 'rc-color-picker';
import moment from 'moment'

import RowsSearchModal from '../DataTable/RowsSearchModal'

// import DatarowSelectInput from '../DataTools/DatarowSelectInput'

const { Option } = Select;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


const DataEditItem = ({
    usersStore,
    datatableStore,
    commonStore,
    title,
    initData,
    table,
    dataTextName = 'dữ liệu',
    width = 550,
    placeholder,
    onSave,
    onClose,
    rows = false,
    noDefaultCamera
}) => {

    // const [selectedData, setSelectedData] = useState(initData)
    const [datatable, setDatatable] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [imageUrl, setImageUrl] = useState(false)
    const [adding, setAdding] = useState(false)

    const [showSLSyncProduct, setShowSLSyncProduct] = useState(false)
    // const [defaultData, setDefaultData] = useState(toJS(initData))

    const getTableById = useAsyncQuery(DataTableServices.GET_TABLE(table.id ? table.id : table))

    // const [updateDatarow] = useMutation(DataTableServices.UPDATE_DATAROW(), {
    //     errorPolicy: 'all',
    //     update(cache, { data }) {
    //     }
    // })

    const getSLProducts = useAsyncQuery(DataTableServices.GET_SL_PRODUCTS())

    // const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

    const getShoplineProducts = async (dataInput) => {
        const { data, error } = await getSLProducts({
            input: {
                data: dataInput
            }
        })
        let results = data?.getShoplineProducts?.data
        // console.log('results', results)
        if (!results?.items) return false
        // var items = flatMap(results.items, myFlat)
        return results
    }

    const [upload] = useMutation(
        UploadServices.UPLOAD()
    )

    const [updateManyDataRows] = useMutation(
        DataTableServices.UPDATE_MANY_ROWS(),
        {
            errorPolicy: 'all'
        }
    )
    function myFlat(v) {
        return Object.assign(v.row_data, { key: v.id })
    }


    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Bạn chỉ có thể tải lên JPG/PNG file!');
        }
        const sizeOK = file.size / 1024 / 1024 < 20;
        if (!sizeOK) {
            message.error('Kích thước không được quá 20 MB!');
        }
        let ok = isJpgOrPng && sizeOK;
        if (ok) {
            getBase64(file, imageUrl => {
                setImageUrl(imageUrl)
                setUploading(false)
            });
        }
        return ok
    }

    const [form] = Form.useForm();
    const closeModal = () => {
        onClose()
    }
    useEffect(async () => {
        // console.log(`initData`, initData)
        if (!table.id) {
            const { data } = await getTableById()
            setDatatable(data.datatable)
        } else {
            setDatatable(table)
        }
    }, [])


    const onFinish = async (values) => {
        // console.log(`initData`, initData)
        let changeData = false
        console.log('values', values)
        for (const [key, value] of Object.entries(values)) {
            let col = datatable.table_columns.findIndex(c => c.dataIndex === key)
            if (value?.file) {
                var f = await value.file.originFileObj
                const { data } = await upload({
                    variables: { file: f },
                })
                if (data.upload)
                    values[key] = data.upload.url
            }
            else {
                if (value?.color) {
                    values[key] = value.color
                }
                if (col?.type === 'number') values[key] = parseInt(value)
                // if (datatable.table_columns[col]?.format === 'price-vn' && values[key] < 1000) {
                //     values[key] = values[key] * 1000
                // }
            }
        }
        // console.log(`values2`, values)
        let newRow = LMerge({ ...initData }, values)
        let se = false
        let column_search = datatable.table_data?.column_search
        if (column_search) {
            column_search.forEach(s => {
                let key = s?.key
                let v = newRow[key]
                if (v) {
                    if (s.extra && (s.extra?.type == 'text' || !s.extra?.type)) {
                        if (s.extra.vn) v = removeVietnameseCharMark(v)
                        if (s.extra.lowercase) {
                            v = v.toLowerCase()
                        }
                    }
                    if (!se) se = v
                    else
                        se += " " + v
                }
            });
        }

        const newData = [...(rows || datatableStore.currentDatarows)]
        const index = newData.findIndex(item => initData.sid === item.sid)
        if (index > -1) {
            const item = newData[index]
            if (item.se) delete item.se
            changeData = getRevisionData(initData, values, datatable.id)
            if (changeData) {
                changeData.id = initData.key || initData.id
                if (se)
                    changeData.se = se
                newData.splice(index, 1, { ...item, ...newRow })
                if (!rows) datatableStore.setCurrentDatarows(newData)
                else rows = newData
            }
        }
        if (changeData) {
            var input = {
                data: {
                    type: "oldnew",
                    rows: [changeData],
                    table: datatable.id
                }
            }
            // message.loading({ content: 'đang lưu dữ liệu...', key: 'saving' });
            let r = await updateManyDataRows({
                variables: { input }
            })
            let result = r.data?.updateManyDataRows?.data
            let ar = [...(rows || datatableStore.currentDatarows)]

            if (result.status === 'OK' && result.changeId && (ar)) {
                ar.forEach(item => {
                    if (result.changeId[item.key]) {
                        item.key = result.changeId[item.key]
                    }
                });
                if (!rows)
                    datatableStore.setCurrentDatarows(ar)
                else
                    rows = ar
            }

            if (result.status === 'OK') {
                onSave({
                    status: 'OK',
                    data: ar
                })
                return
            }
        } else {
            onSave({
                status: 'WARNING',
                msg: 'nothing changed'
            })
            return
        }

        onSave({
            status: 'ERROR',
            msg: 'error'
        })
        // let sid = nanoid(10)
        // let row_data = values
        // row_data.sid = sid

        // for (const [key, value] of Object.entries(values)) {
        //   let col = datatable.table_columns.findIndex(c => c.dataIndex === key)

        //   if (value?.file) {
        //     var f = await value.file.originFileObj
        //     const { data } = await upload({
        //       variables: { file: f },
        //     })
        //     if (data.upload)
        //       values[key] = data.upload.url
        //   }
        //   else {
        //     if (value?.color) {
        //       values[key] = value.color
        //     }
        //     if (col?.type === 'number') values[key] = parseInt(value)
        //   }
        // }

        // // pre calc for search
        // let se = false
        // let column_search = datatable.table_data?.column_search
        // if (column_search) {
        //   column_search.forEach(s => {
        //     let key = s?.key
        //     let v = row_data[key]
        //     if (row_data[key] !== undefined) {
        //       if (s.extra) {
        //         if (s.extra.type === "stock_products") {
        //           v = ""
        //           Object.keys(row_data[key]).forEach(code => {
        //             v = v + " " + code
        //           })
        //         }
        //       }
        //       if (!se) se = v
        //       else
        //         se += " " + v
        //     }
        //   });
        // }

        // let date_column_index = datatable.table_columns.findIndex(c => c.dataIndex === 'ngay_tao')
        // if (date_column_index > -1) {
        //   row_data['ngay_tao'] = new Date()
        //   row_data['ngay_tao'] = row_data['ngay_tao'].toISOString()
        // }

        // const input = {
        //   data: {
        //     datatable: datatable.id,
        //     row_data: row_data,
        //     rid: datatable.id + '_' + sid
        //   },
        // }

        // if (se) input.data.se = removeVietnameseCharMark(se.toLowerCase())
        // var newRow = await updateDatarow({
        //   variables: { input },
        // })
        // setAdding(false)
        // if (newRow) {
        //   message.success(`Thêm mới ${dataTextName} thành công!`)
        //   onSave({
        //     status: 'OK',
        //     data: newRow.data?.createDatarow?.datarow
        //   })
        // } else {
        //   message.error(`Thêm mới ${dataTextName} không thành công!`);
        //   onSave({
        //     status: 'ERROR'
        //   })
        // }

        // // setRedirectLink('/thankyou')
    };

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 19,
        },
    };

    const uploadButton = (
        <div>
            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Tải lên</div>
        </div>
    );

    const TagsInput = ({ value = [], onChange, initValue, tagTable, tagFilter, selectMode = 'tags' }) => {
        // selectMode = 'multiple'
        // console.log(`initValue`, initValue)
        const [tags, setTags] = useState(value || [])
        const [options, setOptions] = useState(false)
        const triggerChange = (changedValue) => {
            // onChange?.({
            //     tags,
            //     ...value,
            //     ...changedValue,
            // });
            onChange?.(changedValue);
        };
        const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())
        const findOptionRow = async (initFilter, tableId) => {
            let dataInput = {
                tableId: tableId,
                hasPreSearch: true,
            }
            if (initFilter)
                dataInput.filter = initFilter
            // if (datatableStore.filterData.sort) dataInput.sort = datatableStore.filterData.sort
            // const { data, error } = await findRowsQuery({
            //     input: {
            //         data: dataInput
            //     }
            // })

            const { data, error } = await findRowsQuery({
                input: {
                    data: dataInput
                }
            })
            let results = data?.findDataRow?.data
            if (!results?.items) return false
            return flatMap(results.items, myFlat)
        }

        useEffect(() => {
            async function init() {
                if (tagTable) {
                    let rows = await findOptionRow(tagFilter, tagTable)
                    // console.log(`rows`, rows)
                    if (initValue) {
                        if (typeof initValue === 'string')
                            setTags(initValue.split('|'))
                        else
                            setTags(initValue)
                    }
                    setOptions(rows)
                }
            }
            init()
        }, [])


        const onChangeTag = (newValue) => {
            // console.log(`newValue`, newValue)
            setTags(newValue)
            triggerChange(newValue)
        }

        return (
            <Select mode={selectMode}
                size={'small'}
                style={{ width: '100%', minWidth: 100 }}
                placeholder={placeholder || 'chọn thẻ'}
                value={tags}
                onChange={onChangeTag}
            >
                {options ? (
                    options.map(row => {
                        return (
                            <Option key={row.key} value={row.name} >
                                <Badge color={row.color} text={row?.name} />
                            </Option>
                        )
                    })
                ) : ""}
            </Select>
        )

    }

    const InputItem = ({ item, dataIndex }) => {
        // console.log(`item`, item)
        const [selectedColor, setSelectedColor] = useState('white')
        const [showColor, setShowColor] = useState(false)
        let dataType = item.type ? item.type : 'text'
        switch (dataType) {
            case 'text':
                return <Form.Item
                    name={dataIndex}
                    label={item.title}
                    initialValue={initData[dataIndex] ? initData[dataIndex] : ""}
                >
                    <Input autocomplete="off" />
                </Form.Item>
            case 'number':
                return <Form.Item
                    name={dataIndex}
                    label={item.title}
                    initialValue={initData[dataIndex] ? initData[dataIndex] : 0}
                >
                    <InputNumber />
                </Form.Item>
            case 'boolean':
                return <Form.Item
                    name={dataIndex}
                    label={item.title}
                    initialValue={initData[dataIndex]}
                    // initialValue={true}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            case 'color':
                return <Form.Item
                    // initialValue={selectedColor}
                    name={dataIndex}
                    label={item.title}
                >
                    <ColorPicker
                        animation="slide-up"
                        color={selectedColor}
                        enableAlpha={false}
                        onChange={(color) => {
                            // console.log(`color`, color)
                            setSelectedColor(color.color)
                            // return { value: color.color }
                        }}
                    />
                </Form.Item>

            case 'tags':
                // let rows = await findOptionRow({ match: { "category": item?.extra?.tag_cate, "sub_cate": item?.extra?.tag_subcate } }, commonStore?.company?.companyData?.tag_table)
                // console.log(`rows`, rows)\
                // console.log('item?.extra?.select_mode', item?.extra?.select_mode)
                return <Form.Item
                    name={dataIndex}
                    label={item.title}
                    initialValue={initData[dataIndex] ? initData[dataIndex] : []}
                >
                    <TagsInput
                        // initValue={initData[dataIndex] ? initData[dataIndex] : []}
                        tagTable={commonStore?.company?.companyData?.tag_table}
                        tagFilter={{ match: { "category": item?.extra?.tag_cate, "sub_cate": item?.extra?.tag_subcate } }}
                        selectMode={item?.extra?.select_mode || 'tags'}
                    />
                </Form.Item>

            case 'image':
                // let imgUrl = imagePreset(imageUrl ? imageUrl : initData[dataIndex])
                if (initData[dataIndex] && !imageUrl) setImageUrl(initData[dataIndex])
                return <Form.Item
                    name={dataIndex}
                    label={item.title}
                >
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        // accept="image/x-png,image/jpeg"
                        accept="image/*"
                        // capture="environment"
                        // onChange={handleChange}
                        maxCount={1}
                    >
                        {imageUrl ? <img src={imagePreset(imageUrl, 'thumbnail')} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

            case 'shopline_pid':
                // if (usersStore.currentUser.company_role !== 'chu-cong-ty') return ''
                let sl_id = form.getFieldValue('sl_id') || initData['sl_id']
                return (
                    <>
                        <Form.Item
                            name={'sl_id'}
                            label={'Shopline ID'}
                            initialValue={initData['sl_id'] ? initData['sl_id'] : false}
                        >
                            {['chu-cong-ty', 'leader1', 'quan-ly'].includes(usersStore.currentUser.company_role) &&
                                <Button
                                    size={'small'}
                                    icon={<SyncOutlined />}
                                    onClick={() => {
                                        setShowSLSyncProduct(true)
                                    }}
                                >
                                    {sl_id || 'Đồng bộ shopline'}
                                </Button>
                            }
                            {sl_id &&
                                <span style={{ marginLeft: 10 }}>
                                    / <a href={`https://nhaiu.myshopline.com/admin//products/${sl_id}/edit`} target="_blank">link SLine</a>
                                </span>
                            }
                        </Form.Item>
                        <Form.Item
                            name={'sline'}
                            type={'hidden'}
                            initialValue={initData['sline'] ? initData['sline'] : false}
                        >
                        </Form.Item>
                    </>)



            case 'shopline_cate':
                let cate = form.getFieldValue('sl_cate')
                let s = []
                if (cate) s = cate.map(v => v.name)
                return <Form.Item
                    name={dataIndex}
                    label={item.title}
                    initialValue={initData[dataIndex] ? initData[dataIndex] : []}
                >
                    {s.join(',')}
                    {/* <TagsInput
                        tagTable={commonStore?.company?.companyData?.scate_table}
                        selectMode={'tags'}
                    /> */}
                </Form.Item>


            case 'pcollection':
                let collection = form.getFieldValue('pcollection')
                let cc = []
                if (collection) cc = collection.map(v => v.name)
                return <Form.Item
                    name={dataIndex}
                    label={item.title}
                    initialValue={initData[dataIndex] ? initData[dataIndex] : []}
                >

                    {cc.join(',')}
                    {/* <TagsInput
            tagTable={commonStore?.company?.companyData?.scate_table}
            selectMode={'tags'}
        /> */}
                </Form.Item>

            default:
                return ''
        }
    }

    const EditForm = () => {
        // let columns = datatable.table_columns ? datatable.table_columns : datatable.data_table_template.table_columns

        let scheme = datatable.table_scheme ? datatable.table_scheme : datatable.data_table_template.table_schema
        let attr = scheme?.attributes

        let quickAddColumn = datatable.table_data?.column_quick
        return (
            // <div className="orderEditForm" style={{ width: "100%" }}>
            <Form
                style={{ width: "100%" }}
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}>
                <Row gutter={[24, 16]}>
                    {
                        quickAddColumn.map(qac => {
                            return (
                                <Col span={24 / quickAddColumn.length} xs={24} sm={24} md={24} lg={24 / quickAddColumn.length}>
                                    {qac.map(col => {
                                        if (col.field) {
                                            if (attr[col.field])
                                                return <InputItem item={attr[col.field]} dataIndex={col.field} />
                                        }
                                        if (attr[col]) {
                                            return <InputItem item={attr[col]} dataIndex={col} />
                                        }
                                    })}
                                </Col>
                            )
                        }
                        )
                    }
                </Row>
            </Form >
            // </div>
        )
    }

    return (
        <>
            <Modal
                // style={{ width: "95%" }}
                title={title ? title : `Chỉnh sửa ${dataTextName}`}
                visible={true}
                width={"95%"}
                maskClosable={false}
                onCancel={closeModal}
                centered
                // footer={false}
                footer={[
                    <Button onClick={closeModal} disabled={adding}>
                        Hủy thay đổi
                    </Button>,
                    <Button key="submit" type="primary"
                        loading={adding}
                        onClick={() => {
                            setAdding(true)
                            form.submit()
                        }}>
                        Cập nhật
                    </Button>,
                ]}
            >
                {datatable ? <EditForm /> : ""}

                {/* {usersStore.currentUser.company_role === 'chu-cong-ty' && commonStore?.company?.companyData?.product_table === table.id &&
                    <Row gutter={[24, 16]}>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Form.Item
                                name={'sl_id'}
                                label={'Shopline ID'}
                                initialValue={initData['sl_id'] ? initData['sl_id'] : false}
                            >
                                <Button
                                    size={'small'}
                                    icon={<SyncOutlined />}
                                    onClick={() => {
                                        setShowSLSyncProduct(true)
                                    }}
                                >Import Shopline</Button>
                            </Form.Item>

                        </Col>
                    </Row>
                } */}
            </Modal>

            {showSLSyncProduct &&
                <RowsSearchModal
                    rowKey='sl_id'
                    selectType='radio'
                    dataType='sl_product'
                    columns={[
                        {
                            title: "Ảnh",
                            dataIndex: "img",
                            key: "img",
                            width: 50,
                            render: (value, row, index) => {
                                return <Image
                                    width={40}
                                    height={40}
                                    src={value}
                                    preview={{
                                        src: value,
                                    }} />
                            }
                        },
                        {
                            title: "Mã sp",
                            dataIndex: "code",
                            key: "code",
                            width: 80,
                            // render: (value, row, index) => {
                            //     if (row.id === initData['sl_id']) return <strong style={{ color: '#45b051' }}>{value}</strong>
                            //     return value
                            // }
                        }, {
                            title: "Giá",
                            dataIndex: "price",
                            key: "price",
                            width: 100,
                            render: (value, row, index) => {
                                return price_k(value, '?', 3)
                            }
                        }, {
                            title: "Giá live",
                            dataIndex: "price_live",
                            key: "price_live",
                            width: 100,
                            render: (value, row, index) => {
                                return price_k(value, '?', 3)
                            }
                        }, {
                            title: "SL",
                            dataIndex: "qty",
                            key: "qty",
                            width: 40,
                        }, {
                            title: "Tên sp",
                            dataIndex: "name",
                            key: "name",
                            width: 'auto',
                            render: (value, row, index) => {
                                if (row.sl_id === initData['sl_id']) return <strong style={{ color: '#45b051' }}>{value}</strong>
                                return value
                            }
                        },
                        // {
                        //     title: "Danh mục",
                        //     dataIndex: "categories",
                        //     key: "categories",
                        //     width: 100,
                        //     render: (value, row, index) => {
                        //         if (value.length > 0) {
                        //             let s = value.map(v => v.name)
                        //             return s.join(',')
                        //         }
                        //         return ''
                        //     }
                        // },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            key: "status",
                            width: 120,
                            render: (value, row, index) => {
                                let color = "gray"
                                let label = "nháp"
                                // console.log('value', value)
                                switch (value) {
                                    case 'active':
                                        color = '#4c9948'
                                        label = 'hoạt động'
                                        break;
                                    default:
                                        break;
                                }
                                return <Tag color={color}
                                    style={{ padding: "2px 8px" }}
                                >
                                    {label}
                                </Tag>
                                // return value === 'active' ? 'Công bố' : "Hủy"
                            }
                        }, {
                            title: "Ngày sửa",
                            dataIndex: "ngay_sua",
                            key: "ngay_sua",
                            width: 150,
                            render: (value, row, index) => {
                                return moment(value).format('DD/MM/YY HH:mm')
                            },
                            defaultSortOrder: 'descend',
                            sorter: (a, b) => new Date(a.ngay_sua) - new Date(b.ngay_sua)
                        }
                    ]}
                    okLabel="Chọn dữ liệu đúng"
                    filterData={[
                        {
                            type: 'text',
                            key: 'handle',
                            defaultValue: "",
                            placeholder: "Mã"
                        }
                    ]}
                    onClose={() => {
                        setShowSLSyncProduct(false)
                    }}
                    onOk={
                        async (items) => {
                            if (items[0] && items[0].sl_id) {
                                form.setFieldsValue({
                                    sl_id: items[0].sl_id,
                                    price_live: items[0].price_live,
                                    price: items[0].price,
                                    sl_qty: items[0].qty,
                                    sl_va_id: items[0].sl_va_id,
                                    sline: items[0].va
                                });
                                // initData['sl_id'] = items[0].id
                            }
                            setShowSLSyncProduct(false)
                        }
                    }
                    findItems={getShoplineProducts}
                    defaultValue={
                        {
                            handle: initData.code
                            // sl_id: initData.sl_id
                        }
                    }
                    rowKeyValue={initData.sl_id}
                    extraData={initData}
                // extraButton={[
                //     <Button>hihi</Button>
                // ]}
                >
                </RowsSearchModal>}
        </>
    )
}
export default inject("usersStore", "datatableStore", "commonStore")(observer(DataEditItem))