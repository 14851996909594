import React, { useEffect, useState, useMemo } from 'react'
// import { Redirect } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Button,
    // Input,
    // Table,
    // Empty,
    Select,
    Badge
} from 'antd';

import {
    EllipsisOutlined
} from '@ant-design/icons'


// import { autorun, set, toJS } from 'mobx'
// import { debounce } from "lodash-es";
import { flatMap, values } from "lodash-es"
import { contrastColor } from 'contrast-color'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import useAsyncQuery from "../../../../ultils/useAsyncQuery";
// import { toJS } from 'mobx';
// import { fn } from 'moment';

import { removeVietnameseCharMark } from '../../../../ultils/datatableUtils'
const { Option } = Select;

const CustomSelect = styled(Select)`
min-width: ${props => props.width}px;
background-color:${props => props.color} !important;
border-color:${props => props.color};
.ant-select-selector {
  background-color:${props => props.color} !important;
  color:${props => contrastColor({ bgColor: props.color })};
}
`;


const DatarowSelectInput = ({
    // commonStore, datatableStore,
    preloadKey,
    tableId,
    initFilter,
    columns,
    limit,
    width = 150,
    initValue,
    viewType = "select",
    emptyLabel = '--tất cả--',
    defaultEmpty,
    placeholder,
    optionType,
    onOk,
    onSelect,
    rows,
    matchField = 'key',
    hasNewItem = false,
    advance = false,
    labelKey = 'name',
    size = 'small',
    // cannotRemove={}
}) => {
    // const [matchItems, setMatchItems] = useState([])
    // const [inputValue, setInputValue] = useState("")

    const [currentColor, setCurrentColor] = useState("white")
    const [options, setOptions] = useState(rows)
    const [inputValue, setInputValue] = useState(false)
    const [oldTags, setOldTags] = useState([])

    const [allOptions, setAllOptions] = useState(rows)
    // const [currentText, setCurrentText] = useState("")
    function myFlat(v) {
        return Object.assign(v.row_data, { key: v.id })
    }

    const findRowsQuery = useAsyncQuery(DataTableServices.FIND_ROW_BY_FILTER())

    // const findRows = useAsyncQuery(DataTableServices.FIND_TABLE_ROW())
    // const findTableRows = async () => {
    //     let where = {
    //         datatable: tableId
    //     }
    //     const { data, error } = await findRows({ where: where })
    //     const results = data ? data.datarowsConnection : ''
    //     const values = results?.values || ''
    //     if (values && values.length > 0) {
    //         return flatMap(values, myFlat)
    //     }
    //     return false
    // }

    const findOptionRow = async () => {
        let dataInput = {
            tableId: tableId,
            hasPreSearch: true,
        }
        if (initFilter)
            dataInput.filter = initFilter
        // if (datatableStore.filterData.sort) dataInput.sort = datatableStore.filterData.sort
        const { data, error } = await findRowsQuery({
            input: {
                data: dataInput
            }
        })
        let results = data?.findDataRow?.data
        if (!results?.items) return false
        return flatMap(results.items, myFlat)
    }

    // useEffect(async () => {
    //   if (!rows && tableId) {
    //     rows = await findTableRows()
    //     setOptions(rows)
    //   }
    //   if (liveStore.currentLiveOrder.status) {
    //     setInputValue(liveStore.currentLiveOrder.status.ref?.key)
    //     setCurrentColor(liveStore.currentLiveOrder.status.ref.color)
    //     // doSearch(initValue)
    //   }
    // }, [liveStore.currentLiveOrder.status])

    useEffect(async () => {
        if (!rows && tableId) {
            rows = await findOptionRow()
            rows.forEach(r => {
                if (r.name) {
                    r.name_vn = removeVietnameseCharMark(r.name)
                } else r.name_vn = ''
            });
            setAllOptions(rows)
            if (viewType === 'select') {
                if (initValue === '-') setInputValue(defaultEmpty)
                else {
                    let v = rows.find(item => item[matchField] === initValue)
                    if (v) setInputValue(v)
                }
            } else {
                if (initValue) {
                    let v = initValue
                    if (typeof initValue === 'string')
                        v = initValue.split('|')
                    let o = v.filter(vv => !rows.find(item => item.name === vv))
                    v = v.filter(vv => rows.find(item => item.name === vv))
                    setOldTags(o)
                    setInputValue(v)
                }
            }
            setOptions(rows)
        }
    }, [])

    const renderSelected = (value) => {
        if (value.color && value[labelKey] !== defaultEmpty[labelKey])
            return (
                <Badge color={value.color} text={value[labelKey]} />
            )
        else {
            return value?.name
        }
    }

    function onChange(value) {
        if (value && value != inputValue) {
            let selectedItem = options.find(item => item[matchField] === value)
            if (selectedItem) {
                if (selectedItem.color)
                    setCurrentColor(selectedItem.color)
                setInputValue(selectedItem[matchField])
                onOk(selectedItem)
            } else {
                setInputValue(defaultEmpty.id)
                onOk({
                    key: defaultEmpty?.id ? defaultEmpty.id : '-'
                })
            }
        }
    }

    function onChangeTag(values) {
        // console.log(`value`, values)
        setInputValue(values)
        // console.log(`initValue`, initValue)

        onOk(oldTags.concat(values))
    }

    if (viewType === 'select') {
        return (
            <>
                {inputValue != undefined ?
                    <CustomSelect
                        width={width}
                        size={'small'}
                        color={currentColor}
                        onChange={onChange}
                        // defaultValue={'-'}
                        value={renderSelected(inputValue)}
                    >
                        <Option key={defaultEmpty.id} value={defaultEmpty.id} style={{ background: 'white', color: "black" }}>
                            {emptyLabel ? emptyLabel : "--chưa chọn--"}
                        </Option>
                        {options ? (
                            options.map(row => {
                                return (
                                    <Option key={row.key} value={row[matchField]} style={{ background: row.color, color: contrastColor({ bgColor: row.color }) }}>
                                        {row[labelKey]}
                                    </Option>
                                )
                            })
                        ) : ""}
                    </CustomSelect>
                    : ""}
            </>
        )
    }

    if (viewType === 'tag') {
        return (
            <>
                {options &&
                    <Select mode="multiple"
                        // autoClearSearchValue={true}
                        // allowClear={true}
                        size={size}
                        width={width}
                        style={{ width: width, minWidth: 110 }}
                        placeholder={placeholder || 'chọn thẻ'}
                        dropdownMatchSelectWidth={false}
                        value={inputValue || []}
                        onChange={onChangeTag}
                        filterOption={(v, opt) => {
                            let vn = removeVietnameseCharMark(v)
                            if (vn) vn = vn.toLowerCase()
                            if (vn === '') return true
                            let name_vn = removeVietnameseCharMark(opt.value)
                            if (name_vn) name_vn = name_vn.toLowerCase()
                            return name_vn.includes(vn)
                        }}
                    >
                        {options ? (
                            options.map(row => {
                                return (
                                    <Option key={row.key} value={row.name} >
                                        <Badge color={row.color} text={row?.name} />
                                    </Option>
                                )
                            })
                        ) : ""}
                    </Select>
                }

                {advance && <Button icon={<EllipsisOutlined />} size="small" />}
            </>
        )
    }

}

// export default inject("usersStore", "commonStore", "liveStore", "datatableStore")(observer(DatarowSelectInput))
export default DatarowSelectInput
// export default SearchBoxItem