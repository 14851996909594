import React, { useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { inject, observer } from 'mobx-react'
import {
    Dropdown,
    Menu
} from 'antd'
import {
    SyncOutlined,
    PlusOutlined
} from '@ant-design/icons'
// import moment from 'moment'
// import { flatMap, merge as LMerge } from 'lodash-es'
// import { autorun, set, toJS } from 'mobx'

// import { nanoid } from 'nanoid'

// import { buildFilterByParam, updateFilterParam } from '../../../../ultils/datatableUtils'
import DefaultLayout from '../../../layouts/DefaultLayout'
import DataTableServices from '../../../../services/datatableServices'
import styled from 'styled-components'
import DataRowOrder from './DataRowOrder'
import DataRowPCollection from './DataRowPCollection'
import useAsyncQuery from '../../../../ultils/useAsyncQuery'

const TopBarContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`
// const defaultLimit = 50

const DataRow = ({ usersStore, commonStore, datatableStore }) => {
    let history = useHistory();

    const [rData, setRData] = useState(false)

    const { id } = useParams()
    const location = useLocation();

    const getRow = useAsyncQuery(DataTableServices.GET_ROW_BY_ID(id))

    useEffect(() => {

        async function init() {
            let r = await getRow()
            if (r && r.data?.datarow) {
                // r.data.datarow.se =
                setRData(r.data.datarow)
            }
            // console.log(`r`, r)
        }


        init()
        // console.log(`id change`, id)
        return () => {

        }
    }, [id])

    const RowDetailByType = ({ rowData }) => {
        // console.log(`data`, rowData)
        if (rowData?.datatable.type === 'order') {
            return (
                <DataRowOrder orderData={rowData} />
            )
        }

        if (rowData?.datatable.type === 'pcollection') {
            return (
                <DataRowPCollection rowData={rowData} />
            )
        }

        return 'hihi'
    }

    return (
        <DefaultLayout>
            <>
                {rData ?
                    <RowDetailByType rowData={rData} />
                    :
                    ""
                }
            </>
        </DefaultLayout>
    )
}

export default inject(
    'usersStore',
    'commonStore',
    'datatableStore'
)(observer(DataRow))
