//Libraries
import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Form, Input, Button, notification } from 'antd'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import Cookies from 'js-cookie'
import { Redirect, Link } from 'react-router-dom'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
//Actions & services
import { ConnectionServices } from '../../../../services'
// import { loginSuccess, getBooth, getInfoBooth } from '../../../../actions'
import Image from './images/logo.png'
import Background from './images/background_login.svg'
import { useAsyncQuery, extractObjectKey } from '../../../../ultils'

import socketClient from '../../../../plugins/socket'
import { DefaultLayout } from '../../../layouts/'

const WrapperLogin = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
`
const FormWrapper = styled(Form)`
  max-width: calc(100vw - 40px);
  width: 450px;
  height: auto;
  background: #fff;
  padding: 30px;
  position: absolute; 
  left: 50%;
  top: 30%;
  transform: translate(-50%,-50%);
  box-shadow:  0px 2px 9px 3px rgba(86, 86, 86, 0.15);
`
const Heading = styled.h2`
  text-align: center;
  margin: 0 0 20px 0;
  color: #6f6f6f;
  font-weight: 300
`
const ButtonWrapp = styled(Form.Item)`
  text-align: center;
  width: 100%
`

const LoginLogo = styled.img`
  margin: auto;
  margin-bottom: 20px;
  height: 70px;
`
const LogoWrapper = styled.div`
  width:100%;
  text-align:center;
`

const Login = (props) => {
  let {
    usersStore,
    history
  } = props
  const [isLoading, setIsLoading] = useState(false)

  const [login] = useMutation(ConnectionServices.LOGIN(), { errorPolicy: 'all' })

  const onFinish = async (values) => {
    // setIsLoading(true)
    const inputValues = {
      identifier: values.username,
      password: values.password,
      portal: 'cms',
      company_slug: 'nhaiu'
    }
    try {
      const { data } = await login({
        variables: { input: inputValues },
      })
      let user = data.login.user
      let role = user.role.type
      switch (role) {
        case 'authenticated':
          await Cookies.set('access_token', data.login.jwt, { expires: 2 })
          usersStore.setCurrentUser(user, data.login.jwt)
          history.push('/')
          // return <Redirect to={{ pathname: '/dashboard' }} />
          break
        case 'company_user':
          await Cookies.set('access_token', data.login.jwt, { expires: 2 })
          // console.log('user', user)
          usersStore.setCurrentUser(user, data.login.jwt)
          // let socket = socketClient(data.login.jwt)
          // global.socket = socket
          // socket.emit("changeStage",{id:"hihi",msg:"botya"})
          // window.socket = socket
          history.push('/')
          break
        default:
          throw 'Unrecognized account!'
      }

      // setIsLoading(false)
    } catch (e) {
      let extractedCode = extractObjectKey(e, 'code')
      if (!extractedCode) {
        notification.error({
          message: 'Login failed',
          description: e,
          duration: 3
        })
      }
      // setIsLoading(false)
    }
  }

  let token = Cookies.get('access_token')

  if (token) {
    return <Redirect to={{ pathname: '/dashboard' }} />
  } else {
    return (
      <DefaultLayout>
        {usersStore.currentUser ? <Redirect to={{ pathname: '/dashboard' }} /> : ''}
        <WrapperLogin>
          <FormWrapper
            name='basic'
            onFinish={onFinish}>
            {/* <LogoWrapper>
              <LoginLogo src={Image} />
            </LogoWrapper> */}
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}>
              <Input prefix={<UserOutlined />} size='large' placeholder='Username' />
            </Form.Item>
            <Form.Item
              name='password'
              placeholder='Password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}>
              <Input.Password prefix={<LockOutlined />} size='large' placeholder='Password' />
            </Form.Item >
            <ButtonWrapp style={{ marginBottom: '15px' }}>
              <Button type='primary' htmlType='submit' loading={isLoading} block size='large' >
                Login
              </Button>
            </ButtonWrapp>
            <Link style={{ fontStyle: 'italic' }} to='/forgot-password' >Forgot Password?</Link>
          </FormWrapper>
        </WrapperLogin >
      </DefaultLayout>
    )
  }
}

export default inject('usersStore')(observer(Login))